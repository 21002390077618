import React, {type FC, memo, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {push} from 'store/router';
import {type AppState, type UnitInstance} from 'store/interface';
import {
  grammarPath,
  unitInstancePagePath,
  unitInstancePath,
  unitInstancePreviewPath,
  type UnitInstancePreviewUrlParams,
  type UnitInstanceUrlParams
} from 'common/paths';

import UnitInstancesBreadcrumbView from '../breadcrumbs/components/UnitInstancesBreadcrumb';
import {toggleUnitInstancesDropdown} from '../pages/UnitInstancePage/actions';

type Params = UnitInstanceUrlParams &
  UnitInstancePreviewUrlParams & {
    grammarId?: string;
  };

interface Props {
  unitInstances: UnitInstance[];
}

export const UnitInstancesBreadcrumb: FC<Props> = memo(({unitInstances}) => {
  const dispatch = useDispatch();
  const {unitInstanceId, grammarId, coursebookInstanceId, courseId, studentTeacherId} =
    useParams<Params>();

  const lastUnitPages = useSelector((state: AppState) => state.classroom?.lastUnitPages);
  const selectedUnitInstanceId = unitInstanceId ? Number(unitInstanceId) : undefined;
  const dropdownIsOpen = useSelector(
    (state: AppState) =>
      state.classroom!.courseInstanceState.coursebookInstanceState.unitInstancesDropdownOpen
  );
  const isStudent = useSelector((state: AppState) => state.user.role === 'student');
  const hasGrammar = useSelector((state: AppState) => state.classroom?.hasGrammar);

  const toggleDropdown = useCallback(
    (show: boolean) => dispatch(toggleUnitInstancesDropdown(show)),
    [dispatch]
  );

  const goToUnitInstance = useCallback(
    (id: number) => {
      const lastUnitPage = lastUnitPages?.[id];
      dispatch(
        push(
          lastUnitPage
            ? unitInstancePagePath(
                {coursebookInstanceId: coursebookInstanceId!, courseId, studentTeacherId},
                id,
                lastUnitPage
              )
            : unitInstancePath(
                {coursebookInstanceId: coursebookInstanceId!, courseId, studentTeacherId},
                id
              )
        )
      );
    },
    [courseId, coursebookInstanceId, dispatch, lastUnitPages, studentTeacherId]
  );

  const goToUnitPreview = useCallback(
    (id: number) =>
      dispatch(
        push(
          unitInstancePreviewPath(
            {coursebookInstanceId: coursebookInstanceId!, courseId, studentTeacherId},
            id
          )
        )
      ),
    [courseId, coursebookInstanceId, dispatch, studentTeacherId]
  );

  const goToGrammarPlayer = useCallback(
    () =>
      dispatch(
        push(grammarPath({coursebookInstanceId: coursebookInstanceId!, courseId, studentTeacherId}))
      ),
    [courseId, coursebookInstanceId, dispatch, studentTeacherId]
  );

  return (
    <UnitInstancesBreadcrumbView
      selectedUnitInstanceId={selectedUnitInstanceId}
      dropdownIsOpen={dropdownIsOpen}
      toggleDropdown={toggleDropdown}
      unitInstances={isStudent ? unitInstances.filter(ui => ui.active) : unitInstances}
      goToUnitInstance={goToUnitInstance}
      goToUnitPreview={goToUnitPreview}
      goToGrammarPlayer={goToGrammarPlayer}
      isInGrammarPlayer={!!grammarId}
      hasGrammar={hasGrammar}
    />
  );
});
