import React, {type FC, useCallback, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from 'react-spinkit';
import {type AxiosAction} from 'redux-axios-middleware';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';
import ModalError from 'components/ModalError';
import {type AppState} from 'store/interface';
import {type ClassroomUrlParams} from 'common/paths';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {
  getRecentDocuments,
  getRecentSounds,
  publishMaterialsUpdated
} from '../../../actions/action';
import {libraryModalClosed, requestAddCourseDocuments, requestDocumentLibraryTree} from './actions';
import {addFilesMessages, LIBRARY_SELECT_LIMIT} from './constants';
import LibraryTree from './LibraryTree';
import './library-modal.scss';

const noop = () => ({});

export const LibraryModal: FC = () => {
  const dispatch = useAxiosDispatch();
  const intl = useIntl();

  const {courseId} = useParams<ClassroomUrlParams>();

  const [search, setSearch] = useState('');
  const [index, setIndex] = useState<number | undefined>();

  const show = useSelector((state: AppState) => state.library.libraryModalOpened!);
  const libraryLoading = useSelector((state: AppState) => state.library.library === undefined);
  const filesSelected = useSelector((state: AppState) =>
    state.library.librarySelected ? Object.keys(state.library.librarySelected).length : 0
  );
  const audioSelected = useSelector((state: AppState) =>
    state.library.librarySelected
      ? Object.keys(state.library.librarySelected).reduce((audioCount: number, key: string) => {
          return audioCount + state.library.librarySelected![key];
        }, 0)
      : 0
  );
  const selected = useSelector((state: AppState) =>
    state.library.librarySelected ? state.library.librarySelected : {}
  );
  const librarySubmitting = useSelector((state: AppState) =>
    state.library.librarySubmitting ? state.library.librarySubmitting : false
  );
  const libraryError = useSelector((state: AppState) =>
    state.library.libraryError ? state.library.libraryError : false
  );

  const closeModal = useCallback(() => dispatch(libraryModalClosed()), [dispatch]);
  const addDocuments = useCallback(
    (courseInstanceId: number, docs: string[]) =>
      dispatch(requestAddCourseDocuments(courseInstanceId, docs)),
    [dispatch]
  );
  const loadLibrary = useCallback(
    (courseInstanceId: number) =>
      dispatch(requestDocumentLibraryTree(courseInstanceId) as AxiosAction),
    [dispatch]
  );
  const loadRecentSounds = useCallback(() => dispatch(getRecentSounds()), [dispatch]);
  const loadRecentDocuments = useCallback(() => dispatch(getRecentDocuments()), [dispatch]);
  const dispatchPublishMaterialsUpdated = useCallback(
    (courseInstanceId: number) => dispatch(publishMaterialsUpdated(courseInstanceId)),
    [dispatch]
  );

  const setSearchState = useCallback((search: string) => {
    setSearch(search);
    setIndex(undefined);
  }, []);

  const onSubmit = useCallback(() => {
    const courseInstanceId = Number(courseId);
    addDocuments(courseInstanceId, Object.keys(selected))
      .then(() => {
        toastr.success('', intl.formatMessage(addFilesMessages.successText));
        loadRecentDocuments();
        loadRecentSounds();
        dispatchPublishMaterialsUpdated(courseInstanceId);
      })
      .catch(e => {
        // TODO: review catch needless
        toastr.error('', intl.formatMessage(addFilesMessages.errorText));
      });
  }, [
    addDocuments,
    courseId,
    dispatchPublishMaterialsUpdated,
    intl,
    loadRecentDocuments,
    loadRecentSounds,
    selected
  ]);

  const isSubmitDisabled = libraryLoading || librarySubmitting || filesSelected <= 0;

  const onEnter = useCallback(() => {
    loadLibrary(Number(courseId));
  }, [courseId, loadLibrary]);

  return (
    <Modal
      show={show}
      backdrop="static"
      className={`cloud-docs-library-modal`}
      onHide={noop}
      onEnter={onEnter}
    >
      <Modal.Header>
        <Modal.Title>
          <span>
            <Icon className="title-icon" name="pc-book" tag="i" />
            <FormattedMessage id="LibraryModal.ModalTitle" />
          </span>
          <a onClick={closeModal}>
            <Icon name="pc-close" tag="i" />
          </a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!libraryLoading || libraryError ? (
          libraryError ? (
            <ModalError
              reload={() => loadLibrary(Number(courseId))}
              errorText={<FormattedMessage id="LibraryError.Title" />}
              errorTitle={<FormattedMessage id="LibraryError.Description" />}
            />
          ) : (
            <LibraryTree
              search={search}
              index={index}
              setSearch={setSearchState}
              setIndex={setIndex}
            />
          )
        ) : (
          <Spinner name="three-bounce" className="center-block brand-primary" fadeIn="none" />
        )}
        {librarySubmitting ? <SimpleLoadingOverlay /> : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-footer-text">
          <FormattedMessage
            id="LibraryModal.SelectedFiles"
            values={{
              selectedCount: filesSelected,
              limit: LIBRARY_SELECT_LIMIT
            }}
          />
          {audioSelected ? (
            <FormattedMessage
              id="LibraryModal.AudiosAdded"
              values={{
                selectedCount: audioSelected
              }}
            />
          ) : null}
        </div>
        <div className="buttons">
          <Button
            bsStyle="default"
            className="btn-transparent"
            bsSize="sm"
            disabled={librarySubmitting}
            onClick={closeModal}
          >
            <FormattedMessage id="Common.Cancel" />
          </Button>
          <Button bsStyle="primary" bsSize="sm" onClick={onSubmit} disabled={isSubmitDisabled}>
            <FormattedMessage id="LibraryModal.SubmitButton" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
