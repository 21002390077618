import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import {getSearchResultsNumber} from '../../utils';
import {changePlaybackRate, changePlayStatus, changeTimestamp, playSound} from '../actions/action';
import AudioTab from '../components/AudioTab';
import {type AudioTabDispatchProps, type AudioTabStateProps} from './interface';

type AudioTabStateToProps = (state: AppState) => AudioTabStateProps;

function getFileId(state: AppState) {
  if (state.sounds?.sounds && state.sounds?.uniquePlaybackId) {
    const [uniquePlaybackId] = state.sounds.uniquePlaybackId.split('-');
    return state.sounds!.sounds[uniquePlaybackId]?.id;
  }

  if (state.sounds?.sounds && state.sounds?.activeSound) {
    const activeSound = state.sounds.sounds[state.sounds.activeSound];
    return activeSound?.id;
  }

  return undefined;
}

const mapStateToProps: AudioTabStateToProps = state => {
  let hasNewSounds: boolean = false;
  for (const sound in state.sounds!.sounds!) {
    if (state.sounds!.sounds[sound].isNew) {
      hasNewSounds = true;
      break;
    }
  }
  let searchResultsNumber: number | undefined;
  if (state.filesWorkSpace!.filesFilter.length > 0 && state.sounds!.sounds) {
    searchResultsNumber = getSearchResultsNumber(
      state.sounds!.sounds,
      state.filesWorkSpace!.filesFilter
    );
  }

  const {uniquePlaybackId} = state.sounds!;

  const fileId = getFileId(state);

  return {
    hasNewSounds,
    searchResultsNumber,
    uniquePlaybackId,
    fileId,
    timestamp: state.sounds!.timestamp || 0,
    playStatus: state.sounds!.playStatus,
    playbackRate: state.sounds!.playbackRate,
    activeSound: state.sounds!.sounds
      ? state.sounds!.sounds[state.sounds!.activeSound!]
      : undefined,
    role: state.user.role,
    collapsed: state.filesWorkSpace!.searchBarOpen,
    isMobile: state.layout.isMobile
  } as AudioTabStateProps;
};

const mapDispatchToProps: AudioTabDispatchProps = {
  changePlayStatus,
  changeTimestamp,
  changePlaybackRate,
  playSound
};

const AudioTabContainer = connect<AudioTabStateProps, AudioTabDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(AudioTab);

export default AudioTabContainer;
