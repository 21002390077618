import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import Scrollbar from 'react-custom-scrollbars';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import Icon from 'components/Icon';
import PageControls from 'components/PageControls';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import {type ClassroomUrlParams} from 'common/paths';

import CourseInstanceInfo from './CourseInstanceInfo';
import Cards from './Cards';
import AddCoursebookModal from './AddCoursebookModal';
import DisplayButton from '../../components/DisplayButton';

import './CourseInstancePage.scss';

const CourseInstancePage: React.FC = () => {
  const {coursebookInstanceList, courseInstanceOverview: overview} = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState
  );
  const params = useParams<ClassroomUrlParams>();
  const {courseId, studentTeacherId} = params;
  const activeCourseInstance = useSelector((s: AppState) => s.classroom?.courses?.[courseId!])!;
  const coursePartner = useSelector(
    (s: AppState) => s.studentTeachers?.studentTeachers?.[studentTeacherId!]
  )?.recipient!;
  const isStudent = useSelector((state: AppState) => state.user.role === 'student');
  const isAllowedToAddCoursebooks = useSelector<AppState, boolean>(
    state => !!state.settings?.teacher?.vircBookAccess
  );
  const [modalOpened, toggleModal] = useState<boolean>(false);
  const canOpenTogether = useCanPlayTogether({studentTeacherId});
  const showModal = useCallback(() => toggleModal(true), []);
  const hideModal = useCallback(() => toggleModal(false), []);

  return (
    <div className="course-instance-page">
      <Scrollbar>
        <div className="course-instance-page-content">
          <CourseInstanceInfo courseInstance={activeCourseInstance} coursePartner={coursePartner} />
          <Cards
            courseInstanceIsActive={!activeCourseInstance.deleted_at}
            coursebookInstanceList={coursebookInstanceList!}
            urlParams={params}
            overview={overview!}
          />
        </div>
      </Scrollbar>

      <PageControls>
        {isStudent || !isAllowedToAddCoursebooks || activeCourseInstance.deleted_at ? null : (
          <>
            <Button bsStyle="success" className="add-coursebook-button" onClick={showModal}>
              <Icon name="plus" />
              <FormattedMessage id="LessonPage.AddCoursebook" />
            </Button>
            <AddCoursebookModal
              isModalShown={modalOpened}
              hideModal={hideModal}
              courseId={Number(courseId)}
              alreadyAdded={coursebookInstanceList!}
            />
          </>
        )}
        {canOpenTogether && <DisplayButton />}
      </PageControls>
    </div>
  );
};

export default CourseInstancePage;
