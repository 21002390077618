import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {push} from 'store/router';
import {type AppState, type HomeworkWithContents} from 'store/interface';
import {type AxiosRequestError} from 'services/axios/interface';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import PageControls from 'components/PageControls';
import {ContentsPopover} from 'components/UnitContents/Popover';
import {useContentsList} from 'components/UnitContents/useContenstList';
import {Pager} from 'components/UnitContents/Pager';
import SendHomeworkNotificationModal from 'components/Homework/Modals/SendHomeworkNotificationModal';
import {DictionaryContextProvider} from 'components/Dictionary/shared/contexts';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import {usePagesPopoverSync} from 'hooks/player/usePagesPopoverSync';
import {setIncorrectUrlId} from 'common/action';
import {homeworkPlayerPath} from 'common/paths';

import {type HomeworkPlayerUrlParams} from '../HomeworkPage/interface';
import {clearHomeworkPlayer, loadHomework, setSelectedHomework} from './actions';
import HomeworkExercise from './HomeworkExercise';
import HomeworkDocument from './HomeworkDocument';
import HomeworkDraftControl from '../../components/HomeworkDraftControl/HomeworkDraftControl';
import ActivatedHomeworkControl from './ActivatedHomeworkControl';
import HomeworkDocCommentViewer from '../../components/HomeworkDocCommentViewer';
import DocumentControls from '../../components/HomeworkDraftControl/DocumentControls';
import ExerciseControls from '../../components/HomeworkDraftControl/ExerciseControls';
import EditHomeworkDocCommentPopover from '../FilesPage/EditHomeworkDocCommentPopover';
import {useEditorViewerSync} from '../../utils';
import DisplayButton from '../../components/DisplayButton';
import HomeworkDocumentPage from './HomeworkDocumentPage';
import {DocumentPageControls} from '../../components/HomeworkDraftControl/DocumentPageControls';
import EditHomeworkDocPageCommentPopover from '../FilesPage/EditHomeworkDocPageCommentPopover';

import './HomeworkPlayerPage.scss';

const HomeworkPlayerPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams<HomeworkPlayerUrlParams>();
  const {courseId, homeworkId, page = 1, studentTeacherId} = params;
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);

  const isStudent = user.role === 'student';
  const isTeacher = user.role === 'teacher';

  const [notificationHomeworkId, setNotificationHomeworkId] = useState<string | undefined>(
    undefined
  );

  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  const {
    commentEditorJustClosed,
    setCommentEditorJustClosed,
    editorCloseRequested,
    viewerCloseRequested,
    tellCommentEditorJustClosed,
    tellCommentViewerJustClosed,
    closeCommentViewer,
    closeCommentEditor
  } = useEditorViewerSync();

  const openChangeHomeworkStatusModal = useCallback(
    () => setNotificationHomeworkId(homeworkId),
    [homeworkId]
  );
  const hideChangeHomeworkStatusModal = useCallback(() => setNotificationHomeworkId(undefined), []);

  const activateCallback = useCallback(
    () => navigate(`/room/${studentTeacherId}/course/${courseId}/homework`, {replace: true}),
    [navigate, studentTeacherId, courseId]
  );

  const {selectedHomework} = useSelector(
    (appState: AppState) => appState.classroom!.courseInstanceState.homework.homeworkPlayer
  );

  const pageCount =
    (selectedHomework?.documents.length || 0) +
    (selectedHomework?.exercises.length || 0) +
    (selectedHomework?.documentPages.length || 0);
  const pageNumber = Number(page);
  const {hasNext, hasPrev, listIsOpened, toggleList} = useContentsList(pageCount, pageNumber);
  usePagesPopoverSync(listIsOpened);
  const selectPage = useCallback(
    (page: number) =>
      dispatch(push(homeworkPlayerPath(params, params.homeworkId!, page === 1 ? undefined : page))),
    [dispatch, params]
  );

  const action = useMemo(() => loadHomework(courseId!, homeworkId!), [courseId, homeworkId]);

  useEffect(
    () => () => {
      dispatch(clearHomeworkPlayer());
    },
    [dispatch, homeworkId]
  );

  const {isError, reload} = useApiRequest(
    action,
    (respData: HomeworkWithContents) => dispatch(setSelectedHomework(respData)),
    (e: AxiosRequestError) => {
      if (e.error.response && e.error.response.status === 404) {
        dispatch(setIncorrectUrlId());
      }
    }
  );
  if (isError) {
    return <WampErrorMask reload={reload} />;
  }

  if (!selectedHomework) {
    return <Loader />;
  }
  const {documents, exercises, documentPages} = selectedHomework;
  if (pageNumber > pageCount) {
    return (
      <Navigate replace={true} to={homeworkPlayerPath(params, params.homeworkId!, pageCount)} />
    );
  }
  if (pageNumber < 0) {
    return <Navigate replace={true} to={homeworkPlayerPath(params, params.homeworkId!)} />;
  }

  const pageIsDocument = Number(page) > exercises.length + documentPages.length;
  const pageIsDocumentPage = Number(page) > exercises.length;
  const selectedDoc = pageIsDocument
    ? documents[Number(page) - exercises.length - documentPages.length - 1]
    : undefined;
  const selectedDocPage = pageIsDocumentPage
    ? documentPages[Number(page) - exercises.length - 1]
    : undefined;
  const selectedExercise = selectedDoc || selectedDocPage ? undefined : exercises[Number(page) - 1];
  const isViewingDraft = !selectedHomework.activatedAt;

  const comment = selectedDocPage?.comment || selectedDoc?.comment;

  const isLastPage = pageNumber === pageCount;

  return (
    <DictionaryContextProvider isTeacherDictionary={isTeacher} dictionaryOwnerId={user.id!}>
      <div className="homework-player-page">
        {selectedDoc ? (
          <HomeworkDocument documentId={selectedDoc.documentInstanceId} />
        ) : selectedDocPage ? (
          <HomeworkDocumentPage documentInstancePageId={selectedDocPage.documentInstancePageId} />
        ) : (
          <HomeworkExercise exerciseId={exercises[Number(page) - 1].exerciseInstanceId} />
        )}
        <PageControls>
          <ContentsPopover
            currentPage={pageNumber}
            listIsOpened={listIsOpened}
            pageCount={pageCount}
            selectPage={selectPage}
            toggleList={toggleList}
          />
          <Pager
            batchListToggle={() => {}}
            currentPage={pageNumber}
            hasNext={hasNext}
            hasPrev={hasPrev}
            listIsOpened={listIsOpened}
            pageCount={pageCount}
            selectPage={selectPage}
            toggleList={toggleList}
          />
          {selectedDocPage ? (
            <EditHomeworkDocPageCommentPopover
              courseId={Number(courseId)}
              pages={documentPages}
              homeworkPlayerActiveHomework={isViewingDraft ? undefined : homeworkId}
              // sync with viewer props
              closeCommentViewer={closeCommentViewer}
              closeRequested={editorCloseRequested}
              tellCommentEditorJustClosed={tellCommentEditorJustClosed}
            />
          ) : selectedDoc ? (
            <EditHomeworkDocCommentPopover
              courseId={Number(courseId)}
              homeworkDocumentsList={documents}
              homeworkPlayerActiveHomework={isViewingDraft ? undefined : homeworkId}
              // sync with viewer props
              closeCommentViewer={closeCommentViewer}
              closeRequested={editorCloseRequested}
              tellCommentEditorJustClosed={tellCommentEditorJustClosed}
            />
          ) : null}
          {isViewingDraft ? (
            <HomeworkDraftControl
              isViewingDraft={true}
              renderActivateButton={isLastPage}
              activateCallback={activateCallback}
            >
              {selectedDocPage ? (
                <DocumentPageControls
                  documentInstanceId={selectedDocPage.documentInstancePage?.documentInstanceId}
                  pageNumber={selectedDocPage.documentInstancePage?.pageNumber}
                  courseId={Number(courseId)}
                  studentTeacherId={Number(studentTeacherId)}
                  pages={documentPages}
                  homeworkId={homeworkId}
                  hasViewInDocumentButton={true}
                />
              ) : selectedDoc ? (
                <DocumentControls
                  documentInstanceId={selectedDoc.documentInstanceId}
                  courseId={Number(courseId)}
                  homeworkDocumentsList={documents}
                  homeworkId={homeworkId}
                />
              ) : (
                <ExerciseControls exerciseId={selectedExercise!.exerciseInstanceId} />
              )}
            </HomeworkDraftControl>
          ) : (
            <ActivatedHomeworkControl
              homework={selectedHomework}
              courseId={Number(courseId)}
              openChangeHomeworkStatusModal={openChangeHomeworkStatusModal}
            >
              {selectedDocPage ? (
                <DocumentPageControls
                  documentInstanceId={selectedDocPage.documentInstancePage?.documentInstanceId}
                  pageNumber={selectedDocPage.documentInstancePage?.pageNumber}
                  courseId={Number(courseId)}
                  studentTeacherId={Number(studentTeacherId)}
                  pages={documentPages}
                  homeworkId={homeworkId}
                  hasViewInDocumentButton={true}
                />
              ) : selectedDoc ? (
                isStudent ? null : (
                  <DocumentControls
                    documentInstanceId={selectedDoc.documentInstanceId}
                    courseId={Number(courseId)}
                    homeworkDocumentsList={documents}
                    homeworkId={homeworkId}
                  />
                )
              ) : (
                <ExerciseControls exerciseId={selectedExercise!.exerciseInstanceId} />
              )}
            </ActivatedHomeworkControl>
          )}
          {comment && (
            <HomeworkDocCommentViewer
              comment={comment}
              // sync with editor props
              closeCommentEditor={closeCommentEditor}
              closeRequested={viewerCloseRequested}
              commentEditorJustClosed={commentEditorJustClosed}
              setCommentEditorJustClosed={setCommentEditorJustClosed}
              tellCommentViewerJustClosed={tellCommentViewerJustClosed}
            />
          )}
          {canOpenTogether && <DisplayButton />}
        </PageControls>
        <SendHomeworkNotificationModal
          courseInstanceId={Number(courseId)}
          homeworkId={notificationHomeworkId}
          onHide={hideChangeHomeworkStatusModal}
          studentTeacherId={studentTeacherId}
        />
      </div>
    </DictionaryContextProvider>
  );
};

export default HomeworkPlayerPage;
