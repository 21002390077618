import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import WampErrorMask from 'components/WampErrorMask';
import NoFilesComponent from 'components/NoFiles';
import {toLocalDate} from 'services/common-methods';
import {type Documents, type MaterialFile, type Sounds, type UploadingFile} from 'store/interface';

import preventFilterRegexpErrors from '../../../../../helpers/toValidFilter';
import FileUploadPanel from '../uploadingFiles/components/FileUploadPanel';
import FilesByDateComponent from './FilesByDate';
import {type FilesByDate, type FilesListProps} from './interface';
import PinnedFiles from './PinnedFiles';
import SearchFailed from './SearchFailed';

import './fileslist.scss';

class FilesList extends React.PureComponent<FilesListProps, {}> {
  public render() {
    const filter = preventFilterRegexpErrors(this.props.filter);
    const groupedFiles: FilesByDate = this.groupFilesByDate(this.props.files, filter);
    const pinnedFiles = this.getPinnedFiles(groupedFiles);
    this.addCurrentUploads(groupedFiles, filter);
    const listIsEmpty: boolean = this.props.listIsEmpty || Object.keys(groupedFiles).length === 0;
    const className: string = `lesson-files-list ${listIsEmpty ? 'no-dates-column' : ''}`;
    return (
      <div className={className}>
        {this.renderErrorMask()}
        {!listIsEmpty ? <div className="vertical-line" /> : null}
        <Scrollbars autoHide={true}>
          <table>
            <tbody>
              {this.renderFileUploadPanel()}
              {this.renderContent(groupedFiles, pinnedFiles as MaterialFile[][])}
            </tbody>
          </table>
          {!listIsEmpty ? <div className="flexbox-helper" /> : null}
        </Scrollbars>
      </div>
    );
  }

  private renderContent = (groupedFiles: FilesByDate, pinnedFiles: MaterialFile[][]) => {
    if (this.props.listIsEmpty) {
      return this.tableWrapper(
        <NoFilesComponent readonly={this.props.readonly} role={this.props.role} />
      );
    }
    if (Object.keys(groupedFiles).length === 0) {
      return this.tableWrapper(<SearchFailed filter={this.props.filter} />);
    }
    return (
      <React.Fragment>
        {Object.keys(groupedFiles)
          .reverse()
          .map((date: string) => this.renderFilesByDate(date, groupedFiles[date], true))}
        <PinnedFiles
          files={pinnedFiles}
          readonly={this.props.readonly}
          fileType={this.props.fileType}
          courseId={this.props.courseId}
          removeFileFromUploads={this.props.removeFileFromUploads}
          publishMaterialsOpen={this.props.publishMaterialsOpen}
          publishMaterialsUpdated={this.props.publishMaterialsUpdated}
          selectDoc={this.props.selectDocument}
        />
        {Object.keys(groupedFiles)
          .reverse()
          .map((date: string) => this.renderFilesByDate(date, groupedFiles[date]))}
      </React.Fragment>
    );
  };

  private renderFilesByDate = (
    date: string,
    files: Array<MaterialFile | UploadingFile>,
    errorBlock?: true
  ) => (
    <FilesByDateComponent
      locale={this.props.locale}
      date={date}
      files={files}
      readonly={this.props.readonly}
      fileType={this.props.fileType}
      courseId={this.props.courseId}
      removeFileFromUploads={this.props.removeFileFromUploads}
      publishMaterialsOpen={this.props.publishMaterialsOpen}
      publishMaterialsUpdated={this.props.publishMaterialsUpdated}
      key={date}
      errorBlock={errorBlock}
      selectDocument={this.props.selectDocument}
    />
  );

  private getPinnedFiles = (groupedFiles: FilesByDate) =>
    Object.keys(groupedFiles)
      .map((date: string) => groupedFiles[date].filter((file: MaterialFile) => file.pinned))
      .filter(files => files.length);

  private groupFilesByDate = (files: Sounds | Documents, filter: string) => {
    const groupedFiles: FilesByDate = {};
    for (const id in files) {
      if (files[id].title.toLowerCase().search(filter) !== -1) {
        const dateString: string = toLocalDate(
          files[id].created_at,
          this.props.userTimezone
        ).toDateString();
        if (!groupedFiles[dateString]) {
          groupedFiles[dateString] = [];
        }
        groupedFiles[dateString].push(files[id] as MaterialFile);
      }
    }
    return groupedFiles;
  };

  private addCurrentUploads = (groupedFiles: FilesByDate, filter: string) => {
    const [thisDate, {uploadingFiles}] = [new Date().toDateString(), this.props];
    for (const id in uploadingFiles) {
      if (
        uploadingFiles[id].title.toLowerCase().search(filter) !== -1 &&
        uploadingFiles[id].course_instance_id.toString() === this.props.courseId
      ) {
        if (!groupedFiles[thisDate]) {
          groupedFiles[thisDate] = [];
        }
        groupedFiles[thisDate].unshift(uploadingFiles[id]);
      }
    }
  };

  private renderErrorMask = () => {
    if (this.props.networkError) {
      return <WampErrorMask reload={this.props.reloadFiles} />;
    }
    return null;
  };

  private renderFileUploadPanel = () => {
    if (!this.props.readonly && this.props.role === 'teacher') {
      return (
        <tr className="upload-panel-tr">
          <td />
          <td>
            <FileUploadPanel
              fileType={this.props.fileType}
              courseId={Number(this.props.courseId)}
              studentTeacherId={this.props.studentTeacherId}
            />
          </td>
        </tr>
      );
    }
    return null;
  };

  private tableWrapper = (el: JSX.Element) => {
    return (
      <tr className="no-files-tr">
        <td />
        <td>{el}</td>
      </tr>
    );
  };
}

export default FilesList;
