import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {dropdownDropupCheckCreator} from 'helpers/dropdownDropupCheck';
import {FileExtension} from 'common/enums';

import {type FileEditDropdownDispatchProps, type FileEditDropdownOwnProps} from './index';

const messages = defineMessages({
  ActionsDropdownMessage: {
    id: 'File.Actions'
  },
  DeleteSuccessToast: {
    id: 'File.DeleteSuccessToast'
  },
  DeleteErrorToast: {
    id: 'File.DeleteErrorToast'
  },
  DeleteConfirm: {
    id: 'File.DeleteConfirm'
  },
  DownloadFile: {
    id: 'File.Download'
  }
});

interface FileEditDropdownProps extends FileEditDropdownDispatchProps, FileEditDropdownOwnProps {}

interface State {
  dropdownIsOpen: boolean;
  dropup: boolean;
}

type Props = FileEditDropdownProps & WrappedComponentProps;

class FileEditDropdown extends React.Component<Props, State> {
  private static dropdownHeight: number = 120;

  public state: State = {
    dropdownIsOpen: false,
    dropup: false
  };

  private dropdownDropupCheck = dropdownDropupCheckCreator(
    (dropup: boolean) => this.setState({dropup}),
    FileEditDropdown.dropdownHeight
  );

  public render() {
    const {
      fileIsBeingEdited,
      fileIsActive,
      readonly,
      deleteFileAwait,
      role,
      fileType,
      thisFile: {type: fileExt, url: fileUrl}
    } = this.props;
    if (
      !!fileUrl &&
      (fileType === 'audio' || fileExt === FileExtension.pdf) &&
      (readonly || (!fileIsBeingEdited && !fileIsActive && !deleteFileAwait && role === 'student'))
    ) {
      return this.renderDownloadButton();
    }
    if (!fileIsBeingEdited && !fileIsActive && !deleteFileAwait && role === 'teacher') {
      return this.renderEditDropdown();
    }
    return null;
  }

  private renderEditDropdown = () => {
    const [{intl, deleteFileAwait, thisFile, fileType}, {dropdownIsOpen, dropup}] = [
      this.props,
      this.state
    ];
    const editFileIconClass = classNames({
      'edit-file-icon': true,
      deactivated: deleteFileAwait
    });
    return (
      <DropdownButton
        className="edit-file-dropdown"
        id="edit-file-dropdown"
        title={
          <Icon
            name="ellipsis-h"
            className={editFileIconClass}
            title={intl.formatMessage(messages.ActionsDropdownMessage)}
          />
        }
        dropup={dropup}
        open={dropdownIsOpen}
        onClick={this.dropdownDropupCheck}
        onToggle={this.toggleDropdown}
      >
        <MenuItem onClick={this.handleEditClick}>
          <FormattedMessage id="Common.Rename" />
        </MenuItem>
        <MenuItem onClick={this.handleDeleteClick}>
          <FormattedMessage id="Common.Delete" />
        </MenuItem>
        {!!thisFile.url && (fileType === 'audio' || thisFile.type === FileExtension.pdf) && (
          <MenuItem onClick={this.handleDownloadClick} href={thisFile.url} target="_blank">
            <FormattedMessage id="File.Download" />
          </MenuItem>
        )}
      </DropdownButton>
    );
  };

  private handleEditClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.editIconClickHandler();
  };

  private handleDeleteClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    const {
      intl,
      thisFile,
      thisFileId,
      deleteFileAwait,
      deleteFile,
      publishMaterialsUpdated,
      setFileDeleting
    } = this.props;
    if (!deleteFileAwait && this.confirmDeleting()) {
      setFileDeleting(true);
      return deleteFile(thisFileId)
        .then(() => {
          publishMaterialsUpdated(thisFile.course_instance_id);
          toastr.success('', intl.formatMessage(messages.DeleteSuccessToast));
        })
        .catch(() => {
          toastr.error('', intl.formatMessage(messages.DeleteErrorToast));
          setFileDeleting(false);
        });
    }
    return null;
  };

  private handleDownloadClick = (e: React.MouseEvent<{}, MouseEvent>) => e.stopPropagation();

  private toggleDropdown = (dropdownState?: boolean) => {
    this.setState({
      dropdownIsOpen: dropdownState !== undefined ? dropdownState : !this.state.dropdownIsOpen
    });
  };

  private renderDownloadButton = () => {
    const {
      intl,
      deleteFileAwait,
      thisFile: {url: fileUrl}
    } = this.props;
    const downloadFileIconClass = classNames({
      'download-file-icon': true,
      deactivated: deleteFileAwait
    });
    return (
      <Button
        onClick={e => {
          e.stopPropagation();
        }}
        className="download-file-button"
        href={fileUrl}
        target="_blank"
        title={intl.formatMessage(messages.DownloadFile)}
      >
        <Icon className={downloadFileIconClass} name="download" />
      </Button>
    );
  };

  private confirmDeleting = () => {
    const {intl, thisFile} = this.props;
    return import.meta.env.MODE === 'test'
      ? true
      : window.confirm(
          intl.formatMessage(messages.DeleteConfirm, {
            soundName: thisFile.title
          })
        );
  };
}

export default injectIntl(FileEditDropdown);
