import {type FC} from 'react';
import {type PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';
import classNames from 'classnames';

import {type Item} from './interface';
import {getOutlinePageIndex} from './util';

interface Props {
  pdf: PDFDocumentProxy;
  items: Item[];
  goToIndex: (i: number) => void;
}

interface ItemProps {
  pdf: PDFDocumentProxy;
  item: Item;
  goToIndex: (i: number) => void;
}

const OutlineItem: FC<ItemProps> = ({pdf, item, goToIndex}) => {
  return (
    <li>
      <a
        className={classNames()}
        onClick={async () => {
          const index = await getOutlinePageIndex(pdf, item);
          goToIndex(index);
        }}
      >
        {item.title}
      </a>
      {!!item.items.length && <OutlineItems pdf={pdf} items={item.items} goToIndex={goToIndex} />}
    </li>
  );
};

export const OutlineItems: FC<Props> = ({pdf, items, goToIndex}) => {
  return (
    <ul>
      {items.map((item, itemIndex) => {
        return (
          <OutlineItem
            pdf={pdf}
            key={typeof item.dest === 'string' ? item.dest : itemIndex}
            item={item}
            goToIndex={goToIndex}
          />
        );
      })}
    </ul>
  );
};
