import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {changeBufferedEventName, changeCurrentTimeEventName, emitter} from 'services/event-emitter';
import Throttle from 'common/Throttle';
import {type ReactMoTEvent} from 'components/media/interface';
import {
  getPageX,
  handleMove,
  handleProgressClick,
  MoTEventPreHandler
} from 'components/media/utils';
import {ActionsTogether} from 'common/enums';

import {
  type ActionTogetherProps,
  AudioTogetherContext
} from '../../../context/AudioTogetherContext';
import {PAUSE, PLAYING} from '../../actions/interface';

import {
  type AudioSliderDispatchProps,
  type AudioSliderOwnProps,
  type AudioSliderStateProps
} from './index';

interface AudioProgressBarProps
  extends AudioSliderStateProps,
    AudioSliderDispatchProps,
    AudioSliderOwnProps {}

interface AudioProgressBarState {
  mouseDown: boolean;
  currentTime: number;
  buffered: number;
}

export default class AudioSlider extends PureComponent<
  AudioProgressBarProps,
  AudioProgressBarState
> {
  static contextType = AudioTogetherContext;

  private schedulePlay: boolean;
  private percentage: number = 100;
  private progress: HTMLDivElement;
  private throttle = new Throttle();

  public declare context: ActionTogetherProps;

  constructor(props: AudioProgressBarProps) {
    super(props);
    this.state = {
      mouseDown: false,
      currentTime: 0,
      buffered: 0
    };
  }

  public componentDidMount() {
    emitter.addListener(changeBufferedEventName, this.receiveBufferedTime);
    emitter.addListener(changeCurrentTimeEventName, this.receiveTime);
  }

  public componentWillUnmount() {
    emitter.removeListener(changeBufferedEventName, this.receiveBufferedTime);
    emitter.removeListener(changeCurrentTimeEventName, this.receiveTime);

    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }

  public render() {
    const {isActive, activeSound, isTogether} = this.props;
    const {currentTime, buffered, mouseDown} = this.state;

    return activeSound ? (
      <div
        ref={this.getRef}
        className={classNames('progress', {active: isActive})}
        onClick={this.progressClickHandler}
        onTouchStart={this.touchStartHandler}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.pointerUpHandler}
      >
        <div
          className={classNames('progress-amount', {active: isActive})}
          style={{
            width: (currentTime / activeSound.length) * this.percentage + '%'
          }}
        >
          <div
            className={classNames('progress-drag-circle', {active: isActive, together: isTogether})}
            onMouseDown={this.circlePointerDownHandler}
          />
        </div>
        <div
          className={classNames('buffered-amount', {active: isActive})}
          style={{width: buffered * this.percentage + '%'}}
        />
        <div
          className={classNames('scroll-helper', {'mouse-down': mouseDown})}
          onClick={this.progressClickHandler}
        />
      </div>
    ) : null;
  }

  private getRef = (progress: HTMLDivElement) => {
    this.progress = progress;
  };

  private changeTimestamp(timestamp: number) {
    if (!this.state.mouseDown && this.context.isTogether && this.props.fieldId) {
      return this.context.actionTogether(ActionsTogether.ChangeTimestamp, {
        fileId: this.props.fieldId,
        uniquePlaybackId: this.props.uniquePlaybackId,
        playStatus: this.props.playStatus,
        timestamp
      });
    }

    this.props.changeTimestamp(timestamp);
  }

  private setValue = (value: number) => {
    const {activeSound} = this.props;

    if (activeSound) {
      this.changeTimestamp(
        parseFloat(
          (value > activeSound.length ? activeSound.length : value < 0 ? 0 : value).toFixed(6)
        )
      );
    }
  };

  private circlePointerDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    const {isTouchEvent} = MoTEventPreHandler(e);

    if (!isTouchEvent) {
      this.setState({mouseDown: true});

      document.addEventListener('mousemove', this.mouseMoveHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
    }
  };

  private mouseUpHandler = () => {
    this.pointerUpHandler();

    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);

    this.setState({mouseDown: false});
    this.setValue(this.state.currentTime);
  };

  private onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    this.moveHandler(e);
  };

  private mouseMoveHandler = (e: MouseEvent) => {
    this.moveHandler(e);
  };

  private moveHandler = (e: MouseEvent | ReactMoTEvent) => {
    const {pageX} = MoTEventPreHandler(e, true);

    const {activeSound, changePlayStatus, playStatus} = this.props;

    if (!this.schedulePlay && playStatus !== PAUSE) {
      this.schedulePlay = true;
      changePlayStatus(PAUSE);
    }

    if (activeSound) {
      this.throttle.throttleAction(() =>
        handleMove(pageX, activeSound.length, this.progress, this.setValue)
      );
    }
  };

  private pointerUpHandler = () => {
    if (this.schedulePlay) {
      this.schedulePlay = false;
      this.props.changePlayStatus(PLAYING);
      this.setValue(this.state.currentTime);
    }
  };

  private progressClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.activeSound) {
      handleProgressClick(e, this.props.activeSound.length, this.progress, this.setValue);
    }
  };

  private touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (this.props.activeSound) {
      const {left, width} = this.progress.getBoundingClientRect();
      this.setValue(((getPageX(e) - left) / width) * this.props.activeSound.length);
    }
  };

  private receiveBufferedTime = (buffered: number) => {
    if (buffered !== this.state.buffered) {
      this.setState(prevState => ({...prevState, buffered}));
    }
  };

  private receiveTime = (currentTime: number) => {
    if (currentTime !== this.state.currentTime) {
      this.setState(prevState => ({...prevState, currentTime}));
    }
  };
}
