import {type Action} from 'redux';
import {type AxiosAction} from 'redux-axios-middleware';

import {type AxiosRequestAction} from 'services/axios/interface';

import {
  CLOUD_DOCUMENT_EXIST,
  FILE_SELECTED,
  type LibraryItem,
  MODAL_CLOSED,
  MODAL_OPENED,
  PREVIEW_LIBRARY_PDF,
  type PreviewLibraryPDFCreator,
  REQUEST_ADD,
  STORE_SCROLL_POSITION,
  type StoreScrollTopPositionAction,
  TREE_COLLAPSE_ALL,
  TREE_COLLAPSE_NODE,
  TREE_REQUEST
} from './interface';

export const libraryModalOpened: () => Action = () => {
  return {
    type: MODAL_OPENED
  };
};

export const libraryCollapseNode: (nodeKey: string) => Action = nodeKey => {
  return {
    type: TREE_COLLAPSE_NODE,
    key: nodeKey
  };
};

export const libraryCollapseAll: () => Action = () => {
  return {
    type: TREE_COLLAPSE_ALL
  };
};

export const libraryModalClosed: () => Action = () => {
  return {
    type: MODAL_CLOSED
  };
};

export const requestAddCourseDocuments: (id: number, docs: string[]) => AxiosAction = (
  id,
  docs
) => {
  return {
    type: REQUEST_ADD,
    payload: {
      request: {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        url: `/v1/classroom/course-instance/${id}/library`,
        data: {docs}
      }
    }
  } as AxiosAction;
};

export const requestDocumentLibraryTree: (id: number) => Action = id => {
  return {
    type: TREE_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: `/v1/classroom/course-instance/${id}/library`,
        params: {tree: true}
      }
    }
  };
};

export const libraryFileSelected: (id: string, audios: number) => Action = (id, audios) => {
  return {
    type: FILE_SELECTED,
    id,
    audios
  };
};

export const previewLibraryPDF: PreviewLibraryPDFCreator = (item: LibraryItem | null) => ({
  type: PREVIEW_LIBRARY_PDF,
  item
});

export const cloudLibraryDocumentExist = (md5: string): AxiosRequestAction => ({
  type: CLOUD_DOCUMENT_EXIST,
  payload: {
    request: {
      url: `/v1/classroom/document/exist?md5=${md5}`,
      method: 'post'
    }
  }
});

export const storeScrollTopPosition = (
  scrollTopPosition?: number
): StoreScrollTopPositionAction => ({
  type: STORE_SCROLL_POSITION,
  scrollTopPosition
});
