import React, {type FC} from 'react';

import {CardsTitle} from 'components/Cards/CardsTitle';

import './UnitsList.scss';

interface Props {
  heading?: JSX.Element;
}

const UnitsList: FC<Props> = ({heading, children}) => {
  return (
    <div className="detailed-cb-info-units-list">
      {!heading ? null : <CardsTitle>{heading}</CardsTitle>}
      <div className="grid">{children}</div>
    </div>
  );
};

export default UnitsList;
