import React, {PureComponent} from 'react';
import classNames from 'classnames';

import {type CourseInstance} from 'store/interface';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';

import CoursesBreadcrumbDropdown from './CoursesDropdown';

interface CourseBreadcrumbProps {
  courses: {[id: string]: CourseInstance};
  selectCourse: (id: number) => void;
  activeCourseId: string;
  toggleDropdown: (show: boolean) => void;
  shouldDisableDropdown?: boolean;
  dropdownIsOpen?: boolean;
  isLast: boolean;
}

export default class CourseBreadcrumb extends PureComponent<CourseBreadcrumbProps, {}> {
  public render() {
    const {
      activeCourseId,
      dropdownIsOpen,
      courses,
      shouldDisableDropdown,
      toggleDropdown,
      selectCourse,
      isLast
    } = this.props;
    const activeElement: CourseInstance | undefined = courses[activeCourseId];
    const className: string = classNames(
      {pointer: this.shouldRenderDropdown || !isLast},
      {active: dropdownIsOpen}
    );

    const title = activeElement?.course?.name;
    const icon = activeElement ? 'pc-book' : undefined;

    return (
      <Breadcrumb
        title={title}
        icon={icon}
        className={className}
        accessibilityTitle={title}
        onClick={this.breadcrumbClickHandler}
      >
        {this.shouldRenderDropdown && (
          <CoursesBreadcrumbDropdown
            elements={courses}
            shouldDisableDropdown={shouldDisableDropdown}
            dropdownIsOpen={dropdownIsOpen}
            toggleDropdown={toggleDropdown}
            handleElementClick={selectCourse}
            selectedCourseId={activeCourseId}
          />
        )}
      </Breadcrumb>
    );
  }

  private breadcrumbClickHandler = () => {
    const {activeCourseId, dropdownIsOpen, isLast, selectCourse, toggleDropdown} = this.props;
    if (!dropdownIsOpen && this.shouldRenderDropdown) {
      toggleDropdown(true);
      return;
    }
    if (!isLast && !this.shouldRenderDropdown) {
      selectCourse(Number(activeCourseId));
    }
  };

  private get shouldRenderDropdown() {
    return Object.keys(this.props.courses).length > 1;
  }
}
