import {type Action} from 'redux';

import {type CoursebookInstanceState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  ACTIVATE_UNIT_INSTANCE_EVENT,
  CLEAR_SELECTED_COURSEBOOK_INSTANCE,
  CLEAR_UNIT_INSTANCES,
  COMPLETE_UNIT_INSTANCE_EVENT,
  DEACTIVATE_UNIT_INSTANCE_EVENT,
  SELECTED_CBI_INCREMENT_COMPLETED_UNITS,
  SET_SELECTED_COURSEBOOK_INSTANCE,
  SET_UNIT_INSTANCES,
  TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK,
  UNIT_INSTANCE_DECREMENT_COMPLETED_EXERCISES,
  UNIT_INSTANCE_INCREMENT_COMPLETED_EXERCISES
} from '../pages/CoursebookInstancePage/actionTypes';
import {
  type CompletedUnitEventAction,
  type IncrementCBICompletedUnitsAction,
  type SetSelectedCoursebookInstanceAction,
  type SetSelectedCoursebookInstanceSelfCheckEnabledAction,
  type SetUnitInstancesAction,
  type UnitEventAction,
  type UnitInstanceAction
} from '../pages/CoursebookInstancePage/actions';
import {TOGGLE_UNIT_INSTANCES_DROPDOWN} from '../pages/UnitInstancePage/actionTypes';
import {type ToggleElementAction} from '../../../common/interface';
import {type SetRedirectedFromUrlAction} from '../../../common/action';
import {CLEAR_REDIRECTED_FROM_URL, SET_REDIRECTED_FROM_URL} from '../../../common/actionTypes';

const changeExercisesCompleted =
  (step: 1 | -1) =>
  (state: CoursebookInstanceState, {unitInstanceId}: UnitInstanceAction) => ({
    ...state,
    unitInstances:
      state.unitInstances &&
      state.unitInstances.map(ui =>
        ui.id !== unitInstanceId
          ? ui
          : {
              ...ui,
              overview: {
                ...ui.overview,
                mainExercisesCompleted: ui.overview.mainExercisesCompleted + step
              }
            }
      )
  });

const ACTION_HANDLERS: ActionHandlersList<CoursebookInstanceState, Action> = {
  [SET_UNIT_INSTANCES]: (
    state: CoursebookInstanceState,
    action: SetUnitInstancesAction
  ): CoursebookInstanceState => ({
    ...state,
    unitInstances: action.instances
  }),
  [TOGGLE_UNIT_INSTANCES_DROPDOWN]: (
    state: CoursebookInstanceState,
    action: ToggleElementAction
  ): CoursebookInstanceState => ({
    ...state,
    unitInstancesDropdownOpen: action.show || undefined
  }),
  [CLEAR_UNIT_INSTANCES]: (state: CoursebookInstanceState): CoursebookInstanceState => ({
    ...state,
    unitInstances: undefined
  }),
  [ACTIVATE_UNIT_INSTANCE_EVENT]: (state: CoursebookInstanceState, {unitId}: UnitEventAction) =>
    state.unitInstances
      ? {
          ...state,
          unitInstances: state.unitInstances.map(ui =>
            ui.id === unitId ? {...ui, active: true} : ui
          )
        }
      : state,
  [COMPLETE_UNIT_INSTANCE_EVENT]: (
    state: CoursebookInstanceState,
    {unitId, completedAt}: CompletedUnitEventAction
  ) =>
    state.unitInstances
      ? {
          ...state,
          unitInstances: state.unitInstances.map(ui =>
            ui.id === unitId ? {...ui, completedAt} : ui
          )
        }
      : state,
  [DEACTIVATE_UNIT_INSTANCE_EVENT]: (state: CoursebookInstanceState, {unitId}: UnitEventAction) =>
    state.unitInstances
      ? {
          ...state,
          unitInstances: state.unitInstances.map(ui =>
            ui.id === unitId ? {...ui, active: false} : ui
          )
        }
      : state,
  [UNIT_INSTANCE_INCREMENT_COMPLETED_EXERCISES]: changeExercisesCompleted(1),
  [UNIT_INSTANCE_DECREMENT_COMPLETED_EXERCISES]: changeExercisesCompleted(-1),
  [SET_SELECTED_COURSEBOOK_INSTANCE]: (
    state: CoursebookInstanceState,
    action: SetSelectedCoursebookInstanceAction
  ): CoursebookInstanceState => ({...state, selectedCoursebookInstance: action.instance}),
  [TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK]: (
    state: CoursebookInstanceState,
    action: SetSelectedCoursebookInstanceSelfCheckEnabledAction
  ): CoursebookInstanceState => {
    return {
      ...state,
      selectedCoursebookInstance: state.selectedCoursebookInstance && {
        ...state.selectedCoursebookInstance,
        selfCheckEnabled: action.selfCheckEnabled
      }
    };
  },
  [SELECTED_CBI_INCREMENT_COMPLETED_UNITS]: (
    state: CoursebookInstanceState,
    {isTest}: IncrementCBICompletedUnitsAction
  ): CoursebookInstanceState => ({
    ...state,
    selectedCoursebookInstance: state.selectedCoursebookInstance && {
      ...state.selectedCoursebookInstance,
      overview: {
        ...state.selectedCoursebookInstance.overview!,
        unitsCompleted: isTest
          ? state.selectedCoursebookInstance.overview!.unitsCompleted
          : state.selectedCoursebookInstance.overview!.unitsCompleted + 1,
        testsCompleted: isTest
          ? state.selectedCoursebookInstance.overview!.testsCompleted + 1
          : state.selectedCoursebookInstance.overview!.testsCompleted
      }
    }
  }),
  [CLEAR_SELECTED_COURSEBOOK_INSTANCE]: (
    state: CoursebookInstanceState
  ): CoursebookInstanceState => ({...state, selectedCoursebookInstance: undefined}),
  [SET_REDIRECTED_FROM_URL]: (
    state: CoursebookInstanceState,
    {url, fromWhere}: SetRedirectedFromUrlAction
  ): CoursebookInstanceState => ({
    ...state,
    redirectedFromUrl: {url, fromWhere}
  }),
  [CLEAR_REDIRECTED_FROM_URL]: (state: CoursebookInstanceState): CoursebookInstanceState => ({
    ...state,
    redirectedFromUrl: undefined
  })
};

export const initialCoursebookInstanceState: CoursebookInstanceState = {};

export default function (action: Action) {
  return ACTION_HANDLERS[action.type] || undefined;
}
