import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {type AppState, type Homework} from 'store/interface';
import {HomeworkStatus} from 'common/enums';

import Icon from '../../../../components/Icon';
import i18n from '../../../../components/Homework/i18n';
import Confirm from '../../../../components/modals/Confirm';
import useSendApiRequest from '../../../../hooks/rest/useSendApiRequest';
import {deleteHomework, reactivateHomework} from '../../../../components/Homework/actions';
import Spinner from '../../../../components/Spinner';

import './ActivatedHomeworkControl.scss';

const messages = defineMessages({
  TurnIn: {
    id: 'Homework.TurnIn'
  },
  Checked: {
    id: 'Homework.CheckedButtonControl'
  },
  Completed: {
    id: 'Homework.Completed'
  },
  Reopen: {
    id: 'Homework.Reopen'
  }
});

interface OwnProps {
  homework: Homework;
  courseId: number;
  openChangeHomeworkStatusModal: () => void;
  showChangeHWStatusControls?: boolean;
}

type Props = OwnProps & WrappedComponentProps;

const ActivatedHomeworkControl: React.FC<Props> = ({
  homework,
  intl: {formatMessage},
  courseId,
  openChangeHomeworkStatusModal,
  children,
  showChangeHWStatusControls
}) => {
  //todo: homework status controls are not used anywhere now, but I did not remove them from the project since they are likely to return;
  //todo: although they might be removed if users are satisfied by the way it is now;

  const [isShowingCompleteConfirm, setShowingCompleteConfirm] = useState<boolean>();

  const hideCompleteConfirm = useCallback(() => setShowingCompleteConfirm(false), []);
  const showCompleteConfirm = useCallback(() => setShowingCompleteConfirm(true), []);

  const [isShowingRestoreConfirm, setShowingRestoreConfirm] = useState<boolean>();
  const hideRestoreConfirm = useCallback(() => setShowingRestoreConfirm(false), []);
  const showRestoreConfirm = useCallback(() => setShowingRestoreConfirm(true), []);

  const {role} = useSelector((state: AppState) => state.user);
  const isStudent = role === 'student';
  const className = 'activated-homework-control';

  const {call: complete, isLoading: completing} = useSendApiRequest(
    deleteHomework,
    [courseId, homework.id],
    () => {
      setShowingCompleteConfirm(false);
      toastr.success('', formatMessage(i18n.homeworkCompleteSuccess));
    },
    () => {
      setShowingCompleteConfirm(false);
      toastr.error('', formatMessage(i18n.homeworkCompleteFail));
    }
  );

  const {call: reactivate, isLoading: reactivating} = useSendApiRequest(
    reactivateHomework,
    [courseId, homework.id],
    () => {
      setShowingRestoreConfirm(false);
      toastr.success('', formatMessage(i18n.homeworkReactivationSuccess));
    },
    () => {
      setShowingRestoreConfirm(false);
      toastr.error('', formatMessage(i18n.homeworkReactivationFail));
    }
  );

  if (isStudent) {
    if (homework.completedAt) {
      return children ? <ButtonGroup className={className}>{children}</ButtonGroup> : null;
    }
    return (
      <ButtonGroup className={className}>
        <Button className="btn-transparent" onClick={openChangeHomeworkStatusModal}>
          <Icon name="virc-homework" />
          <span>{formatMessage(messages.TurnIn)}</span>
        </Button>
        {children}
      </ButtonGroup>
    );
  }

  if (homework.completedAt) {
    if (!showChangeHWStatusControls && !children) {
      return null;
    }
    return (
      <React.Fragment>
        <ButtonGroup className={className}>
          {showChangeHWStatusControls && (
            <React.Fragment>
              <Confirm
                show={isShowingRestoreConfirm}
                onDecline={hideRestoreConfirm}
                headerText={formatMessage(i18n.homeworkReactivationConfirmation)}
                onAccept={reactivate}
                disableButtons={reactivating}
              />
              <Button className="btn-transparent" onClick={showRestoreConfirm}>
                {reactivating ? <Spinner size={16} /> : <Icon name="virc-homework-restore" />}
                <span>{formatMessage(messages.Reopen)}</span>
              </Button>
            </React.Fragment>
          )}
          {children}
        </ButtonGroup>
      </React.Fragment>
    );
  }

  const canBeCompleted = homework.status === HomeworkStatus.CHECKED;
  if (!showChangeHWStatusControls && !children) {
    return null;
  }
  return (
    <React.Fragment>
      <ButtonGroup className={className}>
        {showChangeHWStatusControls && (
          <Button className="btn-transparent" onClick={openChangeHomeworkStatusModal}>
            <Icon name="virc-homework-added" />
            <span>{formatMessage(messages.Checked)}</span>
          </Button>
        )}
        {canBeCompleted && showChangeHWStatusControls && (
          <React.Fragment>
            <Button
              className="btn-transparent"
              title={formatMessage(i18n.homeworkActionComplete)}
              onClick={showCompleteConfirm}
            >
              {completing ? <Spinner size={16} /> : <Icon name="archive" />}
            </Button>
            <Confirm
              show={isShowingCompleteConfirm}
              onDecline={hideCompleteConfirm}
              headerText={formatMessage(i18n.homeworkCompleteConfirm)}
              disableButtons={completing}
              onAccept={complete}
            />
          </React.Fragment>
        )}
        {children}
      </ButtonGroup>
    </React.Fragment>
  );
};

export default injectIntl(ActivatedHomeworkControl);
