import React, {useEffect} from 'react';
import type {PDFPageProxy, PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';
import type {PageViewport} from 'pdfjs-dist/types/src/display/display_utils';

import Loader from 'components/Loader';
import useIntersectionObserver, {type VisibilityChanged} from 'hooks/dom/useIntersectionObserver';

import {Thumbnail} from './Thumbnail';

import './ThumbnailContainer.scss';

const THUMBNAIL_WIDTH = 100;

interface Props {
  doc: PDFDocumentProxy;
  isActive: boolean;
  pageIndex: number;
  rotation: number;
  onActive(target: HTMLElement | null): void;
}

interface PageState {
  height: number;
  isCalculated: boolean;
  page: PDFPageProxy | null;
  viewportRotation: number;
  width: number;
}

export const ThumbnailContainer: React.FC<Props> = ({
  doc,
  isActive,
  pageIndex,
  rotation,
  onActive
}) => {
  const [pageSize, setPageSize] = React.useState<PageState>({
    isCalculated: false,
    page: null,
    viewportRotation: 0,
    width: 100,
    height: 140
  });
  const visibilityRef = React.useRef<VisibilityChanged>({
    isVisible: false,
    ratio: 0
  });
  const {isCalculated, page, height, width} = pageSize;

  const scale = width / height;
  const isVertical = Math.abs(rotation) % 180 === 0;
  const w = isVertical ? THUMBNAIL_WIDTH : THUMBNAIL_WIDTH / scale;
  const h = isVertical ? THUMBNAIL_WIDTH / scale : THUMBNAIL_WIDTH;

  const onVisibilityChanged = (params: VisibilityChanged): void => {
    visibilityRef.current = params;
    if (params.isVisible && !isCalculated) {
      doc.getPage(pageIndex + 1).then((pdfPage: PDFPageProxy) => {
        const viewport = pdfPage.getViewport({scale: 1});
        setPageSize({
          page: pdfPage,
          width: viewport.width,
          height: viewport.height,
          viewportRotation: (viewport as PageViewport & {rotation: number}).rotation || 0,
          isCalculated: true
        });
      });
    }
  };

  const containerRef = useIntersectionObserver({
    onVisibilityChanged
  });

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const ele = containerRef.current;
    const visibility = visibilityRef.current;
    if (!visibility.isVisible || visibility.ratio < 1) {
      Promise.resolve().then(() => onActive(ele));
    }
  }, [containerRef, isActive, onActive]);

  // To support the document which is already rotated
  const rotationNumber = (rotation + pageSize.viewportRotation) % 360;

  return (
    <div
      className="pdf-contents-thumbnail-container"
      ref={containerRef}
      style={{
        height: `${h}px`,
        width: `${w}px`
      }}
    >
      {!page ? (
        <Loader spinnerClassName="pdf-contents-thumbnail-spinner sm" />
      ) : (
        <Thumbnail
          page={page}
          pageHeight={isVertical ? height : width}
          pageWidth={isVertical ? width : height}
          rotation={rotationNumber}
          thumbnailHeight={h}
          thumbnailWidth={w}
        />
      )}
    </div>
  );
};
