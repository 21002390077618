import {type Action} from 'redux';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState, type Role} from 'store/interface';

import FileEditDropdown from './FileEditDropdown';
import {documentDelete, setDocumentDeleting} from '../../../documentsTab/actions/action';
import {publishMaterialsUpdated} from '../../../../../actions/action';
import {type FileType, type SetIsDeletedRequestSentAction} from '../../../../../actions/interface';
import {type Sound} from '../../../soundsTab/actions/interface';
import {type DocumentFile} from '../../../documentsTab/actions/interface';
import {setSoundDeleting, soundDelete} from '../../../soundsTab/actions/action';

export interface FileEditDropdownDispatchProps {
  deleteFile: (id: number | string) => Promise<Action>;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  setFileDeleting: (isBeingDeleted: boolean) => SetIsDeletedRequestSentAction;
}

export interface FileEditDropdownOwnProps {
  thisFileId: number | string;
  fileIsBeingEdited?: true;
  fileIsActive: boolean;
  readonly: boolean;
  deleteFileAwait?: boolean;
  role: Role;
  fileType: FileType;
  thisFile: Sound | DocumentFile;
  editIconClickHandler: () => void;
  pinnedBlock?: boolean;
  controlComponentId: string;
}

type DispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  ownProps: FileEditDropdownOwnProps
) => FileEditDropdownDispatchProps;

const mapDispatchToProps: DispatchToProps = (dispatch, ownProps) => ({
  deleteFile: (id: number) => {
    if (ownProps.fileType === 'document') {
      return dispatch(documentDelete(id));
    }
    return dispatch(soundDelete(id));
  },
  publishMaterialsUpdated: (courseId: number) => dispatch(publishMaterialsUpdated(courseId)),
  setFileDeleting: (isBeingDeleted: boolean) => {
    const {thisFileId, controlComponentId} = ownProps;
    if (ownProps.fileType === 'document') {
      return dispatch(setDocumentDeleting(thisFileId, controlComponentId, isBeingDeleted));
    }
    return dispatch(setSoundDeleting(thisFileId, controlComponentId, isBeingDeleted));
  }
});

const FileEditDropdownContainer = connect<{}, FileEditDropdownDispatchProps>(
  undefined,
  mapDispatchToProps
)(FileEditDropdown);

export default FileEditDropdownContainer;
