import React from 'react';
import classNames from 'classnames';
import {defineMessages, type IntlShape} from 'react-intl';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {type AppState} from 'store/interface';
import {type AxiosRequestAction} from 'services/axios/interface';
import {maxDocsPinCount} from 'config/static';

import {type FileType, type PinAction} from '../../../../actions/interface';
import {pinDocument} from '../../documentsTab/actions/action';

const pinMessages = defineMessages({
  PinSuccess: {
    id: 'File.PinSuccess'
  },
  PinFail: {
    id: 'File.PinFail'
  },
  UnpinFail: {
    id: 'File.UnpinFail'
  },
  Pinned: {
    id: 'File.Pinned'
  },
  NotPinned: {
    id: 'File.NotPinned'
  },
  Unpinnable: {
    id: 'File.Unpinnable'
  }
});

interface OwnProps {
  fileId: number;
  fileType: FileType;
  intl: IntlShape;
  pinnedBlock?: boolean;
  publish?: (courseInstanceId?: number) => void;
}

interface StateProps {
  beingPinned?: boolean;
  courseInstanceId: number;
  pinned?: boolean | null;
  pinnedCount: number;
  pinAwait?: boolean;
}

interface DispatchProps {
  pin: (id: number, pinned: boolean) => Promise<AxiosRequestAction>;
}

type Props = OwnProps & StateProps & DispatchProps;

class PinButton extends React.Component<Props> {
  public render() {
    const {beingPinned, fileType, intl, pinned, pinnedBlock, pinnedCount, pinAwait} = this.props;
    if (beingPinned) {
      return (
        <div className="pin-spinner">
          <Spinner size={22} height={10} />
        </div>
      );
    }
    const shouldDeactivatePin: boolean =
      pinAwait ||
      (!!pinnedCount && pinnedCount >= maxDocsPinCount && !pinned) ||
      (!!pinned && !pinnedBlock);
    const pinClassName = classNames('btn', 'btn-circle', 'pin', {
      deactivated: shouldDeactivatePin,
      pinned,
      hidden: fileType === 'audio'
    });
    let title: string;
    if (shouldDeactivatePin) {
      title = intl.formatMessage(pinMessages.Unpinnable);
    } else {
      title = intl.formatMessage(pinned ? pinMessages.Pinned : pinMessages.NotPinned);
    }
    return (
      <button
        className={pinClassName}
        onClick={this.pin}
        disabled={shouldDeactivatePin}
        title={title}
      >
        <Icon name="virc-pin" size="lg" />
      </button>
    );
  }

  private pin = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {intl, pin, fileId, fileType, pinned, publish, courseInstanceId} = this.props;
    e.stopPropagation();
    if (fileType === 'document') {
      pin(fileId, !pinned).then(
        () => {
          publish!(courseInstanceId);

          !pinned && toastr.success('', intl.formatMessage(pinMessages.PinSuccess));
        },
        () => {
          if (pinned) {
            toastr.error('', intl.formatMessage(pinMessages.UnpinFail));
          } else {
            toastr.error('', intl.formatMessage(pinMessages.PinFail));
          }
        }
      );
    }
  };
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  const file =
    ownProps.fileType === 'document'
      ? state.docs!.documents![ownProps.fileId]
      : state.sounds!.sounds![ownProps.fileId];
  return {
    beingPinned: file.beingPinned,
    courseInstanceId: file.course_instance_id,
    pinned: file.pinned,
    pinnedCount: state.docs!.pinnedCount,
    pinAwait: state.docs!.pinAwait
  };
};

const mapDispatchToProps = (dispatch: Dispatch<PinAction, AppState>) => ({
  pin: (id: number, pinned: boolean) => dispatch(pinDocument(id, pinned) as AxiosRequestAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(PinButton);
