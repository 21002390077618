import {type Action} from 'redux';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState, type UploadingFile} from 'store/interface';
import {type UploadProgressHandler} from 'services/axios/interface';

import {type FileType} from '../../../../../actions/interface';
import {
  getRecentDocuments,
  getRecentSounds,
  publishMaterialsUpdated
} from '../../../../../actions/action';
import {libraryModalOpened} from '../../../Library/actions';
import {
  addFileToUploads,
  blockUploadPanel,
  cloneFile,
  fileUploaded,
  MD5Check,
  removeFileFromUploads,
  uploadFile,
  uploadingFailed,
  uploadingProgress
} from '../../actions/action';
import FileUploadPanel from './FileUploadPanel';
import {type DispatchProps, type OwnProps, type StateProps} from './interface';

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  return {
    blocked:
      ownProps.fileType === 'audio'
        ? Boolean(state.uploads.blockSoundsUploading)
        : Boolean(state.uploads.blockDocsUploading),
    token: 'Bearer ' + state.user.token,
    locale: state.intl.locale,
    uploadingSounds: state.uploads.uploadingSounds,
    uploadingDocuments: state.uploads.uploadingDocuments,
    workspaceExpanded: state.layout.collapsed
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  openLibraryModal: () => dispatch(libraryModalOpened()),
  checkMD5: (fileType: FileType, hash: string) => dispatch(MD5Check(fileType, hash)),
  addFileToUploads: (file: UploadingFile) => dispatch(addFileToUploads(file)),
  blockUploadPanel: (fileType: FileType, blocked: boolean) =>
    dispatch(blockUploadPanel(fileType, blocked)),
  removeFileFromUploads: (md5: string) => dispatch(removeFileFromUploads(md5)),
  cloneFile: (courseId: number, fileId: number, fileType: FileType, title: string) =>
    dispatch(cloneFile(courseId, fileId, fileType, title)),
  uploadingProgress: (fileId: string, percents: number) =>
    dispatch(uploadingProgress(fileId, percents)),
  uploadingFailed: (md5: string, errorText: string) => dispatch(uploadingFailed(md5, errorText)),
  getRecentDocuments: () => dispatch(getRecentDocuments()),
  getRecentSounds: () => dispatch(getRecentSounds()),
  publishMaterialsUpdated: (courseId: number) => dispatch(publishMaterialsUpdated(courseId)),
  fileUploaded: (md5: string) => dispatch(fileUploaded(md5)),
  uploadRequest: (
    courseId: number,
    fileType: FileType,
    data: FormData,
    onUploadProgress: UploadProgressHandler
  ) => dispatch(uploadFile(courseId, fileType, data, onUploadProgress))
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FileUploadPanel));
