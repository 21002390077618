import React from 'react';
import {defineMessages, FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';

import Icon from 'components/Icon';
import {maxFilesSearchResultNumber} from 'config/static';

import {type DocumentsTabOwnProps, type DocumentsTabStateProps} from './interface';

const messages = defineMessages({
  ReturnToDocumentsListMessage: {
    id: 'File.ReturnToDocumentsListMessage'
  }
});

interface Props extends DocumentsTabStateProps, DocumentsTabOwnProps, WrappedComponentProps {}

class DocumentsTab extends React.Component<Props, {}> {
  private tabClass: string = `lesson-tab-${this.props.role}`;

  public render() {
    const {activeDocument, intl, collapsed, returnToDocumentsList} = this.props;
    const text = activeDocument ? (
      activeDocument.title
    ) : (
      <FormattedMessage id="LessonPage.Materials" />
    );
    const textClassName = 'lesson-tab-text tab-docs-text' + (activeDocument ? ' small-text' : '');
    return (
      <div className={this.tabClass}>
        {activeDocument && returnToDocumentsList && (
          <Icon
            name="arrow-circle-o-left"
            title={intl.formatMessage(messages.ReturnToDocumentsListMessage)}
            size="lg"
            onClick={returnToDocumentsList}
          />
        )}
        {!activeDocument && <Icon name="virc-docs" size="lg" />}
        {this.renderMaterialsNotificationCircle()}
        {activeDocument && <Icon name="pc-book" size="lg" />}
        {!collapsed && <span className={textClassName}>{text}</span>}
        {this.renderSearchResultsNumber(textClassName)}
      </div>
    );
  }

  private renderSearchResultsNumber = (className: string) => {
    const {searchResultsNumber} = this.props;
    if (searchResultsNumber !== undefined) {
      const numberToDisplay =
        searchResultsNumber <= maxFilesSearchResultNumber
          ? searchResultsNumber
          : `${maxFilesSearchResultNumber}+`;
      return <span className={className}>({numberToDisplay})</span>;
    }
    return null;
  };

  private renderMaterialsNotificationCircle = () => {
    if (this.props.hasNewDocuments) {
      return <span className="lesson-notification-circle" />;
    } else {
      return null;
    }
  };
}

export default injectIntl(DocumentsTab);
