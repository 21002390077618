import {useCallback, useState} from 'react';

export const useEditorViewerSync = () => {
  const [commentEditorJustClosed, setCommentEditorJustClosed] = useState<boolean | undefined>();
  const [editorCloseRequested, setEditorCloseRequested] = useState<boolean>(false);
  const [viewerCloseRequested, setViewerCloseRequested] = useState<boolean>(false);

  const closeCommentViewer = useCallback<() => void>(() => setViewerCloseRequested(true), []);
  const closeCommentEditor = useCallback<() => void>(() => setEditorCloseRequested(true), []);

  const tellCommentEditorJustClosed = useCallback<(f: boolean) => void>(flag => {
    setCommentEditorJustClosed(flag);
    setEditorCloseRequested(false);
  }, []);

  const tellCommentViewerJustClosed = useCallback<() => void>(
    () => setViewerCloseRequested(false),
    []
  );

  return {
    commentEditorJustClosed,
    setCommentEditorJustClosed,
    editorCloseRequested,
    setEditorCloseRequested,
    viewerCloseRequested,
    setViewerCloseRequested,
    closeCommentEditor,
    closeCommentViewer,
    tellCommentEditorJustClosed,
    tellCommentViewerJustClosed
  };
};
