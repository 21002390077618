import {Document, Page} from 'react-pdf';
import React, {type FC, useCallback, useLayoutEffect, useRef, useState} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {type PDFPageProxy, type PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';
import classNames from 'classnames';
import {unstable_batchedUpdates} from 'react-dom';
import {normalizeOrientation} from '@englex/paint';

import {genKey} from '../Slate/utils';
import {usePDFViewerPage} from './PDFViewerPageContext';

interface OwnProps {
  url?: string;
  pageNumber: number;
  onLoad: (onErrorReload: (() => void) | null, doc?: PDFDocumentProxy) => void;
  renderPageAsPng?: boolean;
}

type Props = OwnProps;

const PDFDocument: FC<Props> = ({url, onLoad, pageNumber, children, renderPageAsPng = true}) => {
  const [initialized, setInitialized] = useState(false);
  const doc = useRef<PDFDocumentProxy | undefined>();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const [docKey, setDocKey] = useState(genKey());
  const [pageKey, setPageKey] = useState(genKey());

  const reloadDoc = useCallback(() => setDocKey(genKey()), []);
  const reloadPage = useCallback(() => setPageKey(genKey()), []);

  const onDocSuccess = useCallback((proxy: PDFDocumentProxy) => {
    doc.current = proxy;
  }, []);

  const onDocError = useCallback(() => {
    doc.current = undefined;
    onLoad(reloadDoc);
  }, [onLoad, reloadDoc]);

  const onPageError = useCallback(
    (e: Error) => {
      if (e?.message?.startsWith('Rendering cancelled, page')) {
        // pdf-js throws error on render cancel
        // we SHOULD skip it on production to prevent showing the error overlay
        // but it may indicate that react-pdf Page component has unnecessary re-renders that MUST be fixed
        if (import.meta.env.MODE === 'production') {
          return;
        }
      }
      onLoad(reloadPage, doc.current);
    },
    [onLoad, reloadPage]
  );

  const {
    width: pageWidth,
    height: pageHeight,
    scale,
    orientation,
    setOrientation,
    setViewport,
    isSwapDimensions
  } = usePDFViewerPage();

  const onPageLoadSuccess = useCallback(
    (page: PDFPageProxy) => {
      const rotate = normalizeOrientation(page.rotate);

      const viewport = page.getViewport({
        scale: 1,
        rotation: 0
      });
      unstable_batchedUpdates(() => {
        setViewport(viewport);
        setOrientation(rotate);
      });
    },
    [setOrientation, setViewport]
  );
  const onRenderSuccess = useCallback(() => {
    if (renderPageAsPng) {
      canvasRef.current?.toBlob(blob => {
        if (blob) {
          const imgUrl = URL.createObjectURL(blob);
          unstable_batchedUpdates(() => {
            setImgUrl(imgUrl);
            setInitialized(true);
            onLoad(null, doc.current);
          });
        }
      }, 'image/png');
    } else {
      setInitialized(true);
      onLoad(null, doc.current);
    }
  }, [onLoad, renderPageAsPng]);

  useLayoutEffect(() => {
    if (pageNumber) {
      unstable_batchedUpdates(() => {
        setImgUrl(null);
        setInitialized(false);
      });
    }
  }, [pageNumber]);

  useLayoutEffect(() => {
    setImgUrl(null);
    setInitialized(false);
  }, [orientation]);

  const shouldRenderChildren = renderPageAsPng ? scale && initialized && imgUrl : initialized;
  const imgWidth = Math.floor((isSwapDimensions ? pageHeight : pageWidth) * scale!);
  const imgHeight = Math.floor((isSwapDimensions ? pageWidth : pageHeight) * scale!);

  return (
    <div className={classNames('pdf-viewer-resizable')}>
      <Document
        key={docKey}
        file={url}
        renderMode="canvas"
        loading={null as never}
        error={null as never}
        noData={null as never}
        onLoadSuccess={onDocSuccess}
        onLoadError={onDocError}
        onSourceError={onDocError}
      >
        <Page
          key={pageKey}
          className={classNames({'as-png': renderPageAsPng})}
          canvasRef={canvasRef}
          pageNumber={pageNumber}
          loading={null as never}
          error={null as never}
          noData={null as never}
          renderAnnotationLayer={false}
          renderInteractiveForms={false}
          renderTextLayer={false}
          onLoadSuccess={onPageLoadSuccess}
          onLoadError={onPageError}
          onGetTextError={onPageError}
          onGetAnnotationsError={onPageError}
          onRenderSuccess={onRenderSuccess}
          onRenderError={onPageError}
          scale={scale || 1}
          rotate={orientation}
          renderMode={!scale || !pageWidth ? 'none' : 'canvas'}
        >
          {renderPageAsPng && shouldRenderChildren ? (
            <img
              className="pdf-viewer-page__img"
              src={imgUrl!}
              alt=""
              width={imgWidth}
              height={imgHeight}
            />
          ) : null}
          {shouldRenderChildren && children}
        </Page>
      </Document>
    </div>
  );
};
export const PDFViewerDocument: FC<OwnProps> = props => {
  const {
    fitToWidth,
    width: pageWidth,
    height: pageHeight,
    scale,
    setScale,
    isSwapDimensions,
    orientation
  } = usePDFViewerPage();
  const targetRef = useRef<HTMLDivElement | null>(null);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null);
  const containerWidthRef = useRef<number | null>(null);

  const pWidth = isSwapDimensions ? pageHeight : pageWidth;

  const onResize = useCallback(
    (width: number) => {
      containerWidthRef.current = width;

      if (fitToWidth && width && pWidth) {
        const zoom = width / pWidth;
        targetRef.current?.classList.add('resizing');

        if (timerIdRef.current) {
          clearTimeout(timerIdRef.current);
        }

        timerIdRef.current = setTimeout(() => {
          timerIdRef.current = null;
          const sc = zoom;
          if (scale !== sc) {
            setScale(sc);
          }
          targetRef.current?.classList.remove('resizing');
        }, 400);
      }
    },
    [fitToWidth, pWidth, scale, setScale]
  );
  useLayoutEffect(() => {
    if (pWidth && !scale && containerWidthRef.current) {
      setScale(containerWidthRef.current / pWidth);
    }
  }, [pWidth, scale, setScale]);

  useLayoutEffect(() => {
    if (fitToWidth && pWidth && containerWidthRef.current) {
      setScale(containerWidthRef.current / pWidth);
    }
  }, [pWidth, setScale, orientation, fitToWidth]);

  useResizeDetector({
    handleHeight: false,
    skipOnMount: false,
    targetRef,
    onResize
  });

  return (
    <div
      ref={targetRef}
      className={classNames('pdf-viewer-doc', {
        'fit-to-width': fitToWidth
      })}
    >
      <PDFDocument {...props} />
    </div>
  );
};
