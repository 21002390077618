import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {matchPath, Outlet, useLocation, useParams} from 'react-router-dom';
import {type AxiosError} from 'axios';

import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type AppState, type HomeworkWithContents} from 'store/interface';
import {setIncorrectUrlId} from 'common/action';
import {coursePattern, type CoursebookInstanceUrlParams} from 'common/paths';
import {useRole} from 'hooks/user/useRole';

import {type CourseInstanceRouteProps} from '../components/interface';
import {
  clearCourseInstanceInfo,
  clearHomeworkDraft,
  isHomeworkDraft,
  loadHomeworkDraft,
  requestCourseInstanceInfo as requestCourseInstanceInfoCreator,
  type RequestCourseInstanceInfoResponseData,
  setCoursebookInstances,
  setCourseInstanceOverview,
  setHomeworkDraft
} from '../pages/CourseInstancePage/actions';

export interface CourseInstanceOutletContext {
  isInactive: boolean;
  isWampSubscribed: boolean;
}

const CourseInstanceRoute: React.FC<CourseInstanceRouteProps> = ({
  courseOrSTInactive,
  wampSubscribed
}) => {
  const {pathname} = useLocation();
  const params = useParams<CoursebookInstanceUrlParams>();
  const courseId = Number(params.courseId);
  const coursebookInstanceId = params.coursebookInstanceId || undefined;
  const isNotStudent = useRole() !== 'student';
  const dataWasAlreadyLoaded = useRef(false);

  const dispatch = useDispatch();

  const requestCourseInstanceInfo = useMemo(() => {
    dataWasAlreadyLoaded.current = false;
    return requestCourseInstanceInfoCreator(courseId, {
      expandOverview: true,
      expandCoursebookInstances: true
    });
  }, [courseId]);

  const courseInstanceInfoRequestSuccessHandler = useCallback(
    (data: RequestCourseInstanceInfoResponseData) => {
      const {coursebookInstances, overview} = data;
      if (
        coursebookInstanceId &&
        !coursebookInstances!.find(ci => ci.id === coursebookInstanceId)
      ) {
        dispatch(setIncorrectUrlId());
      }
      dispatch(setCoursebookInstances(coursebookInstances!));
      dispatch(setCourseInstanceOverview(overview!));
      dataWasAlreadyLoaded.current = true;
    },
    [coursebookInstanceId, dispatch, dataWasAlreadyLoaded]
  );
  const {isError: cbiRequestError, reload: reloadCbi} = useApiRequest(
    requestCourseInstanceInfo,
    courseInstanceInfoRequestSuccessHandler
  );

  const requestHomeworkDraftAction = useMemo(
    () => (isNotStudent ? loadHomeworkDraft(courseId) : null),
    [courseId, isNotStudent]
  );
  const {isError: homeworkDraftRequestError, reload: reloadHomeworkDraft} = useApiRequest(
    requestHomeworkDraftAction,
    (data: HomeworkWithContents | AxiosError) =>
      isHomeworkDraft(data) && dispatch(setHomeworkDraft(data))
  );

  const reload = useCallback(() => {
    reloadCbi();
    reloadHomeworkDraft();
  }, [reloadCbi, reloadHomeworkDraft]);

  const {coursebookInstanceList, courseInstanceOverview} = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState
  );

  useEffect(
    // reload course instance data when user returns to course instance page to update info on files and homework cards;
    // dataWasAlreadyLoaded added as dependency so that this hook doesn't execute on initial page mount or when user switches to another course instance;
    function () {
      const isOnCourseInstancePage = matchPath(
        {
          end: true,
          path: coursePattern
        },
        pathname
      );

      if (isOnCourseInstancePage && dataWasAlreadyLoaded.current) {
        reload();
      }
    },
    [pathname, dataWasAlreadyLoaded, reload]
  );

  useEffect(
    () =>
      function clearDataOnCourseChanged() {
        dispatch(clearCourseInstanceInfo());
        dispatch(clearHomeworkDraft());
      },
    [courseId, dispatch]
  );

  if (cbiRequestError || homeworkDraftRequestError) {
    return <WampErrorMask reload={reload} />;
  }
  if (!coursebookInstanceList || !courseInstanceOverview) {
    return <Loader />;
  }
  return <Outlet context={{isInactive: !!courseOrSTInactive, isWampSubscribed: wampSubscribed}} />;
};

export default CourseInstanceRoute;
