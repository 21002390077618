import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import {type HomeworkDocumentInstance, type HomeworkDocumentInstancePage} from 'store/interface';

import Icon from '../../../../components/Icon';
import EditHomeworkCommentForm from './EditHomeworkCommentForm';
import Loader from '../../../../components/Loader';
import useEditHomeworkDocComment from './useEditHomeworkDocComment';

import './EditHomeworkCommentModal.scss';

export interface EditHomeworkDocCommentProps extends EditHomeworkCommentProps {
  homeworkDocumentsList?: HomeworkDocumentInstance[];
}

export interface EditHomeworkDocPageCommentProps extends EditHomeworkCommentProps {
  pages?: HomeworkDocumentInstancePage[];
}

export interface EditHomeworkCommentProps {
  courseId: number;
  // pass undefined if viewing draft
  homeworkPlayerActiveHomework?: string;
  // sync with viewer props
  closeCommentViewer?: () => void;
  closeRequested?: boolean;
  tellCommentEditorJustClosed?: (flag: boolean) => void;
}

const EditHomeworkCommentModal: React.FC<EditHomeworkDocCommentProps> = props => {
  const intl = useIntl();
  const {isLoading, save, initialComment, editDocumentInstanceId, closeCommentEditor} =
    useEditHomeworkDocComment(props);
  return (
    <Modal
      show={!!editDocumentInstanceId}
      onHide={closeCommentEditor}
      backdrop="static"
      className="edit-homework-comment-modal"
    >
      <Modal.Header>
        <Modal.Title>
          {intl.formatMessage({
            id: 'Files.EditHomeworkCommentModalTitle'
          })}
          <a onClick={closeCommentEditor}>
            <Icon name="pc-close" tag="i" />
          </a>
        </Modal.Title>
      </Modal.Header>
      {editDocumentInstanceId && (
        <EditHomeworkCommentForm
          handleSubmit={save}
          submittingForm={isLoading}
          initialComment={initialComment}
          FormWrapper={Modal.Body}
          textAreaRows={5}
          inputSize="lg"
        >
          <Modal.Footer>
            <Button
              bsStyle="default"
              className="btn-transparent"
              type="reset"
              bsSize="sm"
              onClick={closeCommentEditor}
              disabled={isLoading}
            >
              <FormattedMessage id="Common.Cancel" />
            </Button>
            <Button
              className={classNames('submit-comment-button', {submitting: isLoading})}
              bsStyle="primary"
              bsSize="sm"
              type="submit"
              disabled={isLoading}
            >
              <div className="loader-button-positioning-helper">
                <Loader shouldRender={isLoading} />
              </div>
              <FormattedMessage id="Common.Save" />
            </Button>
          </Modal.Footer>
        </EditHomeworkCommentForm>
      )}
    </Modal>
  );
};

export default EditHomeworkCommentModal;
