import React, {type FC, useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import {useParams} from 'react-router-dom';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {type ClassroomUrlParams} from 'common/paths';
import {dropdownDropupCheckCreator} from 'helpers/dropdownDropupCheck';
import {type AppState} from 'store/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {editHomeworkCommentForDocument} from '../../../documentsTab/actions/action';
import {deleteDocumentInstanceFromDraft} from '../../../../../pages/CourseInstancePage/actions';
import {documentDraftControlMessages} from '../../../../HomeworkDraftControl/i18n';
import './AddToHomeworkControl.scss';

const DROPDOWN_HEIGHT = 98;

interface P {
  docInstanceId: number;
  isPinned?: boolean;
}

export const AddToHomeworkControl: FC<P> = ({docInstanceId, isPinned}) => {
  const dispatch = useAxiosDispatch();
  const {formatMessage} = useIntl();
  const {courseId} = useParams<ClassroomUrlParams>();

  const [dropup, setDropup] = useState(false);

  const draft = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState.homeworkDraft
  );

  const dispatchEditHomeworkCommentForDocument = useCallback(
    (id: number) => dispatch(editHomeworkCommentForDocument(id)),
    [dispatch]
  );

  const dispatchDeleteFromDraft = useCallback(
    (courseInstanceId: number, homeworkId: string, documentInstanceId: number) =>
      dispatch(deleteDocumentInstanceFromDraft(courseInstanceId, homeworkId, documentInstanceId)),
    [dispatch]
  );

  const dropdownDropupCheck = useMemo(
    () => dropdownDropupCheckCreator(setDropup, DROPDOWN_HEIGHT),
    []
  );

  const openHomeworkCommentModal = useCallback(
    (e: React.MouseEvent<{}, MouseEvent>) => {
      e.stopPropagation();
      dispatchEditHomeworkCommentForDocument(docInstanceId);
    },
    [dispatchEditHomeworkCommentForDocument, docInstanceId]
  );

  const deleteFromDraft = useCallback(
    (e: React.MouseEvent<{}, MouseEvent>) => {
      e.stopPropagation();

      dispatchDeleteFromDraft(Number(courseId), draft!.id, docInstanceId).then(
        () =>
          toastr.success('', formatMessage(documentDraftControlMessages.DeleteFromDraftSuccess)),
        () => toastr.error('', formatMessage(documentDraftControlMessages.DeleteFromDraftFail))
      );
    },
    [courseId, dispatchDeleteFromDraft, docInstanceId, draft, formatMessage]
  );

  const isInDraft =
    !!draft && !!draft.documents.find(doc => doc.documentInstanceId === docInstanceId);

  const className = classNames('btn', {
    'is-in-draft': isInDraft
  });

  return (
    <div className="add-file-to-homework-control">
      {!isInDraft ? (
        <ButtonGroup>
          <button
            className={className}
            title={formatMessage(documentDraftControlMessages.Title)}
            onClick={openHomeworkCommentModal}
          >
            <Icon name="virc-homework" />
          </button>
        </ButtonGroup>
      ) : (
        <DropdownButton
          title={<Icon name="virc-homework" />}
          id={`add-file-to-homework-${docInstanceId}${isPinned ? '-pinned' : ''}`}
          onClick={dropdownDropupCheck}
          dropup={dropup}
          noCaret={true}
          className={className}
        >
          <MenuItem onClick={deleteFromDraft}>
            {formatMessage(documentDraftControlMessages.DeleteFromHomework)}
          </MenuItem>
          <MenuItem onClick={openHomeworkCommentModal}>
            {formatMessage(documentDraftControlMessages.EditCommentary)}
          </MenuItem>
        </DropdownButton>
      )}
    </div>
  );
};
