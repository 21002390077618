import React from 'react';

import {type HomeworkDocumentInstancePage} from 'store/interface';

import {useDocPageControls} from './useDocPageControls';
import {DocControls} from './DocControls';

interface Props {
  documentInstanceId: number;
  pageNumber: number;
  courseId: number;
  studentTeacherId: number;
  homeworkId?: string;
  pages?: HomeworkDocumentInstancePage[];
  hasViewInDocumentButton?: boolean;
}

export const DocumentPageControls: React.FC<Props> = ({
  documentInstanceId,
  pageNumber,
  pages,
  homeworkId,
  courseId,
  studentTeacherId,
  hasViewInDocumentButton
}) => {
  return (
    <DocControls
      hasViewInDocumentButton={hasViewInDocumentButton}
      {...useDocPageControls(
        pages,
        courseId,
        studentTeacherId,
        homeworkId,
        documentInstanceId,
        pageNumber
      )}
    />
  );
};
