import React, {type FC, useMemo} from 'react';
import {matchPath, useParams} from 'react-router-dom';
import {connect} from 'react-redux';

import {
  type AppState,
  type CoursebookInstance,
  type Courses,
  type HomeworkWithContents,
  type StudentTeachers,
  type UnitInstance
} from 'store/interface';
import StudentTeacherBreadcrumb from 'components/Breadcrumbs/StudentTeacherBreadcrumb/StudentTeacherBreadcrumb';
import BreadcrumbsPanel from 'components/Breadcrumbs/BreadcrumbsPanel';
import lineImage from 'components/Breadcrumbs/assets/Line.svg';
import {
  coursebookInstancePattern,
  type CoursebookInstanceUrlParams,
  grammarPlayerPattern,
  unitInstancePattern,
  unitInstancePreviewPattern,
  filesListPattern,
  courseInstanceHomeworkPattern
} from 'common/paths';

import CourseBreadcrumb from '../breadcrumbs/components/CourseBreadcrumb';
import {
  changePartnersFilter,
  openPartnersTab,
  toggleCoursesDropdown,
  toggleStudentTeacherPopover
} from '../actions/action';
import {CoursebookInstanceBreadcrumb} from './CoursebookInstanceBreadcrumb';
import {UnitInstancesBreadcrumb} from './UnitInstancesBreadcrumb';
import {HomeworkBreadcrumb} from './HomeworkBreadcrumb';
import {type HomeworkPlayerUrlParams} from '../pages/HomeworkPage/interface';

interface OwnProps {
  studentTeachers: StudentTeachers;
  selectedStudentTeacherId: string;
  selectedCourseId?: string;
  changeActiveStudentTeacher(id: number): void;
  selectCourse(id: number): void;
}

interface StateProps {
  studentTeacherPopoverOpen?: boolean;
  studentTeacherPopoverOpenTab: string;
  studentTeachersFilter: string;
  courses?: Courses;
  loadingMaterials?: boolean;
  coursesDropdownOpen?: boolean;
  coursebookInstances?: CoursebookInstance[];
  unitInstances?: UnitInstance[];
  selectedHomework?: HomeworkWithContents;
  pathname: string;
}

interface DispatchProps {
  changeStudentTeacherFilter(filter: string): void;
  changeStudentTeacherPopoverTab(tabId: string): void;
  toggleCoursesDropdown(show: boolean): void;
  toggleStudentTeacherPopover(show: boolean): void;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

const Breadcrumbs: FC<Props> = ({
  studentTeachers,
  selectedStudentTeacherId,
  selectedCourseId,

  coursebookInstances,
  courses,
  coursesDropdownOpen,
  loadingMaterials,
  pathname,
  selectedHomework,
  studentTeachersFilter,
  studentTeacherPopoverOpen,
  studentTeacherPopoverOpenTab,
  unitInstances,

  changeActiveStudentTeacher,
  changeStudentTeacherFilter,
  changeStudentTeacherPopoverTab,
  selectCourse,
  toggleCoursesDropdown,
  toggleStudentTeacherPopover
}) => {
  const {
    isOnFilesList,
    isCourseInstancePath,
    isOnHomeworkPage,
    isUnitInstancePage,
    isUnitPreviewPage,
    isGrammarPage
  } = useMemo(() => {
    const isCourseInstancePath = !!matchPath(
      {path: coursebookInstancePattern, end: false},
      pathname
    );
    const isOnFilesList = !!matchPath({path: filesListPattern, end: false}, pathname);
    const isOnHomeworkPage = !!matchPath(
      {path: courseInstanceHomeworkPattern, end: false},
      pathname
    );
    const isUnitInstancePage = !!matchPath({path: unitInstancePattern, end: false}, pathname);
    const isUnitPreviewPage = !!matchPath({path: unitInstancePreviewPattern, end: false}, pathname);
    const isGrammarPage = !!matchPath({path: grammarPlayerPattern, end: false}, pathname);

    return {
      isCourseInstancePath,
      isOnFilesList,
      isOnHomeworkPage,
      isUnitInstancePage,
      isUnitPreviewPage,
      isGrammarPage
    };
  }, [pathname]);

  const isThirdOpen = isOnFilesList || isCourseInstancePath || isOnHomeworkPage;

  const {homeworkId, coursebookInstanceId} = useParams<
    HomeworkPlayerUrlParams & CoursebookInstanceUrlParams
  >();

  return (
    <BreadcrumbsPanel>
      <StudentTeacherBreadcrumb
        studentTeachers={studentTeachers}
        activeStudentTeacher={studentTeachers[selectedStudentTeacherId!]}
        toggleStudentTeachersPopover={toggleStudentTeacherPopover}
        changeActiveStudentTeacher={changeActiveStudentTeacher}
        studentTeacherPopoverOpen={studentTeacherPopoverOpen}
        popoverOpenTabId={studentTeacherPopoverOpenTab}
        studentTeachersFilter={studentTeachersFilter}
        changeActiveTab={changeStudentTeacherPopoverTab}
        changeFilter={changeStudentTeacherFilter}
        popoverId="classroom-select-partner"
        popoverTopPosition="40px"
        popoverLeftPosition="5px"
        isSidebar={false}
      />

      {courses && selectedCourseId ? (
        <CourseBreadcrumb
          selectCourse={selectCourse}
          courses={courses}
          activeCourseId={selectedCourseId}
          toggleDropdown={toggleCoursesDropdown}
          dropdownIsOpen={coursesDropdownOpen}
          shouldDisableDropdown={loadingMaterials}
          isLast={!isThirdOpen}
        />
      ) : (
        <img src={lineImage} alt="" />
      )}

      {coursebookInstances && (coursebookInstanceId || isOnFilesList || isOnHomeworkPage) ? (
        <CoursebookInstanceBreadcrumb
          coursebookInstances={coursebookInstances}
          isOnFilesList={isOnFilesList}
          isOnHomeworkPage={isOnHomeworkPage}
        />
      ) : null}

      {unitInstances && (isUnitInstancePage || isUnitPreviewPage || isGrammarPage) ? (
        <UnitInstancesBreadcrumb unitInstances={unitInstances} />
      ) : null}

      {selectedHomework && homeworkId ? <HomeworkBreadcrumb homework={selectedHomework} /> : null}
    </BreadcrumbsPanel>
  );
};

const mapStateToProps = ({classroom, docs, router, sounds}: AppState): StateProps => ({
  studentTeacherPopoverOpen: classroom!.studentTeachersPopoverOpen,
  studentTeacherPopoverOpenTab: classroom!.partnersOpenTabId,
  studentTeachersFilter: classroom!.partnersFilter,
  coursesDropdownOpen: classroom!.coursesDropdownOpen,
  loadingMaterials: sounds?.loadingSounds || docs?.loadingDocuments,
  courses: classroom!.courses,
  coursebookInstances: classroom!.courseInstanceState.coursebookInstanceList,
  unitInstances: classroom!.courseInstanceState.coursebookInstanceState.unitInstances,
  selectedHomework: classroom!.courseInstanceState.homework.homeworkPlayer.selectedHomework,
  pathname: router.location!.pathname
});

export default React.memo<OwnProps>(
  connect(mapStateToProps, {
    toggleStudentTeacherPopover,
    changeStudentTeacherFilter: changePartnersFilter,
    changeStudentTeacherPopoverTab: openPartnersTab,
    toggleCoursesDropdown
  })(Breadcrumbs)
);
