import React from 'react';
import {type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';

import {audioMessages} from '../../../messages';

interface Props extends WrappedComponentProps {
  className?: string;
  handlePlayIconClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  handlePauseIconClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  fileIsBeingEdited?: boolean;
  renderPauseIcon: boolean;
}

export default class PlayIcon extends React.PureComponent<Props> {
  public render() {
    const {
      intl,
      className,
      fileIsBeingEdited,
      handlePauseIconClick,
      handlePlayIconClick,
      renderPauseIcon
    } = this.props;
    const handleClick = renderPauseIcon ? handlePauseIconClick : handlePlayIconClick;
    const name = renderPauseIcon ? 'pause' : 'play';
    const title = renderPauseIcon
      ? intl.formatMessage(audioMessages.PauseMessage)
      : intl.formatMessage(audioMessages.PlayMessage);
    return !fileIsBeingEdited ? (
      <div className={classNames('file-control-left', className)} title={title}>
        <Icon name={name} onClick={handleClick} size="lg" />
      </div>
    ) : null;
  }
}
