import React, {type FC} from 'react';
import {Route, Routes} from 'react-router-dom';

import Loader from '../../components/Loader';
import CourseInstancePage from './pages/CourseInstancePage/CourseInstancePage';
import CoursebookInstanceLayout from './containers/CoursebookInstanceLayout';
import CoursebookInstancePage from './pages/CoursebookInstancePage/CoursebookInstancePage';
import {UnitInstancePage} from './pages/UnitInstancePage/UnitInstancePage';
import UnitPreviewPage from './pages/UnitPreviewPage/UnitPreviewPage';
import {GrammarPlayerPageWithPointer} from '../../common/GrammarPlayerPage/GrammarPlayerPageWithPointer';
import InvalidUrlId from '../../containers/InvalidUrlId';
import HomeworkPage from './pages/HomeworkPage/HomeworkPage';
import HomeworkPlayerPage from './pages/HomeworkPlayerPage/HomeworkPlayerPage';
import FilesPage from './pages/FilesPage/FilesPage';
import CourseInstanceLayout from './containers/CourseInstanceLayout';

const ClassRoomRoute: FC = () => {
  return (
    <Routes>
      <Route element={<CourseInstanceLayout />}>
        <Route path=":studentTeacherId?/course?" element={<Loader />} />

        <Route path=":studentTeacherId/course/:courseId/*">
          <Route index={true} element={<CourseInstancePage />} />

          <Route path="coursebook/:coursebookInstanceId/*" element={<CoursebookInstanceLayout />}>
            <Route index={true} element={<CoursebookInstancePage />} />

            <Route path="unit/:unitInstanceId/page?/:page?" element={<UnitInstancePage />} />
            <Route path="preview/:unitInstanceId/page?/:page?" element={<UnitPreviewPage />} />
            <Route path="grammar/:grammarId?" element={<GrammarPlayerPageWithPointer />} />

            <Route path="*" element={<InvalidUrlId />} />
          </Route>

          <Route path="homework">
            <Route index={true} element={<HomeworkPage />} />
            <Route path=":homeworkId/page?/:page?" element={<HomeworkPlayerPage />} />

            <Route path="*" element={<InvalidUrlId />} />
          </Route>

          <Route path="files">
            <Route index={true} element={<FilesPage />} />
            <Route path=":documentId/page?/:pageNumber?" element={<FilesPage />} />

            <Route path="*" element={<InvalidUrlId />} />
          </Route>

          <Route path="*" element={<InvalidUrlId />} />
        </Route>

        <Route path="*" element={<InvalidUrlId />} />
      </Route>
    </Routes>
  );
};

export default ClassRoomRoute;
