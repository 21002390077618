import React from 'react';
import classNames from 'classnames';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import {type UnitInstance} from 'store/interface';
import Icon from 'components/Icon';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';

import UnitInstanceProgress from './UnitInstanceProgress';
import UnitInstancesDropdown from './UnitInstancesDropdown';

interface OwnProps {
  unitInstances: UnitInstance[];
  selectedUnitInstanceId?: number;
  dropdownIsOpen?: true;
  toggleDropdown: (show: boolean) => void;
  goToUnitInstance: (id: number) => void;
  goToUnitPreview: (id: number) => void;
  goToGrammarPlayer: () => void;
  isInGrammarPlayer?: boolean;
  hasGrammar?: boolean;
}

interface State {
  showTitle: boolean;
}

type Props = OwnProps & WrappedComponentProps;

class UnitInstancesBreadcrumb extends React.Component<Props, State> {
  public componentWillUnmount(): void {
    this.props.toggleDropdown(false);
  }

  public render() {
    const {
      dropdownIsOpen,
      unitInstances,
      toggleDropdown,
      goToUnitInstance,
      goToUnitPreview,
      goToGrammarPlayer,
      isInGrammarPlayer,
      hasGrammar,
      intl
    } = this.props;

    if (!this.selectedUnitInstance && !isInGrammarPlayer) {
      const title = intl.formatMessage({id: 'Exercise.Sidebar.SectionHeading.Grammar'});

      return (
        <Breadcrumb
          title={title}
          icon="pc-course"
          iconTag="i"
          iconClassName="always-visible-icon"
          className="pointer exercise-breadcrumb"
          accessibilityTitle={title}
          onClick={this.breadcrumbClickHandler}
        >
          <Icon name="caret-down" tag="i" />
        </Breadcrumb>
      );
    }

    const prefix =
      this.selectedUnitInstance?.ordinal !== null ? `${this.selectedUnitInstance?.ordinal}. ` : '';

    const title =
      !this.selectedUnitInstance && isInGrammarPlayer
        ? intl.formatMessage({id: 'Exercise.Sidebar.SectionHeading.Grammar'})
        : `${prefix} ${this.selectedUnitInstance?.unit.title}`;

    const icon = !this.selectedUnitInstance && isInGrammarPlayer ? 'pc-course' : undefined;

    const iconElement =
      !this.selectedUnitInstance && isInGrammarPlayer ? undefined : (
        <UnitInstanceProgress instance={this.selectedUnitInstance!} />
      );

    const className = classNames('pointer', 'exercise-breadcrumb', {
      active: dropdownIsOpen
    });

    const accessibilityTitle = isInGrammarPlayer
      ? intl.formatMessage({id: 'Exercise.Sidebar.SectionHeading.Grammar'})
      : this.selectedUnitInstance?.unit.title;

    return (
      <Breadcrumb
        title={title}
        className={className}
        icon={icon}
        iconElement={iconElement}
        iconClassName="always-visible-icon"
        accessibilityTitle={accessibilityTitle}
        onClick={this.breadcrumbClickHandler}
      >
        <UnitInstancesDropdown
          elements={unitInstances}
          toggleDropdown={toggleDropdown}
          dropdownIsOpen={dropdownIsOpen}
          selectedUnitInstanceId={this.selectedUnitInstance?.id}
          isInGrammarPlayer={isInGrammarPlayer}
          goToUnitInstance={goToUnitInstance}
          goToUnitPreview={goToUnitPreview}
          goToGrammarPlayer={goToGrammarPlayer}
          hasGrammar={hasGrammar}
        />
      </Breadcrumb>
    );
  }

  private breadcrumbClickHandler = () => {
    if (!this.props.dropdownIsOpen) {
      this.props.toggleDropdown(true);
    }
  };

  private get selectedUnitInstance() {
    const {unitInstances, selectedUnitInstanceId} = this.props;
    return unitInstances.find(ui => ui.id === selectedUnitInstanceId);
  }
}

export default injectIntl(UnitInstancesBreadcrumb);
