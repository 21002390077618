import React from 'react';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import EditHomeworkCommentForm from './EditHomeworkCommentForm';
import Loader from '../../../../components/Loader';

interface Props {
  isLoading?: boolean;
  closeCommentEditor: () => void;
  initialComment?: string;
  save: (comment: string) => void;
  setCurrentValue(value: string): void;
  title: React.ReactNode;
}

export const CommentPopover: React.FC<Props> = ({
  isLoading,
  closeCommentEditor,
  initialComment,
  save,
  setCurrentValue,
  title
}) => {
  const {formatMessage} = useIntl();
  return (
    <div className="edit-homework-comment-popover">
      <b className="title">{title}:</b>
      <EditHomeworkCommentForm
        handleSubmit={save}
        submittingForm={isLoading}
        initialComment={initialComment}
        textAreaRows={3}
        setCurrentValue={setCurrentValue}
      >
        <div className="control-buttons">
          <Button
            bsSize="sm"
            className="btn-transparent"
            onClick={closeCommentEditor}
            disabled={isLoading}
          >
            {formatMessage({id: 'Common.Cancel'})}
          </Button>
          <Button
            bsSize="sm"
            bsStyle="primary"
            type="submit"
            className="submit-comment-button"
            disabled={isLoading}
          >
            <div className="loader-button-positioning-helper">
              <Loader shouldRender={isLoading} />
            </div>
            {formatMessage({id: 'Common.Save'})}
          </Button>
        </div>
      </EditHomeworkCommentForm>
    </div>
  );
};
