import React from 'react';
import {matchPath} from 'react-router-dom';
import memoize from 'fast-memoize';

import {coursePattern} from 'common/paths';
import {
  type GetActions,
  type WampAutoSubInjectedProps,
  withWampSubscription
} from 'services/wamp/withWampSubscription';
import Loader from 'components/Loader';
import {type WampSubscribeAction} from 'services/wamp/actions/interface';

import CourseInstanceRoute from './CourseInstanceRoute';
import {subscribeCourseEvents} from '../actions/action';
import {type StudentTeacherRouteProps} from './interface';

class StudentTeacherRoute extends React.Component<
  StudentTeacherRouteProps & WampAutoSubInjectedProps
> {
  public render() {
    const {courseInstanceId, courseInstances, wampSubscribed, courseOrSTInactive} = this.props;
    const activeCourseInstance = courseInstanceId && courseInstances?.[courseInstanceId];
    if (!activeCourseInstance) {
      return <Loader />;
    }
    return (
      <CourseInstanceRoute
        courseOrSTInactive={courseOrSTInactive}
        wampSubscribed={wampSubscribed}
      />
    );
  }
}

const memoizePath2Actions = memoize(
  (locationPath: string): WampSubscribeAction<{}, {}>[] | null => {
    const matched = matchPath({path: coursePattern, end: false}, locationPath);
    if (matched?.params.courseId) {
      return [subscribeCourseEvents(Number(matched.params.courseId))];
    }

    return null;
  }
);

const getActions: GetActions = getState =>
  memoizePath2Actions(getState().router.location!.pathname);

const SubscribedStudentTeacherRoute = withWampSubscription(getActions)(StudentTeacherRoute);

export default SubscribedStudentTeacherRoute;
