import * as React from 'react';

import {useIsomorphicLayoutEffect} from './useIsomorphicLayoutEffect';

export interface VisibilityChanged {
  isVisible: boolean;
  ratio: number;
}

interface UseIntersectionObserverProps {
  threshold?: number | number[];
  onVisibilityChanged(params: VisibilityChanged): void;
}

const useIntersectionObserver = (props: UseIntersectionObserverProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const {threshold, onVisibilityChanged} = props;

  useIsomorphicLayoutEffect(() => {
    const io = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const isVisible = entry.isIntersecting;
          const ratio = entry.intersectionRatio;
          onVisibilityChanged({isVisible, ratio});
        });
      },
      {
        threshold: threshold || 0
      }
    );
    const container = containerRef.current;
    if (!container) {
      return;
    }
    io.observe(container);

    return (): void => {
      io.unobserve(container);
    };
  }, [onVisibilityChanged, threshold]);

  return containerRef;
};

export default useIntersectionObserver;
