import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import classNames from 'classnames';

import Icon from 'components/Icon';

import VolumeSlider from '../VolumeSlider';
import {type VolumeControlDispatchProps, type VolumeControlStateProps} from './interface';

interface Props extends VolumeControlStateProps, VolumeControlDispatchProps {}

export default class VolumeControl extends React.Component<Props> {
  public componentWillUnmount() {
    this.props.toggleVolumePopover(false);
  }

  public render() {
    const {volume, changeVolume, showVolumePopover} = this.props;
    const volumeIconName =
      'volume-' + (volume === undefined || volume === 1 ? 'up' : volume === 0 ? 'off' : 'down');

    return (
      <div className="popover-overlay volume-popover">
        <Button
          className={classNames('btn-circle btn-volume', {active: showVolumePopover})}
          onClick={this.iconOnClickHandler}
        >
          <Icon
            name={volumeIconName}
            className={classNames({
              danger: volumeIconName === 'volume-off'
            })}
          />
        </Button>
        <Overlay
          rootClose={true}
          placement="bottom"
          container={this}
          animation={false}
          show={showVolumePopover}
          onHide={this.overlayOnHideHandler}
          target={this}
        >
          <Popover
            id="volume-popover"
            className="volume-popover"
            arrowOffsetLeft="50%"
            onClick={this.onPopoverClick}
          >
            <VolumeSlider volume={volume} changeVolume={changeVolume} />
          </Popover>
        </Overlay>
      </div>
    );
  }

  private iconOnClickHandler = (e: React.SyntheticEvent<Button>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.toggleVolumePopover(!this.props.showVolumePopover);
  };

  private overlayOnHideHandler = () => {
    this.props.toggleVolumePopover(false);
  };

  private onPopoverClick = (e: React.MouseEvent<Popover>) => {
    // prevent switching to this tab when clicking on popover empty space
    e.preventDefault();
    e.stopPropagation();
  };
}
