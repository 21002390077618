import React from 'react';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import Tab from 'react-bootstrap/lib/Tab';
import {defineMessages, injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {type SelectCallback} from 'components/interface';
import Loader from 'components/Loader';
import {MaterialsTab} from 'common/enums';

import {DocumentsContent} from '../documentsTab/components/DocumentsContent';
import DocumentsTabContainer from '../documentsTab/components/DocumentsTab';
import DocumentsTabView from '../documentsTab/components/DocumentsTab/DocumentsTab';
import MaterialsSearchBar from '../MaterialsSearchContainer';
import AudioTab from '../soundsTab/containers/AudioTab';
import ConnectedSoundsList from '../soundsTab/containers/SoundsList';
import {ThirdTab} from '../uploadingFiles/components/UploadTab';
import UploadsList from '../uploadingFiles/containers/UploadsList';
import {isMaterialsTab, materialsTabParamName, type WorkSpaceProps} from './interface';
import './tabs.scss';

const messages = defineMessages({
  ExpandWorkspaceToFullScreen: {
    id: 'File.ExpandWorkspaceToFullScreen'
  },
  ResetWorkspaceFromFullScreen: {
    id: 'File.ResetWorkspaceFromFullScreen'
  },
  FindAFile: {
    id: 'File.FindAFile'
  }
});

type Props = WorkSpaceProps & WrappedComponentProps;

class WorkSpace extends React.PureComponent<Props, {}> {
  public componentDidMount(): void {
    this.props.resetFiles(); // moved from constructor with comment: "this code fixes runtime error that was occurring if user navigates to courseInstance files from homework document page"
    const {
      courseId,
      loadSounds,
      loadDocuments,
      getRecentDocuments,
      getRecentSounds,
      homeworkDoc,
      shouldRenderUploadsTab,
      wampSubscribed
    } = this.props;
    if (wampSubscribed) {
      !homeworkDoc && loadDocuments(Number(courseId));
      loadSounds(Number(courseId));
    }
    if (shouldRenderUploadsTab) {
      getRecentDocuments();
      getRecentSounds();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      loadSounds,
      loadDocuments,
      courseId,
      homeworkDoc,
      wampSubscribed,
      appOnline,
      soundsLoaded,
      openWhenLoadedSoundId,
      playSound,
      documents,
      selectedDocId,
      unselectDoc,
      documentViewed,
      clearOpenWhenLoadedSound,
      openTab
    } = this.props;
    const {wampSubscribed: prevWampSubscribed, appOnline: prevAppOnline} = prevProps;
    if (!courseId) {
      return;
    }
    if (
      (wampSubscribed && !prevWampSubscribed) ||
      // on Chrome Ubuntu app sometimes goes offline without WAMP being disconnected;
      // still, we want to refresh documents and sounds lists when user returns back online;
      (appOnline && !prevAppOnline && wampSubscribed)
    ) {
      loadSounds(Number(courseId));
      !homeworkDoc && loadDocuments(Number(courseId));
    }
    if (
      !prevProps.soundsLoaded &&
      soundsLoaded &&
      openWhenLoadedSoundId &&
      clearOpenWhenLoadedSound
    ) {
      playSound(openWhenLoadedSoundId);
      clearOpenWhenLoadedSound();
    }
    if (documents && selectedDocId && !documents[selectedDocId] && unselectDoc) {
      unselectDoc();
    }
    if (
      documents &&
      selectedDocId &&
      selectedDocId !== prevProps.selectedDocId &&
      documents[selectedDocId] &&
      documents[selectedDocId].isNew
    ) {
      documentViewed(selectedDocId);
    }
    if (this.tabSearchParam && !isMaterialsTab(this.tabSearchParam)) {
      openTab(MaterialsTab.DOCUMENTS);
    }
  }

  public componentWillUnmount(): void {
    this.props.resetFiles();
  }

  public render() {
    const {
      documents,
      soundsLoaded,
      readonly,
      courseId,
      studentTeacherId,
      studentTeachers,
      homeworkDoc,
      selectedPageNumber,
      shouldRenderUploadsTab,
      selectedDocId,
      selectDoc,
      children
    } = this.props;
    const {openedTabId} = this;
    return (
      <div className="files-work-space">
        <Tab.Container
          id="work-space-tabs"
          activeKey={openedTabId}
          className="files-work-space-tabs"
          onSelect={this.tabsOnSelectHandler as SelectCallback}
        >
          <div>
            <div
              className={classNames('tabs-header-panel', openedTabId, {
                'is-doc-opened':
                  openedTabId === MaterialsTab.DOCUMENTS && !!(selectedDocId || homeworkDoc)
              })}
            >
              <Nav bsStyle="tabs">
                {
                  // we can't just return null or undefined instead of third tab for student, bc of bug in react-bootstrap:
                  // when user presses left or right arrow, the lib treats null or undefined as valid react element which causes app to crash
                  // todo: try to simplify logic once react-bootstrap is updated to version higher then 0.32.4
                  [
                    this.renderDocumentsTab(),
                    this.renderAudioTab(),
                    ...(shouldRenderUploadsTab ? [this.renderUploadsTab()] : [])
                  ]
                }
              </Nav>
              <div className="search">{this.renderSearch()}</div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey={MaterialsTab.DOCUMENTS}>
                {!documents ? (
                  <Loader />
                ) : (
                  <DocumentsContent
                    readonly={readonly}
                    courseId={String(courseId)}
                    studentTeacherId={studentTeacherId}
                    homeworkDoc={homeworkDoc}
                    selectedDocId={selectedDocId}
                    selectedDocPageNumber={selectedPageNumber}
                    selectDocument={selectDoc || this.emptyFunction}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={MaterialsTab.AUDIO}>
                {!soundsLoaded ? (
                  <Loader />
                ) : (
                  <ConnectedSoundsList
                    readonly={readonly}
                    courseId={String(courseId)}
                    studentTeacherId={studentTeacherId}
                    selectDocument={this.emptyFunction}
                  />
                )}
              </Tab.Pane>
              {shouldRenderUploadsTab && studentTeachers && (
                <Tab.Pane eventKey={MaterialsTab.UPLOADS}>
                  <UploadsList studentTeachers={studentTeachers} />
                </Tab.Pane>
              )}
            </Tab.Content>
          </div>
        </Tab.Container>
        {children}
      </div>
    );
  }

  private renderDocumentsTab = () => {
    const {homeworkDoc, role, selectedDocId, unselectDoc} = this.props;
    return (
      <NavItem eventKey={MaterialsTab.DOCUMENTS} key="1">
        {homeworkDoc ? (
          <DocumentsTabView
            activeDocument={homeworkDoc}
            role={role}
            collapsed={this.props.searchBarExpanded}
          />
        ) : (
          <DocumentsTabContainer
            selectedDocId={selectedDocId}
            returnToDocumentsList={unselectDoc}
          />
        )}
      </NavItem>
    );
  };

  private renderAudioTab = () => (
    <NavItem eventKey={MaterialsTab.AUDIO} key="2">
      <AudioTab />
    </NavItem>
  );

  private renderUploadsTab = () => {
    const {uploadsNumber, searchBarExpanded, uploadsSearchResultsNum} = this.props;
    return (
      <NavItem eventKey={MaterialsTab.UPLOADS} key="3">
        <ThirdTab
          uploadsNumber={uploadsNumber}
          searchResultsNumber={uploadsSearchResultsNum}
          collapsed={searchBarExpanded}
        />
      </NavItem>
    );
  };

  private emptyFunction = () => null;

  private tabsOnSelectHandler = (eventKey: MaterialsTab) => {
    const {
      props: {openTab},
      openedTabId
    } = this;
    if (openedTabId !== String(eventKey)) {
      openTab(eventKey);
    }
  };

  private renderSearch = () => {
    const {intl, searchBarExpanded} = this.props;
    if (searchBarExpanded) {
      return <MaterialsSearchBar />;
    }
    return (
      <Icon
        name="virc-search"
        size="lg"
        onClick={this.props.openMaterialsSearchBar}
        title={intl.formatMessage(messages.FindAFile)}
        className="open-searchbar-icon"
      />
    );
  };

  private get openedTabId(): MaterialsTab {
    const {tabSearchParam} = this;

    if (!tabSearchParam || !isMaterialsTab(tabSearchParam)) {
      return MaterialsTab.DOCUMENTS;
    }
    return tabSearchParam;
  }

  private get tabSearchParam(): string | null {
    return new URLSearchParams(this.props.searchParam).get(
      materialsTabParamName
    ) as MaterialsTab | null;
  }
}

export default injectIntl(WorkSpace);
