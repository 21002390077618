import React, {type FC} from 'react';
import {defineMessages, FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {type Action, type Dispatch} from 'redux';
import {connect} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import {type AppState} from 'store/interface';
import {openingUrlForStudentTimeout} from 'config/static';
import {urlOpened, publishOpenUrl, setOpeningUrlForPartner} from 'common/action';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {type ClassroomUrlParams} from 'common/paths';

import './DisplayButton.scss';

interface StateProps {
  partnerSessionId: number;
  openingUrlForStudent?: boolean;
}

interface DispatchProps {
  openUrlForStudent: (courseId: number, url: string, partnerSessionId: number) => void;
  setOpeningUrlForStudent: () => void;
  clearOpeningUrlForStudent: () => void;
}

interface RouteProps {
  courseInstanceId: number;
  urlForStudent: string;
}

interface Props extends StateProps, DispatchProps, RouteProps, WrappedComponentProps {}

const messages = defineMessages({
  UrlOpeningSuccess: {
    id: 'LessonPage.URLOpenedSuccessfully'
  },
  UrlOpeningFail: {
    id: 'LessonPage.URLOpeningFailed'
  }
});

class DisplayButtonView extends React.Component<Props> {
  private openingTimeout?: NodeJS.Timeout;

  public componentWillUnmount(): void {
    if (this.openingTimeout) {
      this.openingTimeout = undefined;
      clearTimeout(this.openingTimeout);
    }
    if (this.props.openingUrlForStudent) {
      this.props.clearOpeningUrlForStudent();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const {
      openingUrlForStudent,
      intl: {formatMessage}
    } = this.props;
    if (prevProps.openingUrlForStudent && !openingUrlForStudent && this.openingTimeout) {
      clearTimeout(this.openingTimeout);
      this.openingTimeout = undefined;
      this.props.clearOpeningUrlForStudent();
      toastr.success('', formatMessage(messages.UrlOpeningSuccess));
    }
  }

  public render() {
    const {intl, openingUrlForStudent} = this.props;
    return (
      <Button
        className="display-button"
        bsStyle="success"
        disabled={openingUrlForStudent}
        onClick={this.handleClick}
        title={intl.formatMessage({id: 'LessonPage.Display'})}
      >
        <Icon name={openingUrlForStudent ? 'refresh' : 'virc-target'} spin={openingUrlForStudent} />
        <FormattedMessage id="LessonPage.Display" />
      </Button>
    );
  }

  private handleClick = () => {
    const {
      openUrlForStudent,
      partnerSessionId,
      openingUrlForStudent,
      courseInstanceId,
      urlForStudent
    } = this.props;
    if (openingUrlForStudent) {
      return;
    }
    this.openingTimeout = setTimeout(this.handleNoResponse, openingUrlForStudentTimeout);
    openUrlForStudent(courseInstanceId, urlForStudent, partnerSessionId);
    this.props.setOpeningUrlForStudent();
  };

  private handleNoResponse = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.openingTimeout = undefined;
    this.props.clearOpeningUrlForStudent();
    toastr.error('', formatMessage(messages.UrlOpeningFail));
  };
}

const mapStateToProps = (state: AppState): StateProps => ({
  partnerSessionId: state.rtc.partnerSessionId!,
  openingUrlForStudent: state.classroom!.openingUrlForPartner
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  openUrlForStudent: (courseId: number, url: string, partnerSession: number) =>
    dispatch(publishOpenUrl({courseId, url, partnerSession})),
  setOpeningUrlForStudent: () => dispatch(setOpeningUrlForPartner()),
  clearOpeningUrlForStudent: () => dispatch(urlOpened())
});

const Connected = injectIntl(connect(mapStateToProps, mapDispatchToProps)(DisplayButtonView));

const DisplayButton: FC = () => {
  const {courseId} = useParams<ClassroomUrlParams>();
  const {pathname, search} = useLocation();
  return <Connected courseInstanceId={Number(courseId)} urlForStudent={pathname + search} />;
};

export default DisplayButton;
