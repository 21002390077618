import React from 'react';
import classNames from 'classnames';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import {type HomeworkWithContents} from 'store/interface';
import lineImage from 'components/Breadcrumbs/assets/Line.svg';

import {getHomeworkLabel} from '../../pages/HomeworkPage/utils';

interface Props extends WrappedComponentProps {
  homework: HomeworkWithContents;
  activeHomeworkId: string;
  userTimeZone: string;
}

class HomeworkBreadcrumb extends React.Component<Props> {
  public render() {
    const className: string = classNames('breadcrumb');
    return (
      <div className={className} title={this.title}>
        <span className="breadcrumb-title">{this.title}</span>
        <img src={lineImage} className="triangle-divider" alt="" />
      </div>
    );
  }

  private get title() {
    const {userTimeZone, intl} = this.props;
    return getHomeworkLabel(this.props.homework, userTimeZone, intl);
  }
}

export default injectIntl(HomeworkBreadcrumb);
