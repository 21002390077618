import Button from 'react-bootstrap/lib/Button';
import React, {type FC} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import Tooltip from 'rc-tooltip';

import Icon from 'components/Icon';

interface Props {
  grammarUrl?: string;
  onShowGrammarClick?: () => void;
  previewHasGrammar?: boolean;
  hasGrammar?: boolean;
  overlayClassName?: string;
}

export const GrammarBankButton: FC<Props> = ({
  grammarUrl,
  onShowGrammarClick,
  previewHasGrammar,
  hasGrammar,
  overlayClassName = 'btn-tooltip'
}) => {
  return (
    <>
      {onShowGrammarClick && previewHasGrammar && (
        <Tooltip
          overlay={<FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />}
          overlayClassName={overlayClassName}
          placement="top"
          defaultVisible={false}
          trigger={['hover']}
        >
          <Button bsSize="sm" onClick={onShowGrammarClick}>
            <Icon name="pc-course" tag="i" />{' '}
            <FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />
          </Button>
        </Tooltip>
      )}
      {hasGrammar && (
        <Tooltip
          overlay={<FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />}
          overlayClassName="coursebook-btn-tooltip"
          placement="top"
          defaultVisible={false}
          trigger={['hover']}
        >
          <Link to={grammarUrl!} className="btn btn-sm btn-default">
            <Icon name="pc-course" tag="i" />
            <FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />
          </Link>
        </Tooltip>
      )}
    </>
  );
};
