import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {LibraryModal} from '../../../Library/LibraryModal';

interface Props {
  blocked: boolean;
  openLibraryModal(): void;
}

const LibraryButton: FC<Props> = ({blocked, openLibraryModal}) => (
  <>
    <Button bsStyle="default" bsSize="sm" disabled={blocked} onClick={openLibraryModal}>
      <Icon name="enlx-logo" />
      <FormattedMessage id="File.FromLibrary" />
    </Button>
    <LibraryModal />
  </>
);

export default LibraryButton;
