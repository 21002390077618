import React, {type PropsWithChildren} from 'react';
import {type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import {visibleOnlyTeacher} from 'authentication';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import {FileExtension} from 'common/enums';

import {type FileType} from '../../../../actions/interface';
import PinButton from './PinButton';
import {AddToHomeworkControl} from './DocumentFileControl/AddToHomeworkControl';
import {audioMessages, documentMessages, fileMessages} from '../../messages';

type Props = PropsWithChildren<{
  readonly: boolean;
  fileIsBeingEdited?: boolean;
  editFileAwait?: boolean;
  componentBeingEdited: boolean;
  deleteFileAwait?: boolean;
  openTogetherFileId?: number;
  isBeingOpenedTogether?: boolean;
  fileType: FileType;
  fileExt: FileExtension;
  saveEditingChanges: (e: React.MouseEvent<HTMLSpanElement>) => void;
  discardEditingChanges: (e: React.MouseEvent<HTMLSpanElement>) => void;
  fileId: number;
  pinnedBlock?: boolean;
  openTogether?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  publish?: (courseInstanceId?: number) => void;
}>;

class RightIcons extends React.PureComponent<Props & WrappedComponentProps> {
  public render() {
    const {readonly, componentBeingEdited, fileIsBeingEdited} = this.props;
    if (!readonly) {
      if (!componentBeingEdited) {
        return this.renderRightIconsClosedEditor();
      } else if (fileIsBeingEdited && componentBeingEdited) {
        return this.renderRightIconsOpenedEditor();
      }
    }
    return null;
  }

  protected renderRightIconsOpenedEditor = () => {
    const {componentBeingEdited, editFileAwait, intl} = this.props;
    const editingRequestSent = componentBeingEdited && editFileAwait;
    const firstButton = (
      <button
        onClick={this.props.saveEditingChanges}
        title={intl.formatMessage(fileMessages.EditFileConfirm)}
        className="btn btn-circle"
        disabled={editingRequestSent}
      >
        {editingRequestSent ? <Spinner size={17} /> : <Icon name="check" size="lg" />}
      </button>
    );

    return (
      <div className="file-control-right">
        {firstButton}
        <button
          onClick={this.props.discardEditingChanges}
          className={classNames('btn', 'btn-circle', 'cancel-editing-button')}
          title={intl.formatMessage(fileMessages.EditFileReject)}
          disabled={editingRequestSent}
        >
          <Icon name="times" size="lg" />
        </button>
      </div>
    );
  };

  protected renderRightIconsClosedEditor = () => {
    const {
      fileId,
      intl,
      pinnedBlock,
      publish,
      fileType,
      fileExt,
      isBeingOpenedTogether,
      deleteFileAwait,
      openTogetherFileId,
      openTogether
    } = this.props;
    const isDocument = fileType === 'document';

    const className = classNames('file-control-right', {
      active: isBeingOpenedTogether
    });
    const playTogetherClassName = classNames('btn', 'btn-circle', 'play-together', {
      deactivated: !!(deleteFileAwait || (!isBeingOpenedTogether && openTogetherFileId))
    });
    const title = intl.formatMessage(
      fileType === 'audio'
        ? audioMessages.PlayTogetherMessage
        : documentMessages.ViewTogetherMessage
    );

    return (
      <div className={className}>
        {isDocument && fileExt !== FileExtension.pdf ? (
          <AddToHomeworkControl docInstanceId={fileId} isPinned={pinnedBlock} />
        ) : null}
        <PinButton
          intl={intl}
          fileId={fileId}
          fileType={fileType}
          pinnedBlock={pinnedBlock}
          publish={publish}
        />
        {isDocument && (
          <button className={playTogetherClassName} onClick={openTogether}>
            <Icon name="virc-play_together" size="lg" className="play-together" title={title} />
          </button>
        )}
      </div>
    );
  };
}

export default visibleOnlyTeacher(RightIcons);
