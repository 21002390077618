import 'config/react-pdf';
import React, {type FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {Portal} from 'react-portal';
import type {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';
import {unstable_batchedUpdates} from 'react-dom';
import {Focusable} from '@englex/paint-react';

import PageControls from 'components/PageControls';
import {Pager} from 'components/UnitContents/Pager';
import {useContentsList} from 'components/UnitContents/useContenstList';
import {PDFContents} from 'components/PDFViewer/contents/PDFContents';

import {PDFViewerDocument} from './PDFViewerDocument';
import SimpleLoadingOverlay from '../loaders/overlay/SimpleLoadingOverlay';
import {ErrorMask} from '../ErrorMask';
import {type PDFViewerProps} from './interface';
import {PDFViewerPageProvider, usePDFPageCtxState} from './PDFViewerPageContext';

import './PDFViewer.scss';

export const PDFViewer: FC<PDFViewerProps> = ({
  url,
  pageNumber: propsPageNumber,
  hidePager,
  tocPortalNodeClassName,
  goToPage,
  children
}) => {
  const [numPages, setNumPages] = useState(null);
  const [localPageNumber, setLocalPageNumber] = useState<number | null>(
    !goToPage ? propsPageNumber || 1 : null
  );
  const [pdf, setPdf] = useState<null | PDFDocumentProxy>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const reloadRef = useRef<(() => void) | undefined>();

  const pageNumber: number = localPageNumber || propsPageNumber || 1;

  const hasChildren = !!children;

  const pageCtx = usePDFPageCtxState(hasChildren);
  const {childrenError, setChildrenError, setChildrenLoading, fitToWidth, setScale, setViewport} =
    pageCtx;

  const setPageNumber = useCallback(
    (page: number) => {
      unstable_batchedUpdates(() => {
        if (pageNumber !== page) {
          childrenError && setChildrenError();
          setError(false);
          setLoading(true);
          goToPage ? goToPage(page) : setLocalPageNumber(page);
          setViewport(null);
          fitToWidth && setScale(null);
          hasChildren && setChildrenLoading(true);
        }
      });
    },
    [
      childrenError,
      fitToWidth,
      goToPage,
      hasChildren,
      pageNumber,
      setChildrenError,
      setChildrenLoading,
      setScale,
      setViewport
    ]
  );
  const reload = useCallback(() => {
    if (reloadRef.current) {
      unstable_batchedUpdates(() => {
        setLoading(true);
        setError(false);
      });
    }

    setTimeout(() => (reloadRef.current ? reloadRef.current?.() : childrenError?.()), 400);
  }, [childrenError]);

  const onLoad = useCallback(
    (onErrorRefresh, pdf) => {
      unstable_batchedUpdates(() => {
        if (pdf) {
          setError(false);
          reloadRef.current = undefined;
          if (pageNumber > pdf.numPages) {
            setPageNumber(pdf.numPages);
          }
        }
        const maxPageNumber: null | number = numPages || null;
        const isPageNumberOverflow = !!maxPageNumber && pageNumber > maxPageNumber;

        setLoading(false);
        if (onErrorRefresh) {
          if (!isPageNumberOverflow) {
            setError(true);
          } else {
            setLoading(true);
          }

          reloadRef.current = onErrorRefresh;
        }

        setPdf(pdf);
        setNumPages(pdf ? pdf.numPages : null);
      });
    },
    [numPages, pageNumber, setPageNumber]
  );

  const tocPortalNode = useMemo(
    () => tocPortalNodeClassName && document.querySelector(`.${tocPortalNodeClassName}`),
    [tocPortalNodeClassName]
  );

  useEffect(() => {
    if (pageNumber < 1) {
      setPageNumber(1);
    }
  }, [pageNumber, setPageNumber]);

  const {hasNext, hasPrev, listIsOpened, toggleList} = useContentsList(numPages, pageNumber);

  const pageControls =
    !hidePager && pdf ? (
      <Pager
        batchListToggle={toggleList}
        currentPage={pageNumber}
        hasNext={hasNext}
        hasPrev={hasPrev}
        listIsOpened={listIsOpened}
        pageCount={numPages}
        selectPage={setPageNumber}
        toggleList={toggleList}
        disableButtons={loading || pageCtx.childrenLoading}
      />
    ) : null;

  return (
    <PDFViewerPageProvider value={pageCtx}>
      <Focusable className="pdf-viewer">
        <div className="pdf-viewer-canvas">
          <div className="pdf-viewer-container">
            <Scrollbars>
              <PDFViewerDocument url={url} onLoad={onLoad} pageNumber={pageNumber}>
                {children}
              </PDFViewerDocument>
            </Scrollbars>
            {!hidePager && (
              <PDFContents
                doc={pdf}
                currentPage={pageNumber}
                show={listIsOpened}
                toggle={toggleList}
                selectPage={setPageNumber}
              />
            )}
          </div>
          <div
            className="pdf-viewer-toolbar-portal"
            ref={(e: HTMLDivElement | null) => pageCtx.setToolbarPortalNode(e)}
          />
        </div>
        {pageControls ? (
          tocPortalNodeClassName && tocPortalNode ? (
            <Portal node={tocPortalNode}>{pageControls}</Portal>
          ) : (
            <PageControls>{pageControls}</PageControls>
          )
        ) : null}
        <SimpleLoadingOverlay
          className="pdf-viewer__loader"
          isLoading={loading || pageCtx.childrenLoading}
        />
        {!(loading || pageCtx.childrenLoading) && (error || !!pageCtx.childrenError) && (
          <ErrorMask reload={reload} />
        )}
      </Focusable>
    </PDFViewerPageProvider>
  );
};
