import React, {type FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Tooltip from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';

import Icon from 'components/Icon';

import i18n from './i18n';

interface Props {
  blocked: boolean;
  isOver: boolean;
  fileName: string;
  isDocument: boolean;
  placement: 'bottom' | 'top';
}

const TextBlock: FC<Props> = ({blocked, isOver, fileName, isDocument, placement}) => {
  const intl = useIntl();
  if (blocked) {
    return <span className="filename-blocked">{fileName}</span>;
  }
  if (isOver) {
    return (
      <span>
        <Icon name="virc-upload" size="lg" />
        <FormattedMessage id="Common.Upload" />
      </span>
    );
  }
  return (
    <div className="tooltip-trigger">
      <FormattedMessage id="File.DragOrUploadSign" />
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip className="file-upload-tooltip white" id="tooltip">
            {isDocument
              ? intl.formatMessage(i18n.DocumentTooltip)
              : intl.formatMessage(i18n.AudioFileTooltip)}
          </Tooltip>
        }
      >
        <span className="info-icon">
          <Icon name="info-circle" />
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default TextBlock;
