import React, {useCallback, useEffect} from 'react';
import {type FormikBag, type InjectedFormikProps, withFormik} from 'formik';
import {defineMessages, injectIntl, type WrappedComponentProps} from 'react-intl';
import * as Yup from 'yup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ReactRouterPrompt from 'react-router-prompt';

import Confirm from 'components/modals/Confirm';

const maxCommentLength = 4096;

const messages = defineMessages({
  CommentEmpty: {
    id: 'LessonPage.HomeworkCommentForm.CommentEmptyError'
  },
  CommentTooLong: {
    id: 'LessonPage.HomeworkCommentForm.CommentTooLongError'
  },
  CommentPlaceholder: {
    id: 'LessonPage.HomeworkCommentForm.InputPlaceholder'
  },
  LeavePageConfirm: {
    id: 'LessonPage.HomeworkCommentForm.LeavePageConfirm'
  }
});

interface Values {
  comment: string;
}

interface OwnProps {
  handleSubmit: (comment: string) => void;
  initialComment?: string;
  submittingForm?: boolean;
  FormWrapper?: React.ComponentType;
  textAreaRows: number;
  inputSize?: 'lg';
  setCurrentValue?: (value: string) => void;
}

type Props = OwnProps & WrappedComponentProps;

type FormProps = InjectedFormikProps<Props, Values>;

const beforeUnloadHandler = (event: Event) => (event.returnValue = true);

const EditHomeworkCommentForm: React.FC<FormProps> = ({
  errors,
  touched,
  handleSubmit,
  intl: {formatMessage},
  handleBlur,
  values,
  handleChange,
  setFieldTouched,
  children,
  submittingForm,
  FormWrapper,
  textAreaRows,
  inputSize,
  initialComment,
  setCurrentValue
}) => {
  const onChange = useCallback(
    (e: React.FormEvent<FormControl>) => {
      // set field as touched so that validation message can appear before input blurred
      setFieldTouched('comment');
      handleChange(e);
    },
    [setFieldTouched, handleChange]
  );

  const commentChanged = values.comment !== initialComment;
  useEffect(() => {
    if (commentChanged) {
      if (setCurrentValue) setCurrentValue(values.comment);
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }
  }, [commentChanged, setCurrentValue, values.comment]);

  useEffect(() => {
    if (setCurrentValue) setCurrentValue(values.comment);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formGroup = (
    <FormGroup
      controlId="comment"
      validationState={errors.comment && touched.comment ? 'error' : undefined}
    >
      <FormControl
        name="comment"
        bsSize={inputSize}
        componentClass="textarea"
        placeholder={formatMessage(messages.CommentPlaceholder)}
        rows={textAreaRows}
        value={values.comment}
        autoComplete="off"
        onBlur={handleBlur}
        onChange={onChange}
        disabled={submittingForm}
      />
      <div className="help-block error">{errors.comment}</div>
    </FormGroup>
  );

  return (
    <form onSubmit={handleSubmit}>
      {FormWrapper ? <FormWrapper>{formGroup}</FormWrapper> : formGroup}
      {children}
      <ReactRouterPrompt when={commentChanged}>
        {({isActive, onConfirm, onCancel}) => (
          <Confirm
            show={isActive}
            headerText={formatMessage(messages.LeavePageConfirm)}
            hideCloseButton={true}
            disableButtons={false}
            onAccept={onConfirm}
            onDecline={onCancel}
          />
        )}
      </ReactRouterPrompt>
    </form>
  );
};

const mapPropsToValues = (props: Props): Values => ({
  comment: props.initialComment || ''
});

const handleSubmit = (values: Values, {props}: FormikBag<Props, Values>) => {
  props.handleSubmit(values.comment);
};

const validationSchema = ({intl: {formatMessage}, touched}: FormProps) =>
  Yup.object().shape({
    comment: Yup.string()
      .trim()
      .required(formatMessage(messages.CommentEmpty))
      .max(maxCommentLength, formatMessage(messages.CommentTooLong, {length: maxCommentLength}))
  });

const WithFormik = withFormik<Props, Values>({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  validateOnChange: true
})(EditHomeworkCommentForm);

export default injectIntl(WithFormik);
