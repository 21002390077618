import React, {type FC, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import {type ClassroomUrlParams} from 'common/paths';

import {AudioTogetherContext} from '../context/AudioTogetherContext';

import './TogetherButton.scss';

export const TogetherButton: FC = React.memo(() => {
  const intl = useIntl();
  const {studentTeacherId} = useParams<ClassroomUrlParams>();

  const {isTogether, toggleIsTogether, turnOffTogether} = useContext(AudioTogetherContext);

  const canPlayTogether = useCanPlayTogether({studentTeacherId});

  useEffect(() => {
    if (!canPlayTogether) {
      turnOffTogether();
    }
  }, [turnOffTogether, canPlayTogether]);

  if (canPlayTogether) {
    return (
      <button
        className={classNames('btn play-together', {active: isTogether})}
        onClick={toggleIsTogether}
        title={intl.formatMessage({id: 'File.PlayTogether'})}
      >
        <Icon name="virc-listen-together" />
      </button>
    );
  }

  return null;
});
