import React, {type FC} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {type AppState, type HomeworkWithContents} from 'store/interface';

import {type HomeworkPlayerUrlParams} from '../pages/HomeworkPage/interface';
import HomeworkBreadcrumbView from '../breadcrumbs/components/HomeworkBreadcrumb';

interface Props {
  homework: HomeworkWithContents;
}

export const HomeworkBreadcrumb: FC<Props> = ({homework}) => {
  const {homeworkId} = useParams<HomeworkPlayerUrlParams>();
  const userTimezone = useSelector((state: AppState) => state.user!.profile!.timeZone);

  return (
    <HomeworkBreadcrumbView
      homework={homework}
      activeHomeworkId={homeworkId!}
      userTimeZone={userTimezone}
    />
  );
};
