import React, {type CSSProperties} from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';

import './PieProgress.scss';

interface Props {
  dimmed?: boolean;
  size: number;
  filled: number;
  total: number;
  title?: string | JSX.Element;
}

class PieProgress extends React.Component<Props> {
  public render() {
    const {dimmed, size, title} = this.props;
    return (
      <Tooltip
        trigger={title ? ['hover'] : []}
        overlay={this.tooltipOverlay}
        placement="top"
        overlayClassName="pie-progress-tooltip"
      >
        <div
          className={classNames('pie-progress', {dimmed: dimmed})}
          style={{height: size, width: size}}
        >
          <div className="pie-progress-chart">
            <div
              className="filled-segment"
              style={
                {
                  '--filled': this.filledPercents,
                  '--over50': this.moreThenHalfFilled && 1
                } as CSSProperties
              }
            />
          </div>
        </div>
      </Tooltip>
    );
  }

  private get filledPercents() {
    const {filled, total} = this.props;
    return filled / (total / 100);
  }

  private get moreThenHalfFilled() {
    return this.filledPercents > 50;
  }

  private tooltipOverlay = () => <div onClick={this.onTooltipContentClick}>{this.props.title}</div>;

  private onTooltipContentClick = (e: React.MouseEvent) => e.stopPropagation();
}

export default PieProgress;
