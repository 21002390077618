import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';

import {type VolumeControlDispatchProps, type VolumeControlStateProps} from './interface';
import VolumeControl from './VolumeControl';
import {changeVolume, toggleVolumePopover} from '../../actions/action';

type VolumePopoverStateToProps = (state: AppState) => VolumeControlStateProps;
type VolumePopoverDispatchToProps = (dispatch: Dispatch<Action>) => VolumeControlDispatchProps;

const mapStateToProps: VolumePopoverStateToProps = state => ({
  volume: state.sounds!.volume,
  showVolumePopover: state.sounds!.volumePopoverOpen
});

const mapDispatchToProps: VolumePopoverDispatchToProps = dispatch => ({
  changeVolume: (volume: number) => dispatch(changeVolume(volume)),
  toggleVolumePopover: (show: boolean) => dispatch(toggleVolumePopover(show))
});

export default connect<VolumeControlStateProps, VolumeControlDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(VolumeControl);
