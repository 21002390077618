import React from 'react';

export interface AddCoursebookStateManagement {
  selectCoursebook: (id: string) => void;
  viewCoursebookGrammar: (id: string) => void;
  viewCoursebookContents: (id: string) => void;
  addCoursebook: (id: string) => void;
  isAlreadyAdded: (id: string) => boolean;
}

export const AddCoursebookStateManagementContext =
  React.createContext<AddCoursebookStateManagement>({
    selectCoursebook: () => null,
    viewCoursebookGrammar: () => null,
    viewCoursebookContents: () => null,
    addCoursebook: () => null,
    isAlreadyAdded: () => false
  });
