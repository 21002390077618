import React from 'react';
import {FormattedMessage} from 'react-intl';

export const NotFound: React.FC = () => {
  return (
    <div className="not-found">
      <FormattedMessage id="LibraryModal.NotFound" />
    </div>
  );
};
