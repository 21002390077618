import React, {type FC, useCallback} from 'react';
import {PaintTool} from '@englex/paint-react';

import {
  CloneButton,
  ColorButton,
  DebugButton,
  DeleteButton,
  DownloadPNGButton,
  FontSizePicker,
  HistoryButton,
  OrientationButton,
  ScaleButton,
  SelectButton,
  Separator,
  ThicknessButton,
  Toolbar,
  ToolButton,
  YSyncHwStatus
} from 'components/Paint/Toolbar';

import {type PainterProps} from './Painter/Painter';
import {usePDFViewerPage} from '../PDFViewer/PDFViewerPageContext';
import {YPainter} from './YPainter';

type Props = Omit<PainterProps, 'stage' | 'children' | 'scale'> & {
  docId: string;
};

export const PDFPainter: FC<Props> = ({docId}) => {
  const {
    setChildrenError,
    setChildrenLoading,
    width,
    height,
    scale,
    orientation,
    setScale,
    rotatePage,
    fitToWidth,
    setFitToWidth,
    toolbarPortalNode
  } = usePDFViewerPage();
  const renderNull = useCallback(() => null, []);
  if (!width || !height || !scale) {
    return null;
  }

  return (
    <YPainter
      toolbarPortalNode={toolbarPortalNode}
      docId={docId}
      scale={scale}
      orientation={orientation}
      renderError={renderNull}
      renderLoader={renderNull}
      onError={setChildrenError}
      onLoading={setChildrenLoading}
    >
      <Toolbar empty={true}>
        <SelectButton />
        <CloneButton />
        <DeleteButton />

        <Separator />

        <ColorButton />
        <ThicknessButton />

        <Separator />

        <ToolButton tool={PaintTool.Pencil} iconName="pencil" />
        <ToolButton tool={PaintTool.Highlighter} iconName="virc-border-color" />
        <ToolButton tool={PaintTool.Line} iconName="virc-line" />
        <ToolButton tool={PaintTool.Arrow} iconName="arrow-popup" />
        <ToolButton tool={PaintTool.Rect} iconName="rectangle-popup" />
        <ToolButton tool={PaintTool.Ellipse} iconName="virc-ellipse" />

        <Separator />

        <ToolButton tool={PaintTool.Text} iconName="font" />
        <FontSizePicker />

        <Separator />

        <HistoryButton type="undo" />
        <HistoryButton type="redo" />

        <Separator />

        {import.meta.env.MODE === 'development' && (
          <>
            <DebugButton />
            <DownloadPNGButton />
          </>
        )}

        <Separator />

        <ScaleButton
          scale={scale}
          setScale={setScale}
          fitToWidth={fitToWidth}
          setFitToWidth={setFitToWidth}
        />

        <OrientationButton clockwise={false} rotate={rotatePage} />
        <OrientationButton rotate={rotatePage} />

        <Separator />

        <YSyncHwStatus />
      </Toolbar>
    </YPainter>
  );
};
