import {LOCATION_CHANGE} from 'store/router';
import {type ClassroomState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {type ToggleElementAction} from 'common/interface';
import {
  BATCH_MEDIA,
  URL_OPENED_EXERCISE,
  URL_OPENED,
  CLEAR_POINTER_SELECTION,
  URL_OPENED_POINTER,
  SET_INCORRECT_URL_ID,
  SET_LAST_VISITED_UNIT_PAGE,
  SET_OPENING_EXERCISE_FOR_PARTNER,
  SET_OPENING_URL_FOR_PARTNER,
  SET_POINTER_SELECTION,
  SET_PUBLISHING_POINTER
} from 'common/actionTypes';

import {
  CHANGE_PARTNERS_FILTER,
  CLEAR_CLASSROOM,
  CLEAR_COURSES,
  CLEAR_OPEN_SOUND_WHEN_LOADED,
  OPEN_PARTNERS_TAB,
  OPEN_SOUND_WHEN_LOADED,
  REQUEST_COURSES,
  SET_CLASSROOM_NETWORK_ERROR,
  SET_COURSES,
  SET_HAS_GRAMMAR,
  SUBSCRIBE_COURSE_EVENTS_FAIL,
  TOGGLE_COURSES_DROPDOWN,
  TOGGLE_STUDENT_TEACHER_POPOVER
} from '../actions/actionTypes';
import {
  type ChangeFilterAction,
  type ClassroomAction,
  type OpenTabAction,
  type OpenWhenLoadedAction,
  type SetHasGrammarAction,
  type SetLastUnitPageAction
} from '../actions/interface';
import {type SetCoursesAction} from '../actions/action';
import getCourseInstanceReducer, {initialCourseInstanceState} from './courseInstanceReducer';
import {type BatchMediaAction, type SetSelectionAction} from '../../../common/action';

const initialClassroomState: ClassroomState = {
  partnersFilter: '',
  partnersOpenTabId: '1',
  courseInstanceState: initialCourseInstanceState,
  lastUnitPages: {}
};

const ACTION_HANDLERS: ActionHandlersList<ClassroomState, ClassroomAction> = {
  [OPEN_PARTNERS_TAB]: (state: ClassroomState, action: OpenTabAction) => ({
    ...state,
    partnersOpenTabId: action.id
  }),
  [CHANGE_PARTNERS_FILTER]: (state: ClassroomState, action: ChangeFilterAction) => ({
    ...state,
    partnersFilter: action.filter
  }),
  [SET_CLASSROOM_NETWORK_ERROR]: (state: ClassroomState) => ({
    ...state,
    networkError: true
  }),
  [REQUEST_COURSES]: (state: ClassroomState) => ({
    ...state,
    loadingCourses: true
  }),
  [SET_COURSES]: (state: ClassroomState, {courses}: SetCoursesAction): ClassroomState => ({
    ...state,
    courses
  }),
  [CLEAR_CLASSROOM]: () => ({
    ...initialClassroomState
  }),
  [SUBSCRIBE_COURSE_EVENTS_FAIL]: (state: ClassroomState) => ({
    ...state,
    networkError: true
  }),
  [TOGGLE_STUDENT_TEACHER_POPOVER]: (state: ClassroomState, action: ToggleElementAction) => ({
    ...state,
    studentTeachersPopoverOpen: action.show
  }),
  [TOGGLE_COURSES_DROPDOWN]: (state: ClassroomState, action: ToggleElementAction) => ({
    ...state,
    coursesDropdownOpen: action.show
  }),
  [CLEAR_COURSES]: (state: ClassroomState): ClassroomState => ({
    ...state,
    courses: undefined,
    courseInstanceState: initialCourseInstanceState
  }),
  [SET_INCORRECT_URL_ID]: (state: ClassroomState): ClassroomState => ({
    ...state,
    incorrectUrlId: true
  }),
  [OPEN_SOUND_WHEN_LOADED]: (
    state: ClassroomState,
    action: OpenWhenLoadedAction
  ): ClassroomState => ({
    ...state,
    openWhenLoadedSoundId: action.id
  }),
  [CLEAR_OPEN_SOUND_WHEN_LOADED]: (state: ClassroomState): ClassroomState => ({
    ...state,
    openWhenLoadedSoundId: undefined
  }),
  [SET_OPENING_URL_FOR_PARTNER]: (state: ClassroomState): ClassroomState => ({
    ...state,
    openingUrlForPartner: true
  }),
  [URL_OPENED]: (state: ClassroomState): ClassroomState => ({
    ...state,
    openingUrlForPartner: undefined
  }),
  [SET_OPENING_EXERCISE_FOR_PARTNER]: (state: ClassroomState): ClassroomState => ({
    ...state,
    openingExerciseForPartner: true
  }),
  [URL_OPENED_EXERCISE]: (state: ClassroomState): ClassroomState => ({
    ...state,
    openingExerciseForPartner: undefined
  }),
  [BATCH_MEDIA]: (state: ClassroomState, {id, context}: BatchMediaAction): ClassroomState => ({
    ...state,
    batchedMedia: id,
    batchedMediaContext: context
  }),
  [SET_HAS_GRAMMAR]: (state: ClassroomState, {hasGrammar}: SetHasGrammarAction): ClassroomState => {
    return {...state, hasGrammar};
  },
  [LOCATION_CHANGE]: (state: ClassroomState): ClassroomState =>
    state.incorrectUrlId ? {...state, incorrectUrlId: undefined} : state,
  [SET_POINTER_SELECTION]: (state: ClassroomState, {selection}: SetSelectionAction) => ({
    ...state,
    selection
  }),
  [CLEAR_POINTER_SELECTION]: (state: ClassroomState) => ({...state, selection: undefined}),
  [SET_PUBLISHING_POINTER]: (state: ClassroomState): ClassroomState => ({
    ...state,
    publishingPointerUrl: true
  }),
  [URL_OPENED_POINTER]: (state: ClassroomState): ClassroomState => ({
    ...state,
    publishingPointerUrl: undefined
  }),
  [SET_LAST_VISITED_UNIT_PAGE]: (
    state: ClassroomState,
    action: SetLastUnitPageAction
  ): ClassroomState => {
    return {
      ...state,
      lastUnitPages: {...state.lastUnitPages, [action.unitInstanceId]: action.page}
    };
  }
};

export default function (
  state: ClassroomState = initialClassroomState,
  action: ClassroomAction
): ClassroomState {
  const classroomReducer = ACTION_HANDLERS[action.type];
  const courseInstanceReducer = getCourseInstanceReducer(action);
  if (classroomReducer || courseInstanceReducer) {
    const newState = classroomReducer ? classroomReducer(state, action) : state;
    return {
      ...newState,
      courseInstanceState: courseInstanceReducer
        ? courseInstanceReducer(newState.courseInstanceState, action)
        : newState.courseInstanceState
    };
  }
  return state;
}
