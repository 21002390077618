import React, {type CSSProperties, type FC, useRef} from 'react';
import classNames from 'classnames';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import Scrollbars from 'react-custom-scrollbars';

import {type ContainerProps} from '../interface';
import './popover.scss';

interface Props extends ContainerProps {
  listIsOpened: boolean;
  toggleList(): void;
}

export const ContentsPopover: FC<Props> = ({
  currentPage,
  listIsOpened,
  pageCount,
  selectPage,
  toggleList
}) => {
  const itemsInRowMax = 4;
  const linkSize = 40;
  const containerRef = useRef(null);

  if (typeof pageCount !== 'number' || typeof currentPage !== 'number') return null;

  return (
    <div
      className="x-pager-popover-container"
      ref={containerRef}
      style={
        {
          marginRight: 0,
          '--popoverPageLinkSize': `${linkSize}px`,
          '--itemsInRow': `${pageCount < itemsInRowMax ? pageCount : itemsInRowMax}`
        } as CSSProperties
      }
    >
      {containerRef.current && (
        <Overlay
          rootClose={true}
          container={containerRef.current}
          show={listIsOpened}
          onHide={toggleList}
          shouldUpdatePosition={true}
          placement="top"
          target={containerRef.current!}
        >
          <Popover id="x-pager-popover" className="x-pager-popover">
            <Scrollbars
              autoHide={true}
              autoHeight={true}
              autoHeightMin={linkSize}
              autoHeightMax={linkSize * 3.5}
            >
              <div className="pages-links-list">
                {Array.from({length: pageCount}, (_, k) => {
                  const i = k + 1;
                  const isActive = i === currentPage;
                  const className = classNames('page-number-circle', {'active-page': isActive});
                  return (
                    <div className="link-to-page" key={i}>
                      <button className="btn-ico" onClick={() => selectPage(i)} disabled={isActive}>
                        <div className={className}>{i}</div>
                      </button>
                    </div>
                  );
                })}
              </div>
            </Scrollbars>
          </Popover>
        </Overlay>
      )}
    </div>
  );
};
