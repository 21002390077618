import {useIntl} from 'react-intl';
import {type SyntheticEvent, useCallback, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import type Button from 'react-bootstrap/lib/Button';

import {type AppState, type HomeworkDocumentInstance} from 'store/interface';

import {useAxiosDispatch} from '../../../../hooks/redux/useAxiosDispatch';
import {editHomeworkCommentForDocument} from '../FilesWorkSpace/documentsTab/actions/action';
import {deleteDocumentInstanceFromDraft} from '../../pages/CourseInstancePage/actions';
import * as toastr from '../../../../components/toastr';
import {documentDraftControlMessages} from './i18n';
import {type DocControlsProps} from './useDocPageControls';

export const useDocControls = (
  docs: HomeworkDocumentInstance[] | undefined,
  courseId: number,
  homeworkId: string | undefined,
  documentInstanceId: number
): DocControlsProps => {
  const intl = useIntl();
  const [isShowingConfirm, setIsShowingConfirm] = useState<boolean>();
  const [deleting, setDeleting] = useState(false);
  const isMounted = useRef(true);
  const isStudent = useSelector((state: AppState) => state.user.role === 'student');
  const hideConfirm = useCallback(() => setIsShowingConfirm(false), []);
  const onDeleteClick = useCallback((e: SyntheticEvent<HTMLButtonElement | Button>) => {
    // fix invalid position of buttons after clicking this button and then declining confirm
    (e.currentTarget as HTMLButtonElement).blur();
    setIsShowingConfirm(true);
  }, []);

  const documentIsInDraft = useMemo(
    () => docs && docs.find(doc => doc.documentInstanceId === documentInstanceId),
    [documentInstanceId, docs]
  );
  const dispatch = useAxiosDispatch();
  const editDocInstanceId = useSelector(
    (state: AppState) => state.docs && state.docs.editHomeworkCommentForDocument
  );
  const toggle = useCallback(
    (e: SyntheticEvent<HTMLButtonElement | Button>) => {
      // fix invalid position of buttons after clicking this button and then closing modal
      (e.currentTarget as HTMLButtonElement).blur();
      if (!editDocInstanceId) {
        dispatch(editHomeworkCommentForDocument(documentInstanceId));
      }
    },
    [dispatch, documentInstanceId, editDocInstanceId]
  );

  const onDeleteConfirmAccept = useCallback(() => {
    setIsShowingConfirm(false);
    if (deleting) return;
    setDeleting(true);
    dispatch(deleteDocumentInstanceFromDraft(courseId, homeworkId!, documentInstanceId))
      .then(
        () =>
          toastr.success(
            '',
            intl.formatMessage(documentDraftControlMessages.DeleteFromDraftSuccess)
          ),
        () => toastr.error('', intl.formatMessage(documentDraftControlMessages.DeleteFromDraftFail))
      )
      .finally(() => {
        if (isMounted.current) setDeleting(false);
      });
  }, [deleting, dispatch, courseId, homeworkId, documentInstanceId, intl]);

  return {
    onDeleteConfirmAccept,
    toggle: toggle,
    isInDraft: !!documentIsInDraft,
    isShowConfirm: !!isShowingConfirm,
    isStudent,
    hideConfirm,
    onDeleteClick,
    editDocInstanceId,
    isDeleting: deleting,
    deleteConfirmTitle: intl.formatMessage(documentDraftControlMessages.DeleteConfirm)
  };
};
