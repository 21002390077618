import React, {type FC, useCallback} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';

import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';

import {type DraftModule} from '../../../pages/CoursebookInstancePage/useDraftModule';
import {draftAllExercises} from './actions';

interface Props {
  draftModule: DraftModule;
  unitInstanceId: number;
}

export const HomeworkButton: FC<Props> = ({draftModule, unitInstanceId}) => {
  const intl = useIntl();
  const {clearDraftable, draftingId, isDraftable, setDraftingId} = draftModule;
  const disabled = !!draftingId || !isDraftable(unitInstanceId);
  const axiosDispatch = useAxiosDispatch();
  const onClick = useCallback(
    async (e: React.MouseEvent<Button, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (draftingId) return;
      setDraftingId(unitInstanceId);
      try {
        await axiosDispatch(draftAllExercises(unitInstanceId));
        toastr.success('', intl.formatMessage({id: 'Homework.Draft.MassAssignment.Success'}));
        clearDraftable(unitInstanceId);
      } catch (e) {
        toastr.error('', intl.formatMessage({id: 'Homework.Draft.MassAssignment.Fail'}));
      } finally {
        setDraftingId();
      }
    },
    [axiosDispatch, draftingId, clearDraftable, intl, setDraftingId, unitInstanceId]
  );

  return (
    <Button className="homework-btn" onClick={onClick} disabled={disabled}>
      <Icon name={draftingId ? 'refresh' : 'virc-homework'} spin={!!draftingId} />
    </Button>
  );
};
