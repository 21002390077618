import React from 'react';
import {FormattedMessage} from 'react-intl';

import {type Role} from 'store/interface';

interface NoFilesProps {
  role: Role;
  readonly: boolean;
}

export default class NoFilesComponent extends React.Component<NoFilesProps, {}> {
  public render() {
    return (
      <div className="no-files">
        <div>
          <div>{this.renderFirstMessage()}</div>
          <div>{this.renderSecondMessage()}</div>
        </div>
      </div>
    );
  }

  private renderFirstMessage = () => {
    if (this.props.role === 'student') {
      return <FormattedMessage id="LessonPage.NoMaterialsStudent" />;
    }
    return <FormattedMessage id="LessonPage.NoMaterialsTeacher" />;
  };

  private renderSecondMessage = () => {
    if (this.props.role === 'student') {
      return <FormattedMessage id="LessonPage.WaitForMaterials" />;
    }
    if (!this.props.readonly) {
      return <FormattedMessage id="LessonPage.AddMaterials" />;
    }
    return null;
  };
}
