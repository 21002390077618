import React, {type FC, useCallback, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {
  type AppState,
  type Coursebook,
  type CoursebookInstanceOverview,
  type UnitInstance
} from 'store/interface';
import {ACTIVE_UNITS_LIMIT} from 'config/static';
import {
  type CoursebookInstanceUrlParams,
  unitInstancePagePath,
  unitInstancePath,
  unitInstancePreviewPath
} from 'common/paths';

import Header from '../../components/CoursebookInfoDetailed/Header';
import UnitsList from '../../components/CoursebookInfoDetailed/UnitsList';
import {CardContainer} from '../../components/CoursebookInfoDetailed/CardContainer';
import Card from '../../components/CoursebookInfoDetailed/Card';
import CardFooter from '../../components/CoursebookInfoDetailed/CardFooter';
import {useDraftModule} from './useDraftModule';

interface Props {
  courseInstanceIsActive: boolean;
  isTeacher: boolean;
  coursebook: Coursebook;
  unitInstances: UnitInstance[];
  overview: CoursebookInstanceOverview;
  setUnitToToggle: (id: number) => void;
  showContents?: () => void;
}

const CoursebookInstancePageView: FC<Props> = ({
  courseInstanceIsActive,
  isTeacher,
  coursebook,
  unitInstances,
  overview,
  setUnitToToggle,
  showContents
}) => {
  const draftModule = useDraftModule(unitInstances);
  const params = useParams<CoursebookInstanceUrlParams>() as CoursebookInstanceUrlParams;

  const lastUnitPages = useSelector<AppState, Record<string, number> | undefined>(
    s => s.classroom?.lastUnitPages
  );

  const activeUnitInstances = useMemo(
    () => unitInstances.filter(ui => ui.active && !ui.completedAt),
    [unitInstances]
  );

  const activationForbidden = useMemo(
    () => activeUnitInstances.length >= ACTIVE_UNITS_LIMIT,
    [activeUnitInstances]
  );

  const getActiveUnitHref = useCallback(
    (unitInstanceId: number) => {
      const lastUnitPage = lastUnitPages && lastUnitPages[unitInstanceId];

      if (lastUnitPage) {
        return unitInstancePagePath(params, unitInstanceId, lastUnitPage);
      }

      return unitInstancePath(params, unitInstanceId);
    },
    [lastUnitPages, params]
  );
  const getInactiveUnitHref = useCallback(
    (unitId: number) => unitInstancePreviewPath(params, unitId),
    [params]
  );
  return (
    <>
      <Header
        cover={coursebook.cover}
        title={coursebook.title}
        unitsCount={overview.unitsCount}
        completedUnitsCount={overview.unitsCompleted}
        exercisesCount={overview.mainExercisesCount}
        completedExercisesCount={overview.mainExercisesCompleted}
        testsCount={overview.testsCount}
        testsCompleted={overview.testsCompleted}
        onShowContentsClick={showContents}
      />
      {!activeUnitInstances.length ? null : (
        <UnitsList heading={<FormattedMessage id="CoursebookInstancePage.UnitsList.OpenUnits" />}>
          {activeUnitInstances.map(ui => (
            <CardContainer key={ui.id} redirectsTo={getActiveUnitHref(ui.id)}>
              <Card
                unitInstanceId={ui.id}
                active={ui.active}
                completed={!!ui.completedAt}
                cover={ui.unit.cover}
                draftModule={ui.ordinal ? undefined : draftModule}
                ordinal={ui.ordinal}
                title={ui.unit.title}
              >
                <CardFooter
                  active={ui.active}
                  courseInstanceIsActive={courseInstanceIsActive}
                  id={ui.id}
                  setUnitToToggle={setUnitToToggle}
                  completed={!!ui.completedAt}
                  renderControlBtn={isTeacher}
                  exercisesCount={ui.overview.mainExercisesCount}
                  exercisesCompleted={ui.overview.mainExercisesCompleted}
                  extraExercisesCount={ui.overview.extraExercisesCount}
                />
              </Card>
            </CardContainer>
          ))}
        </UnitsList>
      )}
      <UnitsList heading={<FormattedMessage id="CoursebookInstancePage.UnitsList.AllUnits" />}>
        {unitInstances.map(ui => {
          const redirectsTo = (() => {
            if (ui.active) {
              return getActiveUnitHref(ui.id);
            }
            if (isTeacher) {
              return getInactiveUnitHref(ui.id);
            }
            return undefined;
          })();

          return (
            <CardContainer
              key={ui.id}
              renderIsClosedOverlay={!ui.active}
              hideOverlayOnHover={isTeacher}
              redirectsTo={redirectsTo}
            >
              <Card
                unitInstanceId={ui.id}
                active={ui.active}
                completed={!!ui.completedAt}
                cover={ui.unit.cover}
                draftModule={ui.ordinal ? undefined : draftModule}
                ordinal={ui.ordinal}
                title={ui.unit.title}
              >
                <CardFooter
                  active={ui.active}
                  id={ui.id}
                  setUnitToToggle={setUnitToToggle}
                  completed={!!ui.completedAt}
                  renderControlBtn={isTeacher}
                  exercisesCount={ui.overview.mainExercisesCount}
                  exercisesCompleted={ui.overview.mainExercisesCompleted}
                  extraExercisesCount={isTeacher ? ui.overview.extraExercisesCount : undefined}
                  courseInstanceIsActive={courseInstanceIsActive}
                  activationForbidden={activationForbidden}
                />
              </Card>
            </CardContainer>
          );
        })}
      </UnitsList>
    </>
  );
};

export default CoursebookInstancePageView;
