import React from 'react';

import {changeCurrentTimeEventName, emitter} from 'services/event-emitter';
import {formatSecondsToString} from 'services/common-methods/secondsToString';

interface State {
  currentTime: string;
}

export default class TimeDisplay extends React.Component<{}, State> {
  public state: State = {
    currentTime: '0:00'
  };

  public componentDidMount() {
    emitter.addListener(changeCurrentTimeEventName, this.receiveTime);
  }

  public componentWillUnmount() {
    emitter.removeListener(changeCurrentTimeEventName, this.receiveTime);
  }

  public render() {
    return <span>{`${this.state.currentTime}/`}</span>;
  }

  private receiveTime = (time: number) => {
    this.setState({currentTime: formatSecondsToString(time)});
  };
}
