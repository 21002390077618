import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import {replace} from 'store/router';
import {type AppState, type HomeworkWithOverview} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import HomeworkList from 'components/Homework/HomeworkList';
import Filter from 'components/Homework/Filter';
import {useSanitizeSearch, validStatuses} from 'components/Homework/utils';
import {homeworkPageSize} from 'components/Homework/static';
import useFilter from 'components/Homework/useFilter';
import SendHomeworkNotificationModal from 'components/Homework/Modals/SendHomeworkNotificationModal';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import PageControls from 'components/PageControls';
import {type ClassroomUrlParams, studentTeacherAndCoursePath} from 'common/paths';

import CourseInstanceInfo from '../CourseInstancePage/CourseInstanceInfo';
import {clearHomeworkList, requestHomeworkList, setHomeworkList} from './actions';
import DisplayButton from '../../components/DisplayButton';

const HomeworkPage: FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {search: routerSearch} = useLocation();
  const {courseId, studentTeacherId} = useParams<ClassroomUrlParams>();

  const activeCourseInstance = useSelector((s: AppState) => s.classroom?.courses?.[courseId!])!;
  const coursePartner = useSelector(
    (s: AppState) => s.studentTeachers?.studentTeachers?.[studentTeacherId!]
  )?.recipient!;

  const axiosDispatch = useAxiosDispatch();
  const isStudent = useSelector<AppState, boolean>(state => state.user.role === 'student');
  const canOpenTogether = useCanPlayTogether({studentTeacherId});
  const userTimeZone = useSelector(({user}: AppState) => user.profile?.timeZone);
  const homeworkList = useSelector<AppState, HomeworkWithOverview[]>(
    ({classroom}) => classroom!.courseInstanceState.homework.homeworkList
  );
  const isLoading = useSelector<AppState, boolean | undefined>(
    ({classroom}) => classroom!.courseInstanceState.homework.isLoading
  );
  const isReduxError = useSelector<AppState, boolean | undefined>(
    ({classroom}) => classroom!.courseInstanceState.homework.isError
  );
  const [homeworkId, setHomeworkId] = useState<string | undefined>(undefined);

  const onHide = useCallback(() => setHomeworkId(undefined), []);

  const [hasMore, setHasMore] = useState(false);
  const [shouldResetLoader, setShouldResetLoader] = useState(false);
  const [isErrorOnPageRequest, setIsErrorOnPageRequest] = useState(false);

  const knownParams = useMemo(
    () => ({
      hideCompleted: ['true', 'false'],
      statuses: validStatuses
    }),
    []
  );
  const [requestOptions, search, shouldRefresh] = useSanitizeSearch(routerSearch, knownParams);

  const action = useMemo(
    () =>
      requestHomeworkList(
        courseId as string,
        requestOptions.hideCompleted,
        requestOptions.statuses
      ),
    [courseId, requestOptions.hideCompleted, requestOptions.statuses]
  );
  const reqSuccessHandler = useCallback(
    (hl: HomeworkWithOverview[]) => {
      setHasMore(hl.length === homeworkPageSize);
      dispatch(setHomeworkList(hl));
    },
    [dispatch]
  );
  const {isError, reload} = useApiRequest(action, reqSuccessHandler);

  const searchChangedCallback = useCallback(() => {
    setHasMore(false);
    setShouldResetLoader(true);
  }, []);

  const {applyHideCompletedFilter, applyStatusesFilter} = useFilter(
    search,
    requestOptions,
    searchChangedCallback
  );

  useEffect(() => {
    if (shouldRefresh) dispatch(replace({search}));
  }, [dispatch, search, shouldRefresh]);

  useEffect(() => {
    if (isLoading) {
      setShouldResetLoader(true);
      setHasMore(false);
    } else {
      setHasMore(!(homeworkList.length % homeworkPageSize) && !!homeworkList.length);
    }
  }, [homeworkList.length, isLoading]);

  useEffect(
    () => () => {
      dispatch(clearHomeworkList());
    },
    [dispatch, courseId]
  );

  const homeworkPathMapper = useCallback(
    ({id}: HomeworkWithOverview) =>
      `${studentTeacherAndCoursePath(Number(studentTeacherId), Number(courseId))}/homework/${id}`,
    [courseId, studentTeacherId]
  );

  const loadMore = useMemo<
    (page: number) => Promise<AxiosResponseAction<HomeworkWithOverview[]> | void>
  >(() => {
    return page => {
      if (isLoading) {
        return Promise.resolve();
      }
      const promise = axiosDispatch<AxiosResponseAction<HomeworkWithOverview[]>>(
        requestHomeworkList(
          courseId as string,
          requestOptions.hideCompleted,
          requestOptions.statuses,
          page
        )
      );
      promise
        .then(({payload: {data}}) => {
          if (data.length < homeworkPageSize) {
            setHasMore(false);
          }

          dispatch(setHomeworkList([...homeworkList, ...data]));
        })
        .catch(() => setIsErrorOnPageRequest(true));
      return promise;
    };
  }, [axiosDispatch, dispatch, homeworkList, isLoading, requestOptions, courseId]);

  if (isError || isErrorOnPageRequest || isReduxError) {
    return (
      <WampErrorMask
        reload={() => {
          reload();
          setIsErrorOnPageRequest(false);
        }}
      />
    );
  }

  if (!homeworkList) {
    return <Loader />;
  }

  return (
    <>
      <HomeworkList
        userTimeZone={userTimeZone}
        hasMore={hasMore}
        homeworkList={homeworkList}
        homeworkPathMapper={homeworkPathMapper}
        isLoading={!!isLoading}
        isStudent={isStudent}
        loadMore={loadMore}
        shouldResetLoader={shouldResetLoader}
        setShouldResetLoader={setShouldResetLoader}
        onChangeHomeworkStatus={setHomeworkId}
        renderFilter={() => (
          <Filter
            applyHideCompletedFilter={applyHideCompletedFilter}
            applyStatusesFilter={applyStatusesFilter}
            intl={intl}
            requestOptions={requestOptions}
          />
        )}
        renderHeader={() => (
          <CourseInstanceInfo courseInstance={activeCourseInstance} coursePartner={coursePartner} />
        )}
      />
      <SendHomeworkNotificationModal
        courseInstanceId={courseId ? Number(courseId) : undefined}
        homeworkId={homeworkId}
        onHide={onHide}
        studentTeacherId={studentTeacherId}
      />
      {canOpenTogether && (
        <PageControls>
          <DisplayButton />
        </PageControls>
      )}
    </>
  );
};

export default HomeworkPage;
