import React from 'react';
import {FormattedMessage} from 'react-intl';
import Label from 'react-bootstrap/lib/Label';

import {type CourseInstance, type Recipient} from 'store/interface';

import Avatar from '../../../../components/Avatar';
import {userFullName} from '../../../../helpers/user';

import './CourseInstanceInfo.scss';

interface Props {
  courseInstance: CourseInstance;
  coursePartner: Recipient;
}

const CourseInstanceInfo: React.FC<Props> = (props: Props) => {
  const {courseInstance, coursePartner} = props;
  const isActive = !courseInstance.deleted_at;
  return (
    <div className="course-instance-info">
      <div className="course-avatar ">
        <Avatar size={92} url={courseInstance.course.image} />
      </div>

      <div className="info-right">
        <Label bsStyle={isActive ? 'success' : undefined}>
          {isActive ? (
            <FormattedMessage id="LessonPage.CourseInstanceActive" />
          ) : (
            <FormattedMessage id="LessonPage.CourseInstanceInactive" />
          )}
        </Label>
        <h3 className="course-name">{courseInstance.course.name}</h3>
        <div className="user-info">
          <Avatar size={42} url={coursePartner.profile!.avatars['sm']} />
          <b>{userFullName(coursePartner.profile!)}</b>
        </div>
      </div>
    </div>
  );
};

export default CourseInstanceInfo;
