export const validateOnClient = (
  file: File,
  isDocument: boolean,
  maxDocSize: number,
  maxSoundSize: number,
  invalidSizeError: string,
  emptyFileError: string,
  invalidExtensionError: string
): void => {
  const {name, size} = file;
  if (isDocument) {
    if (!name.match(/\.(docx?|pdf)$/i)) {
      throw new Error(invalidExtensionError);
    }
    if (size > maxDocSize) {
      throw new Error(invalidSizeError);
    }
    if (size === 0) {
      throw new Error(emptyFileError);
    }
  } else {
    if (!name.match(/\.mp3$/i)) {
      throw new Error(invalidExtensionError);
    }
    if (size > maxSoundSize) {
      throw new Error(invalidSizeError);
    }
  }
};
