import React, {type FC, type PropsWithChildren} from 'react';
import classNames from 'classnames';
import RetinaImage from '@englex/react-retina-image';
import {useSelector} from 'react-redux';

import tUrl1 from 'layouts/assets/revision_cover.jpg';
import tUrl2 from 'layouts/assets/revision_cover@2x.jpg';
import {type AppState, type Cover} from 'store/interface';

import {type DraftModule} from '../../pages/CoursebookInstancePage/useDraftModule';
import {HomeworkButton} from './HomeworkButton/HomeworkButton';
import './Card.scss';

interface Props {
  active?: boolean;
  completed?: boolean;
  cover?: Cover;
  draftModule?: DraftModule;
  ordinal: number | null;
  title: string;
  unitInstanceId?: number;
}

const tUrls = [tUrl1, tUrl2];
const Card: FC<PropsWithChildren<Props>> = ({
  children,
  completed,
  cover,
  draftModule,
  ordinal,
  title,
  unitInstanceId
}) => {
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');
  return (
    <>
      <div className={classNames('card', {completed})}>
        <div className={classNames('card-cover', {empty: !cover, revision: !ordinal})}>
          {cover ? (
            <RetinaImage src={cover.urls} />
          ) : !ordinal ? (
            <RetinaImage src={tUrls} />
          ) : (
            <div className="label label-u">
              <span>U</span>
            </div>
          )}
        </div>
        <div className="content">
          <div className="title" title={title}>
            {title}
          </div>
          {children}
        </div>
      </div>
      {ordinal && <span className="ordinal">{ordinal}</span>}
      {draftModule && !isStudent && (
        <HomeworkButton draftModule={draftModule} unitInstanceId={unitInstanceId!} />
      )}
    </>
  );
};

export default React.memo(Card);
