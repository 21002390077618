import {type Action} from 'redux';

import {type DocsState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {type WampPublishAction} from 'services/wamp/actions/interface';
import {resolveParamFromUri} from 'services/wamp/uriIdResolver';
import {type AxiosRequestError} from 'services/axios/interface';

import {
  type FileDeleteSuccessAction,
  type FileEditSuccessAction,
  type FileViewedSuccessAction,
  type LoadFilesSuccessAction,
  type OpenEditorAction,
  type PinAction,
  type SetIsDeletedRequestSentAction
} from '../../../../actions/interface';
import {
  CLEAR_OPENED_TOGETHER,
  CLOSE_EDIT_HOMEWORK_COMMENT_MODAL,
  DELETE_DOCUMENT_SUCCESS,
  DOCUMENT_VIEWED_SUCCESS,
  DOCUMENTS_RESET,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_ERROR,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT,
  EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT_PAGE,
  OPEN_EDITOR,
  PIN_DOCUMENT,
  PIN_DOCUMENT_ERROR,
  PIN_DOCUMENT_SUCCESS,
  REQUEST_DOCUMENTS_FAIL,
  REQUEST_DOCUMENTS_SUCCESS,
  SET_DOCUMENT_DELETING,
  SET_LAST_DOC_PAGE_NUMBER
} from '../actions/actionTypes';
import {type DocumentFile} from '../actions/interface';
import {PUBLISH_OPEN_MATERIALS} from '../../../../actions/actionTypes';
import {
  type EditHomeworkCommentForDocAction,
  type EditHomeworkCommentForDocPageAction,
  type SetLastDocPagesAction
} from '../actions/action';
import {type ActionTogetherContext} from '../../context/AudioTogetherContext';

const initialDocsState: DocsState = {
  editDocumentAwait: false,
  pinnedCount: 0,
  lastPages: {}
};

const ACTION_HANDLERS: ActionHandlersList<DocsState, Action> = {
  [EDIT_DOCUMENT]: (state: DocsState): DocsState => ({
    ...state,
    editDocumentAwait: true
  }),
  [OPEN_EDITOR]: (state: DocsState, action: OpenEditorAction): DocsState => {
    if (action.id === null || state.documents![action.id]) {
      return {
        ...state,
        editedDocument: action.id || undefined
      };
    } else {
      return state;
    }
  },
  [REQUEST_DOCUMENTS_SUCCESS]: (state: DocsState, action: LoadFilesSuccessAction) => {
    const {loadingDocuments, ...newState} = state;
    if (!newState.documents) {
      newState.documents = {};
    } else {
      newState.documents = {...newState.documents};
    }
    const ids = (action.payload.data as DocumentFile[]).map(document => {
      if (!newState.documents![document.id]) {
        newState.documents![document.id] = document;
        document.created_at = new Date(document.created_at);
        document.href = document.gdoc_id;
        document.isNew =
          document.views && (document.views.length === 0 || !document.views[0].read_at);
      } else if (newState.documents![document.id].title !== document.title) {
        newState.documents![document.id] = {
          ...newState.documents![document.id],
          title: document.title
        };
      } else if (newState.documents![document.id].pinned !== document.pinned) {
        newState.documents![document.id] = {
          ...newState.documents![document.id],
          pinned: document.pinned
        };
      }
      return String(document.id);
    });
    let pins = 0;
    Object.keys(newState.documents).forEach(key => {
      if (!ids.includes(key)) {
        delete newState.documents![key];
      } else if (newState.documents![key].pinned) {
        pins++;
      }
    });
    newState.pinnedCount = pins;
    return newState;
  },
  [REQUEST_DOCUMENTS_FAIL]: state => ({
    ...state,
    networkError: true,
    loadingDocuments: undefined,
    documents: {}
  }),
  [EDIT_DOCUMENT_SUCCESS]: (state: DocsState, action: FileEditSuccessAction) => {
    const {editedDocument, ...newState} = state;
    newState.editDocumentAwait = false;
    if (newState.documents && newState.documents[action.payload.data.id]) {
      newState.documents = {...newState.documents};
      newState.documents[action.payload.data.id] = {
        ...newState.documents[action.payload.data.id],
        title: action.payload.data.title
      };
    }
    return newState;
  },
  [EDIT_DOCUMENT_ERROR]: (state: DocsState) => ({
    ...state,
    editDocumentAwait: false
  }),
  [PIN_DOCUMENT]: (state: DocsState, {id}: PinAction) => {
    const newState = {...state};
    newState.pinAwait = true;
    newState.documents![id] = {
      ...newState.documents![id],
      beingPinned: true
    };
    return newState;
  },
  [PIN_DOCUMENT_SUCCESS]: (state: DocsState, action: FileEditSuccessAction) => {
    const {pinAwait, ...newState} = state;
    const {id, pinned} = action.payload.data;
    if (newState.documents && newState.documents[id]) {
      newState.documents = {...newState.documents};
      newState.documents[id] = {
        ...newState.documents[id],
        beingPinned: false,
        pinned
      };
      pinned ? newState.pinnedCount++ : newState.pinnedCount--;
    }
    return newState;
  },
  [PIN_DOCUMENT_ERROR]: (state: DocsState, action: AxiosRequestError) => {
    const {pinAwait, ...newState} = state;
    const id = action.meta.previousAction['id'];

    if (newState.documents && newState.documents[id]) {
      newState.documents = {...newState.documents};
      newState.documents[id] = {
        ...newState.documents[id],
        beingPinned: false
      };
    }
    return newState;
  },
  [SET_DOCUMENT_DELETING]: (state: DocsState, action: SetIsDeletedRequestSentAction) => {
    return {
      ...state,
      documents: {
        ...state.documents,
        [action.fileId]: {
          ...state.documents![action.fileId],
          isBeingDeletedComponentId: action.isBeingDeleted ? action.componentId : undefined
        }
      }
    };
  },
  [DELETE_DOCUMENT_SUCCESS]: (state: DocsState, action: FileDeleteSuccessAction) => {
    const newState: DocsState = {
      ...state,
      documents: {...state.documents}
    };
    const docId: number | null = resolveParamFromUri(
      'instanceId',
      action.meta.previousAction.payload.request.url!
    );
    if (docId) {
      delete newState.documents![docId];
    }
    return newState;
  },
  [DOCUMENT_VIEWED_SUCCESS]: (state: DocsState, action: FileViewedSuccessAction) => {
    if (!state.documents || !state.documents[action.payload.data.id]) {
      return state;
    }
    return {
      ...state,
      documents: {
        ...state.documents,
        [action.payload.data.id]: {
          ...state.documents[action.payload.data.id],
          isNew: false
        }
      }
    };
  },
  [DOCUMENTS_RESET]: (state: DocsState) => ({
    ...initialDocsState,
    lastPages: state.lastPages
  }),
  [PUBLISH_OPEN_MATERIALS]: (
    state: DocsState,
    action: WampPublishAction<Array<ActionTogetherContext>, {}>
  ) => {
    if (!action.wamp.args) return state;

    const [context] = action.wamp.args;
    const {type, fileId} = context;

    if (type === 'document') {
      return {
        ...state,
        openTogetherFileId: fileId
      };
    }

    return state;
  },
  [CLEAR_OPENED_TOGETHER]: (state: DocsState) => ({
    ...state,
    openTogetherFileId: undefined
  }),
  [EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT]: (
    state: DocsState,
    {docId}: EditHomeworkCommentForDocAction
  ): DocsState => ({
    ...state,
    editHomeworkCommentForDocument: docId,
    editHomeworkCommentForDocumentPage: undefined
  }),
  [EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT_PAGE]: (
    state: DocsState,
    {docId, pageNumber}: EditHomeworkCommentForDocPageAction
  ): DocsState => ({
    ...state,
    editHomeworkCommentForDocument: docId,
    editHomeworkCommentForDocumentPage: pageNumber
  }),
  [CLOSE_EDIT_HOMEWORK_COMMENT_MODAL]: (state: DocsState): DocsState => ({
    ...state,
    editHomeworkCommentForDocument: undefined,
    editHomeworkCommentForDocumentPage: undefined
  }),
  [SET_LAST_DOC_PAGE_NUMBER]: (state: DocsState, action: SetLastDocPagesAction): DocsState => ({
    ...state,
    lastPages: {...state.lastPages, [action.documentInstanceId]: action.page}
  })
};

export default function (state: DocsState = initialDocsState, action: Action): DocsState {
  const docsReducer = ACTION_HANDLERS[action.type];
  return docsReducer ? docsReducer(state, action) : state;
}
