import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOutletContext, useParams} from 'react-router-dom';
import classNames from 'classnames';

import {push} from 'store/router';
import {type AppState} from 'store/interface';
import PageControls from 'components/PageControls';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import {useRole} from 'hooks/user/useRole';
import {
  filesListPath,
  type FilesListSelectedDocPageUrlParams,
  filesListSelectedDocPath
} from 'common/paths';
import {MaterialsTab} from 'common/enums';

import FilesWorkSpace from '../../components/FilesWorkSpace';
import {clearOpenWhenLoadedSound as clearOpenWhenLoadedSoundAction} from '../../actions/action';
import EditHomeworkCommentModal from './EditHomeworkCommentModal';
import HomeworkDraftControl from '../../components/HomeworkDraftControl/HomeworkDraftControl';
import DocumentControls from '../../components/HomeworkDraftControl/DocumentControls';
import EditHomeworkDocCommentPopover from './EditHomeworkDocCommentPopover';
import HomeworkDocCommentViewer from '../../components/HomeworkDocCommentViewer';
import {useEditorViewerSync} from '../../utils';
import DisplayButton from '../../components/DisplayButton';
import {useFilesTab} from '../../hooks/useFilesTab';
import {TogetherButton} from '../../components/FilesWorkSpace/components/TogetherButton';
import {filesPdfPainterPagerPortalClassName} from '../../components/FilesWorkSpace/components/interface';
import {DocumentPageControls} from '../../components/HomeworkDraftControl/DocumentPageControls';
import EditHomeworkDocPageCommentPopover from './EditHomeworkDocPageCommentPopover';
import BackToRedirected from '../UnitInstancePage/BackToRedirected';
import {clearRedirectedFromUrl} from '../../../../common/action';
import {type CourseInstanceOutletContext} from '../../containers/CourseInstanceRoute';

const FilesPage: React.FC = () => {
  const {isInactive, isWampSubscribed} = useOutletContext<CourseInstanceOutletContext>();
  const {
    studentTeacherId,
    courseId,
    documentId,
    pageNumber: page
  } = useParams<FilesListSelectedDocPageUrlParams>();

  const studentTeachers = useSelector((s: AppState) => s.studentTeachers?.studentTeachers);

  const role = useRole();
  const {openWhenLoadedSoundId} = useSelector((state: AppState) => state.classroom!);
  const pageNumber = Number.isInteger(Number(page)) ? Number(page) : undefined;
  const dispatch = useDispatch();
  const {homeworkDraft} = useSelector((state: AppState) => state.classroom!.courseInstanceState);
  const lastPages = useSelector((state: AppState) => state.docs?.lastPages);
  const selectDoc = useCallback(
    (id: number) => {
      const lastPage = lastPages?.[id];
      dispatch(
        push(filesListSelectedDocPath(studentTeacherId!, courseId!, id, pageNumber || lastPage))
      );
    },
    [dispatch, lastPages, studentTeacherId, courseId, pageNumber]
  );
  const hasRedirect = useSelector<AppState, boolean>(state =>
    Boolean(state.classroom!.courseInstanceState.coursebookInstanceState.redirectedFromUrl)
  );
  const unselectDoc = useCallback(
    () => dispatch(push(filesListPath(studentTeacherId!, courseId!))),
    [studentTeacherId, courseId, dispatch]
  );
  const clearOpenWhenLoadedSound = useCallback(
    () => dispatch(clearOpenWhenLoadedSoundAction()),
    [dispatch]
  );
  const clearRedirectedUrl = useCallback(() => dispatch(clearRedirectedFromUrl()), [dispatch]);

  const openedTab = useFilesTab();
  const isOnUploadsTab = openedTab === MaterialsTab.UPLOADS;
  const isDocTab = openedTab === MaterialsTab.DOCUMENTS;
  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  const {
    commentEditorJustClosed,
    setCommentEditorJustClosed,
    editorCloseRequested,
    viewerCloseRequested,
    tellCommentEditorJustClosed,
    tellCommentViewerJustClosed,
    closeCommentViewer,
    closeCommentEditor
  } = useEditorViewerSync();

  const selectedDocId = typeof documentId === 'string' ? Number(documentId) : undefined;

  const selectedDocInDraft =
    selectedDocId &&
    homeworkDraft &&
    homeworkDraft.documents.find(doc => doc.documentInstanceId === selectedDocId);

  const selectedDocPageInDraft =
    selectedDocId &&
    pageNumber &&
    homeworkDraft &&
    homeworkDraft.documentPages.find(
      doc =>
        doc.documentInstancePage?.documentInstanceId === selectedDocId &&
        doc.documentInstancePage?.pageNumber === pageNumber
    );

  const comment = selectedDocPageInDraft
    ? selectedDocPageInDraft.comment
    : selectedDocInDraft
      ? selectedDocInDraft.comment
      : null;

  useEffect(() => {
    if (hasRedirect && (!selectedDocId || !isDocTab)) {
      clearRedirectedUrl();
    }

    return () => {
      if (hasRedirect) {
        clearRedirectedUrl();
      }
    };
  }, [clearRedirectedUrl, hasRedirect, isDocTab, selectedDocId]);

  return (
    <FilesWorkSpace
      studentTeachers={studentTeachers}
      studentTeacherId={Number(studentTeacherId)}
      courseId={Number(courseId)}
      readonly={isInactive}
      wampSubscribed={isWampSubscribed}
      shouldRenderUploadsTab={role === 'teacher'}
      openWhenLoadedSoundId={openWhenLoadedSoundId}
      clearOpenWhenLoadedSound={clearOpenWhenLoadedSound}
      selectedDocId={selectedDocId}
      selectDoc={selectDoc}
      unselectDoc={unselectDoc}
    >
      <PageControls>
        <div
          className={classNames(filesPdfPainterPagerPortalClassName, {
            hidden: !isDocTab || !selectedDocId
          })}
        />
        {role === 'teacher' && <TogetherButton />}
        {pageNumber && selectedDocId ? (
          <EditHomeworkDocPageCommentPopover
            courseId={Number(courseId)}
            pages={homeworkDraft && homeworkDraft.documentPages}
            // sync with viewer props
            closeCommentViewer={closeCommentViewer}
            closeRequested={editorCloseRequested}
            tellCommentEditorJustClosed={tellCommentEditorJustClosed}
          />
        ) : selectedDocId ? (
          <EditHomeworkDocCommentPopover
            courseId={Number(courseId)}
            homeworkDocumentsList={homeworkDraft && homeworkDraft.documents}
            // sync with viewer props
            closeCommentViewer={closeCommentViewer}
            closeRequested={editorCloseRequested}
            tellCommentEditorJustClosed={tellCommentEditorJustClosed}
          />
        ) : (
          <EditHomeworkCommentModal
            courseId={Number(courseId)}
            homeworkDocumentsList={homeworkDraft && homeworkDraft.documents}
          />
        )}
        {hasRedirect ? (
          <BackToRedirected />
        ) : (
          <HomeworkDraftControl
            renderDeleteAndViewButtons={!selectedDocId}
            renderActivateButton={!selectedDocId}
          >
            {pageNumber && selectedDocId ? (
              <DocumentPageControls
                studentTeacherId={Number(studentTeacherId)}
                homeworkId={homeworkDraft && homeworkDraft.id}
                pages={homeworkDraft && homeworkDraft.documentPages}
                documentInstanceId={selectedDocId}
                pageNumber={pageNumber}
                courseId={Number(courseId)}
              />
            ) : selectedDocId ? (
              <DocumentControls
                homeworkId={homeworkDraft && homeworkDraft.id}
                homeworkDocumentsList={homeworkDraft && homeworkDraft.documents}
                documentInstanceId={selectedDocId}
                courseId={Number(courseId)}
              />
            ) : null}
          </HomeworkDraftControl>
        )}
        {comment && (
          <HomeworkDocCommentViewer
            comment={comment}
            // sync with editor
            closeCommentEditor={closeCommentEditor}
            closeRequested={viewerCloseRequested}
            commentEditorJustClosed={commentEditorJustClosed}
            setCommentEditorJustClosed={setCommentEditorJustClosed}
            tellCommentViewerJustClosed={tellCommentViewerJustClosed}
          />
        )}
        {canOpenTogether && !isOnUploadsTab && <DisplayButton />}
      </PageControls>
    </FilesWorkSpace>
  );
};

export default FilesPage;
