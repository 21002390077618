import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import type {PageViewport} from 'pdfjs-dist/types/src/display/display_utils';
import {normalizeOrientation, type POrientation} from '@englex/paint';

interface PDFViewerPageCtx {
  setViewport: (page: PageViewport | null) => void;
  width: number;
  height: number;
  scale: number | null;
  orientation: POrientation;
  isSwapDimensions: boolean;
  setOrientation(orientation: POrientation): void;
  rotatePage(cw?: boolean): void;
  setScale(scale: number | null): void;
  fitToWidth: boolean;
  setFitToWidth(fitToWidth: boolean): void;
  toolbarPortalNode: HTMLDivElement | null;
  setToolbarPortalNode(e: HTMLDivElement | null): void;
  childrenError?: () => void;
  childrenLoading: boolean;
  setChildrenError: (reload?: () => void) => void;
  setChildrenLoading: (loading: boolean) => void;
}

const PDFViewerPageContext = React.createContext<PDFViewerPageCtx>({
  setViewport: () => {},
  width: 0,
  height: 0,
  scale: 1,
  orientation: 0,
  isSwapDimensions: false,
  setScale: () => {},
  setOrientation: () => {},
  rotatePage: () => {},
  fitToWidth: true,
  setFitToWidth: () => {},
  toolbarPortalNode: null,
  setToolbarPortalNode: () => {},
  childrenLoading: false,
  setChildrenError: () => {},
  setChildrenLoading: () => {}
});

export const PDFViewerPageProvider = PDFViewerPageContext.Provider;

export const usePDFViewerPage = () => useContext(PDFViewerPageContext);

export const usePDFPageCtxState = (defaultChildrenLoading: boolean = false) => {
  const [viewport, setViewport] = useState<PageViewport | null>(null);
  const [scale, setScale] = useState<number | null>(null);
  const [orientation, setOrientation] = useState<POrientation>(0);
  const [fitToWidth, setFitToWidth] = useState(true);
  const [toolbarPortalNode, setToolbarPortalNode] = useState<HTMLDivElement | null>(null);
  const [childrenLoading, setChildrenLoading] = useState(defaultChildrenLoading);
  const [childrenError, setChildrenError] = useState<boolean>(false);
  const reloadChildren = useRef<(() => void) | undefined>(undefined);

  const setError = useCallback((reload?: () => void) => {
    reloadChildren.current = reload;
    setChildrenError(!!reload);
  }, []);

  const setLoading = useCallback(l => setChildrenLoading(l), []);

  const ctx: PDFViewerPageCtx = useMemo(() => {
    return {
      setViewport,
      get width() {
        return viewport ? viewport.width : 0;
      },
      get height() {
        return viewport ? viewport.height : 0;
      },
      get isSwapDimensions() {
        return orientation !== 0 && orientation !== 180;
      },
      scale,
      setScale,
      orientation,
      setOrientation,
      rotatePage(cw: boolean = true) {
        return setOrientation(normalizeOrientation(orientation, cw ? 90 : -90));
      },
      fitToWidth,
      setFitToWidth,
      toolbarPortalNode,
      setToolbarPortalNode,
      childrenError: childrenError ? reloadChildren.current : undefined,
      setChildrenError: setError,
      childrenLoading,
      setChildrenLoading: setLoading
    };
  }, [
    childrenError,
    childrenLoading,
    fitToWidth,
    orientation,
    scale,
    setError,
    setLoading,
    toolbarPortalNode,
    viewport
  ]);
  return ctx;
};
