import {useMemo} from 'react';
import {matchPath, useLocation} from 'react-router-dom';

import {filesListPattern} from 'common/paths';
import {MaterialsTab} from 'common/enums';

import {materialsTabParamName} from '../components/FilesWorkSpace/components/interface';
import {enumContainsValue} from '../../../helpers/enum';

export const useFilesTab = (): MaterialsTab | null => {
  const {pathname, search} = useLocation();
  return useMemo(() => {
    if (matchPath({path: filesListPattern, end: false}, pathname)) {
      const openedTab = new URLSearchParams(search).get(materialsTabParamName);
      return (
        (openedTab && enumContainsValue(MaterialsTab, openedTab) && (openedTab as MaterialsTab)) ||
        MaterialsTab.DOCUMENTS
      );
    }
    return null;
  }, [pathname, search]);
};
