import React from 'react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import GenericFileControl, {
  type GenericFileControlProps,
  type GenericFileControlState
} from '../GenericFileControl';
import FileEditDropdown from '../FileEditDropdown';
import {type DocumentFile} from '../../../documentsTab/actions/interface';
import RightIcons from '../RightIcons';

import {
  type DocumentFileControlDispatchProps,
  type DocumentFileControlOwnProps,
  type DocumentFileControlStateProps
} from './index';

interface Props
  extends GenericFileControlProps,
    DocumentFileControlOwnProps,
    DocumentFileControlStateProps,
    DocumentFileControlDispatchProps {
  thisFile?: DocumentFile;
}

interface State extends GenericFileControlState {}

class DocumentFileControl extends GenericFileControl<Props & WrappedComponentProps, State> {
  public componentDidUpdate(prevProps: Props & WrappedComponentProps) {
    super.componentDidUpdate(prevProps);
  }

  public componentWillUnmount() {
    super.componentWillUnmount();
  }

  public render() {
    const {
      thisFileId,
      role,
      fileIsActive,
      publishMaterialsUpdated,
      fileIsBeingEdited,
      pinnedBlock,
      thisFile
    } = this.props;
    if (!thisFile) {
      return null;
    }
    const {nameIsValid} = this.state;
    const className = classNames(`fc-${role}`, {
      'active-file-control': fileIsActive,
      'edited-file-control': fileIsBeingEdited && this.state.componentBeingEdited,
      'invalid-name': !nameIsValid,
      pinned: thisFile.pinned
    });
    const isBeingOpenedTogether = this.props.thisFileId === this.props.openTogetherFileId;
    return (
      <li className={className} onClick={this.handleControlClick}>
        {this.renderNotificationCircle()}
        <div className="file-control-middle">
          {this.renderFileName()}
          {this.renderEditor()}
          {this.renderFileEditDropdown()}
        </div>
        <RightIcons
          fileExt={thisFile.type}
          readonly={this.props.readonly}
          fileIsBeingEdited={this.props.fileIsBeingEdited}
          componentBeingEdited={this.state.componentBeingEdited}
          editFileAwait={this.props.editFileAwait}
          deleteFileAwait={!!this.props.deletingFileComponentId}
          fileId={thisFileId}
          fileType="document"
          pinnedBlock={pinnedBlock}
          publish={publishMaterialsUpdated}
          isBeingOpenedTogether={isBeingOpenedTogether}
          saveEditingChanges={this.saveEditingChanges}
          discardEditingChanges={this.discardEditingChanges}
          openTogether={this.openTogether}
          intl={this.props.intl}
        />
        {this.renderDeletingOverlay()}
      </li>
    );
  }

  private renderFileEditDropdown = () => (
    <FileEditDropdown
      thisFileId={this.props.thisFileId}
      fileIsBeingEdited={this.props.fileIsBeingEdited}
      fileIsActive={this.props.fileIsActive}
      readonly={this.props.readonly}
      deleteFileAwait={!!this.props.deletingFileComponentId}
      role={this.props.role}
      fileType="document"
      thisFile={this.props.thisFile!}
      editIconClickHandler={this.editIconClickHandler}
      pinnedBlock={this.props.pinnedBlock}
      controlComponentId={this.componentId}
    />
  );
}

export default injectIntl(DocumentFileControl);
