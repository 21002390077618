import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useOutletContext, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {type AppState, type CoursebookInstance, type UnitInstance} from 'store/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import Confirm from 'components/modals/Confirm';
import WampErrorMask from 'components/WampErrorMask';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {ACTIVE_UNITS_LIMIT} from 'config/static';
import {type ViewContentsModalTab} from 'components/CoursebookContentsViewer/interface';
import CoursebookInstanceContentsViewer from 'components/CoursebookContentsViewer/CoursebookInstanceContentsViewer';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import PageControls from 'components/PageControls';
import Loader from 'components/Loader';
import {type CoursebookInstanceUrlParams} from 'common/paths';

import {
  activateUnit,
  clearSelectedCoursebookInstance,
  deactivateUnit,
  requestCoursebookInstance,
  setSelectedCoursebookInstance
} from './actions';
import i18n from './i18n';
import CoursebookInfoDetailed from '../../components/CoursebookInfoDetailed/CoursebookInfoDetailed';
import CoursebookInstancePageView from './CoursebookInstancePageView';
import ViewContentsModal from '../../../Library/Common/ViewContentsModal';
import DisplayButton from '../../components/DisplayButton';
import HomeworkDraftControl from '../../components/HomeworkDraftControl/HomeworkDraftControl';
import {setHasGrammar} from '../../actions/action';
import {type CoursebookInstanceOutletContext} from '../../containers/CoursebookInstanceLayout';

const CoursebookInstancePage: React.FC = () => {
  const intl = useIntl();

  const {
    studentTeacherId,
    courseId,
    coursebookInstanceId: cbIdParam
  } = useParams<CoursebookInstanceUrlParams>();

  const {reloadUnits: refreshUnitInstances} = useOutletContext<CoursebookInstanceOutletContext>();

  const coursebookInstanceId = useSelector((state: AppState) =>
    state.classroom!.courseInstanceState.coursebookInstanceList!.find(
      instance => instance.id === cbIdParam
    )
  )?.id!;

  const unitInstances = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState.coursebookInstanceState.unitInstances!
  );

  const activeCourseInstance = useSelector((s: AppState) => s.classroom?.courses?.[courseId!])!;

  const courseInstanceIsActive = !activeCourseInstance?.deleted_at;

  const selectedCoursebookInstance = useSelector(
    (state: AppState) =>
      state.classroom!.courseInstanceState.coursebookInstanceState.selectedCoursebookInstance
  );

  const activationForbidden = useMemo(
    () => unitInstances.filter(ui => ui.active && !ui.completedAt).length >= ACTIVE_UNITS_LIMIT,
    [unitInstances]
  );

  const isTeacher = useSelector((state: AppState) => state.user.role === 'teacher');

  const [unitIdToToggle, setUnitToToggle] = useState<number | undefined>(undefined);
  const [confirmHeaderText, setConfirmHeaderText] = useState('');
  const [dispatching, setDispatching] = useState(false);
  const mounted = useRef(true);
  const dispatch = useAxiosDispatch();

  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  const coursebookInstanceRequestAction = useMemo(
    () => requestCoursebookInstance(coursebookInstanceId),
    [coursebookInstanceId]
  );
  useEffect(
    () =>
      function clearSelectedCbi() {
        dispatch(clearSelectedCoursebookInstance());
      },
    [coursebookInstanceId, dispatch]
  );

  const successHandler = useCallback(
    (cbi: CoursebookInstance) => {
      dispatch(setHasGrammar(!!cbi.hasGrammar));
      dispatch(setSelectedCoursebookInstance(cbi));
    },
    [dispatch]
  );

  const {isError, reload} = useApiRequest(coursebookInstanceRequestAction, successHandler);

  const [isShowingContents, toggleContents] = useState<boolean>(false);
  const hideContents = useCallback(() => toggleContents(false), []);
  const showContents = useCallback(() => toggleContents(true), []);

  const unitToToggle = useMemo<UnitInstance | undefined>(
    () => unitInstances.find(ui => ui.id === unitIdToToggle),
    [unitInstances, unitIdToToggle]
  );

  const toggleUnit = useCallback(async () => {
    if (unitToToggle && !dispatching) {
      try {
        if (activationForbidden && !unitToToggle.active) {
          throw new Error('Activation Forbidden');
        }
        setDispatching(true);
        await dispatch(
          unitToToggle.active ? deactivateUnit(unitToToggle.id) : activateUnit(unitToToggle.id)
        );
      } catch (e) {
        if (e.message === 'Activation Forbidden') {
          toastr.error(
            '',
            intl.formatMessage(
              {id: 'CoursebookInstancePage.UnitsList.MaxUnitsCountAchieved'},
              {maxOpenUnits: ACTIVE_UNITS_LIMIT}
            )
          );
        } else {
          toastr.error(
            '',
            intl.formatMessage(unitToToggle.active ? i18n.closeError : i18n.openError)
          );
        }
      } finally {
        if (mounted.current) setDispatching(false);
      }
      setUnitToToggle(undefined);
    }
  }, [activationForbidden, dispatch, dispatching, intl, unitToToggle]);

  useEffect(() => {
    if (unitToToggle) {
      setConfirmHeaderText(
        intl.formatMessage(
          unitToToggle.active ? i18n.confirmModalHeaderClose : i18n.confirmModalHeaderOpen
        )
      );
    }
  }, [intl, unitToToggle]);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  if (isError) {
    return <WampErrorMask reload={reload} />;
  }
  if (!selectedCoursebookInstance) {
    return <Loader />;
  }
  const selectedCoursebookId = isShowingContents
    ? selectedCoursebookInstance.coursebook.id
    : undefined;
  const selectedCoursebookInstanceId = isShowingContents
    ? selectedCoursebookInstance.id
    : undefined;
  const showViewContentsModal = !!(selectedCoursebookId && coursebookInstanceId);
  return (
    <>
      <CoursebookInfoDetailed>
        <ViewContentsModal
          show={showViewContentsModal}
          close={hideContents}
          renderBody={(tab: ViewContentsModalTab) =>
            showViewContentsModal && (
              <CoursebookInstanceContentsViewer
                selectedTab={tab}
                coursebookId={selectedCoursebookId!}
                coursebookInstanceId={selectedCoursebookInstanceId!}
              />
            )
          }
        />
        {isTeacher && (
          <Confirm
            headerText={confirmHeaderText}
            onAccept={toggleUnit}
            onDecline={() => setUnitToToggle(undefined)}
            show={!!unitToToggle}
            disableButtons={dispatching}
          />
        )}
        <CoursebookInstancePageView
          courseInstanceIsActive={courseInstanceIsActive}
          isTeacher={isTeacher}
          coursebook={selectedCoursebookInstance.coursebook}
          unitInstances={unitInstances}
          overview={selectedCoursebookInstance.overview!}
          setUnitToToggle={setUnitToToggle}
          showContents={
            selectedCoursebookInstance.coursebook.overview!.unitSectionsNumber
              ? showContents
              : undefined
          }
        />
      </CoursebookInfoDetailed>
      {isTeacher && (
        <PageControls>
          <HomeworkDraftControl
            deleteCallback={refreshUnitInstances}
            renderDeleteAndViewButtons={true}
            renderActivateButton={true}
          />
          {canOpenTogether && <DisplayButton />}
        </PageControls>
      )}
    </>
  );
};

export default CoursebookInstancePage;
