import {type Action} from 'redux';

import {type CourseInstanceState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  CLEAR_COURSE_INSTANCE_INFO,
  CLEAR_HOMEWORK_DRAFT,
  COURSEBOOK_INSTANCE_DELETED,
  SET_COURSE_INSTANCE_OVERVIEW,
  SET_COURSEBOOK_INSTANCES,
  SET_HOMEWORK_DRAFT,
  TOGGLE_BREADCRUMB_DROPDOWN,
  TOGGLE_PAGER_POPOVER
} from '../pages/CourseInstancePage/actionTypes';
import {
  type CoursebookInstanceAction,
  type SetCoursebookInstancesAction,
  type SetCourseInstanceOverviewAction,
  type SetHomeworkDraftAction
} from '../pages/CourseInstancePage/actions';
import getCoursebookInstanceReducer, {
  initialCoursebookInstanceState
} from './coursebookInstanceReducer';
import {type ToggleElementAction} from '../../../common/interface';
import getHomeworkReducer, {defaultHomeworkState} from './homeworkReducer';
import getCoursebookInstanceListReducer from './coursebookInstanceListReducer';

export const initialCourseInstanceState: CourseInstanceState = {
  coursebookInstanceState: initialCoursebookInstanceState,
  homework: defaultHomeworkState
};

const ACTION_HANDLERS: ActionHandlersList<CourseInstanceState, Action> = {
  [SET_COURSEBOOK_INSTANCES]: (
    state: CourseInstanceState,
    action: SetCoursebookInstancesAction
  ): CourseInstanceState => ({
    ...state,
    coursebookInstanceList: action.instances
  }),
  [SET_COURSE_INSTANCE_OVERVIEW]: (
    state: CourseInstanceState,
    action: SetCourseInstanceOverviewAction
  ): CourseInstanceState => ({
    ...state,
    courseInstanceOverview: action.overview
  }),
  [TOGGLE_BREADCRUMB_DROPDOWN]: (
    state: CourseInstanceState,
    action: ToggleElementAction
  ): CourseInstanceState => ({
    ...state,
    coursebookInstancesDropdownOpen: action.show
  }),
  [CLEAR_COURSE_INSTANCE_INFO]: (state: CourseInstanceState): CourseInstanceState => ({
    ...state,
    coursebookInstanceList: undefined,
    courseInstanceOverview: undefined
  }),
  [TOGGLE_PAGER_POPOVER]: (
    state: CourseInstanceState,
    action: ToggleElementAction
  ): CourseInstanceState => ({
    ...state,
    pagerPopoverOpen: action.show
  }),
  [SET_HOMEWORK_DRAFT]: (
    state: CourseInstanceState,
    {draft}: SetHomeworkDraftAction
  ): CourseInstanceState => ({
    ...state,
    homeworkDraft: draft
  }),
  [CLEAR_HOMEWORK_DRAFT]: (state: CourseInstanceState): CourseInstanceState => ({
    ...state,
    homeworkDraft: undefined
  }),
  [COURSEBOOK_INSTANCE_DELETED]: (
    state: CourseInstanceState,
    {coursebookInstanceId}: CoursebookInstanceAction
  ): CourseInstanceState => ({
    ...state,
    coursebookInstanceList:
      state.coursebookInstanceList &&
      state.coursebookInstanceList.filter(cbi => cbi.id !== coursebookInstanceId)
  })
};

export default function (action: Action) {
  const courseInstanceReducer = ACTION_HANDLERS[action.type];
  const coursebookInstanceReducer = getCoursebookInstanceReducer(action);
  const homeworkReducer = getHomeworkReducer(action);
  const coursebookInstanceListReducer = getCoursebookInstanceListReducer(action);
  if (
    !courseInstanceReducer &&
    !coursebookInstanceReducer &&
    !homeworkReducer &&
    !coursebookInstanceListReducer
  ) {
    return undefined;
  }
  return (state: CourseInstanceState, action: Action): CourseInstanceState => {
    const newState = courseInstanceReducer ? courseInstanceReducer(state, action) : state;
    return {
      ...newState,
      coursebookInstanceState: coursebookInstanceReducer
        ? coursebookInstanceReducer(newState.coursebookInstanceState, action)
        : newState.coursebookInstanceState,
      homework: homeworkReducer ? homeworkReducer(newState.homework, action) : newState.homework,
      coursebookInstanceList: coursebookInstanceListReducer
        ? coursebookInstanceListReducer(newState.coursebookInstanceList)
        : newState.coursebookInstanceList
    };
  };
}
