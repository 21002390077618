import React from 'react';

import {PDFViewer} from './PDFViewer';
import {usePDFRoutePageNumber} from './usePDFRoutePageNumber';
import {type PDFViewerProps} from './interface';

export const PDFViewerRoute: React.FC<Omit<PDFViewerProps, 'goToPage'>> = props => {
  const [pageNumber, setPageNumber] = usePDFRoutePageNumber(props.pageNumber);

  return (
    <PDFViewer
      {...props}
      pageNumber={props.pageNumber || Number(pageNumber)}
      goToPage={setPageNumber}
    >
      {props.children}
    </PDFViewer>
  );
};
