import React, {useCallback, useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {defineMessages, FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useDispatch, useSelector} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import classNames from 'classnames';

import * as toastr from 'components/toastr';
import {type AxiosRequestError} from 'services/axios/interface';
import {Resources} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import Icon from 'components/Icon';
import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';
import CoursebookContentsViewer from 'components/CoursebookContentsViewer/CoursebookContentsViewer';
import UnitPreviewContainer from 'components/modals/UnitPreview/UnitPreviewContainer';
import {clearGrammarPreviewOpen, clearMainExerciseId} from 'components/modals/UnitPreview/actions';
import {ViewContentsModalTab} from 'components/CoursebookContentsViewer/interface';
import {type AppState, type CoursebookFilter, type CoursebookInstance} from 'store/interface';
import GrammarPlayerPage from 'common/GrammarPlayerPage/GrammarPlayerPage';

import CoursebookUnits from './CoursebookUnits';
import Library from './Library';
import {type AddCoursebookStateManagement, AddCoursebookStateManagementContext} from './context';
import {createCoursebookInstance} from '../actions';
import {defaultCoursebookFilter, emptyCoursebookFilter} from './defaultFilter';

import './AddCoursebookModal.scss';

interface Props extends WrappedComponentProps {
  isModalShown: boolean;
  hideModal: () => void;
  courseId: number;
  alreadyAdded: CoursebookInstance[];
}

const messages = defineMessages({
  SuccessfullyAdded: {
    id: 'AddCoursebookModal.SuccessfullyAdded'
  },
  AssignFailed: {
    id: 'AddCoursebookModal.AssignFailed'
  },
  AssignFailedEmptyCoursebook: {
    id: 'AddCoursebookModal.AssignFailedEmptyCoursebook'
  }
});

const AddCoursebookModal: React.FC<Props> = ({
  hideModal,
  isModalShown,
  courseId,
  alreadyAdded,
  intl: {formatMessage}
}: Props) => {
  const [showingUnitsForCoursebook, showUnitsForCoursebook] = useState<undefined | string>();
  const [showingContentsForCoursebook, showContentsForCoursebook] = useState<undefined | string>();
  const [showingGrammarForCoursebook, showGrammarForCoursebook] = useState<undefined | string>();
  const [selectedUnit, selectUnit] = useState<undefined | number>();
  const [filter, changeFilter] = useState<CoursebookFilter>(emptyCoursebookFilter);
  const [showLoadingMask, setShowLoadingMask] = useState<boolean>();
  const [selectedTab, changeSelectedTab] = useState<ViewContentsModalTab>(
    ViewContentsModalTab.UNITS
  );
  const viewingSupplementaryUnit = !!useSelector(
    (state: AppState) => state.unitPreview && state.unitPreview.mainId
  );
  const grammarPreview = useSelector<AppState, string | undefined>(
    state => state.unitPreview?.grammarPreview
  );

  const hasUnpublishedBookAccess = useSelector(
    (state: AppState) => !!state.settings?.teacher?.vircUnpublishedBookAccess
  );

  const dispatch = useDispatch<Dispatch<Action, AppState>>();

  const prevIsModalShown = useRef(false);

  useEffect(
    function handleModalClosed() {
      if (!isModalShown) {
        showUnitsForCoursebook(undefined);
        showContentsForCoursebook(undefined);
        selectUnit(undefined);
        changeFilter(defaultCoursebookFilter);
        changeSelectedTab(ViewContentsModalTab.UNITS);
        showGrammarForCoursebook(undefined);
      }
    },
    [isModalShown]
  );

  const viewCoursebookContents = useCallback((id: string) => {
    showContentsForCoursebook(id);
  }, []);

  const viewCoursebookGrammar = useCallback((id: string) => {
    showGrammarForCoursebook(id);
  }, []);

  const goBack = useCallback(() => {
    if (viewingSupplementaryUnit) {
      dispatch(clearMainExerciseId);
      return;
    }

    if (grammarPreview) {
      dispatch(clearGrammarPreviewOpen());
      return;
    }

    if (selectedUnit) {
      selectUnit(undefined);
      return;
    }

    if (showingContentsForCoursebook) {
      changeSelectedTab(ViewContentsModalTab.UNITS);
      showContentsForCoursebook(undefined);
      return;
    }

    if (showingGrammarForCoursebook) {
      showGrammarForCoursebook(undefined);
      return;
    }

    if (showingUnitsForCoursebook) {
      showUnitsForCoursebook(undefined);
      return;
    }
  }, [
    viewingSupplementaryUnit,
    grammarPreview,
    selectedUnit,
    showingContentsForCoursebook,
    showingGrammarForCoursebook,
    showingUnitsForCoursebook,
    dispatch
  ]);

  const addCoursebook = useCallback(
    (coursebookId: string) => {
      setShowLoadingMask(true);
      dispatch(createCoursebookInstance(courseId, coursebookId)).then(
        () => {
          setShowLoadingMask(false);
          hideModal();
          toastr.success('', formatMessage(messages.SuccessfullyAdded));
        },
        (error: AxiosRequestError) => {
          setShowLoadingMask(false);
          if (error.error.response && error.error.response.status === 423) {
            toastr.error('', formatMessage(messages.AssignFailedEmptyCoursebook));
          } else {
            toastr.error('', formatMessage(messages.AssignFailed));
          }
        }
      );
    },
    [dispatch, courseId, hideModal, formatMessage]
  );

  const selectedCoursebook =
    showingContentsForCoursebook || showingUnitsForCoursebook || showingGrammarForCoursebook;

  const addSelectedCoursebook = useCallback(
    () => selectedCoursebook && addCoursebook(selectedCoursebook),
    [addCoursebook, selectedCoursebook]
  );

  const isAlreadyAdded = useCallback(
    (id: string) => !!alreadyAdded.find(cbi => cbi.coursebook.id === id),
    [alreadyAdded]
  );

  const stateManager: AddCoursebookStateManagement = {
    selectCoursebook: showUnitsForCoursebook,
    viewCoursebookContents,
    viewCoursebookGrammar,
    addCoursebook,
    isAlreadyAdded
  };

  const resourcesNode = useNode(
    '.add-coursebook-modal .modal-body',
    isModalShown && !prevIsModalShown.current
  );

  prevIsModalShown.current = isModalShown;

  return (
    <Modal
      show={isModalShown}
      onHide={hideModal}
      backdrop="static"
      className="add-coursebook-modal"
    >
      <AddCoursebookStateManagementContext.Provider value={stateManager}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="AddCoursebookModal.Header" />

            <ToggleButtonGroup
              type="radio"
              bsSize="sm"
              name="ViewContentsModalTab"
              value={selectedTab}
              className={classNames('contents-viewer-tab-switch', {
                invisible: !showingContentsForCoursebook
              })}
              onChange={changeSelectedTab}
            >
              <ToggleButton value={ViewContentsModalTab.UNITS}>
                <FormattedMessage id="ViewCoursebookContentsModal.Units" />
              </ToggleButton>
              <ToggleButton value={ViewContentsModalTab.SECTIONS}>
                <FormattedMessage id="ViewCoursebookContentsModal.Sections" />
              </ToggleButton>
            </ToggleButtonGroup>

            <a onClick={hideModal}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {(() => {
            if (showingGrammarForCoursebook) {
              return (
                <GrammarPlayerPage coursebookId={showingGrammarForCoursebook} isModal={true} />
              );
            }
            if (showingContentsForCoursebook) {
              return (
                <CoursebookContentsViewer
                  coursebookId={showingContentsForCoursebook}
                  selectedTab={selectedTab}
                />
              );
            }
            if (!showingUnitsForCoursebook) {
              return (
                <Library
                  filter={filter}
                  changeFilter={changeFilter}
                  hasUnpublishedBookAccess={hasUnpublishedBookAccess}
                />
              );
            }

            if (selectedUnit) {
              return (
                <Resources id="add-coursebook-modal" node={resourcesNode}>
                  <UnitPreviewContainer
                    unitId={selectedUnit}
                    coursebookId={showingUnitsForCoursebook}
                  />
                </Resources>
              );
            }
            return (
              <CoursebookUnits
                coursebookId={showingUnitsForCoursebook}
                showCoursebookContents={showContentsForCoursebook}
                showGrammarCoursebook={showGrammarForCoursebook}
                selectUnit={selectUnit}
              />
            );
          })()}
          {showLoadingMask ? <SimpleLoadingOverlay /> : null}
        </Modal.Body>

        <Modal.Footer>
          <div className="buttons">
            {selectedCoursebook ? (
              <React.Fragment>
                <Button className="btn-transparent" bsSize="sm" onClick={goBack}>
                  <FormattedMessage id="Common.Back" />
                </Button>
                {selectedCoursebook && isAlreadyAdded(selectedCoursebook) ? null : (
                  <Button bsStyle="primary" bsSize="sm" onClick={addSelectedCoursebook}>
                    <FormattedMessage id="AddCoursebookModal.Add" />
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <Button bsStyle="primary" bsSize="sm" onClick={hideModal}>
                <FormattedMessage id="Common.Close" />
              </Button>
            )}
          </div>
        </Modal.Footer>
      </AddCoursebookStateManagementContext.Provider>
    </Modal>
  );
};

export default injectIntl(AddCoursebookModal);
