import React from 'react';
import type {PDFPageProxy} from 'pdfjs-dist/types/src/display/api';

import Loader from 'components/Loader';

interface Props {
  page: PDFPageProxy;
  pageHeight: number;
  pageWidth: number;
  rotation: number;
  thumbnailHeight: number;
  thumbnailWidth: number;
}

export const Thumbnail: React.FC<Props> = ({
  page,
  pageHeight,
  pageWidth,
  rotation,
  thumbnailHeight,
  thumbnailWidth
}) => {
  const renderTask = React.useRef<{promise: Promise<unknown>; cancel: () => void}>();
  const [src, setSrc] = React.useState('');

  React.useEffect(() => {
    const task = renderTask.current;
    if (task) {
      task.cancel();
    }

    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const canvasContext = canvas.getContext('2d', {alpha: false}) as CanvasRenderingContext2D;

    const w = thumbnailWidth * window.devicePixelRatio;
    const h = w / (pageWidth / pageHeight);
    const scale = w / pageWidth;

    canvas.height = h;
    canvas.width = w;
    canvas.style.height = `${h}px`;
    canvas.style.width = `${w}px`;

    const viewport = page.getViewport({rotation, scale});
    renderTask.current = page.render({canvasContext, viewport});
    renderTask.current.promise.then(
      () => {
        setSrc(canvas.toDataURL());
        canvas.width = 0;
        canvas.height = 0;
      },
      () => {}
    );
  }, [page, pageHeight, pageWidth, rotation, thumbnailWidth]);

  return !src ? (
    <Loader spinnerClassName="pdf-contents-thumbnail-spinner sm" />
  ) : (
    <img src={src} height={`${thumbnailHeight}px`} width={`${thumbnailWidth}px`} alt="" />
  );
};
