import React from 'react';

import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import Spinner from 'components/Spinner';
import {type AvatarUrl, type UploadingFile} from 'store/interface';
import {FileExtension} from 'common/enums';

import {type RemoveFileFromUploadsCreator} from '../actions/interface';
import UploadProgress from './UploadProgress';
import {fileIcon} from '../../../../../../helpers/file';

interface UploadingFileControlProps extends UploadingFile {
  avatar?: AvatarUrl;
  bigText?: boolean;
  removeFileFromUploads: RemoveFileFromUploadsCreator;
  recipientFullName?: string;
}

export default class UploadingFileControl extends React.PureComponent<
  UploadingFileControlProps,
  {}
> {
  public render() {
    const className: string = `fc-uploading ${this.props.uploadingStatus === 'fail' ? 'fail' : ''}`;
    return (
      <li className={className}>
        {this.renderIcon()}
        <div className="text">
          <div>
            <span className={this.props.bigText ? 'big-text' : ''}>{this.props.title}</span>
            {this.renderPercentage()}
          </div>
          {this.renderProgress()}
          {this.renderError()}
        </div>
        <div className="file-control-right">
          {this.renderAvatar()}
          {this.renderAbortBtn()}
        </div>
      </li>
    );
  }

  private renderPercentage = () => {
    if (this.props.uploadingStatus === 'uploading' && this.props.percentsUploaded) {
      return <span className="percentage">{this.props.percentsUploaded}%</span>;
    }
    return null;
  };

  private renderError = () => {
    if (this.props.uploadingStatus === 'fail' && this.props.errorMessage) {
      return <span>{this.props.errorMessage}</span>;
    }
    return null;
  };

  private renderIcon = () => {
    if (this.props.uploadingStatus === 'fail') {
      return <Icon name="minus-circle" size="xlg" />;
    }
    const iconName = this.props.fileType === 'audio' ? FileExtension.mp3 : FileExtension.pdf;

    return <Icon name={fileIcon(iconName)} size="lg" />;
  };

  private renderProgress = () => {
    if (this.props.uploadingStatus === 'uploading' && this.props.percentsUploaded) {
      return <UploadProgress percents={this.props.percentsUploaded} />;
    }
    return null;
  };

  private renderAvatar = () => {
    if (this.props.avatar) {
      return <Avatar url={this.props.avatar} size={28} title={this.props.recipientFullName} />;
    }
    return null;
  };

  private renderAbortBtn = () => {
    switch (this.props.uploadingStatus) {
      case 'fail':
        return <Icon name="times" size="lg" onClick={this.removeFile} />;
      case 'uploaded':
      case 'uploading':
        return null;
      default:
        return <Spinner size={18} />;
    }
  };

  private removeFile = () => {
    this.props.removeFileFromUploads(this.props.md5, this.props.id);
  };
}
