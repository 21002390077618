import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import React, {type FC, type SyntheticEvent} from 'react';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';

import {documentDraftControlMessages} from './i18n';
import Confirm from '../../../../components/modals/Confirm';

interface Props {
  isStudent: boolean;
  isShowConfirm: boolean;
  isInDraft: boolean;
  isDeleting: boolean;
  editDocInstanceId?: number;
  onDeleteClick: (e: SyntheticEvent<HTMLButtonElement | Button>) => void;
  toggle: (e: SyntheticEvent<HTMLButtonElement | Button>) => void;
  onDeleteConfirmAccept: () => void;
  hideConfirm: () => void;
  redirectToDocument?: () => void;
  deleteConfirmTitle: string | React.ReactElement;
  hasViewInDocumentButton?: boolean;
}

export const DocControls: FC<Props> = ({
  isStudent,
  isInDraft,
  editDocInstanceId,
  onDeleteClick,
  toggle,
  isShowConfirm,
  deleteConfirmTitle,
  onDeleteConfirmAccept,
  redirectToDocument,
  hideConfirm,
  isDeleting,
  hasViewInDocumentButton
}) => {
  const intl = useIntl();

  if (isStudent) {
    return hasViewInDocumentButton ? (
      <Button
        className="btn-transparent"
        title={intl.formatMessage(documentDraftControlMessages.ViewInDocument)}
        onClick={redirectToDocument}
      >
        <Icon name="virc-mnu" />
      </Button>
    ) : null;
  }

  if (isInDraft) {
    return (
      <>
        <Button
          className="btn-transparent"
          title={intl.formatMessage(documentDraftControlMessages.DeleteFromHomework)}
          onClick={onDeleteClick}
        >
          <Icon name="virc-homework-remove" />
        </Button>
        <Button
          className={classNames('btn-transparent', {'is-active': !!editDocInstanceId})}
          title={intl.formatMessage(documentDraftControlMessages.EditCommentary)}
          onClick={toggle}
        >
          <Icon name="edit" />
        </Button>
        {hasViewInDocumentButton && (
          <Button
            className="btn-transparent"
            title={intl.formatMessage(documentDraftControlMessages.ViewInDocument)}
            onClick={redirectToDocument}
          >
            <Icon name="virc-mnu" />
          </Button>
        )}
        <Confirm
          headerText={deleteConfirmTitle}
          show={isShowConfirm}
          onAccept={onDeleteConfirmAccept}
          onDecline={hideConfirm}
          disableButtons={isDeleting}
        />
      </>
    );
  }
  return (
    <Button
      className={classNames('btn-transparent', 'add-document-button', {
        'is-active': !!editDocInstanceId
      })}
      onClick={toggle}
    >
      <Icon name="virc-homework-add" />
      {intl.formatMessage(documentDraftControlMessages.Title)}
    </Button>
  );
};
