import React from 'react';
import classNames from 'classnames';
import {FormattedDate, FormattedMessage} from 'react-intl';
import moment from 'moment';

import {type Locale, type MaterialFile, type UploadingFile} from 'store/interface';

import UploadingFileControl from '../uploadingFiles/components/UploadingFileControl';
import DocumentFileControl from './FileControl/DocumentFileControl';
import AudioFileControl from './FileControl/AudioFileControl';
import {type FileType, type PublishMaterialsOpenCreator} from '../../../actions/interface';
import {type RemoveFileFromUploadsCreator} from '../uploadingFiles/actions/interface';
import {type PublishMaterialsOpen} from './interface';

interface Props {
  locale: Locale;
  files: Array<MaterialFile | UploadingFile>;
  date: string;
  readonly: boolean;
  fileType: FileType;
  courseId: string;
  removeFileFromUploads: RemoveFileFromUploadsCreator;
  publishMaterialsOpen: PublishMaterialsOpenCreator | PublishMaterialsOpen;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  errorBlock?: true;
  selectDocument: (id: number) => void;
}

class FilesByDate extends React.Component<Props, {}> {
  public shouldComponentUpdate(newProps: Props) {
    const filesLengthChanged = this.props.files.length !== newProps.files.length;
    const localeChanged = this.props.locale !== newProps.locale;
    return (
      localeChanged ||
      filesLengthChanged ||
      !!newProps.files.find((file, index) => file !== this.props.files[index])
    );
  }

  public render() {
    const {errorBlock, files} = this.props;
    const sortedFiles = errorBlock ? this.sortErrorFiles(files) : this.sortFiles(files);
    return (
      sortedFiles && (
        <tr>
          {this.renderDate()}
          <td>
            <ul>{sortedFiles.map(this.renderFileControl)}</ul>
          </td>
        </tr>
      )
    );
  }

  private renderDate = () => {
    const {date, locale} = this.props;

    const today = moment(new Date());
    const fileDate = new Date(date);

    const isToday = today.diff(fileDate, 'days') === 0;
    const isYesterday = today.diff(fileDate, 'days') === 1;
    const isLastYears = today.diff(fileDate, 'years') > 0;

    if (isToday) {
      return (
        <td className={classNames('date-cell is-today', locale)}>
          <FormattedMessage id="Common.Today" />
        </td>
      );
    }

    if (isYesterday) {
      return (
        <td className={classNames('date-cell is-yesterday', locale)}>
          <FormattedMessage id="Common.Yesterday" />
        </td>
      );
    }

    if (isLastYears) {
      return (
        <td className={classNames('date-cell is-last-years', locale)}>
          <FormattedDate value={new Date(date)} day="numeric" month="long" />
          <FormattedDate value={new Date(date)} year="numeric" />
        </td>
      );
    }

    return (
      <td className={classNames('date-cell', locale)}>
        <FormattedDate value={new Date(date)} day="numeric" month="long" />
        <FormattedDate value={new Date(date)} weekday="long" />
      </td>
    );
  };

  private renderFileControl = (el: UploadingFile | MaterialFile) => {
    if ((el as UploadingFile).uploadingStatus) {
      return (
        <UploadingFileControl
          key={'uploading' + String(el.id)}
          removeFileFromUploads={this.props.removeFileFromUploads}
          {...(el as UploadingFile)}
        />
      );
    }
    const props = {
      publishMaterialsOpen: this.props.publishMaterialsOpen,
      publishMaterialsUpdated: this.props.publishMaterialsUpdated,
      deletingFileComponentId: (el as MaterialFile).isBeingDeletedComponentId,
      readonly: this.props.readonly,
      key: String(el.id),
      thisFileId: (el as MaterialFile).id
    };
    return this.props.fileType === 'document' ? (
      <DocumentFileControl {...props} changeActiveFile={this.props.selectDocument} />
    ) : (
      <AudioFileControl {...props} />
    );
  };

  private sortErrorFiles = (files: Array<MaterialFile | UploadingFile>) => {
    const errorFiles = files
      .filter(
        file =>
          file.hasOwnProperty('errorMessage') &&
          file.course_instance_id.toString() === this.props.courseId
      )
      .sort(this.sortFilesByUploadingQueue);
    return errorFiles.length === 0 ? null : errorFiles;
  };

  private sortFiles = (files: Array<MaterialFile | UploadingFile>) => {
    const uploadedFiles = files
      .filter(file => !file.hasOwnProperty('uploadingStatus'))
      .sort(this.sortFilesByNameCallback);
    const uploadingFiles = files
      .filter(
        file =>
          file.hasOwnProperty('uploadingStatus') &&
          !file.hasOwnProperty('errorMessage') &&
          file.course_instance_id.toString() === this.props.courseId
      )
      .sort(this.sortFilesByUploadingQueue);
    const allFilesForDate = uploadingFiles.concat(uploadedFiles);
    return allFilesForDate.length === 0 ? null : allFilesForDate;
  };

  private sortFilesByNameCallback = (el1: MaterialFile, el2: MaterialFile) =>
    el1.title.localeCompare(el2.title, undefined, {
      numeric: true,
      sensitivity: 'base'
    });

  private sortFilesByUploadingQueue = (el1: UploadingFile, el2: UploadingFile) =>
    el2.uploadingStartedTime - el1.uploadingStartedTime;
}

export default FilesByDate;
