import React, {useCallback, useState} from 'react';
import RetinaImage from '@englex/react-retina-image';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import classNames from 'classnames';

import Confirm from 'components/modals/Confirm';
import EnglexCoursebookCover from 'components/EnglexCoursebookCover';
import * as toastr from 'components/toastr';
import {type CoursebookInstance, type Role} from 'store/interface';
import Icon from 'components/Icon';

import CoursebookInstanceProgress from './CoursebookInstanceProgress';
import ActionButton from './CoursebookInstanceActionButton';
import useSendApiRequest from '../../../../hooks/rest/useSendApiRequest';
import {
  deactivateCoursebookInstance,
  hideCoursebookInstance,
  reactivateCoursebookInstance
} from './actions';

import './CoursebookInstanceCardContent.scss';

interface Props extends WrappedComponentProps {
  courseInstanceIsActive: boolean;
  coursebookInstance: CoursebookInstance;
  role: Role;
}

const messages = defineMessages({
  ClosingError: {
    id: 'LessonPage.CoursebookInstanceClosingError'
  },
  DeletingError: {
    id: 'LessonPage.CoursebookInstanceDeletingError'
  },
  ReopeningError: {
    id: 'LessonPage.CoursebookInstanceReopeningError'
  },
  Hiding: {
    id: 'CoursebookLibrary.CoursebookHideSuccessToast'
  },
  HidingError: {
    id: 'LessonPage.CoursebookInstanceHidingError'
  }
});

const CoursebookInstanceCardContent: React.FC<Props> = ({
  courseInstanceIsActive,
  coursebookInstance: {coursebook, overview, completedAt, id},
  intl: {formatMessage},
  role
}) => {
  const {
    unitsActivated,
    testsActivated,
    unitsCompleted,
    testsCompleted,
    unitsCount,
    mainExercisesCompleted,
    mainExercisesCount,
    testsCount
  } = overview!;

  const isCoursebookEmpty = unitsActivated + testsActivated === 0;
  const isCompleted = !!completedAt;
  const showHidingBtn = isCompleted && courseInstanceIsActive && role === 'teacher';

  const [showConfirm, setShowingConfirm] = useState<boolean>(false);
  const [showHideCoursebookInstanceConfirm, setShowHideCoursebookInstanceConfirm] =
    useState<boolean>(false);

  const hideConfirm = useCallback(() => setShowingConfirm(false), []);
  const hideConfirmHiding = () => setShowHideCoursebookInstanceConfirm(false);

  const onBtnClick = useCallback((e: React.MouseEvent<Button>) => {
    setShowingConfirm(true);
    e.preventDefault();
  }, []);

  const onBtnHidingClick = (e: React.MouseEvent<Button>) => {
    e.preventDefault();
    setShowHideCoursebookInstanceConfirm(true);
  };

  const {call: deactivate, isLoading: isDeactivating} = useSendApiRequest(
    deactivateCoursebookInstance,
    [id],
    () => null,
    () => toastr.error('', formatMessage(messages.ClosingError))
  );

  const {call: deleteCBI, isLoading: isDeleting} = useSendApiRequest(
    deactivateCoursebookInstance,
    [id],
    () => null,
    () => toastr.error('', formatMessage(messages.DeletingError))
  );

  const {call: reactivate, isLoading: isReactivating} = useSendApiRequest(
    reactivateCoursebookInstance,
    [id],
    () => null,
    () => toastr.error('', formatMessage(messages.ReopeningError))
  );

  const {call: requestHideCoursebookInstance, isLoading: isHiding} = useSendApiRequest(
    hideCoursebookInstance,
    [id],
    () => toastr.success('', formatMessage(messages.Hiding)),
    () => toastr.error('', formatMessage(messages.HidingError))
  );

  const onActionConfirmed = useCallback(() => {
    hideConfirm();
    if (isCompleted) {
      reactivate();
      return;
    }
    if (isCoursebookEmpty) {
      deleteCBI();
      return;
    }
    deactivate();
  }, [isCompleted, deactivate, hideConfirm, isCoursebookEmpty, deleteCBI, reactivate]);

  const onConfirmClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => e.preventDefault(),
    []
  );

  const onActionConfirmedHiding = () => {
    hideConfirmHiding();
    requestHideCoursebookInstance();
  };

  const headerClassName = classNames('coursebook-instance-card-header', {
    deleted: isCompleted
  });

  return (
    <div className="coursebook-instance-card-content">
      <div className={headerClassName}>
        <div className="book-cover">
          {coursebook.cover ? (
            <>
              <div className="mask" />
              <RetinaImage src={coursebook.cover.urls} />
            </>
          ) : (
            <EnglexCoursebookCover isCompleted={isCompleted} fontSize={6} />
          )}
        </div>
        <h4 className="coursebook-instance-title" title={coursebook.title}>
          {coursebook.title}
        </h4>
      </div>

      <div className="coursebook-instance-card-footer">
        <CoursebookInstanceProgress
          unitsClosed={unitsCompleted}
          unitsTotal={unitsCount}
          exercisesCompleted={mainExercisesCompleted}
          exercisesTotal={mainExercisesCount}
          testsClosed={testsCompleted}
          testsTotal={testsCount}
        />
        {showHidingBtn && (
          <Button
            onClick={onBtnHidingClick}
            className="btn-circle"
            title={formatMessage({id: 'Common.Hide'})}
          >
            <Icon name="eye-slash" />
          </Button>
        )}
        {isCompleted && !courseInstanceIsActive ? null : (
          <ActionButton
            onClick={onBtnClick}
            isCompleted={isCompleted}
            isEmpty={isCoursebookEmpty}
            isLoading={isDeactivating || isDeleting || isReactivating}
          />
        )}
      </div>

      <div onClick={onConfirmClick}>
        <Confirm
          show={showConfirm}
          onAccept={onActionConfirmed}
          onDecline={hideConfirm}
          headerText={
            isCompleted ? (
              <FormattedMessage id="LessonPage.ReopenCoursebookConfirm" />
            ) : isCoursebookEmpty ? (
              <FormattedMessage id="LessonPage.DeleteCoursebookConfirm" />
            ) : (
              <FormattedMessage id="LessonPage.CloseCoursebookConfirm" />
            )
          }
          disableButtons={isReactivating || isDeactivating}
        />
      </div>

      <div onClick={onConfirmClick}>
        <Confirm
          show={showHideCoursebookInstanceConfirm}
          onAccept={onActionConfirmedHiding}
          onDecline={hideConfirmHiding}
          headerText={<FormattedMessage id="LessonPage.HideCoursebookConfirm" />}
          disableButtons={isHiding}
        />
      </div>
    </div>
  );
};

export default injectIntl(CoursebookInstanceCardContent);
