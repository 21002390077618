import {defineMessages} from 'react-intl';

export const addFilesMessages = defineMessages({
  successText: {
    id: 'Library.Toastr.Success.Text'
  },
  errorText: {
    id: 'Library.Toastr.Error.Text'
  },
  previewRequestErrorHeader: {
    id: 'Library.Preview.Request.Error.Header'
  },
  previewRequestErrorText: {
    id: 'Library.Preview.Request.Error.Text'
  }
});

export const LIBRARY_SELECT_LIMIT = 10;
