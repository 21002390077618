import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';

import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import './SelfCheckButton.scss';

interface Props {
  loading: boolean;
  disabled?: boolean;
  show?: boolean;
  onClick(e: React.MouseEvent<Button, MouseEvent>): void;
}

export const SelfCheckButton: FC<Props> = ({disabled, show, onClick, loading}) => {
  const intl = useIntl();

  return (
    <Button
      className="btn-self-check"
      bsStyle={show ? 'danger' : 'success'}
      bsSize="xs"
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <Spinner size={13} />
      ) : (
        <>
          <Icon name={show ? 'times-circle' : 'check-circle'} />
          {show
            ? intl.formatMessage({id: 'XPlayer.SelfCheckDisable'})
            : intl.formatMessage({id: 'XPlayer.SelfCheckEnable'})}
        </>
      )}
    </Button>
  );
};
