import React from 'react';
import {FormattedMessage} from 'react-intl';

interface SearchFailedProps {
  filter: string;
}

export default class SearchFailed extends React.Component<SearchFailedProps, {}> {
  public render() {
    return (
      <div className="no-files">
        <div>
          <FormattedMessage id="LessonPage.NothingFound1" />
          <b> «{this.props.filter}» </b>
          <FormattedMessage id="LessonPage.NothingFound2" />
        </div>
      </div>
    );
  }
}
