import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {matchPath, Outlet, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {replace} from 'store/router';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import WampErrorMask from 'components/WampErrorMask';
import {type AppState, type UnitInstance} from 'store/interface';
import Loader from 'components/Loader';
import InvalidUrlId from 'containers/InvalidUrlId';
import {
  requestUnitInstances as requestUnitInstancesCreator,
  setIncorrectUrlId
} from 'common/action';
import {
  coursebookInstancePath,
  coursebookInstancePattern,
  unitInstancePattern,
  unitInstancePreviewPattern,
  type CoursebookInstanceUrlParams,
  type UnitInstanceUrlParams
} from 'common/paths';

import {clearUnitInstances, setUnitInstances} from '../pages/CoursebookInstancePage/actions';

export interface CoursebookInstanceOutletContext {
  reloadUnits: () => void;
}

const CoursebookInstanceLayout: React.FC = () => {
  const dataWasAlreadyLoaded = useRef(false);

  const {pathname} = useLocation();
  const params = useParams<CoursebookInstanceUrlParams | UnitInstanceUrlParams>();
  const {coursebookInstanceId} = params;

  const [notFound, setNotFound] = useState<boolean>(false);

  const matchPreview = useMemo(
    () => matchPath({path: unitInstancePreviewPattern, end: false}, pathname),
    [pathname]
  );
  const isPreview = !!matchPreview;

  const unitInstanceId = useMemo(() => {
    const unitInstanceMatch =
      matchPath({path: unitInstancePattern, end: false}, pathname) || matchPreview;

    return unitInstanceMatch && Number(unitInstanceMatch.params.unitInstanceId);
  }, [matchPreview, pathname]);

  const prevCoursebookInstance = useRef<string | null>(null);

  const coursebookInstance = useSelector((state: AppState) =>
    state.classroom!.courseInstanceState.coursebookInstanceList!.find(
      instance => instance.id === coursebookInstanceId
    )
  );

  const dispatch = useDispatch();

  const requestUnitInstancesAction = useMemo(() => {
    dataWasAlreadyLoaded.current = false;
    return requestUnitInstancesCreator(coursebookInstanceId!, true);
  }, [coursebookInstanceId]);

  const unitInstances = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState.coursebookInstanceState.unitInstances
  );

  const unitInstancesRequestSuccessHandler = useCallback(
    (data: UnitInstance[]) => {
      if (unitInstanceId && !data.find(ui => ui.id === unitInstanceId)) {
        dispatch(setIncorrectUrlId());
      }
      dispatch(setUnitInstances(data));
      dataWasAlreadyLoaded.current = true;
      prevCoursebookInstance.current = coursebookInstanceId!;
    },
    [coursebookInstanceId, dispatch, unitInstanceId]
  );

  useEffect(() => {
    return () => {
      dispatch(clearUnitInstances());
    };
  }, [dispatch]);

  const {isError, reload} = useApiRequest(
    requestUnitInstancesAction,
    unitInstancesRequestSuccessHandler,
    e => {
      if (e.error.response?.status === 404) {
        setNotFound(true);
      }
    }
  );

  useEffect(
    function redirectFromDeactivatedUnitInstance() {
      if (
        !unitInstances ||
        !unitInstanceId ||
        (prevCoursebookInstance.current && prevCoursebookInstance.current !== coursebookInstanceId)
      ) {
        return;
      }
      const selectedUI = unitInstances.find(ui => ui.id === unitInstanceId);
      if (!selectedUI || (!isPreview && !selectedUI.active) || (isPreview && selectedUI.active)) {
        dispatch(replace(coursebookInstancePath(params, params.coursebookInstanceId!)));
      }
    },
    [coursebookInstanceId, unitInstances, unitInstanceId, dispatch, params, isPreview]
  );

  useEffect(
    // reload unit instances when user returns to coursebook instance page to update info on additional exercises in cards;
    // dataWasAlreadyLoaded added as dependency so that this hook doesn't execute on initial page mount or when user switches to another coursebook instance;
    function () {
      const isOnCoursebookInstancePage = matchPath(
        {
          end: true,
          path: coursebookInstancePattern
        },
        pathname
      );

      if (isOnCoursebookInstancePage && dataWasAlreadyLoaded.current) {
        reload();
      }
    },
    [pathname, dataWasAlreadyLoaded, reload]
  );

  if (notFound) {
    return <InvalidUrlId />;
  }

  if (isError) {
    return <WampErrorMask reload={reload} />;
  }

  if (!unitInstances || !coursebookInstance) {
    return <Loader />;
  }
  return <Outlet context={{reloadUnits: reload}} />;
};

export default CoursebookInstanceLayout;
