import React from 'react';

import {type HomeworkDocumentInstance} from 'store/interface';

import {DocControls} from './DocControls';
import {useDocControls} from './useDocControls';

interface Props {
  documentInstanceId: number;
  courseId: number;
  homeworkId?: string;
  homeworkDocumentsList?: HomeworkDocumentInstance[];
}

const DocumentControls: React.FC<Props> = ({
  documentInstanceId,
  homeworkDocumentsList,
  homeworkId,
  courseId
}) => {
  return (
    <DocControls
      {...useDocControls(homeworkDocumentsList, courseId, homeworkId, documentInstanceId)}
    />
  );
};

export default DocumentControls;
