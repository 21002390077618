import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {persistReducer} from 'redux-persist';
import {type Action} from 'redux';

import {push, replace} from 'store/router';
import injectReducer from 'store/injectReducer';
import {type AppState, type Courses, type Role, type StudentTeachers} from 'store/interface';
import {requestStudentTeachers} from 'store/studentTeachers/requests';
import {setSelectedStudentTeacher, setStudentTeachers} from 'store/studentTeachers/actions';
import {selectChatRoomRelatedToSelectedStudentTeacher} from 'components/Chat/actions/action';
import {setIncorrectUrlId} from 'common/action';
import {persistClassroomConfig} from 'store/persist';

import {type DispatchLessonPageProps, type LessonPageStateProps} from '../components/interface';
import LessonPage from '../components/LessonPage';
import classRoomReducer from '../reducers/classroomReducer';
import {
  clearClassroom,
  clearCourses,
  requestCourses,
  setClassroomNetworkError,
  setCourses
} from '../actions/action';
import {type RequestStudentTeachersSuccessAction} from '../actions/interface';

const mapStateToProps = (state: AppState): LessonPageStateProps => {
  return {
    role: state.user.role!,
    networkError: state.classroom!.networkError,
    selectedStudentTeacher: state.studentTeachers?.selectedStudentTeacher,
    studentTeachers: state.studentTeachers?.studentTeachers,
    courses: state.classroom!.courses,
    incorrectUrlId: state.classroom!.incorrectUrlId
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchLessonPageProps => ({
  push: (path: string) => dispatch(push(path)),
  replace: (path: string) => dispatch(replace(path)),
  requestStudentTeachers: (token: Role) =>
    dispatch<RequestStudentTeachersSuccessAction>(requestStudentTeachers(token)),
  clearClassroom: () => dispatch(clearClassroom()),
  setClassroomNetworkError: () => dispatch(setClassroomNetworkError()),
  requestCourses: (studentTeacherId: number) => dispatch(requestCourses(studentTeacherId)),
  setCourses: (courses: Courses) => dispatch(setCourses(courses)),
  setStudentTeachers: (studentTeachers: StudentTeachers) =>
    dispatch(setStudentTeachers(studentTeachers)),
  setSelectedStudentTeacher: (id: number, isStudent: boolean) =>
    dispatch(setSelectedStudentTeacher(id, isStudent)),
  clearCourses: () => dispatch(clearCourses()),
  setIncorrectUrlId: () => dispatch(setIncorrectUrlId()),
  selectChatRoomRelatedToSelectedStudentTeacher: () =>
    dispatch(selectChatRoomRelatedToSelectedStudentTeacher())
});

const CourseInstanceLayout = injectReducer({
  classroom: persistReducer(persistClassroomConfig, classRoomReducer)
})(connect(mapStateToProps, mapDispatchToProps)(LessonPage));

export default CourseInstanceLayout;
