import React, {useCallback, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {serverCoursebookToClientCoursebook} from 'components/CoursebookLibrary/helpers';
import Loader from 'components/Loader';
import ModalError from 'components/ModalError';
import {
  loadCoursebookData,
  type LoadCoursebookDataResponse
} from 'components/CoursebookLibrary/actions/action';
import {type Coursebook, type CoursebookUnit} from 'store/interface';
import {useApiRequest} from 'hooks/rest/useApiRequest';

import Header from '../../../components/CoursebookInfoDetailed/Header';
import CoursebookInfoDetailed from '../../../components/CoursebookInfoDetailed/CoursebookInfoDetailed';
import UnitsList from '../../../components/CoursebookInfoDetailed/UnitsList';
import Card from '../../../components/CoursebookInfoDetailed/Card';
import CardFooter from '../../../components/CoursebookInfoDetailed/CardFooter';
import {CardContainer} from '../../../components/CoursebookInfoDetailed/CardContainer';

interface Props {
  coursebookId: string;
  showCoursebookContents: (id: string) => void;
  showGrammarCoursebook: (coursebookId: string) => void;
  selectUnit: (id: number) => void;
}

const CoursebookUnits: React.FC<Props> = ({
  coursebookId,
  showCoursebookContents,
  showGrammarCoursebook,
  selectUnit
}: Props) => {
  const [coursebook, setCoursebook] = useState<Coursebook | null>(null);
  const [units, setUnits] = useState<CoursebookUnit[] | null>();
  const action = useMemo(() => loadCoursebookData(coursebookId, true), [coursebookId]);
  const handleCoursebookLoaded = useCallback(
    (response: LoadCoursebookDataResponse) => {
      const {units, ...coursebook} = response;
      setCoursebook(serverCoursebookToClientCoursebook(coursebook));
      setUnits(units);
    },
    [setCoursebook]
  );
  const {isError, reload} = useApiRequest(action, handleCoursebookLoaded);
  const onShowContentsClick = useCallback(
    () => showCoursebookContents(coursebookId),
    [showCoursebookContents, coursebookId]
  );

  const onShowGrammarClick = useCallback(
    () => showGrammarCoursebook(coursebookId),
    [showGrammarCoursebook, coursebookId]
  );

  if (isError) {
    return (
      <ModalError
        errorTitle={<FormattedMessage id="AddCoursebookModal.ErrorLoadingCoursebook" />}
        errorText={<FormattedMessage id="LibraryError.Description" />}
        reload={reload}
      />
    );
  }
  if (!coursebook || !units) {
    return <Loader />;
  }
  const contentsNotEmpty = coursebook.overview!.unitSectionsNumber > 0;
  return (
    <CoursebookInfoDetailed>
      <Header
        title={coursebook.title}
        onShowContentsClick={contentsNotEmpty ? onShowContentsClick : undefined}
        onShowGrammarClick={onShowGrammarClick}
        exercisesCount={coursebook.overview!.exercisesNumber}
        testsCount={coursebook.overview!.testsCount}
        unitsCount={coursebook.overview!.unitsCount}
        cover={coursebook.cover}
        coursebookId={coursebook.id}
      />
      <UnitsList>
        {units.map((unit: CoursebookUnit) => (
          <CardContainer key={unit.id} unit={unit} selectUnit={selectUnit}>
            <Card cover={unit.cover} ordinal={unit.ordinal} title={unit.title}>
              <CardFooter
                active={true}
                id={unit.id}
                setUnitToToggle={() => null}
                completed={false}
                exercisesCount={unit.overview!.mainExercisesCount}
                exercisesCompleted={0}
                extraExercisesCount={unit.overview!.extraExercisesCount}
                courseInstanceIsActive={true} // if we get to open AddCoursebookModal, then this prop is true
              />
            </Card>
          </CardContainer>
        ))}
      </UnitsList>
    </CoursebookInfoDetailed>
  );
};

export default CoursebookUnits;
