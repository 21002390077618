import classNames from 'classnames';
import React from 'react';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import {type CoursebookInstance} from 'store/interface';
import {Breadcrumb} from 'components/Breadcrumbs/Breadcrumb';

import CoursebookInstanceProgress from './CoursebookInstanceProgress';
import CoursebookInstancesDropdown from './CoursebookInstancesDropdown';
import {coursebookInstanceBreadcrumbMessages} from './messages';

interface Props extends WrappedComponentProps {
  coursebookInstances: CoursebookInstance[];
  selectCoursebookInstance: (id: string) => void;
  toggleDropdown: (show: boolean) => void;
  dropdownIsOpen?: boolean;
  isOnFilesList?: boolean;
  selectedCoursebookInstanceId?: string;
  goToFilesList: () => void;
  goToHomeworkPage: () => void;
  isOnHomeworkPage?: boolean;
}

class CoursebookInstanceBreadcrumb extends React.PureComponent<Props> {
  public componentWillUnmount(): void {
    this.props.toggleDropdown(false);
  }

  public render() {
    const {
      dropdownIsOpen,
      isOnFilesList,
      toggleDropdown,
      coursebookInstances,
      selectCoursebookInstance,
      goToFilesList,
      selectedCoursebookInstanceId,
      goToHomeworkPage,
      isOnHomeworkPage
    } = this.props;

    const title = this.getTitleText();
    const className = classNames('pointer', {active: dropdownIsOpen});

    const icon = isOnHomeworkPage ? 'virc-homework' : undefined;
    const iconClassName = this.activeCoursebookInstance ? 'always-visible-icon' : undefined;
    const iconElement = this.activeCoursebookInstance
      ? this.renderCoursebookProgress(this.activeCoursebookInstance)
      : undefined;

    return (
      <Breadcrumb
        icon={icon}
        iconClassName={iconClassName}
        iconElement={iconElement}
        title={title}
        className={className}
        accessibilityTitle={title}
        onClick={this.breadcrumbClickHandler}
      >
        <CoursebookInstancesDropdown
          dropdownIsOpen={dropdownIsOpen}
          toggleDropdown={toggleDropdown}
          elements={coursebookInstances}
          goToCoursebookInstance={selectCoursebookInstance}
          goToFilesList={goToFilesList}
          isOnFilesList={isOnFilesList}
          selectedCoursebookInstanceId={selectedCoursebookInstanceId}
          goToHomeworkPage={goToHomeworkPage}
          isOnHomeworkPage={isOnHomeworkPage}
        />
      </Breadcrumb>
    );
  }

  private getTitleText = () => {
    const {
      isOnHomeworkPage,
      isOnFilesList,
      intl: {formatMessage}
    } = this.props;
    if (isOnFilesList) {
      return formatMessage(coursebookInstanceBreadcrumbMessages.Files);
    }
    if (isOnHomeworkPage) {
      return formatMessage(coursebookInstanceBreadcrumbMessages.Homework);
    }
    if (!this.activeCoursebookInstance) {
      return '';
    }
    return this.activeCoursebookInstance.coursebook.title;
  };

  private renderCoursebookProgress = (activeCoursebookInstance: CoursebookInstance) => {
    return <CoursebookInstanceProgress instance={activeCoursebookInstance} showHoverTitle={true} />;
  };

  private breadcrumbClickHandler = () => {
    if (!this.props.dropdownIsOpen) {
      this.props.toggleDropdown(true);
    }
  };

  private get activeCoursebookInstance() {
    return this.props.coursebookInstances.find(
      c => c.id === this.props.selectedCoursebookInstanceId
    );
  }
}

export default injectIntl(CoursebookInstanceBreadcrumb);
