import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import {type AvatarUrl} from 'store/interface';
import {type FileExtension} from 'common/enums';

import {fileIcon} from '../../../../../../helpers/file';
import {type FileType} from '../../../../actions/interface';

interface UploadedFileControlProps {
  id: number;
  avatar: AvatarUrl;
  title: string;
  recipientFullName: string;
  type: FileExtension;
  goToFile: (fileId: number, fileType: FileType) => void;
  created_at: string; // don't remove it, needed for sorting
  fileType: FileType;
}

export default class UploadedFileControl extends React.PureComponent<UploadedFileControlProps, {}> {
  public render() {
    const {title, recipientFullName, avatar} = this.props;
    return (
      <li className="fc-uploading">
        <Icon name={fileIcon(this.props.type)} size="lg" />
        <div className="text">
          <span className="big-text">{title}</span>
        </div>
        <div className="file-control-right">
          <Avatar url={avatar} size={28} title={recipientFullName} />
          <Button bsStyle="default" bsSize="sm" onClick={this.goToFile}>
            <FormattedMessage id="LessonPage.GoToFile" />
          </Button>
        </div>
      </li>
    );
  }

  private goToFile = () => this.props.goToFile(this.props.id, this.props.fileType);
}
