import classNames from 'classnames';
import React, {type FC, useCallback} from 'react';
import type {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

import {ThumbnailContainer} from './ThumbnailContainer';

import './ThumbnailItem.scss';

interface Props {
  doc: PDFDocumentProxy;
  currentIndex: number;
  index: number;
  rotation: number;
  goToIndex(index: number): void;
  scrollToThumbnail(element: HTMLElement): void;
}

export const ThumbnailItem: FC<Props> = ({
  doc,
  currentIndex,
  index,
  goToIndex,
  rotation,
  scrollToThumbnail
}) => {
  const onClick = useCallback(() => goToIndex(index), [goToIndex, index]);
  return (
    <div
      className={classNames('pdf-contents-thumbnail-item', {
        selected: currentIndex === index
      })}
      onClick={onClick}
    >
      <ThumbnailContainer
        doc={doc}
        isActive={currentIndex === index}
        pageIndex={index}
        rotation={rotation}
        onActive={scrollToThumbnail}
      />
      <span>{index + 1}</span>
    </div>
  );
};
