import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {FormattedMessage} from 'react-intl';

import WampErrorMask from 'components/WampErrorMask';
import {
  type AvatarUrl,
  type StudentTeacher,
  type StudentTeachers,
  type UploadingFiles
} from 'store/interface';
import {userFullName} from 'helpers/user';
import preventFilterRegexpErrors from 'helpers/toValidFilter';

import UploadingFileControl from './UploadingFileControl';
import {type RemoveFileFromUploadsCreator} from '../actions/interface';
import UploadedFileControl from './UploadedFileControl';
import {type FileType} from '../../../../actions/interface';

export interface UploadsListStateProps {
  uploadingSounds: UploadingFiles;
  uploadingDocuments: UploadingFiles;
  recentDocuments: {};
  recentSounds: {};
  networkError?: boolean;
  filter: string;
}

export interface DispatchUploadsListProps {
  removeFileFromUploads: RemoveFileFromUploadsCreator;
  resetUploads: () => void;
  goToFile: (fileId: number, fileType: FileType) => void;
}

export interface UploadsListOwnProps {
  studentTeachers: StudentTeachers;
}

interface UploadsListProps
  extends UploadsListStateProps,
    DispatchUploadsListProps,
    UploadsListOwnProps {}

export default class UploadsList extends React.PureComponent<UploadsListProps, {}> {
  public render() {
    const scrollBarStyle: {[key: string]: string} = {
      width: '100%',
      height: '100%'
    };
    return (
      <div className="lesson-files-list no-dates-column">
        {this.renderErrorMask()}
        <Scrollbars style={scrollBarStyle} autoHide={true}>
          <table>
            <tbody className="no-flex">
              {this.renderCurrentUploads()}
              {this.renderRecentUploads()}
            </tbody>
          </table>
        </Scrollbars>
      </div>
    );
  }

  private renderErrorMask = () => {
    if (this.props.networkError) {
      return <WampErrorMask reload={this.props.resetUploads} />;
    }
    return null;
  };

  private renderCurrentUploads = () => {
    const currentUploads = Object.assign(
      {},
      this.props.uploadingSounds,
      this.props.uploadingDocuments
    );
    if (Object.keys(currentUploads).length === 0) {
      return this.renderEmptyCurrentUploads();
    }
    const content: JSX.Element[] = [];

    for (const id in currentUploads) {
      const filter: string = preventFilterRegexpErrors(this.props.filter);
      if (currentUploads[id].title.toLowerCase().search(filter) !== -1) {
        const studentTeacher: StudentTeacher =
          this.props.studentTeachers[currentUploads[id].studentTeacherId];
        const avatar: AvatarUrl | undefined = studentTeacher
          ? studentTeacher.recipient.profile!.avatars.xs
          : undefined;
        const fullName: string = studentTeacher
          ? userFullName(studentTeacher.recipient.profile!)
          : '';
        if (currentUploads[id].uploadingStatus !== 'uploaded') {
          content.push(
            <UploadingFileControl
              avatar={avatar}
              key={id}
              bigText={true}
              recipientFullName={fullName}
              {...currentUploads[id]}
              {...this.props}
            />
          );
        }
      }
    }
    if (content.length === 0) {
      return this.renderEmptyCurrentUploads();
    }
    return (
      <tr>
        <td />
        <td>
          <ul>{content}</ul>
        </td>
      </tr>
    );
  };

  private renderEmptyCurrentUploads = () => {
    const {recentSounds, recentDocuments, uploadingSounds, uploadingDocuments, filter} = this.props;
    const noRecentUploads: boolean =
      Object.keys(recentSounds).length === 0 && Object.keys(recentDocuments).length === 0;
    const emptyListMessage: JSX.Element = <FormattedMessage id="LessonPage.NothingIsUploaded" />;
    const nothingFoundMessage: JSX.Element = (
      <span>
        <FormattedMessage id="LessonPage.NothingFound1" />
        <b>«{filter}»</b>
        <FormattedMessage id="LessonPage.NothingFound2" />
      </span>
    );
    const currentUploadsLength =
      Object.keys(uploadingSounds).filter(
        sound => uploadingSounds[sound].uploadingStatus !== 'uploaded'
      ).length +
      Object.keys(uploadingDocuments).filter(
        doc => uploadingDocuments[doc].uploadingStatus !== 'uploaded'
      ).length;
    return (
      <tr className={noRecentUploads ? 'no-files-tr' : ''}>
        <td />
        <td>
          <div className="no-files no-files-uploaded">
            <div>{currentUploadsLength > 0 ? nothingFoundMessage : emptyListMessage}</div>
          </div>
        </td>
      </tr>
    );
  };

  private addRecentFiles = (fileType: FileType) => {
    const content: JSX.Element[] = [];
    const recentFilesObjectName: string = fileType === 'audio' ? 'recentSounds' : 'recentDocuments';
    const filter: string = preventFilterRegexpErrors(this.props.filter);

    for (const fileId in this.props[recentFilesObjectName]) {
      const file = this.props[recentFilesObjectName][fileId];
      if (file.title.toLowerCase().search(filter) !== -1) {
        const studentTeacher: StudentTeacher =
          this.props.studentTeachers[file.courseInstance.student_teacher_id];
        if (studentTeacher) {
          const avatar: AvatarUrl = studentTeacher.recipient.profile!.avatars.xs;
          const fullName: string = userFullName(studentTeacher.recipient.profile!);
          const fileControl: JSX.Element = (
            <UploadedFileControl
              key={`${file.fileType}-${fileId}`}
              id={Number(fileId)}
              avatar={avatar}
              recipientFullName={fullName}
              title={file.title}
              goToFile={this.props.goToFile}
              type={file.type}
              created_at={file.created_at}
              fileType={fileType}
            />
          );
          content.push(fileControl);
        }
      }
    }
    return content;
  };

  private renderRecentUploads = () => {
    if (
      Object.keys(this.props.recentDocuments).length > 0 ||
      Object.keys(this.props.recentSounds).length > 0
    ) {
      let content: JSX.Element[] = this.addRecentFiles('document').concat(
        this.addRecentFiles('audio')
      );
      if (content.length === 0) {
        const nothingFound = (
          <div className="no-files no-files-uploaded" key="1">
            <div>
              <span>
                <FormattedMessage id="LessonPage.NothingFound1" />
                <b>«{this.props.filter}»</b>
                <FormattedMessage id="LessonPage.NothingFound2" />
              </span>
            </div>
          </div>
        );
        content.push(nothingFound);
      } else {
        content.sort((el1: JSX.Element, el2: JSX.Element) =>
          new Date(el1.props.created_at) > new Date(el2.props.created_at) ? -1 : 1
        );
        content = [<ul key="1">{content}</ul>];
      }
      return [
        <tr key="1">
          <td />
          <td>
            <div className="divider">
              <div className="divider-text">
                <FormattedMessage id="LessonPage.Uploaded" />
              </div>
              <hr />
            </div>
          </td>
        </tr>,
        <tr key="2">
          <td />
          <td>{content}</td>
        </tr>
      ];
    } else {
      return null;
    }
  };
}
