import {type CoursebookFilter} from 'store/interface';
import {BooleanEnum} from 'common/enums';

export const emptyCoursebookFilter: CoursebookFilter = {
  title: null,
  role: null,
  published: null,
  courses: null,
  levels: null,
  authorId: null,
  originalAuthorId: null
};

export const defaultCoursebookFilter: CoursebookFilter = {
  ...emptyCoursebookFilter,
  published: BooleanEnum.TRUE
};
