import React, {type SyntheticEvent, useCallback, useMemo, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';

import {push} from 'store/router';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import Confirm from 'components/modals/Confirm';
import {homeworkExerciseControlMessages} from 'components/XPlayer/components/ExerciseActions/Bookmark/HomeworkButton';
import useSendApiRequest from 'hooks/rest/useSendApiRequest';
import {
  moveHomeworkExerciseToDraft,
  removeHomeworkExerciseRequest
} from 'store/exercise/player/actions';
import {type AppState} from 'store/interface';
import {type ExerciseProperties} from 'store/exercise/player/Exercise/interface';
import {setRedirectedFromUrl} from 'common/action';
import {type ClassroomUrlParams, unitInstancePagePath} from 'common/paths';

import {useAxiosDispatch} from '../../../../hooks/redux/useAxiosDispatch';
import {useMovingHomeworkErrorMessage} from '../../../../components/XPlayer/components/ExerciseActions/useMovingExerciseErrorMessage';
import {documentDraftControlMessages} from './i18n';
import {exerciseActionMessages} from '../../../../components/XPlayer/components/ExerciseActions/exerciseActionMessages';

interface Props {
  exerciseId: string;
}

const ExerciseControls: React.FC<Props> = ({exerciseId}) => {
  const {formatMessage} = useIntl();
  const params = useParams<ClassroomUrlParams>();
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const axiosDispatch = useAxiosDispatch();

  const [isShowingDeleteConfirm, setShowingDeleteConfirm] = useState<boolean>();
  const [awaiting, setAwaiting] = useState<boolean>(false);
  const [isShowingMoveConfirm, setIsShowingMoveConfirm] = useState(false);

  const isStudent = useSelector((state: AppState) => state.user.role === 'student');
  const exerciseLocation = useSelector((state: AppState) => {
    if (!state.xplayer) {
      return undefined;
    }
    const exercise = state.xplayer.exercises.find((e: ExerciseProperties) => e.id === exerciseId);
    return exercise ? exercise.location : undefined;
  });
  const isDraft = useSelector<AppState, boolean | undefined>(state =>
    state.classroom?.courseInstanceState.homeworkDraft?.exercises.some(
      ex => ex.exerciseInstanceId === exerciseId
    )
  );

  const coursebookLocationRoute = useMemo<string | null>(() => {
    if (!exerciseLocation) {
      return null;
    }
    const {courseId, studentTeacherId} = params;
    const {coursebookInstanceId, unitInstanceId, pageNumber} = exerciseLocation;
    return unitInstancePagePath(
      {courseId, studentTeacherId, coursebookInstanceId},
      unitInstanceId,
      pageNumber
    );
  }, [params, exerciseLocation]);

  const hideDeleteConfirm = useCallback(() => setShowingDeleteConfirm(false), []);
  const hideMoveConfirm = useCallback(() => setIsShowingMoveConfirm(false), []);
  const {call: deleteExercise, isLoading: deleting} = useSendApiRequest(
    removeHomeworkExerciseRequest,
    [exerciseId],
    () => {
      setShowingDeleteConfirm(false);
      toastr.success('', formatMessage(exerciseActionMessages.DeletingExerciseFromHomeworkSuccess));
    },
    () => {
      setShowingDeleteConfirm(false);
      toastr.error('', formatMessage(exerciseActionMessages.DeletingExerciseFromHomeworkError));
    }
  );

  const getMessage = useMovingHomeworkErrorMessage();

  const isHidden = exerciseLocation?.hidden;
  const showViewInCoursebook = !isHidden;

  const moveToDraft = useCallback(() => {
    setAwaiting(true);
    axiosDispatch(moveHomeworkExerciseToDraft(exerciseId))
      .then(() => {
        toastr.success('', formatMessage(exerciseActionMessages.MoveHomeworkToDraftSuccess));
      })
      .catch((e: Error) => {
        toastr.error('', getMessage(e.message));
      })
      .finally(() => {
        setAwaiting(false);
        setIsShowingMoveConfirm(false);
      });
  }, [axiosDispatch, exerciseId, formatMessage, getMessage]);

  const onDeleteExerciseClick = useCallback((e: SyntheticEvent<HTMLButtonElement | Button>) => {
    // fix invalid position of buttons after clicking this button and then declining confirm
    (e.currentTarget as HTMLButtonElement).blur();
    setShowingDeleteConfirm(true);
  }, []);

  const onMoveClick = useCallback(() => {
    setIsShowingMoveConfirm(true);
  }, []);

  const coursebookExerciseHref = `${coursebookLocationRoute!}#${exerciseId}`;

  const redirectToCoursebook = useCallback(() => {
    dispatch(setRedirectedFromUrl(pathname, 'homework'));
    dispatch(push(coursebookExerciseHref));
  }, [dispatch, pathname, coursebookExerciseHref]);
  return (
    <>
      <Confirm
        show={isShowingDeleteConfirm}
        onDecline={hideDeleteConfirm}
        headerText={formatMessage(homeworkExerciseControlMessages.confirmDelete)}
        onAccept={deleteExercise}
        disableButtons={deleting}
      />
      <Confirm
        headerText={formatMessage(homeworkExerciseControlMessages.confirmMove)}
        show={isShowingMoveConfirm}
        onAccept={moveToDraft}
        onDecline={hideMoveConfirm}
        disableButtons={awaiting}
      />
      {!isStudent && (
        <Button
          className="btn-transparent"
          title={formatMessage(documentDraftControlMessages.DeleteFromHomework)}
          onClick={onDeleteExerciseClick}
        >
          <Icon name="virc-homework-remove" />
        </Button>
      )}
      {!isStudent && !isDraft && (
        <Button
          className="btn-transparent"
          title={formatMessage(documentDraftControlMessages.MoveToDraft)}
          onClick={onMoveClick}
        >
          <Icon name="virc-homework-add" />
        </Button>
      )}
      {showViewInCoursebook && (
        <Button
          className="btn-transparent"
          title={formatMessage(exerciseActionMessages.ViewInCoursebook)}
          onClick={redirectToCoursebook}
          href={coursebookExerciseHref}
        >
          <Icon name="virc-mnu" />
        </Button>
      )}
    </>
  );
};

export default ExerciseControls;
