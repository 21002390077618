import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';

import UploadsList, {
  type DispatchUploadsListProps,
  type UploadsListOwnProps,
  type UploadsListStateProps
} from '../components/UploadsList';
import {goToUploadedFile, removeFileFromUploads, uploadsReset} from '../actions/action';
import {type FileType} from '../../../../actions/interface';
import {getRecentDocuments, getRecentSounds} from '../../../../actions/action';

const mapStateToProps = (state: AppState): UploadsListStateProps => ({
  uploadingSounds: state.uploads.uploadingSounds,
  uploadingDocuments: state.uploads.uploadingDocuments,
  recentSounds: state.uploads.recentSounds,
  recentDocuments: state.uploads.recentDocuments,
  networkError: state.uploads.networkError,
  filter: state.filesWorkSpace!.filesFilter
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchUploadsListProps => ({
  removeFileFromUploads: (md5: string, id?: string) => dispatch(removeFileFromUploads(md5, id)),
  resetUploads: () => {
    dispatch(uploadsReset());
    dispatch(getRecentSounds());
    dispatch(getRecentDocuments());
  },
  goToFile: (fileId: number, fileType: FileType) => dispatch(goToUploadedFile(fileId, fileType))
});

export default connect<UploadsListStateProps, DispatchUploadsListProps, UploadsListOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(UploadsList);
