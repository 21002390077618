import React from 'react';
import {type List} from 'immutable';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {type AppState, type SelectionData} from 'store/interface';

import GrammarPlayerPage from './GrammarPlayerPage';
import {SelectionProvider} from '../../components/Pointer/selection/SelectionContext';
import useCanPlayTogether from '../../components/media/_common/useCanPlayTogether';

interface Props {
  coursebookId?: string;
  isInUnitPlayer?: boolean;
  isInHomeworkPlayer?: boolean;
  grammarFilter?: List<{id: number}>;
}

type Params = {studentTeacherId: string};

export const GrammarPlayerPageWithPointer: React.FC<Props> = (props: Props) => {
  const {studentTeacherId} = useParams<Params>();
  const role = useSelector((state: AppState) => state.user.role)!;
  const selectionData = useSelector<AppState, SelectionData | undefined>(
    state => state.classroom?.selection
  );
  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  const selectionEnabled =
    canOpenTogether || Boolean(import.meta.env.REACT_APP_POINTER_DEV_MODE_ENABLED);

  return (
    <SelectionProvider role={role} active={selectionEnabled} selectionData={selectionData}>
      <GrammarPlayerPage {...props} hasPointer={true} />
    </SelectionProvider>
  );
};
