import React from 'react';
import './audio-slider.scss';

interface VolumeSliderProps {
  volume?: number;
  changeVolume: (volume: number) => void;
}

interface VolumeSliderState {
  mouseDown: boolean;
}

export default class VolumeSlider extends React.PureComponent<
  VolumeSliderProps,
  VolumeSliderState
> {
  private static percentage: number = 100;

  public state: VolumeSliderState = {mouseDown: false};

  protected progress: HTMLDivElement;

  public render() {
    const [{volume}, {mouseDown}] = [this.props, this.state];
    return (
      <div
        className={'volume active'}
        onClick={this.volumeClickHandle}
        onMouseMove={this.moveHandler}
        ref={progress => {
          this.progress = progress!;
        }}
      >
        <div
          className={'progress-amount active'}
          style={{
            height: volume !== undefined ? volume * VolumeSlider.percentage + '%' : '100%'
          }}
        >
          <div
            className={'progress-drag-circle active'}
            onMouseDown={this.dragCircleMouseDownHandler}
          />
        </div>
        <div
          className={'scroll-helper' + (mouseDown ? ' mouse-down' : '')}
          onClick={this.volumeClickHandle}
        />
      </div>
    );
  }

  private dragCircleMouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.addEventListener('mouseup', this.mouseUpEvent);
    this.volumeClickHandle(e);
    this.setState({mouseDown: true});
  };

  private mouseUpEvent = () => {
    this.setState({mouseDown: false}, () => {
      window.removeEventListener('mouseup', this.mouseUpEvent);
    });
  };

  private volumeClickHandle = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    const sliderBounds = this.progress.getBoundingClientRect();
    const value: number = Number((sliderBounds.bottom - e.pageY) / 80);
    this.setValue(value);
  };

  private setValue = (value: number) => {
    const $value = value > 1 ? 1 : value < 0 ? 0 : value;
    this.props.changeVolume($value);
  };

  private moveHandler: (e: React.MouseEvent<HTMLDivElement>) => void = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.mouseDown) {
      this.volumeClickHandle(e);
    }
  };
}
