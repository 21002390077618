import React, {type FC, useMemo} from 'react';
import Button from 'react-bootstrap/lib/Button';
import RetinaImage from '@englex/react-retina-image';
import {FormattedMessage} from 'react-intl';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Tooltip from 'rc-tooltip';

import {type AppState, type Cover} from 'store/interface';
import EnglexCoursebookCover from 'components/EnglexCoursebookCover';
import Icon from 'components/Icon';
import {type CoursebookInstanceUrlParams, grammarPath} from 'common/paths';

import {SelfCheckBlock} from './SelfCheckBlock/SelfCheckBlock';
import ProgressLine from './ProgressLine';
import {GrammarBankButton} from './GrammarBankButton';

import './Header.scss';

interface Props {
  cover?: Cover;
  title: string;
  unitsCount: number;
  exercisesCount: number;
  completedUnitsCount?: number;
  completedExercisesCount?: number;
  testsCount: number;
  testsCompleted?: number;
  onShowContentsClick?: () => void;
  onShowGrammarClick?: () => void;
  coursebookId?: string;
}

const Header: FC<Props> = ({
  cover,
  title,
  unitsCount,
  exercisesCount,
  completedUnitsCount,
  completedExercisesCount,
  onShowContentsClick,
  onShowGrammarClick,
  testsCount,
  testsCompleted,
  coursebookId
}) => {
  const params = useParams<CoursebookInstanceUrlParams>();
  const grammarUrl = useMemo<string>(
    () => grammarPath(params as Required<CoursebookInstanceUrlParams>),
    [params]
  );

  const role = useSelector((state: AppState) => state.user.role);

  const hasGrammar = useSelector<AppState, boolean | undefined>(
    state =>
      state.classroom?.courseInstanceState.coursebookInstanceState.selectedCoursebookInstance
        ?.hasGrammar
  );

  const previewHasGrammar = useSelector<AppState, boolean | undefined>(
    state => state.coursebookLibrary?.coursebooks?.find(cb => cb.id === coursebookId)?.hasGrammar
  );

  const coursebookSelfCheck = useSelector<AppState, boolean>(
    state =>
      !!state.classroom?.courseInstanceState.coursebookInstanceState.selectedCoursebookInstance
        ?.coursebook.selfCheckEnabled
  );

  const coursebookInstanceSelfCheck = useSelector<AppState, boolean>(
    state => !!state.settings?.teacher?.canEnableSelfCheck
  );

  const selectedCoursebookInstance = useSelector(
    (state: AppState) =>
      state.classroom!.courseInstanceState.coursebookInstanceState.selectedCoursebookInstance
  );

  const canToggleSelfCheck =
    coursebookInstanceSelfCheck || (coursebookSelfCheck && role === 'teacher');

  return (
    <div className="coursebook-instance-page-header">
      <div className="book-cover">
        {cover ? (
          <>
            <div className="mask" />
            <RetinaImage src={cover.urls} />
          </>
        ) : (
          <EnglexCoursebookCover fontSize={14} />
        )}
      </div>
      <div className="info">
        <h3>{title}</h3>
        <div className="stats">
          {typeof completedUnitsCount === 'number' && typeof testsCompleted === 'number' ? (
            <ProgressLine
              completed={completedUnitsCount + testsCompleted}
              total={unitsCount + testsCount}
            />
          ) : null}
          <div className="description">
            <div>
              <div className="units">
                <span className="completed-label">
                  <FormattedMessage id="ViewCoursebookContentsModal.Units" />:
                </span>
                {typeof completedUnitsCount === 'number' ? (
                  <FormattedMessage
                    id="Common.XOfY"
                    values={{x: completedUnitsCount, y: unitsCount}}
                  />
                ) : (
                  unitsCount
                )}
              </div>
              <div className="tests">
                <span className="completed-label">
                  <FormattedMessage id="Coursebook.Tests" />:
                </span>
                {typeof testsCompleted === 'number' ? (
                  <FormattedMessage id="Common.XOfY" values={{x: testsCompleted, y: testsCount}} />
                ) : (
                  testsCount
                )}
              </div>
              <div className="exercises">
                <span className="completed-label">
                  <FormattedMessage id="Coursebook.Exercises" />:
                </span>
                {typeof completedExercisesCount === 'number' ? (
                  <FormattedMessage
                    id="Common.XOfY"
                    values={{x: completedExercisesCount, y: exercisesCount}}
                  />
                ) : (
                  exercisesCount
                )}
              </div>
            </div>

            {selectedCoursebookInstance && (
              <SelfCheckBlock
                canToggleSelfCheck={canToggleSelfCheck}
                selectedCoursebookInstance={selectedCoursebookInstance}
              />
            )}
          </div>
        </div>
        {onShowContentsClick && (
          <Tooltip
            overlay={<FormattedMessage id="CoursebookInstancePage.Header.Program" />}
            overlayClassName="coursebook-btn-tooltip"
            placement="top"
            defaultVisible={false}
            trigger={['hover']}
          >
            <Button bsSize="sm" onClick={onShowContentsClick}>
              <Icon name="th-list" tag="i" />
              <FormattedMessage id="CoursebookInstancePage.Header.Program" />
            </Button>
          </Tooltip>
        )}

        <GrammarBankButton
          hasGrammar={hasGrammar}
          grammarUrl={grammarUrl}
          onShowGrammarClick={onShowGrammarClick}
          previewHasGrammar={previewHasGrammar}
          overlayClassName="coursebook-btn-tooltip"
        />
      </div>
    </div>
  );
};

export default Header;
