import {type AxiosRequestAction} from 'services/axios/interface';

const DRAFT_ALL_EXERCISES = 'DRAFT_ALL_EXERCISES';

export const draftAllExercises = (unitInstanceId: number): AxiosRequestAction => ({
  type: DRAFT_ALL_EXERCISES,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/unit-instance/${unitInstanceId}/homework`
    }
  }
});
