import React from 'react';

import Icon from 'components/Icon';
import {type MaterialFile} from 'store/interface';

import {type FileType, type PublishMaterialsOpenCreator} from '../../../actions/interface';
import {type RemoveFileFromUploadsCreator} from '../uploadingFiles/actions/interface';
import DocumentFileControl from './FileControl/DocumentFileControl';
import AudioFileControl from './FileControl/AudioFileControl';
import {type PublishMaterialsOpen} from './interface';

interface Props {
  courseId: string;
  files: MaterialFile[][];
  fileType: FileType;
  publishMaterialsOpen: PublishMaterialsOpenCreator | PublishMaterialsOpen;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  readonly: boolean;
  removeFileFromUploads: RemoveFileFromUploadsCreator;
  selectDoc: (id: number) => void;
}

export default class PinnedFiles extends React.Component<Props> {
  public render() {
    const {files} = this.props;
    return files.length ? (
      <tr className="pinned-row">
        <td>
          <span>
            <Icon name="virc-pin" />
          </span>
        </td>
        <td>
          <ul>{this.sortFiles(files).map(this.renderFileControl)}</ul>
        </td>
      </tr>
    ) : null;
  }

  private renderFileControl = (file: MaterialFile) => {
    const props = {
      pinnedBlock: true,
      key: String(file.id),
      publishMaterialsOpen: this.props.publishMaterialsOpen,
      publishMaterialsUpdated: this.props.publishMaterialsUpdated,
      readonly: this.props.readonly,
      thisFileId: file.id,
      deletingFileComponentId: file.isBeingDeletedComponentId
    };
    return this.props.fileType === 'document' ? (
      <DocumentFileControl {...props} changeActiveFile={this.props.selectDoc} />
    ) : (
      <AudioFileControl {...props} />
    );
  };

  private sortFiles = (files: MaterialFile[][]) =>
    files.reduce((arr: MaterialFile[], filesByDate: MaterialFile[]) => {
      const sorted = filesByDate.sort((el1: MaterialFile, el2: MaterialFile) =>
        el1.title.localeCompare(el2.title, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
      return arr.concat(sorted);
    }, []);
}
