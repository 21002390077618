import React from 'react';
import {FormattedMessage} from 'react-intl';

interface Props {
  unitsClosed: number;
  unitsTotal: number;
  testsClosed: number;
  testsTotal: number;
  exercisesCompleted: number;
  exercisesTotal: number;
}

const CoursebookInstanceProgressTitle: React.FC<Props> = ({
  unitsTotal,
  unitsClosed,
  testsTotal,
  testsClosed,
  exercisesCompleted,
  exercisesTotal
}) => {
  return (
    <React.Fragment>
      <div>
        <FormattedMessage id="ViewCoursebookContentsModal.Units" />
        {': '}
        <FormattedMessage id="Common.XOfY" values={{x: unitsClosed, y: unitsTotal}} />
      </div>
      <div>
        <FormattedMessage id="Coursebook.Tests" />
        {': '}
        <FormattedMessage id="Common.XOfY" values={{x: testsClosed, y: testsTotal}} />
      </div>
      <div>
        <FormattedMessage id="Coursebook.Exercises" />
        {': '}
        <FormattedMessage id="Common.XOfY" values={{x: exercisesCompleted, y: exercisesTotal}} />
      </div>
    </React.Fragment>
  );
};

export default CoursebookInstanceProgressTitle;
