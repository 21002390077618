import type React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {setIncorrectUrlId} from 'common/action';

const InvalidUrlId: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIncorrectUrlId());
  }, [dispatch]);

  return null;
};

export default InvalidUrlId;
