import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState, type Role} from 'store/interface';

import {type PublishMaterialsOpen} from '../../interface';
import AudioFileControl from './AudioFileControl';
import {
  changeActiveSound,
  changePlayStatus,
  changeTimestamp,
  clearOpenedTogetherSound,
  playSound,
  setUniquePlaybackId,
  soundEdit,
  soundOpenEditor
} from '../../../soundsTab/actions/action';
import {
  type ChangePlayStatusActionCreator,
  type ChangeTimestampActionCreator,
  type PlayMode,
  type PlayStatus,
  type SetUniquePlaybackIdAction,
  type Sound
} from '../../../soundsTab/actions/interface';
import {type PublishMaterialsOpenCreator} from '../../../../../actions/interface';

export interface AudioFileControlOwnProps {
  thisFileId: number;
  deleteFileAwait?: boolean;
  key: string;
  readonly: boolean;
  pinnedBlock?: boolean;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  publishMaterialsOpen: PublishMaterialsOpenCreator | PublishMaterialsOpen;
}

export interface AudioFileControlStateProps {
  playbackRate: number;
  timestamp: number;
  openTogetherFileId?: number;
  editFileAwait?: boolean;
  fileIsActive: boolean;
  fileIsBeingEdited?: true;
  playStatus?: PlayStatus;
  playMode?: PlayMode | null;
  role: Role;
  thisFile?: Sound;
  uniquePlaybackId?: string;
}

export interface AudioFileControlDispatchProps {
  clearOpenedTogether: () => void;
  editFileName: (id: number, title: string) => Promise<{}>;
  changePlayStatus: ChangePlayStatusActionCreator;
  changeActiveFile: (id: number | null) => void;
  openEditor: (id: number | null) => void;
  changeTimestamp: ChangeTimestampActionCreator;
  setUniquePlaybackId: () => SetUniquePlaybackIdAction;
  playSound: (setUniquePlaybackId: string) => void;
}

const mapStateToProps = (
  state: AppState,
  ownProps: AudioFileControlOwnProps
): AudioFileControlStateProps => {
  const fileIsActive =
    ownProps.thisFileId === state.sounds!.activeSound && !!state.sounds!.uniquePlaybackId;
  const fileIsBeingEdited = state.sounds!.editedSound === ownProps.thisFileId ? true : undefined;
  const editFileAwait =
    state.sounds!.editedSound === ownProps.thisFileId ? state.sounds!.editSoundAwait : undefined;
  return {
    playbackRate: state.sounds!.playbackRate || 1,
    timestamp: state.sounds!.timestamp || 0,
    openTogetherFileId: state.sounds!.openTogetherFileId,
    editFileAwait,
    fileIsBeingEdited,
    playStatus: fileIsActive ? state.sounds!.playStatus : undefined,
    playMode:
      state.sounds!.playMode || state.sounds!.playMode !== null
        ? state.sounds!.playMode
        : undefined,
    role: state.user.role!,
    fileIsActive,
    thisFile: state.sounds!.sounds![ownProps.thisFileId],
    uniquePlaybackId: state.sounds!.uniquePlaybackId
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  ownProps: AudioFileControlOwnProps
): AudioFileControlDispatchProps => ({
  editFileName: (id: number, title: string) => dispatch(soundEdit(id, title)),
  changeActiveFile: (id: number) => dispatch(changeActiveSound(id)),
  clearOpenedTogether: () => dispatch(clearOpenedTogetherSound()),
  openEditor: (id: number | null) => dispatch(soundOpenEditor(id)),
  changePlayStatus: (status: PlayStatus, playMode: PlayMode) =>
    dispatch(changePlayStatus(status, playMode)),
  setUniquePlaybackId: () =>
    dispatch(
      setUniquePlaybackId(
        ownProps.pinnedBlock ? `${ownProps.thisFileId}-pinned` : `${ownProps.thisFileId}`
      )
    ),
  changeTimestamp: (timestamp: number) => dispatch(changeTimestamp(timestamp)),
  playSound: (uniquePlaybackId: string) => dispatch(playSound(uniquePlaybackId))
});

const AudioFileControlContainer = connect<
  AudioFileControlStateProps,
  AudioFileControlDispatchProps,
  AudioFileControlOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(AudioFileControl);

export default AudioFileControlContainer;
