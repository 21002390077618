import React, {type FC, useCallback} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';

import {ACTIVE_UNITS_LIMIT} from 'config/static';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import PieProgress from 'components/PieProgress';

import {type IsActive} from '../../pages/CoursebookInstancePage/interface';
import './CardFooter.scss';

interface Props extends IsActive {
  id: number;
  completed: boolean;
  setUnitToToggle: (id: number) => void;
  renderControlBtn?: boolean;
  exercisesCompleted: number;
  exercisesCount: number;
  extraExercisesCount?: number;
  courseInstanceIsActive: boolean;
  activationForbidden?: boolean;
}

const CardFooter: FC<Props> = ({
  active,
  id,
  completed,
  setUnitToToggle,
  renderControlBtn,
  exercisesCount,
  exercisesCompleted,
  extraExercisesCount,
  courseInstanceIsActive,
  activationForbidden
}) => {
  const intl = useIntl();
  const toggleActive = useCallback<(e: React.MouseEvent<Button, MouseEvent>) => void>(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!active && activationForbidden) {
        toastr.error(
          '',
          intl.formatMessage(
            {id: 'CoursebookInstancePage.UnitsList.MaxUnitsCountAchieved'},
            {maxOpenUnits: ACTIVE_UNITS_LIMIT}
          )
        );
        return;
      }
      setUnitToToggle(id);
    },
    [active, activationForbidden, id, intl, setUnitToToggle]
  );

  return (
    <div className="footer">
      <div className="unit-stats">
        {exercisesCompleted === exercisesCount ? (
          <Icon name="enlx-check" />
        ) : (
          <PieProgress size={14} filled={exercisesCompleted} total={exercisesCount} />
        )}
        <span className="exercises-progress">
          {exercisesCompleted} / {exercisesCount}
        </span>
        {extraExercisesCount ? (
          <span className="additional-exercises-count">+{extraExercisesCount}</span>
        ) : null}
      </div>
      {renderControlBtn && !completed && (active || courseInstanceIsActive) ? (
        <Button className="toggle-active" onClick={toggleActive}>
          <Icon name={active ? 'lock' : 'unlock-alt'} />
          {active ? <FormattedMessage id="Common.Close" /> : <FormattedMessage id="Common.Open" />}
        </Button>
      ) : null}
    </div>
  );
};

export default React.memo(CardFooter);
