import React from 'react';
import {FormattedMessage} from 'react-intl';
import Tooltip from 'rc-tooltip';
import './AbbreviatedProperty.scss';

interface Props {
  propertyList: string[];
}

const AbbreviatedProperty: React.FC<Props> = (props: Props) => {
  const {propertyList} = props;
  if (!propertyList.length) {
    return (
      <i>
        {' '}
        <FormattedMessage id="CoursebookLibrary.NotSpecifiedPlaceholder" />
      </i>
    );
  }
  const [firstEl, ...hiddenEls] = propertyList;
  return (
    <React.Fragment>
      {' '}
      {firstEl}{' '}
      {hiddenEls.length ? (
        <>
          <FormattedMessage id="AddCoursebookModal.And" />{' '}
          <Tooltip
            overlay={hiddenEls.map((el: string, i: number) => (
              <div key={i}>{el}</div>
            ))}
            placement="top"
            overlayClassName="black-tooltip"
          >
            <span className="abbreviated-property-trigger">
              <FormattedMessage id="AddCoursebookModal.XMore" values={{count: hiddenEls.length}} />
            </span>
          </Tooltip>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default AbbreviatedProperty;
