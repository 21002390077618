import {type Action} from 'redux';

import {type HomeworkState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {type AxiosRequestAction} from 'services/axios/interface';

import {
  CLEAR_HOMEWORK_LIST,
  REQUEST_HOMEWORK_LIST,
  REQUEST_HOMEWORK_LIST_FAIL,
  SET_HOMEWORK_LIST
} from '../pages/HomeworkPage/actionTypes';
import {type SetHomeworkListAction} from '../pages/HomeworkPage/actions';
import getHomeworkPlayerReducer, {homeworkPlayerDefaultState} from './homeworkPlayerReducer';

const ACTION_HANDLERS: ActionHandlersList<HomeworkState, Action> = {
  [REQUEST_HOMEWORK_LIST]: (
    state: HomeworkState,
    {
      payload: {
        request: {
          params: {pageNumber}
        }
      }
    }: AxiosRequestAction
  ): HomeworkState => ({
    ...state,
    isLoading: !pageNumber,
    isError: false
  }),
  [REQUEST_HOMEWORK_LIST_FAIL]: (state: HomeworkState): HomeworkState => ({
    ...state,
    isLoading: false,
    isError: true
  }),
  [SET_HOMEWORK_LIST]: (
    state: HomeworkState,
    {homeworkList}: SetHomeworkListAction
  ): HomeworkState => ({
    ...state,
    isLoading: false,
    homeworkList
  }),
  [CLEAR_HOMEWORK_LIST]: (state: HomeworkState): HomeworkState => ({
    ...state,
    homeworkList: []
  })
};

export const defaultHomeworkState: HomeworkState = {
  homeworkList: [],
  homeworkPlayer: homeworkPlayerDefaultState
};

export default function (action: Action) {
  const homeworkReducer = ACTION_HANDLERS[action.type];
  const homeworkPlayerReducer = getHomeworkPlayerReducer(action);
  if (!homeworkReducer && !homeworkPlayerReducer) {
    return undefined;
  }
  return (state: HomeworkState, action: Action) => ({
    ...(homeworkReducer ? homeworkReducer(state, action) : state),
    homeworkPlayer: homeworkPlayerReducer
      ? homeworkPlayerReducer(state.homeworkPlayer, action)
      : state.homeworkPlayer
  });
}
