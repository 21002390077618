import React, {type FC, useState} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {requestToToggleCoursebookInstanceSelfCheck} from 'routes/ClassRoom/pages/CourseInstancePage/actions';
import {toggleCoursebookInstanceSelfCheck} from 'routes/ClassRoom/pages/CoursebookInstancePage/actions';
import {type CoursebookInstance} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {SelfCheckButton} from '../SelfCheckButton/SelfCheckButton';

import './SelfCheckBlock.scss';

interface Props {
  canToggleSelfCheck: boolean;
  selectedCoursebookInstance: CoursebookInstance;
}

export const SelfCheckBlock: FC<Props> = ({canToggleSelfCheck, selectedCoursebookInstance}) => {
  const intl = useIntl();
  const dispatch = useAxiosDispatch();

  const [loading, setLoading] = useState(false);

  const instanceId = selectedCoursebookInstance?.id;
  const coursebookInstanceSelfCheck = !!selectedCoursebookInstance?.selfCheckEnabled;

  const onClick = async () => {
    setLoading(true);

    try {
      const {
        payload: {
          data: {selfCheckEnabled}
        }
      } = await dispatch<AxiosResponseAction<CoursebookInstance>>(
        requestToToggleCoursebookInstanceSelfCheck(instanceId, coursebookInstanceSelfCheck)
      );

      dispatch(toggleCoursebookInstanceSelfCheck(!!selfCheckEnabled));

      toastr.success(
        '',
        coursebookInstanceSelfCheck
          ? intl.formatMessage({id: 'XPlayer.SelfCheckSuccessfullyDisabledToast'})
          : intl.formatMessage({id: 'XPlayer.SelfCheckSuccessfullyEnabledToast'})
      );
    } catch (error) {
      toastr.error(
        '',
        coursebookInstanceSelfCheck
          ? intl.formatMessage({id: 'XPlayer.SelfCheckDisabledTryAgainToast'})
          : intl.formatMessage({id: 'XPlayer.SelfCheckEnabledTryAgainToast'})
      );
    }

    setLoading(false);
  };

  return (
    <div className="self-check">
      {(canToggleSelfCheck || coursebookInstanceSelfCheck) && (
        <div className={classNames('title-self-check', {danger: !coursebookInstanceSelfCheck})}>
          <span className="bold-word">{intl.formatMessage({id: 'XPlayer.SelfCheck'})}</span>{' '}
          <span className="full-word">
            {coursebookInstanceSelfCheck
              ? intl.formatMessage({id: 'XPlayer.SelfCheckEnabled'})
              : intl.formatMessage({id: 'XPlayer.SelfCheckDisabled'})}
          </span>
          <span className="short-word">
            {coursebookInstanceSelfCheck
              ? intl.formatMessage({id: 'XPlayer.SelfCheckEnabled.Short'})
              : intl.formatMessage({id: 'XPlayer.SelfCheckDisabled.Short'})}
          </span>
        </div>
      )}

      {canToggleSelfCheck && (
        <SelfCheckButton show={coursebookInstanceSelfCheck} onClick={onClick} loading={loading} />
      )}
    </div>
  );
};
