import React, {useCallback} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';

import {clearMainExerciseId} from '../../../../components/modals/UnitPreview/actions';

import './SupplementaryExerciseModal.scss';

interface Props {
  show?: boolean;
}

const SupplementaryExercisesModal: React.FC<Props> = ({show, children}) => {
  const dispatch = useDispatch();
  const closeModal = useCallback(() => dispatch(clearMainExerciseId), [dispatch]);
  return (
    <Modal
      onHide={closeModal}
      backdrop="static"
      show={show}
      className="unit-preview-supplementary-exercises-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="SupplementaryExercisesModal.Title" />
          <a onClick={closeModal}>
            <Icon name="pc-close" tag="i" />
          </a>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" bsSize="sm" onClick={closeModal}>
          <FormattedMessage id="Common.Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupplementaryExercisesModal;
