import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {push} from 'store/router';
import {type AppState, type UploadingFile} from 'store/interface';
import {type AxiosRequestAction, type UploadProgressHandler} from 'services/axios/interface';
import {filesListPath, filesListSelectedDocPath} from 'common/paths';
import {MaterialsTab} from 'common/enums';

import {
  type AddFileToUploadsCreator,
  type BlockUploadPanelCreator,
  type FileUploadedCreator,
  type MD5CheckActionCreator,
  type RemoveFileFromUploadsCreator,
  type UploadingAction,
  type UploadingFailedCreator,
  type UploadingProgressCreator
} from './interface';
import {type FileType} from '../../../../actions/interface';
import {
  ADD_FILE_TO_UPLOADS_LIST,
  BLOCK_UPLOAD_PANEL,
  CLONE_FILE,
  FILE_MD5_CHECK,
  FILE_UPLOADED,
  REMOVE_FILE_FROM_UPLOADS_LIST,
  UPLOAD_FILE,
  UPLOADING_FAILED,
  UPLOADING_PROGRESS,
  UPLOADS_RESET
} from './actionTypes';
import {playSoundWhenLoaded} from '../../../../actions/action';
import {playSound} from '../../soundsTab/actions/action';

export const MD5Check: MD5CheckActionCreator = (fileType: FileType, md5: string) => ({
  type: FILE_MD5_CHECK,
  payload: {
    request: {
      url: `/v1/classroom/${fileType}/exist`,
      method: 'get',
      params: {
        md5
      }
    }
  }
});

export const goToUploadedFile =
  (fileId: number, fileType: FileType) =>
  (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
    const state = getState();
    const file =
      fileType === 'document'
        ? state.uploads.recentDocuments[fileId]
        : state.uploads.recentSounds[fileId];
    if (file.fileType === 'document') {
      dispatch(
        push(
          filesListSelectedDocPath(
            file.courseInstance.student_teacher_id,
            file.course_instance_id,
            fileId
          )
        )
      );
    }
    if (file.fileType === 'audio') {
      const targetPath = filesListPath(
        file.courseInstance.student_teacher_id,
        file.course_instance_id,
        MaterialsTab.AUDIO
      );
      if (!state.router.location!.pathname.includes(targetPath)) {
        dispatch(push(targetPath));
        dispatch(playSoundWhenLoaded(String(fileId)));
      } else {
        dispatch(playSound(String(fileId)));
      }
    }
  };

export const addFileToUploads: AddFileToUploadsCreator = (file: UploadingFile) => ({
  type: ADD_FILE_TO_UPLOADS_LIST,
  file
});

export const blockUploadPanel: BlockUploadPanelCreator = (
  fileType: FileType,
  blocked: boolean
) => ({
  type: BLOCK_UPLOAD_PANEL,
  fileType,
  blocked
});

export const removeFileFromUploads: RemoveFileFromUploadsCreator = (md5: string, id?: string) => ({
  type: REMOVE_FILE_FROM_UPLOADS_LIST,
  md5,
  id
});

export const uploadingProgress: UploadingProgressCreator = (fileId: string, percents: number) => ({
  type: UPLOADING_PROGRESS,
  fileId,
  percents
});

export const uploadFile = (
  courseId: number,
  fileType: FileType,
  data: FormData,
  onUploadProgress: UploadProgressHandler
): AxiosRequestAction => ({
  type: UPLOAD_FILE,
  payload: {
    request: {
      url: `/v1/classroom/course-instance/${courseId}/${fileType}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'post',
      data,
      onUploadProgress
    }
  }
});

export const cloneFile = (
  courseId: number,
  fileId: number,
  fileType: FileType,
  title: string
): AxiosRequestAction => ({
  type: CLONE_FILE,
  payload: {
    request: {
      url: `/v1/classroom/course-instance/${courseId}/${fileType}-instance`,
      method: 'post',
      data: {
        [`${fileType}_id`]: fileId,
        title
      }
    }
  }
});

export const uploadingFailed: UploadingFailedCreator = (id: string, errorText: string) => ({
  type: UPLOADING_FAILED,
  id,
  errorText
});

export const fileUploaded: FileUploadedCreator = (id: string) => ({
  type: FILE_UPLOADED,
  id
});

export const uploadsReset: () => UploadingAction = () => ({
  type: UPLOADS_RESET
});
