import {type Action} from 'redux';

import {type ActionHandlersList} from 'store/reducers';
import {type CoursebookInstance} from 'store/interface';

import {
  type CoursebookInstanceAction,
  type CoursebookInstanceDeactivatedAction,
  type CoursebookInstanceIncrementCompletedUnitsAction,
  isCoursebookInstanceAction
} from '../pages/CourseInstancePage/actions';
import {
  COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_EXERCISES,
  COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_UNITS,
  SET_COURSEBOOK_INSTANCE_ACTIVATED,
  SET_COURSEBOOK_INSTANCE_DEACTIVATED
} from '../pages/CourseInstancePage/actionTypes';

const ACTION_HANDLERS: ActionHandlersList<CoursebookInstance, CoursebookInstanceAction> = {
  [COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_EXERCISES]: (
    state: CoursebookInstance
  ): CoursebookInstance => ({
    ...state,
    overview: {
      ...state.overview!,
      mainExercisesCompleted: state.overview!.mainExercisesCompleted + 1
    }
  }),
  [COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_UNITS]: (
    state: CoursebookInstance,
    {isTest}: CoursebookInstanceIncrementCompletedUnitsAction
  ): CoursebookInstance => ({
    ...state,
    overview: {
      ...state.overview!,
      unitsCompleted: isTest ? state.overview!.unitsCompleted : state.overview!.unitsCompleted + 1,
      testsCompleted: isTest ? state.overview!.testsCompleted + 1 : state.overview!.testsCompleted
    }
  }),
  [SET_COURSEBOOK_INSTANCE_DEACTIVATED]: (
    state: CoursebookInstance,
    action: CoursebookInstanceDeactivatedAction
  ): CoursebookInstance => ({
    ...state,
    completedAt: action.completedAt
  }),
  [SET_COURSEBOOK_INSTANCE_ACTIVATED]: (state: CoursebookInstance): CoursebookInstance => ({
    ...state,
    completedAt: null
  })
};

export default function (action: Action) {
  if (!ACTION_HANDLERS[action.type] || !isCoursebookInstanceAction(action)) {
    return undefined;
  }
  return (state: CoursebookInstance[] | undefined) =>
    state &&
    state.map(cbi =>
      cbi.id === action.coursebookInstanceId ? ACTION_HANDLERS[action.type]!(cbi, action) : cbi
    );
}
