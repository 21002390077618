import {type IntlShape, defineMessages} from 'react-intl';

import {type Homework} from 'store/interface';

const messages = defineMessages({
  Draft: {
    id: 'Homework.Draft'
  }
});

export function getHomeworkLabel(homework: Homework, userTimeZone: string, intl: IntlShape) {
  if (!homework.activatedAt) {
    return intl.formatMessage(messages.Draft);
  }
  return `${intl.formatDate(homework.activatedAt, {
    timeZone: userTimeZone
  })} ${intl.formatTime(homework.activatedAt, {timeZone: userTimeZone})}`;
}
