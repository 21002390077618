import React, {type FC, memo, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {push} from 'store/router';
import {type AppState, type CoursebookInstance} from 'store/interface';
import {
  filesListPath,
  homeworkPath,
  coursebookInstancePath,
  type CoursebookInstanceUrlParams as Params
} from 'common/paths';

import CoursebookInstanceBreadcrumbView from '../breadcrumbs/components/CoursebookInstanceBreadcrumb';
import {toggleCoursebookInstanceDropdown} from '../pages/CourseInstancePage/actions';

interface Props {
  coursebookInstances: CoursebookInstance[];
  isOnFilesList?: boolean;
  isOnHomeworkPage?: boolean;
}

export const CoursebookInstanceBreadcrumb: FC<Props> = memo(
  ({coursebookInstances, isOnFilesList, isOnHomeworkPage}) => {
    const {
      coursebookInstanceId: selectedCoursebookInstanceId,
      courseId,
      studentTeacherId
    } = useParams<Params>();

    const dispatch = useDispatch();
    const dropdownIsOpen = useSelector(
      (state: AppState) => state.classroom!.courseInstanceState.coursebookInstancesDropdownOpen
    );

    const toggleDropdown = useCallback(
      (show: boolean) => dispatch(toggleCoursebookInstanceDropdown(show)),
      [dispatch]
    );

    const selectCoursebookInstance = useCallback(
      (id: string) => dispatch(push(coursebookInstancePath({studentTeacherId, courseId}, id))),
      [courseId, dispatch, studentTeacherId]
    );
    const goToFilesList = useCallback(
      () => dispatch(push(filesListPath(studentTeacherId!, courseId!))),
      [courseId, dispatch, studentTeacherId]
    );
    const goToHomeworkPage = useCallback(
      () => dispatch(push(homeworkPath(studentTeacherId!, courseId!))),
      [courseId, dispatch, studentTeacherId]
    );

    return (
      <CoursebookInstanceBreadcrumbView
        isOnFilesList={isOnFilesList}
        coursebookInstances={coursebookInstances}
        dropdownIsOpen={dropdownIsOpen}
        selectedCoursebookInstanceId={selectedCoursebookInstanceId}
        selectCoursebookInstance={selectCoursebookInstance}
        toggleDropdown={toggleDropdown}
        goToFilesList={goToFilesList}
        goToHomeworkPage={goToHomeworkPage}
        isOnHomeworkPage={isOnHomeworkPage}
      />
    );
  }
);
