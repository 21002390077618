import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';

import {md5Chunk} from 'services/common-methods';
import {type UploadingStatus} from 'store/interface';
import {type AxiosRequestError} from 'services/axios/interface';
import {maxDocSize, maxSoundSize, megaByteMultiplier} from 'config/static';
import uniqueId from 'helpers/uniqueId';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {useIsSampleLicenseTeacher} from 'hooks/user/useIsSampleLicenseTeacher';

import {type MD5CheckSuccessAction} from '../../actions/interface';
import {validateOnClient} from './validateOnClient';
import i18n from './i18n';
import LibraryButton from './LibraryButton';
import TextBlock from './TextBlock';
import {type FileUploadPanelProps as Props} from './interface';
import './FileUploadPanel.scss';

interface NoResponseError {
  error: {status: number};
  meta: {previousAction: {payload: {request: {params: {md5?: string}}}}};
}

const alreadyUploadingError: string = 'AlreadyUploading';
const invalidExtensionError: string = 'InvalidExtension';
const invalidSizeError: string = 'InvalidSize';
const emptyFileError: string = 'EmptyFile';

const FileUploadPanel: FC<Props> = ({
  blocked,
  openLibraryModal,
  workspaceExpanded,
  fileType,
  blockUploadPanel,
  checkMD5,
  uploadingDocuments,
  uploadingSounds,
  cloneFile,
  courseId,
  fileUploaded,
  intl: {formatMessage},
  publishMaterialsUpdated,
  uploadingFailed,
  addFileToUploads,
  studentTeacherId,
  getRecentDocuments,
  getRecentSounds,
  uploadRequest,
  uploadingProgress
}) => {
  const [fileName, setFileName] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const isDocument = fileType === 'document';

  const [{isOver}, collectDropTarget] = useDrop({
    accept: NativeTypes.FILE,
    drop: ({files}: {files: File[]}) => {
      fileChosenHandler(files);
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  const handleClick = () => {
    if (inputRef) {
      inputRef.current?.click();
    }
  };

  const beforeUnloadHandler = useCallback(
    (event: Event) => {
      const uploadingFiles = {...uploadingSounds, ...uploadingDocuments};
      for (const id in uploadingFiles) {
        if (uploadingFiles[id].uploadingStatus !== 'fail') {
          event.returnValue = true;
        }
      }
    },
    [uploadingDocuments, uploadingSounds]
  );

  const handleUploadFileError = ({error: {response}}: AxiosRequestError, tempId: string) => {
    let message;
    if (response && response.status === 422) {
      const {data} = response;
      message =
        data && Array.isArray(data) && data[0].message
          ? response.data[0].message
          : isDocument
            ? formatMessage(i18n.InvalidDocExtension)
            : formatMessage(i18n.InvalidSoundExtension);
    } else {
      message = formatMessage(i18n.UnknownUploadingError);
    }
    uploadingFailed(tempId, message);
  };

  const getRecentFiles = () => {
    isDocument ? getRecentDocuments() : getRecentSounds();
  };

  const handleUploadFileSuccess = (tempId: string) => {
    fileUploaded(tempId);
    getRecentFiles();
    publishMaterialsUpdated(courseId);
    toastr.success('', formatMessage(i18n.UploadingSuccessful));
  };

  const clearUploadingPanelState = () => {
    if (inputRef && inputRef.current) {
      setFileName('');
      inputRef.current.value = '';
    }
    blockUploadPanel(fileType, false);
  };

  const addFileToUploadsList = (
    id: string,
    fileName: string,
    uploadingStatus: UploadingStatus,
    md5?: string
  ) => {
    addFileToUploads({
      title: fileName,
      studentTeacherId: studentTeacherId,
      course_instance_id: courseId,
      id,
      md5,
      fileType,
      uploadingStatus,
      uploadingStartedTime: new Date().getTime()
    });
    clearUploadingPanelState();
  };

  const uploadFile = async (response: AxiosRequestError | Error | NoResponseError, file: File) => {
    if (response instanceof Error) {
      toastr.error(formatMessage(i18n.UploadingFailed), formatMessage(i18n.UnknownUploadingError));
    }

    const tempId = uniqueId();

    if (
      (response as AxiosRequestError).error.response &&
      (response as AxiosRequestError).error.response!.status === 404
    ) {
      const md5 = (response as AxiosRequestError).error.config.params.md5;
      addFileToUploadsList(tempId, file.name, 'uploading', md5);

      const formData = new FormData();
      formData.append('file', file);

      try {
        await uploadRequest(courseId, fileType, formData, e => {
          const percents = Math.round((e.loaded * 100) / e.total);
          uploadingProgress(tempId, percents);
        });
        handleUploadFileSuccess(tempId);
      } catch (e) {
        handleUploadFileError(e, tempId);
      }
    } else if ((response as NoResponseError).error.status === 0) {
      addFileToUploadsList(
        tempId,
        file.name,
        'cloning',
        (response as NoResponseError).meta.previousAction.payload.request.params.md5
      );
      uploadingFailed(tempId, formatMessage(i18n.UnknownUploadingError));
    } else {
      clearUploadingPanelState();
    }
    return;
  };

  const handleClientValidationError = (response: Error, fileName: string) => {
    const tempId = uniqueId();
    let message: string;
    const title = fileName;

    switch (response.message) {
      case invalidExtensionError:
        message = formatMessage(isDocument ? i18n.InvalidDocExtension : i18n.InvalidSoundExtension);
        break;
      case invalidSizeError:
        const maxSize = isDocument
          ? maxDocSize / megaByteMultiplier
          : maxSoundSize / megaByteMultiplier;
        message = formatMessage(i18n.InvalidFileSize, {size: maxSize});
        break;
      case emptyFileError:
        message = formatMessage(i18n.EmptyFile);
        break;
      case alreadyUploadingError:
        toastr.error('', formatMessage(i18n.FileIsAlreadyUploading));
        clearUploadingPanelState();
        return;
      default:
        toastr.error(
          formatMessage(i18n.UploadingFailed),
          formatMessage(i18n.UnknownUploadingError)
        );
        return;
    }
    addFileToUploadsList(tempId, title, 'uploading');
    uploadingFailed(tempId, message);
  };

  const cloneFileFunc = async (response: MD5CheckSuccessAction, fileName: string) => {
    const {id, md5} = response.payload.data;
    const newFileName: string = fileName.replace(/\.(docx?|pdf|mp3)$/, '');

    try {
      addFileToUploadsList(id, newFileName, 'cloning', md5);
      await cloneFile(courseId, id, fileType, newFileName);
      getRecentFiles();
      toastr.success('', formatMessage(i18n.CloningSuccessful));
      fileUploaded(id);
      publishMaterialsUpdated(courseId);
    } catch (error) {
      if (
        error.error &&
        error.error.response &&
        error.error.response.status &&
        !error.error.response.status.toString().match(/2[0-9]{2}|299/)
      ) {
        addFileToUploadsList(id, fileName, 'fail');
        uploadingFailed(id, formatMessage(i18n.UnknownUploadingError));
        return;
      } else {
        uploadingFailed(id, formatMessage(i18n.UnknownUploadingError));
      }
    }
  };

  const processingFile = async (file: File) => {
    try {
      const uploadingFiles = {...uploadingDocuments, ...uploadingSounds};

      setFileName(file.name);

      validateOnClient(
        file,
        isDocument,
        maxDocSize,
        maxSoundSize,
        invalidSizeError,
        emptyFileError,
        invalidExtensionError
      );

      const md5 = await md5Chunk(file);

      for (const id in uploadingFiles) {
        const f = uploadingFiles[id];
        if (f.md5 === md5 && f.uploadingStatus !== 'fail') {
          throw new Error(alreadyUploadingError);
        }
      }

      const response = (await checkMD5(fileType, md5)) as MD5CheckSuccessAction;
      cloneFileFunc(response, file.name);
    } catch (e) {
      switch (e.message) {
        case alreadyUploadingError:
        case invalidExtensionError:
        case invalidSizeError:
        case emptyFileError:
          handleClientValidationError(e, file.name);
          return;
        default:
          uploadFile(e, file);
          return;
      }
    }
  };

  const fileChosenHandler = async (files: File[] | FileList) => {
    blockUploadPanel(fileType, true);

    for (const file of Array.from(files)) {
      await processingFile(file);
    }
  };

  const handleFileInput = () => {
    if (inputRef && inputRef.current?.files) {
      fileChosenHandler(inputRef.current.files);
    }
  };

  const isSampleLicenseTeacher = useIsSampleLicenseTeacher();

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [beforeUnloadHandler]);

  return collectDropTarget(
    <div className={classNames('file-upload-panel', {'is-over': isOver})}>
      <TextBlock
        blocked={blocked}
        isOver={isOver}
        fileName={fileName}
        isDocument={isDocument}
        placement={workspaceExpanded ? 'bottom' : 'top'}
      />
      <div className="upload-buttons">
        {isDocument && !isSampleLicenseTeacher && (
          <LibraryButton blocked={blocked} openLibraryModal={openLibraryModal} />
        )}
        <Button bsStyle="default" bsSize="sm" disabled={blocked} onClick={handleClick}>
          <Icon name="upload" />
          <FormattedMessage id="File.FromComputer" />
        </Button>
        <input
          accept={isDocument ? '.doc,.docx,.pdf,' : '.mp3'}
          className="file-upload-panel-input"
          id="file-upload-panel-input"
          multiple={true}
          onChange={handleFileInput}
          ref={inputRef}
          type="file"
          tabIndex={-1}
        />
      </div>
    </div>
  );
};

export default FileUploadPanel;
