import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';

import {type CourseInstance} from 'store/interface';

import Icon from '../../../../components/Icon';

interface CoursePanelDropdownStateProps {
  elements: {[id: string]: CourseInstance};
  shouldDisableDropdown?: boolean;
  dropdownIsOpen?: boolean;
  selectedCourseId: string;
}

interface DispatchCoursePanelDropdownProps {
  toggleDropdown: (show: boolean) => void;
}

interface CoursePanelDropdownProps
  extends CoursePanelDropdownStateProps,
    DispatchCoursePanelDropdownProps {
  handleElementClick: (id: number) => void;
}

export default class CoursesDropdown extends React.Component<CoursePanelDropdownProps, {}> {
  private static maxDisplayedElements: number = 4.5;
  private static elementHeight: number = 44;
  private static elementWidth: number = 234;

  public render() {
    const {shouldDisableDropdown, dropdownIsOpen} = this.props;
    return (
      <div className="breadcrumbs-dropdown">
        <DropdownButton
          id="teachers-dropdown"
          title={<Icon name="caret-down" />}
          open={shouldDisableDropdown ? false : dropdownIsOpen}
          onToggle={this.toggleDropDownButton}
          noCaret={true}
        >
          <Scrollbars
            style={{
              width: CoursesDropdown.elementWidth,
              height: this.getScrollbarHeight()
            }}
            autoHide={true}
            title=""
          >
            {this.sortAndRenderElements()}
          </Scrollbars>
        </DropdownButton>
      </div>
    );
  }

  private toggleDropDownButton = () => {
    this.props.toggleDropdown(!this.props.dropdownIsOpen);
  };

  private getScrollbarHeight = () => {
    if (Object.keys(this.props.elements).length > CoursesDropdown.maxDisplayedElements) {
      return CoursesDropdown.elementHeight * CoursesDropdown.maxDisplayedElements;
    }
    return CoursesDropdown.elementHeight * Object.keys(this.props.elements).length;
  };

  private sortByAlphabet = (el1: CourseInstance, el2: CourseInstance) => {
    if (el1.course.name > el2.course.name) {
      return 1;
    }
    return -1;
  };

  private renderElements = (sortedArr: Array<CourseInstance>) =>
    sortedArr.map(element => (
      <MenuItem
        key={element.id}
        onClick={e => {
          e.stopPropagation();
          this.props.handleElementClick(element.id);
          this.props.toggleDropdown(false);
        }}
        style={{height: CoursesDropdown.elementHeight}}
        className={classNames({selected: this.props.selectedCourseId === String(element.id)})}
      >
        <span>{element.course.name}</span>
      </MenuItem>
    ));

  private sortAndRenderElements = () => {
    const elementsArr: Array<CourseInstance> = [];

    for (const id in this.props.elements) {
      elementsArr.push(this.props.elements[id]);
    }
    elementsArr.sort(this.sortByAlphabet);
    return this.renderElements(elementsArr);
  };
}
