import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';

import SearchBar, {
  type DispatchSearchBarProps,
  type SearchBarStateProps
} from '../../../../components/SearchBar';
import {changeFilesFilter, toggleMaterialsSearchBar} from './action';

const mapStateToProps = (state: AppState): SearchBarStateProps => ({
  filter: state.filesWorkSpace!.filesFilter,
  isSmall: true,
  autoFocus: true
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchSearchBarProps => ({
  changeFilter(filter: string): void {
    dispatch(changeFilesFilter(filter));
  },
  collapseSearchbar(): void {
    dispatch(toggleMaterialsSearchBar(false));
  }
});

export default connect<SearchBarStateProps, DispatchSearchBarProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
