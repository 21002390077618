import React from 'react';

import {type EditHomeworkDocPageCommentProps} from './EditHomeworkCommentModal';
import './EditHomeworkCommentPopover.scss';
import {EditHomeworkCommentPopover} from './EditHomeworkCommentPopover';
import useEditHomeworkDocPageComment from './useEditHomeworkDocPageComment';

const EditHomeworkDocCommentPopover: React.FC<EditHomeworkDocPageCommentProps> = props => {
  const {closeCommentViewer, closeRequested, tellCommentEditorJustClosed} = props;

  return (
    <EditHomeworkCommentPopover
      {...useEditHomeworkDocPageComment(props)}
      closeCommentViewer={closeCommentViewer}
      closeRequested={closeRequested}
      tellCommentEditorJustClosed={tellCommentEditorJustClosed}
    />
  );
};

export default EditHomeworkDocCommentPopover;
