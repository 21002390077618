import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import './CoursebookInfoDetailed.scss';

const CoursebookInfoDetailed: React.FC<{}> = props => (
  <div className="coursebook-info-detailed">
    <Scrollbars autoHide={true}>{props.children} </Scrollbars>
  </div>
);

export default CoursebookInfoDetailed;
