import {defineMessages} from 'react-intl';

export const documentDraftControlMessages = defineMessages({
  Title: {
    id: 'File.AddToHomework'
  },
  DeleteFromHomework: {
    id: 'File.DeleteFromHomework'
  },
  EditCommentary: {
    id: 'File.EditHomeworkCommentary'
  },
  ViewInDocument: {
    id: 'XPlayer.ViewExerciseInDocument'
  },
  DeleteFromDraftSuccess: {
    id: 'File.DeleteFromDraftSuccess'
  },
  DeleteFromDraftFail: {
    id: 'File.DeleteFromDraftFail'
  },
  DeleteConfirm: {
    id: 'File.DeleteFileFromHomeworkConfirmText'
  },
  MoveToDraft: {
    id: 'File.MoveHomeworkToDraft'
  }
});

export const documentPageDraftControlMessages = defineMessages({
  DeleteFromDraftSuccess: {
    id: 'File.Page.DeleteFromDraftSuccess'
  },
  DeleteFromDraftFail: {
    id: 'File.Page.DeleteFromDraftFail'
  },
  DeleteConfirm: {
    id: 'File.DeletePageFromHomeworkConfirmText'
  }
});
