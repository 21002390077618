import React from 'react';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

interface Props {
  hasGrammar?: boolean;
  isInGrammarPlayer?: boolean;
  goToGrammarPlayer: () => void;
  toggleDropdown: (show: boolean) => void;
  elementHeight: number;
}

export const GrammarBankItem: React.FC<Props> = ({
  hasGrammar,
  isInGrammarPlayer,
  goToGrammarPlayer,
  toggleDropdown,
  elementHeight
}) => {
  return hasGrammar || isInGrammarPlayer ? (
    <MenuItem
      key="grammar-menu-item"
      style={{height: elementHeight}}
      className={classNames({
        selected: isInGrammarPlayer
      })}
      onClick={e => {
        e.stopPropagation();
        !isInGrammarPlayer ? goToGrammarPlayer() : void 0;
        toggleDropdown(false);
      }}
    >
      <Icon name="pc-course" tag="i" />
      <span className="breadcrumb-dropdown-title">
        <FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />
      </span>
    </MenuItem>
  ) : null;
};
