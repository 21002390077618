import React from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import {maxFilesSearchResultNumber} from 'config/static';

interface UploadTabProps {
  uploadsNumber: number;
  searchResultsNumber?: number;
  collapsed?: boolean;
}

export class ThirdTab extends React.PureComponent<UploadTabProps, {}> {
  public render() {
    return (
      <div className="lesson-upload-tab">
        <Icon name="virc-upload" size="lg" />
        {this.renderUploadsNumberCircle()}
        {!this.props.collapsed && (
          <span className="lesson-tab-text">
            <FormattedMessage id="Common.Uploads" />
          </span>
        )}
        {this.renderSearchResultsNumber()}
      </div>
    );
  }

  private renderUploadsNumberCircle = () => {
    if (this.props.uploadsNumber > 0) {
      return (
        <div className="uploads-number-circle">
          <span>{this.props.uploadsNumber}</span>
        </div>
      );
    }
    return null;
  };

  private renderSearchResultsNumber = () => {
    const {searchResultsNumber} = this.props;
    if (searchResultsNumber !== undefined) {
      const numberToDisplay =
        searchResultsNumber <= maxFilesSearchResultNumber
          ? searchResultsNumber
          : `${maxFilesSearchResultNumber}+`;
      return <span className="lesson-tab-text">({numberToDisplay})</span>;
    }
    return null;
  };
}
