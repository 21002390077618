import React from 'react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import {formatSecondsToString} from 'services/common-methods/secondsToString';
import {ActionsTogether} from 'common/enums';

import GenericFileControl, {
  type GenericFileControlProps,
  type GenericFileControlState
} from '../GenericFileControl';
import FileEditDropdown from '../FileEditDropdown';
import {LOADING, PAUSE, PLAYING, type Sound, STOPPED} from '../../../soundsTab/actions/interface';
import AudioSlider from '../../../soundsTab/components/AudioSlider';
import TimeDisplay from './TimeDisplay';
import RightIcons from '../RightIcons';
import PlayIcon from './PlayIcon';
import {
  type ActionTogetherProps,
  AudioTogetherContext
} from '../../../context/AudioTogetherContext';
import {audioMessages} from '../../../messages';

import {
  type AudioFileControlDispatchProps,
  type AudioFileControlOwnProps,
  type AudioFileControlStateProps
} from './index';

interface Props
  extends AudioFileControlStateProps,
    AudioFileControlDispatchProps,
    AudioFileControlOwnProps,
    GenericFileControlProps {
  thisFile?: Sound;
}

interface State extends GenericFileControlState {
  activeAudioComponent?: boolean;
}

class AudioFileControl extends GenericFileControl<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps, context: {}) {
    super(props, context);
    (this.state as State).activeAudioComponent = this.props.uniquePlaybackId === this.componentId;
  }

  static contextType = AudioTogetherContext;
  public declare context: ActionTogetherProps;

  public componentDidUpdate(prevProps: Props & WrappedComponentProps) {
    super.componentDidUpdate(prevProps);
    if (this.props.uniquePlaybackId !== this.componentId && this.state.activeAudioComponent) {
      this.setState({activeAudioComponent: false});
    }
    if (this.props.uniquePlaybackId === this.componentId && !this.state.activeAudioComponent) {
      this.setState({activeAudioComponent: true});
    }
  }

  public render() {
    const {
      role,
      readonly,
      editFileAwait,
      fileIsBeingEdited,
      intl,
      thisFileId,
      thisFile,
      playStatus,
      deleteFileAwait,
      pinnedBlock
    } = this.props;
    if (!thisFile) {
      return null;
    }
    const {nameIsValid} = this.state;
    const className = classNames(`fc-${role}`, {
      'active-file-control': this.componentIsActive(),
      'edited-file-control': fileIsBeingEdited && this.state.componentBeingEdited,
      'invalid-name': !nameIsValid,
      pinned: thisFile.pinned
    });

    const {isTogether} = this.context;

    return (
      <li className={className} onClick={this.handleAudioFileControlClick}>
        {this.renderNotificationCircle()}
        <PlayIcon
          className={isTogether ? 'together' : ''}
          handlePauseIconClick={this.handlePauseIconClick}
          handlePlayIconClick={this.handlePlayIconClick}
          fileIsBeingEdited={fileIsBeingEdited && this.state.componentBeingEdited}
          renderPauseIcon={
            !!this.componentIsActive() && (playStatus === PLAYING || playStatus === LOADING)
          }
          intl={intl}
        />
        {this.renderStopIcon()}
        <div className="file-control-middle">
          {this.renderFileName()}
          {this.renderEditor()}
          {this.renderFileEditDropdown()}
          <div className="loading-duration-block">
            {this.renderLoading()}
            {this.renderDuration()}
          </div>
          {this.componentIsActive() ? (
            <AudioSlider isActive={true} fieldId={thisFileId} isTogether={isTogether} />
          ) : null}
        </div>
        <RightIcons
          fileExt={thisFile.type}
          readonly={readonly}
          fileIsBeingEdited={fileIsBeingEdited}
          componentBeingEdited={this.state.componentBeingEdited}
          editFileAwait={editFileAwait}
          deleteFileAwait={deleteFileAwait}
          fileId={thisFileId}
          fileType="audio"
          pinnedBlock={pinnedBlock}
          saveEditingChanges={this.saveEditingChanges}
          discardEditingChanges={this.discardEditingChanges}
          intl={intl}
        />
        {this.renderDeletingOverlay()}
      </li>
    );
  }

  private handleAudioFileControlClick = (e: React.MouseEvent<HTMLLIElement>) => {
    this.handleControlClick(e);

    if (this.context.isTogether && !this.state.activeAudioComponent) {
      this.context.actionTogether(ActionsTogether.Play, {
        fileId: this.props.thisFileId,
        uniquePlaybackId: this.componentId,
        playbackRate: this.props.playbackRate,
        timestamp: 0
      });
    }
  };

  protected openFile() {
    if (this.props.uniquePlaybackId !== this.componentId) {
      this.props.playSound(this.componentId);
      this.setState({activeAudioComponent: true});
      return;
    }

    if (this.props.playStatus !== 'PLAYING') {
      this.props.changePlayStatus('PLAYING');
      this.setState({activeAudioComponent: true});
    }
  }

  private renderFileEditDropdown = () => (
    <FileEditDropdown
      editIconClickHandler={this.editIconClickHandler}
      deleteFileAwait={this.props.deleteFileAwait}
      fileIsBeingEdited={this.props.fileIsBeingEdited}
      fileIsActive={this.props.fileIsActive}
      thisFileId={this.props.thisFileId}
      readonly={this.props.readonly}
      thisFile={this.props.thisFile!}
      fileType="audio"
      role={this.props.role}
      pinnedBlock={this.props.pinnedBlock}
      controlComponentId={this.componentId}
    />
  );

  private renderDuration = () =>
    !this.props.fileIsBeingEdited ? (
      <span className="sound-duration">
        {this.componentIsActive() ? <TimeDisplay /> : null}
        {formatSecondsToString(this.props.thisFile!.length)}
      </span>
    ) : null;

  private handlePlayIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (this.context.isTogether) {
      return this.context.actionTogether(ActionsTogether.Play, {
        fileId: this.props.thisFileId,
        uniquePlaybackId: this.componentId,
        playbackRate: this.props.playbackRate,
        timestamp: this.state.activeAudioComponent ? this.context.getCurrentTime() : 0
      });
    }

    if (!this.props.deleteFileAwait) {
      this.openFile();
    }
  };

  private handlePauseIconClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (this.context.isTogether) {
      return this.context.actionTogether(ActionsTogether.Pause, {
        fileId: this.props.thisFileId,
        uniquePlaybackId: this.componentId
      });
    }

    this.props.changePlayStatus(PAUSE);
  };

  private renderStopIcon = () => {
    const {isTogether} = this.context;
    const {playStatus, intl} = this.props;
    const {componentBeingEdited} = this.state;
    return this.componentIsActive() && playStatus !== STOPPED && !componentBeingEdited ? (
      <div
        className={classNames('file-control-left', {together: isTogether})}
        title={intl.formatMessage(audioMessages.StopButtonMessage)}
      >
        <Icon name="stop" size="lg" onClick={this.stopIconClickHandler} />
      </div>
    ) : null;
  };

  private stopIconClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (this.context.isTogether) {
      return this.context.actionTogether(ActionsTogether.Stop, {
        fileId: this.props.thisFileId,
        uniquePlaybackId: this.componentId
      });
    }

    this.props.changePlayStatus(STOPPED);
  };

  private renderLoading = () =>
    this.componentIsActive() && this.props.playStatus === LOADING ? <Spinner size={22} /> : null;

  private componentIsActive = () => this.props.fileIsActive && this.state.activeAudioComponent;
}

export default injectIntl(AudioFileControl);
