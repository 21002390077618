import {type Action} from 'redux';

import {type HomeworkPlayerState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  CLEAR_HOMEWORK_PLAYER,
  SET_SELECTED_HOMEWORK
} from '../pages/HomeworkPlayerPage/actionTypes';
import {type SetSelectedHomeworkAction} from '../pages/HomeworkPlayerPage/actions';

const ACTION_HANDLERS: ActionHandlersList<HomeworkPlayerState, Action> = {
  [SET_SELECTED_HOMEWORK]: (
    state: HomeworkPlayerState,
    {homework}: SetSelectedHomeworkAction
  ): HomeworkPlayerState => ({
    ...state,
    selectedHomework: homework
  }),
  [CLEAR_HOMEWORK_PLAYER]: (): HomeworkPlayerState => homeworkPlayerDefaultState
};

export const homeworkPlayerDefaultState: HomeworkPlayerState = {};

export default function (action: Action) {
  return ACTION_HANDLERS[action.type] || undefined;
}
