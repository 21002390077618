import React from 'react';
import {FormattedMessage} from 'react-intl';

import {type Role} from 'store/interface';

import Icon from '../../../../components/Icon';
import './HomeworkCard.scss';

interface Props {
  role: Role;
  draftCount: number;
  givenCount: number;
  done: number;
  checkedCount: number;
}

const HomeworkCard: React.FC<Props> = (props: Props) => {
  const {givenCount, role, checkedCount, draftCount, done} = props;
  const isStudent = role === 'student';
  return (
    <div className="homework-card">
      <h4>
        <Icon name="virc-homework" size="lg" />
      </h4>
      <h4>
        <FormattedMessage id="LessonPage.Homework" />
      </h4>

      <span className="counters">
        {isStudent && givenCount ? (
          <FormattedMessage id="Homework.Assigned" values={{count: givenCount}} />
        ) : null}
        {isStudent && checkedCount ? (
          <FormattedMessage id="Homework.Checked" values={{count: checkedCount}} />
        ) : null}
      </span>

      <span className="counters">
        {!isStudent && draftCount ? (
          <FormattedMessage id="Homework.NotAssigned" values={{count: draftCount}} />
        ) : null}
        {!isStudent && done ? (
          <FormattedMessage id="Homework.ToCheck" values={{count: done}} />
        ) : null}
      </span>
    </div>
  );
};

export default HomeworkCard;
