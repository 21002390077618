import {type LibraryItem, type LibraryNode, type LibrarySearchItem} from './interface';

function isLibraryItem(obj: LibraryNode): obj is LibraryItem {
  return !!obj && typeof obj === 'object' && !!(obj as LibraryItem).title;
}

export function isSearchLibraryItem(
  item: LibraryItem | LibrarySearchItem
): item is LibrarySearchItem {
  return Boolean((item as LibrarySearchItem).path);
}

function searchByTitle(node: LibraryItem, searchWords: string[], path: string[]) {
  return searchWords.every(word => {
    const isTitleInclude = node.title.toLowerCase().includes(word.toLowerCase());
    const isPathInclude = path.join('/').toLowerCase().includes(word.toLowerCase());
    return isTitleInclude || isPathInclude;
  });
}

export function searchTree(
  searchWords: string[],
  tree: LibraryNode,
  path: string[] = []
): LibrarySearchItem[] {
  if (isLibraryItem(tree)) {
    return searchByTitle(tree, searchWords, path) ? [{...tree, path}] : [];
  }

  if (Array.isArray(tree)) {
    return tree
      .filter(node => searchByTitle(node, searchWords, path))
      .map(item => ({...item, path}));
  }

  const keys = Object.keys(tree);
  const result: LibrarySearchItem[] = [];

  for (const key of keys) {
    const items = searchTree(searchWords, tree[key], path.concat(key));

    if (items?.length) {
      result.push(...items);
    }
  }

  return result;
}
