import React, {PureComponent} from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Scrollbars from 'react-custom-scrollbars';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import {type CoursebookInstance} from 'store/interface';
import Icon from 'components/Icon';

import CoursebookInstanceProgress from './CoursebookInstanceProgress';
import {coursebookInstanceBreadcrumbMessages} from './messages';
import './breadcrumbs-dropdown.scss';

interface Props extends WrappedComponentProps {
  dropdownIsOpen?: boolean;
  elements: CoursebookInstance[];
  toggleDropdown: (show: boolean) => void;
  goToCoursebookInstance: (id: string) => void;
  goToFilesList: () => void;
  isOnFilesList?: boolean;
  goToHomeworkPage: () => void;
  isOnHomeworkPage?: boolean;
  selectedCoursebookInstanceId?: string;
}

class CoursebookInstancesDropdown extends PureComponent<Props> {
  private static maxDisplayedElements: number = 4.5;
  private static elementHeight: number = 44;
  private static elementWidth: number = 176;
  private static specialElementsNumber = 2;

  private activeElements: CoursebookInstance[] | null = null;
  private completedElements: CoursebookInstance[] | null = null;

  private get activeCoursebooks(): CoursebookInstance[] {
    const {elements} = this.props;
    if (this.activeElements) {
      return this.activeElements;
    }
    this.activeElements = elements.filter(e => !e.completedAt).sort(this.sortByAlphabet);
    return this.activeElements;
  }

  private get completedCoursebooks(): CoursebookInstance[] {
    const {elements} = this.props;
    if (this.completedElements) {
      return this.completedElements;
    }
    this.completedElements = elements.filter(e => e.completedAt).sort(this.sortByAlphabet);
    return this.completedElements;
  }

  public componentDidUpdate({elements: prevElements}: Props) {
    const {elements} = this.props;
    if (prevElements !== elements) {
      this.activeElements = null;
      this.completedElements = null;
    }
  }

  public render() {
    const {
      dropdownIsOpen,
      intl: {formatMessage},
      isOnFilesList,
      isOnHomeworkPage
    } = this.props;
    return (
      <div className="breadcrumbs-dropdown">
        <DropdownButton
          id="coursebook-instances-dropdown"
          title={<Icon name="caret-down" />}
          open={dropdownIsOpen}
          onToggle={this.toggleDropDownButton}
          noCaret={true}
        >
          <Scrollbars
            style={{
              width: CoursebookInstancesDropdown.elementWidth,
              height: this.getScrollbarHeight()
            }}
            autoHide={true}
            title=""
          >
            {this.activeCoursebooks.map(this.renderMenuItem)}

            <MenuItem
              style={{height: CoursebookInstancesDropdown.elementHeight}}
              onClick={this.onFilesElClick}
              className={classNames({selected: isOnFilesList})}
            >
              <span className="item-image">
                <Icon name="virc-docs" />
              </span>
              <span>{formatMessage(coursebookInstanceBreadcrumbMessages.Files)}</span>
            </MenuItem>

            <MenuItem
              style={{height: CoursebookInstancesDropdown.elementHeight}}
              onClick={this.onHomeworkElClick}
              className={classNames({selected: isOnHomeworkPage})}
            >
              <span className="item-image">
                <Icon name="virc-homework" />
              </span>
              <span>{formatMessage(coursebookInstanceBreadcrumbMessages.Homework)}</span>
            </MenuItem>

            {this.completedCoursebooks.map(this.renderMenuItem)}
          </Scrollbars>
        </DropdownButton>
      </div>
    );
  }

  private onFilesElClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.toggleDropdown(false);
    this.props.goToFilesList();
  };

  private onHomeworkElClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.toggleDropdown(false);
    this.props.goToHomeworkPage();
  };

  private renderMenuItem = (coursebookInstance: CoursebookInstance) => (
    <MenuItem
      key={coursebookInstance.id}
      style={{height: CoursebookInstancesDropdown.elementHeight}}
      onClick={e => {
        e.stopPropagation();
        this.props.goToCoursebookInstance(coursebookInstance.id);
        this.props.toggleDropdown(false);
      }}
      className={classNames({
        selected: coursebookInstance.id === this.props.selectedCoursebookInstanceId,
        closed: coursebookInstance.completedAt
      })}
      title={coursebookInstance.coursebook.title}
    >
      <span className="item-image">
        <CoursebookInstanceProgress instance={coursebookInstance} />
      </span>
      <span>{coursebookInstance.coursebook.title}</span>
    </MenuItem>
  );

  private sortByAlphabet = (cbi1: CoursebookInstance, cbi2: CoursebookInstance) => {
    return cbi1.coursebook.title > cbi2.coursebook.title ? 1 : -1;
  };

  private getScrollbarHeight = () => {
    const elementsNumber =
      this.props.elements.length + CoursebookInstancesDropdown.specialElementsNumber;
    if (elementsNumber > CoursebookInstancesDropdown.maxDisplayedElements) {
      return (
        CoursebookInstancesDropdown.elementHeight * CoursebookInstancesDropdown.maxDisplayedElements
      );
    }
    return CoursebookInstancesDropdown.elementHeight * elementsNumber;
  };

  private toggleDropDownButton = () => {
    this.props.toggleDropdown(!this.props.dropdownIsOpen);
  };
}

export default injectIntl(CoursebookInstancesDropdown);
