import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Scrollbars from 'react-custom-scrollbars';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {type UnitInstance} from 'store/interface';

import UnitInstanceProgress from './UnitInstanceProgress';
import {GrammarBankItem} from './GrammarBankItem';

interface Props {
  elements: UnitInstance[];
  dropdownIsOpen?: true;
  toggleDropdown: (show: boolean) => void;
  selectedUnitInstanceId?: number;
  goToUnitInstance: (id: number) => void;
  goToUnitPreview: (id: number) => void;
  goToGrammarPlayer: () => void;
  isInGrammarPlayer?: boolean;
  hasGrammar?: boolean;
}

class UnitInstancesDropdown extends React.Component<Props> {
  private static maxDisplayedElements: number = 4.5;
  private static elementHeight: number = 44;
  private static elementWidth: number = 176;
  private static specialElementsNumber = 0;

  public render() {
    const {
      dropdownIsOpen,
      elements,
      goToGrammarPlayer,
      hasGrammar,
      isInGrammarPlayer,
      toggleDropdown
    } = this.props;
    return (
      <div className="breadcrumbs-dropdown">
        <DropdownButton
          id="coursebook-instances-dropdown"
          title={<Icon name="caret-down" />}
          open={dropdownIsOpen}
          onToggle={this.toggleDropDownButton}
          noCaret={true}
        >
          <Scrollbars
            style={{
              width: UnitInstancesDropdown.elementWidth,
              height: this.getScrollbarHeight()
            }}
            autoHide={true}
            title=""
          >
            <GrammarBankItem
              elementHeight={UnitInstancesDropdown.elementHeight}
              goToGrammarPlayer={goToGrammarPlayer}
              hasGrammar={hasGrammar}
              isInGrammarPlayer={isInGrammarPlayer}
              toggleDropdown={toggleDropdown}
            />
            {elements.map(this.renderMenuItem)}
          </Scrollbars>
        </DropdownButton>
      </div>
    );
  }

  private renderMenuItem = (ui: UnitInstance) => {
    const {goToUnitInstance, goToUnitPreview, selectedUnitInstanceId, toggleDropdown} = this.props;
    const prefix = ui.ordinal !== null ? `${ui.ordinal}. ` : '';
    return (
      <MenuItem
        key={ui.id}
        style={{height: UnitInstancesDropdown.elementHeight}}
        className={classNames({
          selected: ui.id === selectedUnitInstanceId,
          closed: !ui.active
        })}
        onClick={e => {
          e.stopPropagation();
          ui.active ? goToUnitInstance(ui.id) : goToUnitPreview(ui.id);
          toggleDropdown(false);
        }}
        title={ui.unit.title}
      >
        {this.renderUnitStatus(ui)}
        <span className="breadcrumb-dropdown-title">
          {prefix}
          {ui.unit.title}
        </span>
      </MenuItem>
    );
  };

  private renderUnitStatus = (ui: UnitInstance) => {
    if (ui.completedAt) {
      return <Icon name="enlx-check" />;
    }
    if (!ui.active) {
      return <Icon name="lock" />;
    }
    return <UnitInstanceProgress instance={ui} />;
  };

  private getScrollbarHeight = () => {
    const elementsNumber = this.props.elements.length + UnitInstancesDropdown.specialElementsNumber;
    if (elementsNumber > UnitInstancesDropdown.maxDisplayedElements) {
      return UnitInstancesDropdown.elementHeight * UnitInstancesDropdown.maxDisplayedElements;
    }
    return UnitInstancesDropdown.elementHeight * elementsNumber;
  };

  private toggleDropDownButton = () => {
    this.props.toggleDropdown(!this.props.dropdownIsOpen);
  };
}

export default UnitInstancesDropdown;
