import type React from 'react';
import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type AxiosAction, type Dispatch} from 'redux-axios-middleware';
import {persistReducer} from 'redux-persist';

import injectReducer from 'store/injectReducer';
import {type AppState, type SoundsState} from 'store/interface';
import {persistAudioConfig, persistDocsConfig} from 'store/persist';
import {type MaterialsTab} from 'common/enums';

import {toggleVideoUndocked} from '../../../../webRTC/action/action';
import {openMaterialsTab, resetFilesWorkspace, toggleMaterialsSearchBar} from './action';
import {
  type DispatchWorkSpaceProps,
  type WorkSpaceOwnProps,
  type WorkSpaceStateProps
} from './components/interface';
import WorkSpace from './components/WorkSpace';
import {documentViewed, loadDocuments, resetDocuments} from './documentsTab/actions/action';
import docsReducer from './documentsTab/reducers/docsReducer';
import filesWorkSpaceReducer from './reducer';
import {loadSounds, playSound, resetSounds} from './soundsTab/actions/action';
import soundsReducer from './soundsTab/reducers/soundsReducer';
import {uploadsReset} from './uploadingFiles/actions/action';
import {getSearchResultsNumber} from './utils';
import {getRecentDocuments, getRecentSounds} from '../../actions/action';

const getUploadsNumber = (state: AppState) => {
  const uploadingSounds = state.uploads.uploadingSounds;
  const uploadingDocuments = state.uploads.uploadingDocuments;
  return (
    Object.keys(uploadingSounds).filter(
      sound => uploadingSounds[sound].uploadingStatus !== 'uploaded'
    ).length +
    Object.keys(uploadingDocuments).filter(
      doc => uploadingDocuments[doc].uploadingStatus !== 'uploaded'
    ).length
  );
};

const mapStateToProps = (state: AppState, ownProps: WorkSpaceOwnProps): WorkSpaceStateProps => {
  const documents =
    state.docs!.documents ||
    (ownProps.homeworkDoc && {[ownProps.homeworkDoc.id]: ownProps.homeworkDoc});
  let uploadsSearchResultsNum: number | undefined;
  if (state.filesWorkSpace!.filesFilter.length > 0) {
    uploadsSearchResultsNum = getSearchResultsNumber(
      state.uploads.uploadingSounds,
      state.filesWorkSpace!.filesFilter
    );
    uploadsSearchResultsNum += getSearchResultsNumber(
      state.uploads.uploadingDocuments,
      state.filesWorkSpace!.filesFilter
    );
    uploadsSearchResultsNum += getSearchResultsNumber(
      state.uploads.recentDocuments,
      state.filesWorkSpace!.filesFilter
    );
    uploadsSearchResultsNum += getSearchResultsNumber(
      state.uploads.recentSounds,
      state.filesWorkSpace!.filesFilter
    );
  }

  return {
    soundsLoaded: !!state.sounds!.sounds,
    documents,
    uploadsNumber: getUploadsNumber(state),
    userId: state.user.id!,
    uploadsSearchResultsNum,
    callInProgress: !!state.rtc.call!,
    videoUndocked: state.video.undocked,
    searchBarExpanded: state.filesWorkSpace!.searchBarOpen,
    role: state.user.role!,
    appOnline: state.layout!.appOnline,
    searchParam: state.router.location!.search
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchWorkSpaceProps => ({
  loadSounds: (courseId: number) => dispatch(loadSounds(courseId) as AxiosAction).catch(() => null),
  loadDocuments: (courseId: number) => dispatch(loadDocuments(courseId)).catch(() => null),
  toggleVideoUndocked: (show: boolean) => dispatch(toggleVideoUndocked(show)),
  openMaterialsSearchBar: () => dispatch(toggleMaterialsSearchBar(true)),
  getRecentDocuments: () => dispatch(getRecentDocuments()),
  getRecentSounds: () => dispatch(getRecentSounds()),
  resetFiles: () => {
    dispatch(resetSounds());
    dispatch(resetDocuments());
    dispatch(uploadsReset());
    dispatch(resetFilesWorkspace());
  },
  playSound: (id: string) => dispatch(playSound(id)),
  documentViewed: (id: number) => dispatch(documentViewed(id)),
  openTab: (tab: MaterialsTab) => dispatch(openMaterialsTab(tab))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(WorkSpace);
const ReduxConnectedComponent: React.ComponentType<WorkSpaceOwnProps> = Connected;
const WorkSpaceContainer = injectReducer({
  filesWorkSpace: filesWorkSpaceReducer,
  sounds: persistReducer<SoundsState>(persistAudioConfig, soundsReducer),
  docs: persistReducer(persistDocsConfig, docsReducer)
})(ReduxConnectedComponent);

export default WorkSpaceContainer;
