import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {type AppState, type CoursebookInstance, type CourseInstanceOverview} from 'store/interface';
import {
  type ClassroomUrlParams,
  coursebookInstancePath,
  filesListPath,
  homeworkPath
} from 'common/paths';

import Card from './Card';
import CoursebookInstanceCardContent from './CoursebookInstanceCardContent';
import HomeworkCard from './HomeworkCard';
import FilesCard from './FilesCard';
import './Cards.scss';

interface Props {
  courseInstanceIsActive: boolean;
  coursebookInstanceList: CoursebookInstance[];
  urlParams: ClassroomUrlParams;
  overview: CourseInstanceOverview;
}

const Cards: React.FC<Props> = (props: Props) => {
  const {
    courseInstanceIsActive,
    coursebookInstanceList,
    urlParams,
    overview: {
      fileAudioAmount,
      fileDocumentAmount,
      homeworkDraft,
      homeworkGiven,
      homeworkChecked,
      homeworkDone
    }
  } = props;
  const role = useSelector((state: AppState) => state.user.role)!;
  const isStudent = role === 'student';
  const sortByAlphabet = useCallback(
    (cb1: CoursebookInstance, cb2: CoursebookInstance) =>
      cb1.coursebook.title.toLowerCase() > cb2.coursebook.title.toLowerCase() ? 1 : -1,
    []
  );
  const activeCoursebookInstances = coursebookInstanceList
    .filter(cbi => !cbi.completedAt)
    .sort(sortByAlphabet);

  const inactiveCoursebookInstances = coursebookInstanceList
    .filter(cbi => cbi.completedAt)
    .sort(sortByAlphabet);

  return (
    <div className="course-instance-page-cards">
      {activeCoursebookInstances.map(cbi => (
        <Card key={cbi.id} redirectsTo={coursebookInstancePath(urlParams, cbi.id)}>
          <CoursebookInstanceCardContent
            coursebookInstance={cbi}
            courseInstanceIsActive={courseInstanceIsActive}
            role={role}
          />
        </Card>
      ))}

      <Card redirectsTo={homeworkPath(urlParams.studentTeacherId!, urlParams.courseId!)}>
        <HomeworkCard
          draftCount={homeworkDraft}
          givenCount={homeworkGiven}
          checkedCount={homeworkChecked}
          done={homeworkDone}
          role={role}
        />
      </Card>

      {fileDocumentAmount + fileAudioAmount > 0 || !isStudent ? (
        <Card redirectsTo={filesListPath(urlParams.studentTeacherId!, urlParams.courseId!)}>
          <FilesCard documentsCount={fileDocumentAmount} soundsCount={fileAudioAmount} />
        </Card>
      ) : null}

      {inactiveCoursebookInstances.map(cbi => (
        <Card key={cbi.id} redirectsTo={coursebookInstancePath(urlParams, cbi.id)}>
          <CoursebookInstanceCardContent
            coursebookInstance={cbi}
            courseInstanceIsActive={courseInstanceIsActive}
            role={role}
          />
        </Card>
      ))}
    </div>
  );
};

export default Cards;
