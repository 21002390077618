import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';

import {type FileType} from '../../../../actions/interface';
import SoundsList from '../../components/FilesList';
import {
  type DispatchFilesListProps,
  type FilesListOwnProps,
  type FilesListStateProps
} from '../../components/interface';
import {changeActiveSound, loadSounds, resetSounds} from '../actions/action';
import {removeFileFromUploads} from '../../uploadingFiles/actions/action';
import {publishMaterialsOpen, publishMaterialsUpdated} from '../../../../actions/action';

const listIsEmpty = (state: AppState, courseId?: string) => {
  const sounds = state.sounds!.sounds;
  const uploadingSounds = state.uploads.uploadingSounds;
  const uploadingCount = Object.keys(uploadingSounds).filter(
    id => uploadingSounds[id].course_instance_id.toString() === courseId
  ).length;
  return sounds && uploadingCount === 0 && Object.keys(sounds).length === 0;
};

const mapStateToProps = (state: AppState, {courseId}: FilesListOwnProps): FilesListStateProps => {
  return {
    locale: state.intl.locale,
    files: state.sounds!.sounds,
    filter: state.filesWorkSpace!.filesFilter,
    fileType: 'audio',
    role: state.user.role!,
    userId: state.user.id,
    userTimezone: state.user.profile!.timeZone,
    uploadingFiles: state.uploads.uploadingSounds,
    listIsEmpty: listIsEmpty(state, courseId),
    networkError: state.sounds!.networkError
  } as FilesListStateProps;
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {courseId}: FilesListOwnProps
): DispatchFilesListProps => ({
  changeActiveFile: (id: number) => dispatch(changeActiveSound(id)),
  removeFileFromUploads: (md5: string, id?: string) => dispatch(removeFileFromUploads(md5, id)),
  reloadFiles: () => {
    dispatch(resetSounds());
    dispatch(loadSounds(Number(courseId)));
  },
  publishMaterialsUpdated: () => {
    if (courseId) {
      dispatch(publishMaterialsUpdated(Number(courseId)));
    }
  },
  publishMaterialsOpen: (fileId: number, uniquePlaybackId?: string) => {
    if (courseId) {
      const context = {type: 'audio' as FileType, fileId, uniquePlaybackId};
      dispatch(publishMaterialsOpen(Number(courseId), context));
    }
  }
});

export default connect<FilesListStateProps, DispatchFilesListProps, FilesListOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(SoundsList);
