import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {push} from 'store/router';
import {type AppState, type UnitInstance} from 'store/interface';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import PageControls from 'components/PageControls';
import {useContentsList} from 'components/UnitContents/useContenstList';
import {Pager} from 'components/UnitContents/Pager';
import {Contents} from 'components/UnitContents/Contents/Contents';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import {usePagesPopoverSync} from 'hooks/player/usePagesPopoverSync';
import {clearRedirectedFromUrl, setLastUnitPage} from 'common/action';
import {IntroRecord} from 'store/intro/IntroRecord';
import {UnitContentItems} from 'components/modals/UnitPreview/UnitContentItems';
import {type UnitContents, type UnitInstanceContents} from 'components/UnitContents/interface';
import {unitInstancePagePath, unitInstancePath, type UnitInstanceUrlParams} from 'common/paths';

import XPlayerPage from '../XPlayerPage/XPlayerPage';
import HomeworkDraftControl from '../../components/HomeworkDraftControl/HomeworkDraftControl';
import BackToRedirected from './BackToRedirected';
import DisplayButton from '../../components/DisplayButton';
import {useFilesTab} from '../../hooks/useFilesTab';
import {setHasGrammar} from '../../actions/action';
import {requestUnitInstance} from './actions';
import {XIntroPreview} from '../../../../components/XIntroPreview';

import './UnitInstancePage.scss';

const getUnitPageUri = (params: UnitInstanceUrlParams, pageNumber: number = 1) =>
  pageNumber
    ? unitInstancePagePath(params, params.unitInstanceId, pageNumber)
    : unitInstancePath(params, params.unitInstanceId);

export const UnitInstancePage: FC = () => {
  const params = useParams<UnitInstanceUrlParams>() as UnitInstanceUrlParams;
  const {studentTeacherId, unitInstanceId, page} = params;
  const [unitInstance, setUnitInstance] = useState<UnitInstance | null>(null);

  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');

  const isOnUploadsTab = useFilesTab();
  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  const pageCount = unitInstance?.exerciseInstances.reduce(
    (maxPageNumber, exerciseInstance) => Math.max(maxPageNumber, exerciseInstance.pageNumber),
    0
  );

  const pageNumber = Number(page);
  const haveIntro = !!unitInstance?.unit.intro;
  const isIntroPreview = Boolean(!page && haveIntro);
  const isExercisePreview = !isIntroPreview;
  const showDisplayButton = canOpenTogether && !isOnUploadsTab;

  const intro = useMemo(
    () => (isIntroPreview ? new IntroRecord(unitInstance!.unit.intro!) : null),
    [isIntroPreview, unitInstance]
  );

  const contentListOptions = useMemo(
    () => ({type: 'instance' as 'instance', unitId: Number(unitInstanceId)}),
    [unitInstanceId]
  );

  const {
    batchListToggle,
    contents,
    hasNext,
    hasPrev,
    listIsOpened,
    listToggleBatched,
    loading,
    toggleList
  } = useContentsList(pageCount, pageNumber, contentListOptions);
  usePagesPopoverSync(listIsOpened);

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearRedirectedFromUrl());
    },
    [unitInstanceId, dispatch]
  );

  useEffect(() => {
    dispatch(setLastUnitPage(unitInstanceId!, pageNumber));
  }, [dispatch, unitInstanceId, pageNumber]);

  const requestUnitInstanceAction = useMemo(() => {
    setUnitInstance(null);
    return requestUnitInstance(Number(unitInstanceId));
  }, [unitInstanceId]);

  const {isLoading, isError, reload} = useApiRequest(
    requestUnitInstanceAction,
    (response: UnitInstance) => {
      dispatch(setHasGrammar(!!response.coursebookInstance?.hasGrammar));
      setUnitInstance(response);
    }
  );

  const selectPage = useCallback(
    (page: number) => dispatch(push(getUnitPageUri(params, page))),
    [dispatch, params]
  );

  const onStart = useCallback(() => dispatch(push(getUnitPageUri(params, 1))), [dispatch, params]);

  const onView = useCallback(
    () => (listIsOpened ? batchListToggle() : toggleList()),
    [batchListToggle, listIsOpened, toggleList]
  );

  const showSupplementaryModalSwitcher = useMemo<boolean | undefined>(() => {
    if (isStudent) {
      return undefined;
    }
    return !(unitInstance && (unitInstance.completedAt || unitInstance.active));
  }, [isStudent, unitInstance]);

  if (isError) {
    return <WampErrorMask reload={reload} />;
  }

  if (isLoading || unitInstance === null) {
    return <Loader />;
  }

  if (!page && !intro) {
    return <Navigate replace={true} to={getUnitPageUri(params, 1)} />;
  }

  if (pageNumber && (Number.isNaN(pageNumber) || pageNumber < 1)) {
    return <Navigate replace={true} to={getUnitPageUri(params, 1)} />;
  }

  if (pageCount && pageNumber > pageCount) {
    return <Navigate replace={true} to={getUnitPageUri(params, pageCount)} />;
  }

  return (
    <div className="unit-instance-page">
      {isIntroPreview ? (
        <XIntroPreview
          intro={intro!}
          unit={unitInstance.unit}
          ordinal={unitInstance.ordinal}
          onStart={onStart}
          onView={onView}
        />
      ) : (
        <XPlayerPage
          unitInstanceId={Number(unitInstanceId)}
          pageNumber={pageNumber}
          showSupplementaryModalSwitcher={showSupplementaryModalSwitcher}
          selfCheckEnabled={unitInstance.coursebookInstance?.selfCheckEnabled}
        />
      )}

      <Contents
        contents={contents}
        listIsOpened={listIsOpened}
        listToggleBatched={listToggleBatched}
        toggleList={toggleList}
        renderItems={closeSelf => (
          <UnitContentItems
            contents={contents as UnitInstanceContents | UnitContents}
            isIntro={Number.isNaN(pageNumber)}
            haveIntro={haveIntro}
            onSelectPage={selectPage}
            closeSelf={closeSelf}
            pageNumber={pageNumber}
          />
        )}
      />

      <PageControls>
        {isExercisePreview && (
          <>
            <Pager
              batchListToggle={batchListToggle}
              currentPage={pageNumber}
              hasNext={hasNext}
              hasPrev={hasPrev}
              listIsOpened={listIsOpened}
              loading={loading}
              pageCount={pageCount}
              selectPage={selectPage}
              toggleList={toggleList}
            />
            <HomeworkDraftControl renderDeleteAndViewButtons={true} renderActivateButton={true} />
            <BackToRedirected />
          </>
        )}

        {showDisplayButton && <DisplayButton />}
      </PageControls>
    </div>
  );
};
