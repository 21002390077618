import React, {type FC, memo, useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {Resources} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import XPreview from 'components/XPreview/XPreview';
import {type UnitPreviewProps} from 'components/modals/UnitPreview/UnitPreviewTypes';
import Loader from 'components/Loader';
import PageControls from 'components/PageControls';
import ExtraExercisesPreview from 'components/modals/UnitPreview/ExtraExercisesPreview';
import {Pager} from 'components/UnitContents/Pager';
import {useContentsList} from 'components/UnitContents/useContenstList';
import {Contents} from 'components/UnitContents/Contents/Contents';
import {type UnitContents, type UnitInstanceContents} from 'components/UnitContents/interface';
import {IntroRecord} from 'store/intro/IntroRecord';
import {UnitContentItems} from 'components/modals/UnitPreview/UnitContentItems';

import SupplementaryExercisesModal from './SupplementaryExerciseModal';
import AddUnitButton from './AddUnitButton';
import BackToRedirected from '../UnitInstancePage/BackToRedirected';
import {XIntroPreview} from '../../../../components/XIntroPreview';
import {type UnitInstancePreviewUrlParams} from '../../../../common/paths';

const UnitInstancePreview: FC<UnitPreviewProps> = memo(
  ({
    isLoading,
    xpreview,
    changeRole,
    pageCount,
    pageNumber,
    selectPage,
    mainId,
    coursebookId,
    unitId,
    courseInstanceIsActive,
    scrollToExerciseId,
    unit
  }) => {
    const {page} = useParams() as UnitInstancePreviewUrlParams;

    const routePageNumber = page !== undefined ? Number(page) : undefined;
    const intro = useMemo(() => (unit?.intro ? new IntroRecord(unit.intro) : null), [unit]);

    const isIntroPreview = routePageNumber === undefined && !!intro;

    const previewRole = useMemo(() => xpreview?.role, [xpreview]);

    const contentListOptions = useMemo(
      () => ({
        type: 'original' as 'original',
        unitId,
        coursebookId,
        forStudent: previewRole === 'student'
      }),
      [coursebookId, previewRole, unitId]
    );
    const {
      batchListToggle,
      contents,
      hasNext,
      hasPrev,
      listIsOpened,
      listToggleBatched,
      loading,
      toggleList
    } = useContentsList(pageCount, pageNumber, contentListOptions);

    const resourcesNode = useNode('.page-left');

    const onStart = useCallback(() => selectPage(1), [selectPage]);

    const onView = useCallback(
      () => (listIsOpened ? batchListToggle() : toggleList()),
      [batchListToggle, listIsOpened, toggleList]
    );

    const shouldHideAddUnitButton = !pageCount || courseInstanceIsActive === false;

    return (
      <>
        <Resources id="x-player-page" node={resourcesNode}>
          {isLoading ? (
            <Loader />
          ) : isIntroPreview ? (
            <XIntroPreview intro={intro} unit={unit!} onStart={onStart} onView={onView} />
          ) : (
            <XPreview
              xpreview={xpreview!}
              changeRole={changeRole}
              pageNumber={pageNumber}
              scrollToExerciseId={scrollToExerciseId}
              isModal={true}
            />
          )}
        </Resources>

        <Contents
          contents={contents}
          listIsOpened={listIsOpened}
          listToggleBatched={listToggleBatched}
          toggleList={toggleList}
          renderItems={closeSelf => (
            <UnitContentItems
              contents={contents as UnitInstanceContents | UnitContents}
              isIntro={isIntroPreview}
              haveIntro={!!intro}
              onSelectPage={selectPage}
              closeSelf={closeSelf}
              pageNumber={pageNumber}
            />
          )}
        />

        {!isIntroPreview && (
          <>
            <PageControls>
              <Pager
                batchListToggle={batchListToggle}
                currentPage={pageNumber!}
                pageCount={pageCount}
                hasNext={hasNext}
                hasPrev={hasPrev}
                listIsOpened={listIsOpened}
                loading={loading}
                selectPage={selectPage}
                toggleList={toggleList}
              />
              {shouldHideAddUnitButton ? null : <AddUnitButton />}
              <BackToRedirected />
            </PageControls>
            <SupplementaryExercisesModal show={!!mainId}>
              {mainId && (
                <ExtraExercisesPreview
                  coursebookId={coursebookId}
                  unitId={unitId}
                  mainId={mainId}
                />
              )}
            </SupplementaryExercisesModal>
          </>
        )}
      </>
    );
  }
);

UnitInstancePreview.displayName = 'UnitInstancePreview';

export default UnitInstancePreview;
