import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

import {changeBufferedEventName, changeCurrentTimeEventName, emitter} from 'services/event-emitter';
import {isSafari} from 'helpers/browser';
import {playMedia} from 'helpers/playMedia';

import {LOADING, PAUSE, PLAYING, STOPPED} from '../../actions/interface';

import {type AudioPlayerProps} from './index';

interface AudioEl {
  play: () => void;
  pause: () => void;
  load: () => void;
  duration: number;
  currentTime: number;
  volume?: number;
  onloadedmetadata?: () => void;
  onwaiting?: () => void;
  onplaying?: () => void;
  oncanplay?: () => void;
  buffered: {
    length: number;
    end: (index: number) => number;
  };
  playbackRate?: number;
}

interface TestAudioEl {
  audioEl: AudioEl;
}

interface State {
  awaitingPlayPromise: boolean;
}

export default class AudioPlayer extends React.Component<AudioPlayerProps, State> {
  public static emitFrequency: number = 400;
  public static timeBeforeStopped: number = 300;
  private static diffError: number = 0.8; // sometimes max buffering length and max play length of a track are different for some reason

  public state: State = {awaitingPlayPromise: false};

  private player: ReactAudioPlayer;
  private bufferInterval: NodeJS.Timeout;
  private playInterval: NodeJS.Timeout;
  private testAudioEl: TestAudioEl = {
    // this is used in tests, cus PhantomJS doesn't fully implement <audio> tag functionality
    audioEl: {
      play: () => null,
      pause: () => null,
      load: () => null,
      oncanplay: () => null,
      duration: 5,
      currentTime: 1,
      buffered: {
        length: 0,
        end: index => {
          const toReturn: number = 3;
          return toReturn;
        }
      }
    }
  };

  private tempTime: number = 0;

  private get audioEl(): HTMLMediaElement | null {
    return (this.audioPlayer() as ReactAudioPlayer)?.audioEl.current;
  }

  public componentWillUnmount(): void {
    this.clearPlayerIntervals();
  }

  public componentDidUpdate(prevProps: AudioPlayerProps) {
    if (this.audioPlayer()) {
      this.shouldLoad(prevProps);
      this.shouldPlay(prevProps);
      this.shouldPause(prevProps);
      this.shouldEmit(prevProps);
      this.shouldChangeTimestamp(prevProps);
      this.shouldChangePlaybackRate(prevProps);
      this.shouldChangeVolume();
    }
    this.shouldClearIntervals(prevProps);
  }

  public componentDidMount() {
    if (this.audioEl) {
      this.audioEl.volume = this.props.volume !== undefined ? this.props.volume : 1;
    }
  }

  public render(): JSX.Element | null {
    if (!this.props.activeSound) {
      return null;
    } else {
      return (
        <ReactAudioPlayer
          src={this.props.activeSound.href}
          ref={(el: ReactAudioPlayer) => {
            this.player = el;
            if (el) {
              el.clearListenTrack();
            }
          }}
          onEnded={this.onEndedHandler}
          onPause={this.onPauseHandler}
          preload="auto"
          controls={false}
          volume={this.props.volume}
        />
      );
    }
  }

  private playMedia = () => {
    if (this.audioPlayer()) {
      this.setState({awaitingPlayPromise: true});
      if (this.audioEl) {
        playMedia(this.audioEl).finally(() => {
          this.setState({awaitingPlayPromise: false});
          this.clearPlayerIntervals();
          this.setIntervals();
        });
      }
    }
  };

  private onEndedHandler = () => {
    if (this.audioEl) {
      emitter.emit(changeCurrentTimeEventName, this.audioEl.duration);
      setTimeout(() => {
        this.props.changePlayStatus(STOPPED);
        this.props.changeTimestamp(0);
      }, AudioPlayer.timeBeforeStopped);
    }
  };

  private onPauseHandler = () => {
    this.props.changePlayStatus(PAUSE);
  };

  private shouldChangeVolume() {
    if (this.audioEl && this.props.volume !== this.audioEl.volume) {
      this.audioEl.volume = this.props.volume;
    }
  }

  private shouldLoad(prevProps: AudioPlayerProps): void {
    const activeSoundWasChanged =
      this.props.uniquePlaybackId && prevProps.uniquePlaybackId !== this.props.uniquePlaybackId;
    const playStatusWasChanged: boolean =
      prevProps.playStatus === STOPPED && this.props.playStatus === PLAYING;
    if (activeSoundWasChanged || playStatusWasChanged) {
      this.props.changePlayStatus(LOADING);
      this.loadSound();
    }
  }

  private shouldPlay(prevProps: AudioPlayerProps): void {
    if (
      prevProps.playStatus !== STOPPED &&
      prevProps.playStatus !== PLAYING &&
      this.props.playStatus === PLAYING
    ) {
      this.playMedia();
    }
  }

  private shouldPause(prevProps: AudioPlayerProps): void {
    if (this.audioEl && prevProps.playStatus !== PAUSE && this.props.playStatus === PAUSE) {
      if (!this.state.awaitingPlayPromise) this.audioEl.pause();
    }
  }

  private shouldEmit(prevProps: AudioPlayerProps): void {
    if (
      this.audioEl &&
      prevProps.filter !== this.props.filter &&
      (this.props.playStatus === PAUSE || this.props.playStatus === LOADING)
    ) {
      emitter.emit(changeCurrentTimeEventName, this.audioEl.currentTime);
      this.emitBuffered();
    }
  }

  private shouldChangeTimestamp(prevProps: AudioPlayerProps): void {
    if (
      this.audioEl &&
      prevProps.startedAt !== this.props.startedAt &&
      (prevProps.playStatus === this.props.playStatus ||
        this.props.timestamp !== prevProps.timestamp)
    ) {
      this.audioEl.currentTime = this.props.timestamp;
      emitter.emit(changeCurrentTimeEventName, this.audioEl.currentTime);
    }
  }

  private loadSound(): void {
    if (!this.audioEl) return;
    this.audioEl.load();
    this.audioEl.currentTime = 0;
    this.audioEl.playbackRate = this.props.playbackRate || 1;
    this.audioEl.onloadedmetadata = () => {
      if (this.audioEl) {
        if (
          this.props.activeSound &&
          this.props.activeSound.length !== Math.round(this.audioEl.duration)
        ) {
          this.props.editDuration(this.props.activeFileId, this.audioEl.duration);
        }
        this.playMedia();
      }
    };
    this.audioEl.onwaiting = () => {
      if (this.props.playStatus !== LOADING) {
        this.props.changePlayStatus(LOADING);
      }
    };
    this.audioEl.onplaying = () => {
      if (this.props.playStatus !== PLAYING) {
        this.props.changePlayStatus(PLAYING, this.props.playMode);
      }
    };
    this.audioEl.onseeked = e => {
      emitter.emit(changeCurrentTimeEventName, (e.currentTarget as HTMLAudioElement).currentTime);
    };
    // this event listener exists simply because safari on some circumstances can skip
    // firing onPlaying after loading provides enough data to play, which caused infinite
    // LOADING state in reducer -> infinite loading indicator.
    if (isSafari()) {
      this.audioEl.oncanplay = () => {
        if (this.props.playStatus === LOADING && this.player) {
          this.props.changePlayStatus(PLAYING);
          this.playMedia();
        }
      };
    }
  }

  private setIntervals = () => {
    this.bufferInterval = setInterval(this.emitBuffered, AudioPlayer.emitFrequency);
    this.playInterval = setInterval(() => {
      if (
        (this.props.playStatus === PLAYING || this.props.playStatus === PAUSE) &&
        this.audioEl &&
        this.tempTime !== this.audioEl.currentTime
      ) {
        this.tempTime = this.audioEl.currentTime;
        emitter.emit(changeCurrentTimeEventName, this.audioEl.currentTime);
      }
    }, AudioPlayer.emitFrequency);
  };

  private emitBuffered = () => {
    if (this.audioEl && this.audioEl.buffered.length) {
      const bufferedEnd = this.audioEl.buffered.end(this.audioEl.buffered.length - 1);
      const diff: number = Math.abs(this.audioEl.duration - bufferedEnd);
      if (diff > AudioPlayer.diffError) {
        emitter.emit(
          changeBufferedEventName,
          this.audioEl.buffered.end(this.audioEl.buffered.length - 1) / this.audioEl.duration
        );
      } else {
        emitter.emit(changeBufferedEventName, 1);
        clearInterval(this.bufferInterval);
      }
    } else {
      emitter.emit(changeBufferedEventName, 0);
    }
  };

  private audioPlayer(): ReactAudioPlayer | TestAudioEl {
    if (import.meta.env.MODE === 'test') {
      return this.testAudioEl;
    }
    return this.player;
  }

  private clearPlayerIntervals() {
    clearInterval(this.bufferInterval);
    clearInterval(this.playInterval);
  }

  private shouldClearIntervals(prevProps: AudioPlayerProps) {
    if (prevProps.playStatus === PLAYING && this.props.playStatus !== PLAYING) {
      this.clearPlayerIntervals();
    }
  }

  private shouldChangePlaybackRate(prevProps: AudioPlayerProps) {
    const {playbackRate} = this.props;
    if (this.audioEl && prevProps.playbackRate !== playbackRate) {
      this.audioEl.playbackRate = playbackRate || 1;
    }
  }
}
