import React, {useCallback} from 'react';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import CoursebookInstanceProgressTitle from '../../components/CoursebookInstanceProgressTitle';

import './CourseInstanceProgress.scss';

interface Props {
  unitsClosed: number;
  unitsTotal: number;
  testsClosed: number;
  testsTotal: number;
  exercisesCompleted: number;
  exercisesTotal: number;
}

const CoursebookInstanceProgress: React.FC<Props> = (props: Props) => {
  const {unitsClosed, unitsTotal, testsTotal, testsClosed} = props;
  const percentsOfUnitsCompleted = Math.floor(
    ((unitsClosed + testsClosed) * 100) / (unitsTotal + testsTotal)
  );
  const stopClickPropagation = useCallback((e: React.MouseEvent<{}>) => e.stopPropagation(), []);
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');

  const tooltipOverlay = (
    <div onClick={stopClickPropagation}>
      <CoursebookInstanceProgressTitle {...props} />
    </div>
  );

  return (
    <Tooltip overlay={tooltipOverlay} placement="top">
      <div className={classNames('course-instance-progress', {student: isStudent})}>
        {`${percentsOfUnitsCompleted}%`}
        <div className="progress-bar">
          <div className="progress-amount" style={{width: `${percentsOfUnitsCompleted}%`}} />
        </div>
      </div>
    </Tooltip>
  );
};

export default CoursebookInstanceProgress;
