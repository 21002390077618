import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {type AppState, type UnitInstance} from 'store/interface';

interface ClearDraftable {
  (toClear: number): void;
}

interface Draftable {
  id: number;
  draftable: boolean;
}

export interface DraftModule {
  clearDraftable: ClearDraftable;
  draftables: Draftable[];
  draftingId?: number;
  isDraftable(id: number): boolean;
  setDraftingId(id?: number): void;
}

const getDraftables = (unitInstances: UnitInstance[], isStudent: boolean) =>
  isStudent
    ? []
    : unitInstances
        .filter(ui => !(ui.ordinal || !ui.active))
        .map(
          ({
            id,
            overview: {
              extraExercisesCount,
              extraExercisesCompleted,
              homeworkExercisesCount,
              mainExercisesCount,
              mainExercisesCompleted
            }
          }) => {
            const draftable =
              mainExercisesCount +
                extraExercisesCount -
                (mainExercisesCompleted + extraExercisesCompleted + homeworkExercisesCount) >
              0;
            return {id, draftable};
          }
        );

export const useDraftModule = (unitInstances: UnitInstance[]): DraftModule => {
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');

  const [draftables, setDraftables] = useState(getDraftables(unitInstances, isStudent));
  const [draftingId, setDraftingId] = useState<number | undefined>();

  const clearDraftable = useCallback<ClearDraftable>(
    toClear =>
      setDraftables(draftables.map(d => (d.id === toClear ? {id: d.id, draftable: false} : d))),
    [draftables]
  );

  const isDraftable = useCallback(
    (id: number) => !!draftables.find(d => d.id === id)?.draftable,
    [draftables]
  );

  useEffect(() => {
    setDraftables(getDraftables(unitInstances, isStudent));
  }, [isStudent, unitInstances]);

  return {clearDraftable, draftables, draftingId, isDraftable, setDraftingId};
};
