import React, {type FC, useCallback, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {replace} from 'store/router';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import useSendApiRequest from 'hooks/rest/useSendApiRequest';
import Loader from 'components/Loader';
import Confirm from 'components/modals/Confirm';
import {ACTIVE_UNITS_LIMIT} from 'config/static';
import {type AppState} from 'store/interface';
import {unitInstancePath, type UnitInstancePreviewUrlParams} from 'common/paths';

import {activateUnit, activateUnitInstanceEvent} from '../CoursebookInstancePage/actions';
import i18n from '../CoursebookInstancePage/i18n';
import './AddUnitButton.scss';

const AddUnitButton: FC = () => {
  const intl = useIntl();
  const urlParams = useParams() as UnitInstancePreviewUrlParams;
  const unitInstanceId = Number(urlParams.unitInstanceId);
  const activationForbidden = useSelector<AppState, boolean>(s => {
    const unitInstances = s.classroom!.courseInstanceState.coursebookInstanceState.unitInstances;
    return unitInstances
      ? unitInstances.filter(ui => ui.active && !ui.completedAt).length >= ACTIVE_UNITS_LIMIT
      : true;
  });
  const [isShowingConfirm, setShowConfirm] = useState<boolean>();
  const showConfirm = useCallback(() => setShowConfirm(true), []);
  const hideConfirm = useCallback(() => setShowConfirm(false), []);
  const dispatch = useDispatch();
  const {call: activate, isLoading} = useSendApiRequest(
    activateUnit,
    [unitInstanceId],
    () => {
      dispatch(activateUnitInstanceEvent(unitInstanceId));
      dispatch(replace(unitInstancePath(urlParams, unitInstanceId)));
    },
    () => toastr.error('', intl.formatMessage(i18n.openError))
  );

  const warn = useCallback(() => {
    toastr.error(
      '',
      intl.formatMessage(
        {id: 'CoursebookInstancePage.UnitsList.MaxUnitsCountAchieved'},
        {maxOpenUnits: ACTIVE_UNITS_LIMIT}
      )
    );
  }, [intl]);

  return (
    <>
      <Button
        bsStyle="success"
        className="add-preview-unit-button"
        onClick={activationForbidden ? warn : showConfirm}
      >
        <span className={classNames('button-label', {invisible: isLoading})}>
          <Icon name="unlock" />
          <FormattedMessage id="Common.Open" />
        </span>
        <div className="loader-button-positioning-helper">
          <Loader shouldRender={isLoading} />
        </div>
      </Button>
      <Confirm
        show={isShowingConfirm}
        headerText={<FormattedMessage id="CoursebookInstancePage.ConfirmModal.Open" />}
        onAccept={activate}
        onDecline={hideConfirm}
        disableButtons={isLoading}
      />
    </>
  );
};

export default AddUnitButton;
