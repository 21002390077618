import React, {type PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';

interface Props {
  redirectsTo: string;
}

const Card: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <Link to={props.redirectsTo} className="link-unstyled">
      <div className="card">{props.children}</div>
    </Link>
  );
};

export default Card;
