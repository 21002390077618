import {useIntl} from 'react-intl';
import {type SyntheticEvent} from 'react';
import type React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';
import {useSelector} from 'react-redux';
import type Button from 'react-bootstrap/lib/Button';
import {useLocation} from 'react-router-dom';

import {push} from 'store/router';
import {type AppState, type HomeworkDocumentInstancePage} from 'store/interface';

import {useAxiosDispatch} from '../../../../hooks/redux/useAxiosDispatch';
import {editHomeworkCommentForDocumentPage} from '../FilesWorkSpace/documentsTab/actions/action';
import {deleteDocumentInstancePageFromDraft} from '../../pages/CourseInstancePage/actions';
import * as toastr from '../../../../components/toastr';
import {documentPageDraftControlMessages} from './i18n';
import {setRedirectedFromUrl} from '../../../../common/action';

export interface DocControlsProps {
  isStudent: boolean;
  isDeleting: boolean;
  editDocInstanceId?: number;
  isInDraft: boolean;
  isShowConfirm: boolean;
  onDeleteConfirmAccept: () => void;
  toggle: (e: SyntheticEvent<HTMLButtonElement | Button>) => void;
  onDeleteClick: (e: SyntheticEvent<HTMLButtonElement | Button>) => void;
  hideConfirm: () => void;
  redirectToDocument?: () => void;
  deleteConfirmTitle: string | React.ReactElement;
}

export const useDocPageControls = (
  pages: HomeworkDocumentInstancePage[] | undefined,
  courseId: number,
  studentTeacherId: number,
  homeworkId: string | undefined,
  documentInstanceId: number,
  pageNumber: number
): DocControlsProps => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const [isShowingConfirm, setIsShowingConfirm] = useState<boolean>();
  const [deleting, setDeleting] = useState(false);
  const isMounted = useIsMounted();
  const isStudent = useSelector((state: AppState) => state.user.role === 'student');
  const hideConfirm = useCallback(() => setIsShowingConfirm(false), []);
  const onDeleteClick = useCallback((e: SyntheticEvent<HTMLButtonElement | Button>) => {
    // fix invalid position of buttons after clicking this button and then declining confirm
    (e.currentTarget as HTMLButtonElement).blur();
    setIsShowingConfirm(true);
  }, []);
  const documentIsInDraft = useMemo(
    () =>
      pages &&
      pages.find(
        doc =>
          doc.documentInstancePage?.documentInstanceId === documentInstanceId &&
          pageNumber === doc.documentInstancePage?.pageNumber
      ),
    [documentInstanceId, pageNumber, pages]
  );
  const documentInstancePageId = documentIsInDraft
    ? documentIsInDraft.documentInstancePageId
    : undefined;
  const dispatch = useAxiosDispatch();
  const editDocInstanceId = useSelector(
    (state: AppState) => state.docs && state.docs.editHomeworkCommentForDocumentPage
  );
  const toggle = useCallback(
    (e: SyntheticEvent<HTMLButtonElement | Button>) => {
      // fix invalid position of buttons after clicking this button and then closing modal
      (e.currentTarget as HTMLButtonElement).blur();
      if (!editDocInstanceId) {
        dispatch(editHomeworkCommentForDocumentPage(documentInstanceId, pageNumber));
      }
    },
    [dispatch, documentInstanceId, editDocInstanceId, pageNumber]
  );

  const onDeleteConfirmAccept = useCallback(() => {
    setIsShowingConfirm(false);
    if (!documentInstancePageId || deleting) return;
    setDeleting(true);
    dispatch(deleteDocumentInstancePageFromDraft(courseId, homeworkId!, documentInstancePageId))
      .then(
        () =>
          toastr.success(
            '',
            intl.formatMessage(documentPageDraftControlMessages.DeleteFromDraftSuccess)
          ),
        () =>
          toastr.error('', intl.formatMessage(documentPageDraftControlMessages.DeleteFromDraftFail))
      )
      .finally(() => {
        if (isMounted.current) setDeleting(false);
      });
  }, [documentInstancePageId, deleting, dispatch, courseId, homeworkId, intl, isMounted]);

  const redirectToDocument = useCallback(() => {
    if (!documentInstanceId) return;

    dispatch(setRedirectedFromUrl(pathname, 'homework'));
    dispatch(
      push(
        `/room/${studentTeacherId}/course/${courseId}/files/${documentInstanceId}/page/${pageNumber}`
      )
    );
  }, [dispatch, pathname, studentTeacherId, courseId, documentInstanceId, pageNumber]);

  return {
    redirectToDocument,
    onDeleteConfirmAccept,
    toggle,
    isInDraft: !!documentIsInDraft,
    isShowConfirm: !!isShowingConfirm,
    isStudent,
    hideConfirm,
    onDeleteClick,
    editDocInstanceId,
    isDeleting: deleting,
    deleteConfirmTitle: intl.formatMessage(documentPageDraftControlMessages.DeleteConfirm)
  };
};
