import React from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import './FilesCard.scss';

interface Props {
  documentsCount: number;
  soundsCount: number;
}

const FilesCard: React.FC<Props> = (props: Props) => {
  return (
    <div className="files-card">
      <h4>
        <Icon name="virc-docs" size="lg" />
      </h4>
      <h4>
        <FormattedMessage id="LessonPage.Files" />
      </h4>
      <span className="counters">
        <span>
          <Icon name="file-text-o" /> {props.documentsCount}
        </span>
        <span>
          <Icon name="virc-audio" /> {props.soundsCount}
        </span>
      </span>
    </div>
  );
};

export default FilesCard;
