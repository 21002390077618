import React, {type FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';

import {push} from 'store/router';
import {type AppState, type RedirectedSign} from 'store/interface';
import Icon from 'components/Icon';
import {clearRedirectedFromUrl} from 'common/action';

import './BackToRedirected.scss';

const BackToRedirected: FC = () => {
  const redirectedFrom = useSelector<AppState, RedirectedSign | undefined>(
    state => state.classroom!.courseInstanceState.coursebookInstanceState.redirectedFromUrl
  );

  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    if (redirectedFrom) {
      dispatch(push(redirectedFrom.url));
      dispatch(clearRedirectedFromUrl());
    }
  }, [dispatch, redirectedFrom]);

  if (!redirectedFrom) {
    return null;
  }

  const {fromWhere} = redirectedFrom;

  return (
    <Button onClick={onClick} className={classNames('back-to-control', fromWhere)}>
      <Icon name="angle-left" />
      {fromWhere === 'grammar' ? (
        <FormattedMessage id="LessonPage.BackToGrammar" />
      ) : (
        <FormattedMessage id="LessonPage.BackToHomework" />
      )}
    </Button>
  );
};

export default BackToRedirected;
