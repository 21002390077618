import {type Action} from 'redux';

import {type FilesWorkSpaceState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {CHANGE_FILES_FILTER, FILES_WORKSPACE_RESET, TOGGLE_MATERIALS_SEARCHBAR} from './action';
import {type ChangeFilterAction} from '../../actions/interface';
import {type ToggleElementAction} from '../../../../common/interface';

const ACTION_HANDLERS: ActionHandlersList<FilesWorkSpaceState, Action> = {
  [CHANGE_FILES_FILTER]: (
    state: FilesWorkSpaceState,
    action: ChangeFilterAction
  ): FilesWorkSpaceState => ({
    ...state,
    filesFilter: action.filter
  }),
  [TOGGLE_MATERIALS_SEARCHBAR]: (
    state: FilesWorkSpaceState,
    action: ToggleElementAction
  ): FilesWorkSpaceState => ({
    ...state,
    searchBarOpen: action.show
  }),
  [FILES_WORKSPACE_RESET]: (): FilesWorkSpaceState => ({
    ...initialState
  })
};

const initialState: FilesWorkSpaceState = {
  filesFilter: ''
};

export default function (
  state: FilesWorkSpaceState = initialState,
  action: Action
): FilesWorkSpaceState {
  const reducer = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action) : state;
}
