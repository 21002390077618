import React, {type FC, useCallback, useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import type {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

import {ContentsHeader} from 'components/ContentsHeader/ContentsHeader';

import {ViewMode, ViewModeToggles} from './ViewModeToggles';
import {ThumbnailList} from './thumbnails/ThumbnailList';
import {Outline} from './outline/Outline';
import {type Item} from './outline/interface';
import './PDFContents.scss';

interface Props {
  doc: PDFDocumentProxy | null;
  show: boolean;
  currentPage: number;
  selectPage: (page: number) => void;
  toggle: () => void;
}

const timeout = {
  enter: 0,
  exit: 300
};

export const PDFContents: FC<Props> = ({doc, show: isShow, currentPage, selectPage, toggle}) => {
  const [nextIndex, setNextIndex] = useState<number | null>(null);
  const [viewMode, setViewMode] = useState(ViewMode.THUMBNAILS);
  const [outline, setOutline] = useState<Item[] | null>(null);
  const onExited = useCallback(() => {
    if (nextIndex !== null) {
      selectPage(nextIndex + 1);
    }
    setNextIndex(null);
  }, [nextIndex, selectPage]);

  const goToIndex = useCallback(
    (i: number) => {
      if (i !== currentPage - 1) {
        setNextIndex(i);
      }
    },
    [currentPage]
  );

  const show = nextIndex === null && isShow;

  useEffect(() => {
    if (doc) {
      const getOutline = async () => {
        const o = await doc?.getOutline();
        setOutline(o as Item[]);
      };
      getOutline().catch();
    }
  }, [doc]);

  const index = currentPage - 1;

  if (!doc) {
    return null;
  }
  return (
    <CSSTransition in={show} timeout={timeout} onExited={onExited}>
      {state => {
        return (
          <div className={classNames('pdf-contents', state)}>
            <ContentsHeader onClose={toggle}>
              <ViewModeToggles mode={viewMode} setMode={setViewMode} hasOutline={!!outline} />
            </ContentsHeader>
            {viewMode === ViewMode.THUMBNAILS && (
              <ThumbnailList doc={doc} currentIndex={index} goToIndex={goToIndex} />
            )}
            {outline && viewMode === ViewMode.OUTLINE && (
              <Outline outline={outline} currentIndex={index} pdf={doc} goToIndex={goToIndex} />
            )}
          </div>
        );
      }}
    </CSSTransition>
  );
};
