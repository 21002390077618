import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import React, {type PropsWithChildren} from 'react';
import classNames from 'classnames';

import {visibleOnlyTeacher} from 'authentication';
import Loader from 'components/Loader';

type Props = PropsWithChildren<{
  isCompleted: boolean;
  isEmpty: boolean;
  onClick: (e: React.MouseEvent<Button>) => void;
  isLoading?: boolean;
}>;

const CoursebookInstanceActionButton: React.FC<Props> = ({
  isCompleted,
  isEmpty,
  onClick,
  isLoading
}) => {
  return (
    <Button bsStyle="default" className="coursebook-instance-action-button" onClick={onClick}>
      <span className={classNames('button-label', {invisible: isLoading})}>
        {isCompleted ? (
          <FormattedMessage id="Common.Open" />
        ) : isEmpty ? (
          <FormattedMessage id="Common.Delete" />
        ) : (
          <FormattedMessage id="Common.Close" />
        )}
      </span>
      <div className="loader-button-positioning-helper">
        <Loader shouldRender={isLoading} />
      </div>
    </Button>
  );
};

export default visibleOnlyTeacher(CoursebookInstanceActionButton);
