import {defineMessages} from 'react-intl';

const i18n = defineMessages({
  confirmModalHeaderOpen: {
    id: 'CoursebookInstancePage.ConfirmModal.Open'
  },
  confirmModalHeaderClose: {
    id: 'CoursebookInstancePage.ConfirmModal.Close'
  },
  openError: {
    id: 'CoursebookInstancePage.OpenError'
  },
  closeError: {
    id: 'CoursebookInstancePage.CloseError'
  }
});

export default i18n;
