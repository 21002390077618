import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState, type Role} from 'store/interface';

import {type PublishMaterialsOpenCreator} from '../../../../../actions/interface';
import {type PublishMaterialsOpen} from '../../interface';
import DocumentFileControl from './DocumentFileControl';
import {
  clearOpenedTogetherDoc,
  documentEdit,
  documentOpenEditor
} from '../../../documentsTab/actions/action';
import {type DocumentFile, type DocumentFileV2} from '../../../documentsTab/actions/interface';

export interface DocumentFileControlOwnProps {
  thisFileId: number;
  deleteFileAwait?: boolean;
  key: string;
  readonly: boolean;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  publishMaterialsOpen: PublishMaterialsOpenCreator | PublishMaterialsOpen;
  pinnedBlock?: boolean;
  openWhenLoadedId?: number;
  changeActiveFile: (id: number | null) => void;
}

export interface DocumentFileControlStateProps {
  openTogetherFileId?: number;
  fileIsActive: boolean;
  editFileAwait?: boolean;
  editedFileId?: number;
  role: Role;
  thisFile?: DocumentFile | DocumentFileV2;
}

export interface DocumentFileControlDispatchProps {
  clearOpenedTogether: () => void;
  editFileName: (id: number, title: string) => Promise<{}>;
  openEditor: (id: number | null) => void;
}

type DocumentFileControlStateToProps = (
  state: AppState,
  ownProps: DocumentFileControlOwnProps
) => DocumentFileControlStateProps;

type DocumentFileControlDispatchToProps = (
  dispatch: Dispatch<Action, AppState>
) => DocumentFileControlDispatchProps;

const mapStateToProps: DocumentFileControlStateToProps = (state, ownProps) => {
  const fileIsBeingEdited = state.docs!.editedDocument === ownProps.thisFileId ? true : undefined;
  const editFileAwait =
    ownProps.thisFileId === state.docs!.editedDocument ? state.docs!.editDocumentAwait : undefined;
  return {
    openTogetherFileId: state.docs!.openTogetherFileId,
    editFileAwait,
    fileIsBeingEdited,
    role: state.user.role!,
    thisFile: state.docs!.documents![ownProps.thisFileId],
    fileIsActive: false
  };
};

const mapDispatchToProps: DocumentFileControlDispatchToProps = dispatch => ({
  editFileName: (id: number, title: string) => dispatch(documentEdit(id, title)),
  clearOpenedTogether: () => dispatch(clearOpenedTogetherDoc()),
  openEditor: (id: number | null) => dispatch(documentOpenEditor(id))
});

const DocumentFileControlContainer = connect<
  DocumentFileControlStateProps,
  DocumentFileControlDispatchProps,
  DocumentFileControlOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(DocumentFileControl);

export default DocumentFileControlContainer;
