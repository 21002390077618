import React from 'react';

import {type CoursebookInstance} from 'store/interface';

import PieProgress from '../../../../components/PieProgress';
import CoursebookInstanceProgressTitle from '../../components/CoursebookInstanceProgressTitle';

interface Props {
  instance: CoursebookInstance;
  showHoverTitle?: true;
}

class CoursebookInstanceProgress extends React.Component<Props> {
  public render() {
    const {
      showHoverTitle,
      instance: {overview, completedAt}
    } = this.props;
    const {unitsCompleted, testsCompleted, unitsAmount} = overview!;
    const completed = unitsCompleted + testsCompleted;
    return (
      <PieProgress
        size={14}
        filled={completed}
        total={unitsAmount}
        dimmed={!!completedAt}
        title={
          showHoverTitle && (
            <CoursebookInstanceProgressTitle
              exercisesCompleted={overview!.mainExercisesCompleted}
              exercisesTotal={overview!.mainExercisesCount}
              testsTotal={overview!.testsCount}
              testsClosed={overview!.testsCompleted}
              unitsTotal={overview!.unitsCount}
              unitsClosed={overview!.unitsCompleted}
            />
          )
        }
      />
    );
  }
}

export default CoursebookInstanceProgress;
