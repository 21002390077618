import React, {type FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {type AppState} from 'store/interface';
import {PDFViewerRoute} from 'components/PDFViewer/PDFViewerRoute';
import {usePrevious} from 'hooks/usePrevious';

import DocumentsList from '../containers/DocumentsList';
import {type DocumentFile, type DocumentFileV2} from '../actions/interface';
import {filesPdfPainterPagerPortalClassName} from '../../components/interface';
import {PDFPagePainter} from './PDFPagePainter';
import {setLastDocPageNumber} from '../actions/action';

interface Props {
  readonly: boolean;
  courseId: string;
  studentTeacherId: number;
  homeworkDoc?: DocumentFile | DocumentFileV2;
  selectedDocPageNumber?: number;
  selectedDocId?: number;
  selectDocument: (id: number) => void;
}

export const DocumentsContent: FC<Props> = ({
  homeworkDoc,
  selectedDocId,
  selectedDocPageNumber,
  readonly,
  courseId,
  studentTeacherId,
  selectDocument
}) => {
  const dispatch = useDispatch();

  const {pageNumber: pageNumberParam} = useParams<{pageNumber: string}>();
  const prevPageNumberParam = usePrevious(pageNumberParam);
  const activeDocument = useSelector(
    (state: AppState) =>
      homeworkDoc ||
      (state.docs!.documents &&
        selectedDocId &&
        (state.docs!.documents[selectedDocId] as DocumentFile)) ||
      undefined
  );

  const dispatchSetLastVisitedPage = useCallback(
    (id: number, page: number) => dispatch(setLastDocPageNumber(id, page)),
    [dispatch]
  );

  useEffect(() => {
    if (!homeworkDoc && selectedDocId && prevPageNumberParam !== pageNumberParam) {
      dispatchSetLastVisitedPage(selectedDocId, Number(pageNumberParam));
    }
  }, [
    dispatchSetLastVisitedPage,
    homeworkDoc,
    pageNumberParam,
    prevPageNumberParam,
    selectedDocId
  ]);

  if (!activeDocument) {
    return (
      <DocumentsList
        readonly={readonly}
        courseId={courseId}
        studentTeacherId={studentTeacherId}
        selectDocument={selectDocument}
      />
    );
  }

  const isHomework = !!homeworkDoc;
  const document = activeDocument;
  const selectedPageNumber = selectedDocPageNumber;
  const pageNumber = isHomework ? selectedPageNumber : undefined;
  return (
    <PDFViewerRoute
      url={document.url}
      hidePager={isHomework}
      pageNumber={pageNumber}
      tocPortalNodeClassName={filesPdfPainterPagerPortalClassName}
    >
      <PDFPagePainter documentInstanceId={document.id} pageNumber={pageNumber} />
    </PDFViewerRoute>
  );
};
