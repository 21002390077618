import {defineMessages} from 'react-intl';

export const coursebookInstanceBreadcrumbMessages = defineMessages({
  Files: {
    id: 'LessonPage.Files'
  },
  Homework: {
    id: 'LessonPage.Homework'
  }
});
