import React from 'react';
import {useParams} from 'react-router-dom';

import {type HomeworkPlayerUrlParams} from '../HomeworkPage/interface';
import WampErrorMask from '../../../../components/WampErrorMask';
import Loader from '../../../../components/Loader';
import FilesWorkSpace from '../../components/FilesWorkSpace';
import {useHomeworkDocumentPage} from './useHomeworkDocumentPage';

interface Props {
  documentInstancePageId: number;
}

const HomeworkDocumentPage: React.FC<Props> = (props: Props) => {
  const {homeworkId, courseId, studentTeacherId} = useParams<HomeworkPlayerUrlParams>();
  const {documentInstancePageId} = props;

  const {docInstancePage, isError, reload} = useHomeworkDocumentPage(
    Number(courseId),
    homeworkId!,
    documentInstancePageId
  );

  if (isError) {
    return <WampErrorMask reload={reload} />;
  }
  if (!docInstancePage) {
    return <Loader />;
  }
  return (
    <FilesWorkSpace
      studentTeacherId={Number(studentTeacherId)}
      courseId={Number(courseId)}
      homeworkDoc={docInstancePage.documentInstance}
      selectedPageNumber={docInstancePage.pageNumber}
      readonly={false}
      wampSubscribed={true}
    />
  );
};

export default HomeworkDocumentPage;
