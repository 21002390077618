import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {openTogetherAwaitTime} from 'config/static';
import {type AppState} from 'store/interface';
import {type ClassroomUrlParams} from 'common/paths';
import {type ActionsTogether} from 'common/enums';

import {type FileType} from '../../../actions/interface';
import {useAxiosDispatch} from '../../../../../hooks/redux/useAxiosDispatch';
import {publishMaterialsOpen} from '../../../actions/action';
import {clearOpenedTogetherSound} from '../soundsTab/actions/action';
import {type PlayStatus} from '../soundsTab/actions/interface';
import {errorMessages} from '../../../../../components/media/_common/errorMessages';
import {changeCurrentTimeEventName, emitter} from '../../../../../services/event-emitter';

export interface ActionTogetherProps {
  isTogether: boolean;
  toggleIsTogether: () => void;
  turnOffTogether: () => void;
  actionTogether: (action: ActionsTogether, meta: ActionTogetherMeta) => void;
  getCurrentTime: () => number;
}

interface ActionTogetherMeta {
  fileId: number;
  uniquePlaybackId?: string;
  timestamp?: number;
  playStatus?: PlayStatus;
  playbackRate?: number;
}

export interface ActionTogetherContext extends ActionTogetherMeta {
  type: FileType;
  action?: ActionsTogether;
}

export const AudioTogetherContext = React.createContext<ActionTogetherProps>({
  isTogether: false,
  toggleIsTogether: () => {},
  actionTogether: () => {},
  turnOffTogether: () => {},
  getCurrentTime: () => 0
});

export const ActionTogetherProvider: React.FC = ({children}) => {
  const openTogetherFileId = useSelector((state: AppState) => state?.sounds?.openTogetherFileId);

  const dispatch = useAxiosDispatch();
  const {courseId} = useParams<ClassroomUrlParams>();
  const intl = useIntl();

  const timer = useRef<NodeJS.Timeout>();
  const prevOpenTogetherFileId = useRef<number>();
  const audioCurrentTime = useRef(0);

  const [isTogether, setIsTogether] = useState(false);

  const toggleIsTogether = useCallback(() => setIsTogether(prevState => !prevState), []);

  const turnOffTogether = useCallback(() => setIsTogether(false), []);

  const clearOpened = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
      dispatch(clearOpenedTogetherSound());
    }
  }, [dispatch]);

  const onError = useCallback(
    action => {
      const errorMessage = errorMessages[action];
      toastr.error('', intl.formatMessage(errorMessage));
      clearOpened();
    },
    [clearOpened, intl]
  );

  useEffect(() => {
    if (prevOpenTogetherFileId.current !== openTogetherFileId) {
      prevOpenTogetherFileId.current = openTogetherFileId;
    }

    return () => {
      if (
        timer.current &&
        openTogetherFileId &&
        prevOpenTogetherFileId.current === openTogetherFileId
      ) {
        clearOpened();
      }
    };
  }, [openTogetherFileId, clearOpened]);

  useEffect(() => {
    const receiveTime = (currentTime: number) => (audioCurrentTime.current = currentTime);

    emitter.addListener(changeCurrentTimeEventName, receiveTime);

    return () => {
      emitter.removeListener(changeCurrentTimeEventName, receiveTime);
      audioCurrentTime.current = 0;
    };
  }, []);

  const actionTogether = useCallback(
    (action: ActionsTogether, meta: ActionTogetherMeta) => {
      if (courseId && !timer.current) {
        const context = {
          type: 'audio' as FileType,
          action,
          ...meta
        };

        dispatch(publishMaterialsOpen(Number(courseId), context));

        timer.current = setTimeout(() => onError(action), openTogetherAwaitTime);
      }
    },
    [courseId, dispatch, onError]
  );

  const getCurrentTime = useCallback(() => audioCurrentTime.current, []);

  const value = {isTogether, toggleIsTogether, actionTogether, turnOffTogether, getCurrentTime};

  return <AudioTogetherContext.Provider value={value}>{children}</AudioTogetherContext.Provider>;
};
