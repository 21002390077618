import React from 'react';

interface UploadProgressProps {
  percents: number;
}

export default class UploadProgress extends React.Component<UploadProgressProps, {}> {
  public render() {
    return (
      <div className="progress upload-progress">
        <div className="progress-amount" style={{width: `${this.props.percents}%`}} />
      </div>
    );
  }
}
