import React from 'react';
import {connect, type MapDispatchToProps, type MapStateToProps} from 'react-redux';
import TreeView from 'react-treeview';
import {FormattedMessage} from 'react-intl';
import {type CheckboxProps, default as Checkbox} from 'react-bootstrap/lib/Checkbox';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import Tooltip from 'rc-tooltip';
import Highlighter from 'react-highlight-words';

import Icon from 'components/Icon';
import {type AppState} from 'store/interface';

import {
  libraryCollapseAll,
  libraryCollapseNode,
  libraryFileSelected,
  previewLibraryPDF,
  storeScrollTopPosition
} from './actions';
import {fileIcon} from '../../../../../helpers/file';
import {
  type LibraryCollapsedState,
  type LibraryItem,
  type LibraryNode,
  type LibrarySearchItem,
  type LibrarySelectedFiles,
  type PreviewLibraryPDFCreator,
  type StoreScrollTopPositionAction
} from './interface';
import {LIBRARY_SELECT_LIMIT} from './constants';
import {LibraryActions} from './LibraryActions';
import {SearchResult} from './SearchResult';
import {isSearchLibraryItem} from './utils';

import './library-tree.scss';

interface StateProps {
  library: LibraryNode;
  libraryCollapsed: LibraryCollapsedState;
  scrollTopPosition?: number;
  selected: LibrarySelectedFiles;
}

interface DispatchProps {
  collapse: (nodeKey: string) => void;
  collapseAll: () => void;
  selectFile: (id: string, audios: number) => void;
  previewLibraryPDF: PreviewLibraryPDFCreator;
  storeScrollTopPosition: (scrollTopPosition?: number) => StoreScrollTopPositionAction;
}

interface OwnProps {
  search: string;
  index?: number;
  setSearch: (search: string) => void;
  setIndex: (index: number) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class LibraryTree extends React.Component<Props> {
  private scrollbars: Scrollbars | null;

  private static getParentNodeKeys(parents: string[]) {
    const keys: string[] = [];

    for (let i = 0; i < parents.length; i++) {
      const parent = parents
        .slice(0, parents.length - i)
        .map(p => p.toLowerCase())
        .join('-');

      keys.push(parent);
    }

    return keys;
  }

  public componentDidMount() {
    if (this.props.scrollTopPosition) {
      this.scrollbars?.scrollTop(this.props.scrollTopPosition);
    }
  }

  public render() {
    const rootKey = this.rootKey();
    const defaultCollapsed = !this.props.search;
    // TODO: review root key name
    return this.showTree() ? (
      <div className="library-tree">
        <LibraryActions
          search={this.props.search}
          onChangeSearch={this.onChangeSearch}
          onClick={this.collapseAll}
        />
        <div>
          {this.hasSearch ? (
            this.renderSearchResult()
          ) : (
            <Scrollbars
              autoHide={true}
              autoHeight={true}
              autoHeightMin={294}
              // Internally RCS uses typical style injection e.g. <SomeNode style={styleObj} />
              // where styleObj can read not only *-height: number, but any valid string like 'number%', 'calc(numbervh +- number)
              // => as @types does not specify anything but number input here 'any' type is used. Might be removed on @types
              // update or via custom module extension.
              autoHeightMax={'calc(100vh - 277px)'}
              onScrollStop={this.onScrollStop}
              ref={this.scrollbarRef}
            >
              {this.renderTreeNode(this.props.library[rootKey], rootKey, '', defaultCollapsed)}{' '}
            </Scrollbars>
          )}
        </div>
      </div>
    ) : null;
  }

  private get searchWords() {
    return this.props.search.split(/[\s,]+/);
  }

  private get hasSearch() {
    return Boolean(this.props.search.trim());
  }

  public collapseAll = () => {
    this.onChangeSearch('');
    this.props.collapseAll();
  };

  public onChangeSearch = (search: string) => {
    this.props.setSearch(search);
  };

  private onScrollStop = () => {
    const scrollTopPosition = this.scrollbars && this.scrollbars.getScrollTop();
    this.props.storeScrollTopPosition(scrollTopPosition || undefined);
  };

  private scrollbarRef = (scrollbars: Scrollbars) => {
    this.scrollbars = scrollbars;
  };

  private renderSearchResult(): JSX.Element {
    return (
      <SearchResult
        search={this.props.search}
        index={this.props.index}
        library={this.props.library}
        renderItem={(item, index, className) => this.renderFile(item, index, className)}
      />
    );
  }

  private renderTreeNode(
    libraryNode: LibraryNode,
    key: string,
    prefix: string = '',
    defaultCollapsed: boolean = true
  ): JSX.Element {
    if (libraryNode.hasOwnProperty('id')) {
      // render item
      const node = libraryNode as LibraryItem;
      return this.renderFile(node);
    }
    return this.renderFolder(libraryNode, key, prefix, defaultCollapsed);
  }

  private showTree() {
    return !!this.props.library;
  }

  private rootKey(): string {
    return this.showTree() && Object.keys(this.props.library).length
      ? Object.keys(this.props.library)[0]
      : '';
  }

  private previewLibraryDocument = (node: LibraryItem, index?: number) => {
    if (this.hasSearch && typeof index === 'number') {
      this.props.setIndex(index);
    }

    this.props.previewLibraryPDF(node);
  };

  private renderFile(node: LibraryItem | LibrarySearchItem, index?: number, className?: string) {
    const containerClassNames = classNames('file', className);

    const fileClasses = classNames({
      'file-info': true,
      [`with-audio-${node.audio_count.toString().length}`]: Boolean(node.audio_count)
    });

    const pathText = isSearchLibraryItem(node) ? node.path.join(' / ') + ' / ' : undefined;

    return (
      <div className={containerClassNames} key={node.id}>
        <div>
          <Checkbox
            checked={this.isFileSelected(node)}
            disabled={node.exist}
            onChange={(e: React.SyntheticEvent<CheckboxProps>) => {
              if (
                !e.currentTarget.checked ||
                (e.currentTarget.checked &&
                  Object.keys(this.props.selected).length < LIBRARY_SELECT_LIMIT)
              ) {
                if (this.hasSearch && isSearchLibraryItem(node) && node.path.length) {
                  const parents: string[] = LibraryTree.getParentNodeKeys(node.path);

                  parents.forEach(parent => {
                    if (
                      (this.isFileSelected(node) && !this.isCollapsedFolder(parent)) ||
                      (!this.isFileSelected(node) && this.isCollapsedFolder(parent))
                    ) {
                      this.props.collapse(parent);
                    }
                  });
                }

                this.props.selectFile(node.id, node.audio_count);
              }
            }}
          >
            <span title={node.title} className={fileClasses}>
              {pathText && (
                <span className="file-path">
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={this.searchWords}
                    textToHighlight={pathText}
                    autoEscape={true}
                  />
                </span>
              )}
              <Icon name={fileIcon(node.type)} className="file-extension-icon" size="lg" />
              <span className="file-title">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={this.searchWords}
                  textToHighlight={node.title}
                  autoEscape={true}
                />
              </span>
              {this.renderFileAudio(node.audio_count)}
            </span>
          </Checkbox>
        </div>
      </div>
    );
  }

  private renderFolder(
    node: LibraryNode | LibraryItem[],
    key: string,
    prefix: string = '',
    defaultCollapsed: boolean = true
  ) {
    const nodeKey = prefix ? `${prefix}-${key.toLowerCase()}` : key.toLowerCase();
    const nodeLabel = (
      <span className="folder-label" onClick={() => this.props.collapse(nodeKey)}>
        {key}
      </span>
    );
    const folderClass = classNames('folder', {
      empty: (node as LibraryItem[]).length === 0
    });
    const collapsed =
      !!this.props.search || prefix === '' ? false : this.isCollapsedFolder(nodeKey);

    return (
      <TreeView
        itemClassName={folderClass}
        childrenClassName="folder-content"
        key={nodeKey}
        nodeLabel={nodeLabel}
        collapsed={collapsed}
        defaultCollapsed={defaultCollapsed}
        onClick={() => this.props.collapse(nodeKey)}
      >
        {!node.hasOwnProperty('length') // node
          ? Object.keys(node).map((currentNodeKey: string) =>
              this.renderTreeNode(node[currentNodeKey], currentNodeKey, nodeKey, true)
            )
          : (node as LibraryItem[]).map((libraryItem: LibraryItem) =>
              this.renderTreeNode(
                libraryItem,
                libraryItem.id,
                prefix ? `${prefix}-${libraryItem.id}` : libraryItem.id,
                true
              )
            )}
      </TreeView>
    );
  }

  private isCollapsedFolder(nodeKey: string) {
    return this.props.libraryCollapsed[nodeKey] ? this.props.libraryCollapsed[nodeKey] : false;
  }

  private isFileSelected(node: LibraryItem) {
    return (this.props.selected && this.props.selected[node.id] !== undefined) || node.exist;
  }

  private renderFileAudio(audioCount: number) {
    if (!audioCount) {
      return null;
    }

    return (
      <Tooltip
        overlay={<FormattedMessage id="library.audioTooltip" />}
        placement="top"
        trigger={['hover']}
        overlayClassName="black-tooltip"
      >
        <span className="audio-count">
          {' '}
          (+{audioCount}
          {<Icon name="virc-audio" />})
        </span>
      </Tooltip>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, AppState> = ({library}) => ({
  library: library.library!,
  libraryCollapsed: library.libraryCollapsed!,
  scrollTopPosition: library.scrollTopPosition,
  selected: library.librarySelected!
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = {
  // todo: does it work??
  collapse: libraryCollapseNode,
  collapseAll: libraryCollapseAll,
  selectFile: libraryFileSelected,
  storeScrollTopPosition,
  previewLibraryPDF
};

export default connect(mapStateToProps, mapDispatchToProps)(LibraryTree);
