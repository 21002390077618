import React, {type FC, useEffect, useRef, useState} from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';
import {useIntl} from 'react-intl';

import Confirm from '../../../../components/modals/Confirm';
import {CommentPopover} from './CommentPopover';
import {type EditHomeworkCommentProps} from './EditHomeworkCommentModal';

interface EditHomeworkCommentPopoverProps extends Omit<EditHomeworkCommentProps, 'courseId'> {
  editDocumentInstanceId?: number;
  initialComment?: string;
  comment?: string;
  closeCommentEditor: () => void;
  isLoading: boolean;
  save: (comment: string) => void;
  setComment: (comment: string) => void;
}

export const EditHomeworkCommentPopover: FC<EditHomeworkCommentPopoverProps> = ({
  closeCommentEditor,
  isLoading,
  editDocumentInstanceId,
  closeRequested,
  tellCommentEditorJustClosed,
  closeCommentViewer,
  initialComment,
  comment,
  save,
  setComment
}) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const refContainer = useRef(null);
  const intl = useIntl();
  useEffect(() => {
    if (closeRequested) {
      if (initialComment === comment) {
        closeCommentEditor();
        if (tellCommentEditorJustClosed) tellCommentEditorJustClosed(true);
      } else {
        setShowConfirm(true);
      }
    }
  }, [closeCommentEditor, comment, initialComment, closeRequested, tellCommentEditorJustClosed]);

  useEffect(() => {
    if (editDocumentInstanceId && closeCommentViewer) {
      closeCommentViewer();
    }
  }, [closeCommentViewer, editDocumentInstanceId]);

  useEffect(
    () => () => {
      closeCommentEditor();
    },
    [closeCommentEditor]
  );

  return (
    <div ref={refContainer} className="edit-homework-comment-popover-container">
      <Confirm
        headerText={intl.formatMessage({id: 'EditHomeworkDocCommentPopover.CloseConfirm'})}
        onAccept={() => {
          setShowConfirm(false);
          closeCommentEditor();
          if (tellCommentEditorJustClosed) tellCommentEditorJustClosed(true);
        }}
        onDecline={() => {
          setShowConfirm(false);
          if (tellCommentEditorJustClosed) tellCommentEditorJustClosed(false);
        }}
        show={showConfirm}
        disableButtons={false}
      />
      <Overlay
        container={refContainer.current}
        show={!!editDocumentInstanceId}
        shouldUpdatePosition={true}
        target={refContainer.current!}
        animation={false}
      >
        <CommentPopover
          title={intl.formatMessage({
            id: 'Files.EditHomeworkCommentModalTitle'
          })}
          isLoading={isLoading}
          closeCommentEditor={closeCommentEditor}
          initialComment={initialComment}
          save={save}
          setCurrentValue={setComment}
        />
      </Overlay>
    </div>
  );
};
