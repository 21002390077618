import React, {type FC} from 'react';
import './ProgressLine.scss';

interface Props {
  total: number;
  completed: number;
}

const ProgressLine: FC<Props> = ({completed, total}) => {
  return (
    <div className="progress-line">
      <div className="completed" style={{width: `${(completed / total) * 100}%`}} />
    </div>
  );
};

export default ProgressLine;
