import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useApiRequest} from '../../../../hooks/rest/useApiRequest';
import {loadHomeworkDocument} from './actions';
import {type HomeworkPlayerUrlParams} from '../HomeworkPage/interface';
import {
  type DocumentFile,
  type DocumentFileV2
} from '../../components/FilesWorkSpace/documentsTab/actions/interface';
import WampErrorMask from '../../../../components/WampErrorMask';
import Loader from '../../../../components/Loader';
import FilesWorkSpace from '../../components/FilesWorkSpace';

interface Props {
  documentId: number;
}

const HomeworkDocument: React.FC<Props> = (props: Props) => {
  const {documentId} = props;
  const {homeworkId, courseId, studentTeacherId} = useParams<HomeworkPlayerUrlParams>();
  const [docInstance, setDocInstance] = useState<DocumentFile | undefined>();
  const action = useMemo(
    () => loadHomeworkDocument(courseId!, homeworkId!, documentId),
    [courseId, homeworkId, documentId]
  );
  const successHandler = useCallback(
    (response: {documentInstance: DocumentFileV2}) =>
      setDocInstance({
        ...response.documentInstance,
        href: response.documentInstance.gdocId,
        gdoc_id: response.documentInstance.gdocId
      }),
    []
  );
  useEffect(
    () => () => {
      setDocInstance(undefined);
    },
    [documentId]
  );
  const {isError, reload} = useApiRequest(action, successHandler);
  if (isError) {
    return <WampErrorMask reload={reload} />;
  }
  if (!docInstance) {
    return <Loader />;
  }
  return (
    <FilesWorkSpace
      studentTeacherId={Number(studentTeacherId)}
      courseId={Number(courseId)}
      homeworkDoc={docInstance}
      readonly={false}
      wampSubscribed={true}
    />
  );
};

export default HomeworkDocument;
