import React, {type FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {unstable_batchedUpdates} from 'react-dom';

import {usePDFViewerPage} from 'components/PDFViewer/PDFViewerPageContext';
import {PDFPainter} from 'components/Paint/PDFPainter';
import {type FilesListSelectedDocPageUrlParams} from 'common/paths';
import {useAxiosRequest} from 'hooks/rest/useAxiosRequest';

import {requestEnsurePDFPage} from '../actions/action';

interface DocumentInstancePageResult {
  yDocId: string;
}

interface Props {
  documentInstanceId: number;
  pageNumber?: number;
}

export const PDFPagePainter: FC<Props> = ({
  documentInstanceId: propDocumentInstanceId,
  pageNumber: propPageNumber
}) => {
  const {width, height, setChildrenLoading, setChildrenError} = usePDFViewerPage();
  const [docId, setDocId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const {documentId: routeDocumentInstanceId, pageNumber: routePageNumber = 1} =
    useParams<FilesListSelectedDocPageUrlParams>();

  const documentInstanceId = propDocumentInstanceId || routeDocumentInstanceId;
  const pageNumber = propPageNumber || routePageNumber;

  const {request} = useAxiosRequest(requestEnsurePDFPage);

  const reload = useCallback(
    () => {
      unstable_batchedUpdates(() => {
        // setChildrenError();
        setIsError(false);
        setDocId(null);
        setIsLoading(false);
      });
    },
    [
      /*setChildrenError*/
    ]
  );

  const requestPage = useCallback(async (): Promise<void> => {
    try {
      const res: DocumentInstancePageResult = await request(
        Number(documentInstanceId),
        Number(pageNumber),
        width,
        height
      );
      unstable_batchedUpdates(() => {
        setIsLoading(false);
        setIsError(false);
        setDocId(res.yDocId);
      });
    } catch (e) {
      unstable_batchedUpdates(() => {
        setIsError(true);
        setIsLoading(false);
        setDocId(null);
        setChildrenLoading(false);
        setChildrenError(reload);
      });
    }
  }, [
    documentInstanceId,
    height,
    pageNumber,
    reload,
    request,
    setChildrenError,
    setChildrenLoading,
    width
  ]);

  useLayoutEffect(() => {
    setChildrenLoading(true);
  }, [setChildrenLoading, requestPage]);

  useEffect(() => {
    if (!isLoading && !docId && !isError && width && height) {
      setIsLoading(true);
      requestPage();
    }
  }, [docId, height, isError, isLoading, requestPage, width]);

  if (!docId || !width || !height) {
    return null;
  }

  return <PDFPainter docId={docId} />;
};
