import {type AxiosRequestAction} from 'services/axios/interface';

const prefix = 'HOMEWORK_DRAFT/';
const ACTIVATE_DRAFT = prefix + 'ACTIVATE_DRAFT';

export const activateDraft = (
  courseInstanceId: number,
  draftId: string,
  comment?: string
): AxiosRequestAction => ({
  type: ACTIVATE_DRAFT,
  payload: {
    client: 'v2',
    request: {
      url: `v2/course-instance/${courseInstanceId}/homework/${draftId}`,
      method: 'POST',
      data: {comment}
    }
  }
});
