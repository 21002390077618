import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';

import SearchBar from '../../../../../components/SearchBar';

const messages = defineMessages({
  Placeholder: {
    id: 'LibraryModal.SearchPlaceholder'
  }
});

interface Props {
  search: string;
  onChangeSearch: (search: string) => void;
  onClick: () => void;
}

export const LibraryActions: React.FC<Props> = ({search, onChangeSearch, onClick}) => {
  const {formatMessage} = useIntl();

  const disabled = Boolean(search.trim().length);

  return (
    <div className="library-actions">
      <Button className="btn-transparent" bsSize="xs" onClick={onClick} disabled={disabled}>
        <FormattedMessage id="Common.Collapse.All" />
      </Button>

      <SearchBar
        filter={search}
        changeFilter={onChangeSearch}
        isSmall={true}
        isMobile={true}
        placeholder={formatMessage(messages.Placeholder)}
        clearOnUnmount={false}
      />
    </div>
  );
};
