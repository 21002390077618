import React, {
  type CSSProperties,
  type PropsWithChildren,
  type SyntheticEvent,
  useCallback,
  useState
} from 'react';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {useParams} from 'react-router-dom';

import {push} from 'store/router';
import useSendApiRequest from 'hooks/rest/useSendApiRequest';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {type AppState} from 'store/interface';
import Spinner from 'components/Spinner';
import Confirm from 'components/modals/Confirm';
import i18n from 'components/Homework/i18n';
import {deleteHomework} from 'components/Homework/actions';
import {setRedirectedFromUrl} from 'common/action';
import {homeworkPlayerPath, type ClassroomUrlParams} from 'common/paths';
import {HomeworkModal} from 'components/Homework/Modals/HomeworkModal';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {activateDraft} from './actions';

import './HomeworkDraftControl.scss';

const messages = defineMessages({
  Publish: {
    id: 'Homework.Draft.Publish'
  },
  Published: {
    id: 'Homework.Draft.Published'
  },
  PublicationFailed: {
    id: 'Homework.Draft.PublicationFailed'
  },
  Delete: {
    id: 'Homework.Draft.Delete'
  },
  View: {
    id: 'Homework.Draft.View'
  },
  Back: {
    id: 'Homework.Draft.GoBack'
  }
});

interface Props {
  deleteCallback?(): void;
  activateCallback?(): void;
  isViewingDraft?: boolean;
  renderDeleteAndViewButtons?: boolean;
  renderActivateButton?: boolean;
}

interface DeleteHomeworkResponseData {
  homeworkId: string;
  courseInstanceId: number;
  isHomeworkDraft: boolean;
}

const HomeworkDraftControl: React.FC<PropsWithChildren<Props>> = ({
  deleteCallback,
  activateCallback,
  children,
  renderDeleteAndViewButtons,
  renderActivateButton
}) => {
  const {formatMessage} = useIntl();
  const axios = useAxiosDispatch();

  const params = useParams<ClassroomUrlParams>();

  const [isShowingActivateConfirm, setShowingActivateConfirm] = useState<boolean>();
  const [titleWidth, setTitleWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const showActivateConfirm = useCallback(() => setShowingActivateConfirm(true), []);
  const hideActivateConfirm = useCallback(() => setShowingActivateConfirm(false), []);

  const [isShowingDeleteConfirm, setShowingDeleteConfirm] = useState<boolean>();
  const onDeleteDraftClick = useCallback((e: SyntheticEvent<HTMLButtonElement | Button>) => {
    // fix invalid position of buttons after clicking this button and then declining confirm
    (e.currentTarget as HTMLButtonElement).blur();
    setShowingDeleteConfirm(true);
  }, []);
  const hideDeleteConfirm = useCallback(() => setShowingDeleteConfirm(false), []);

  const homeworkDraft = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState.homeworkDraft
  );
  const currentURL = useSelector((state: AppState) => state.router.location!.pathname);
  const dispatch = useDispatch();

  const onViewButtonClick = useCallback(() => {
    dispatch(push(homeworkPlayerPath(params, homeworkDraft!.id)));
    dispatch(setRedirectedFromUrl(currentURL, 'homework'));
  }, [dispatch, homeworkDraft, params, currentURL]);

  const titleRefCallback = useCallback(
    node => {
      if (node && !titleWidth) {
        setTitleWidth(node.getBoundingClientRect().width);
      }
    },
    [titleWidth]
  );

  const onAccept = useCallback(
    (comment?: string) => {
      if (!homeworkDraft?.id) return;

      setLoading(true);

      axios(activateDraft(Number(params.courseId), homeworkDraft.id, comment))
        .then(() => {
          setShowingActivateConfirm(false);
          activateCallback?.();
          toastr.success('', formatMessage(messages.Published));
        })
        .catch(() => {
          setShowingActivateConfirm(false);
          toastr.error('', formatMessage(messages.PublicationFailed));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [homeworkDraft?.id, axios, params.courseId, activateCallback, formatMessage]
  );

  const {call: deleteHW, isLoading: deleting} = useSendApiRequest<
    DeleteHomeworkResponseData,
    (number | string | undefined)[]
  >(
    deleteHomework,
    [Number(params.courseId), homeworkDraft && homeworkDraft.id],
    () => {
      setShowingDeleteConfirm(false);
      deleteCallback?.();
      toastr.success('', formatMessage(i18n.homeworkDeleteSuccessToast));
    },
    () => {
      setShowingDeleteConfirm(false);
      toastr.error('', formatMessage(i18n.homeworkDeleteFailToast));
    }
  );

  if (!homeworkDraft && !children) {
    return null;
  }

  const disableButtons = loading || deleting;

  return (
    <ButtonGroup
      className={classNames('homework-draft-control', {
        transparent: renderActivateButton && !titleWidth,
        open: isShowingActivateConfirm || isShowingDeleteConfirm,
        collapsible: !!homeworkDraft && renderActivateButton
      })}
      style={{'--titleWidth': `${-titleWidth}px`} as CSSProperties}
    >
      {homeworkDraft && (
        <>
          <HomeworkModal
            show={isShowingActivateConfirm}
            headerText={formatMessage(i18n.homeworkActivateConfirmation)}
            isLoading={loading}
            onAccept={onAccept}
            onHide={hideActivateConfirm}
          />

          <Confirm
            show={isShowingDeleteConfirm}
            onAccept={deleteHW}
            onDecline={hideDeleteConfirm}
            headerText={formatMessage(i18n.homeworkDeleteConfirmation)}
            disableButtons={deleting}
          />
          {renderActivateButton && (
            <Button
              className="btn-transparent activate-btn"
              disabled={disableButtons}
              onClick={showActivateConfirm}
            >
              <span className="homework-icon-wrapper">
                {loading ? <Spinner size={15} /> : <Icon name="virc-homework" />}
              </span>
              <span className="title" ref={titleRefCallback}>
                {formatMessage(messages.Publish)}
              </span>
              {`(${
                homeworkDraft.exercises.length +
                homeworkDraft.documents.length +
                homeworkDraft.documentPages.length
              })`}
            </Button>
          )}
          {renderDeleteAndViewButtons && (
            <div className="transition-container">
              <Button
                className="btn-transparent btn-view"
                disabled={disableButtons}
                onClick={onViewButtonClick}
                title={formatMessage(messages.View)}
              >
                <Icon name="eye" />
              </Button>
              <Button
                className="btn-transparent btn-delete"
                disabled={disableButtons}
                title={formatMessage(messages.Delete)}
                onClick={onDeleteDraftClick}
              >
                {deleting ? <Spinner size={15} /> : <Icon name="trash" />}
              </Button>
            </div>
          )}
        </>
      )}
      {children}
    </ButtonGroup>
  );
};

export default React.memo(HomeworkDraftControl);
