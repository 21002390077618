import {defineMessages} from 'react-intl';

export const fileMessages = defineMessages({
  EditFileConfirm: {
    id: 'File.FileEditConfirmation'
  },
  EditFileReject: {
    id: 'File.FileEditRejection'
  },
  EditSuccessToast: {
    id: 'File.EditSuccessToast'
  },
  EditErrorToast: {
    id: 'File.EditErrorToast'
  },
  PlayTogetherErrorToast: {
    id: 'File.PlayTogetherErrorToast'
  }
});

export const audioMessages = defineMessages({
  PlayMessage: {
    id: 'Common.Play'
  },
  PauseMessage: {
    id: 'File.PauseButton'
  },
  StopButtonMessage: {
    id: 'File.StopButton'
  },
  PlayTogetherMessage: {
    id: 'File.PlayTogether'
  }
});

export const documentMessages = defineMessages({
  ViewTogetherMessage: {
    id: 'File.ViewTogether'
  }
});
