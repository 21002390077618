import React, {type FC} from 'react';
import {useParams} from 'react-router-dom';

import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {type Courses, type StudentTeachers} from 'store/interface';
import {
  type ClassroomUrlParams,
  roomPath,
  studentTeacherAndCoursePath,
  studentTeacherPath
} from 'common/paths';

import {ActionTogetherProvider} from './FilesWorkSpace/context/AudioTogetherContext';
import {PageNotFound} from '../../PageNotFound/components/PageNotFound';
import Breadcrumbs from '../containers/Breadcrumbs';
import {type LessonPageProps} from './interface';
import StudentTeacherRoute from '../containers/StudentTeacherRoute';
import './LessonPage.scss';
import {
  type RequestCoursesSuccessAction,
  type RequestStudentTeachersSuccessAction
} from '../actions/interface';

interface Props extends LessonPageProps, ClassroomUrlParams {}

class LessonPage extends React.PureComponent<Props> {
  private studentTeachersRequest?: CancellablePromise;
  private coursesRequest?: CancellablePromise;

  private get isStudent(): boolean {
    return this.props.role === 'student';
  }

  public componentDidMount() {
    if (!this.props.studentTeachers) {
      this.requestStudentTeachers();
    } else {
      this.goToStudentTeacherPath();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {studentTeacherId, studentTeachers, selectedStudentTeacher, setSelectedStudentTeacher} =
      this.props;
    const {
      studentTeachers: prevStudentTeachers,
      studentTeacherId: prevActiveStudentTeacherId,
      incorrectUrlId
    } = prevProps;
    if (!studentTeacherId || incorrectUrlId) {
      return;
    }
    if (
      studentTeacherId !== prevActiveStudentTeacherId ||
      (studentTeachers && !prevStudentTeachers)
    ) {
      this.requestCourses(Number(studentTeacherId));
      if (Number(studentTeacherId) !== selectedStudentTeacher) {
        setSelectedStudentTeacher(+studentTeacherId, this.isStudent);
      }
    }
  }

  public componentWillUnmount(): void {
    if (this.coursesRequest) {
      this.coursesRequest.cancel();
    }
    if (this.studentTeachersRequest) {
      this.studentTeachersRequest.cancel();
    }
    this.props.clearClassroom();
  }

  public render() {
    if (this.props.incorrectUrlId) {
      return <PageNotFound />;
    }

    const {courseId, studentTeacherId, studentTeachers, role, courses} = this.props;

    const courseOrSTInactive =
      !!studentTeacherId &&
      Boolean(
        (studentTeachers?.[studentTeacherId] && studentTeachers?.[studentTeacherId]?.deleted_at) ||
          (courseId && courses?.[courseId] && courses?.[courseId]?.deleted_at)
      );
    return (
      <div className="lesson-page">
        {this.props.networkError ? <WampErrorMask reload={this.clearClassroomAndUrl} /> : null}
        {!studentTeachers || !studentTeacherId ? (
          <Loader />
        ) : (
          <ActionTogetherProvider>
            <Breadcrumbs
              selectCourse={this.selectCourse}
              changeActiveStudentTeacher={this.changeActiveStudentTeacher}
              selectedCourseId={courseId}
              selectedStudentTeacherId={studentTeacherId}
              studentTeachers={studentTeachers}
            />
            <div className="lesson-work-space">
              <StudentTeacherRoute
                courseOrSTInactive={courseOrSTInactive}
                studentTeachers={studentTeachers}
                role={role}
                courseInstances={courses}
                courseInstanceId={courseId}
              />
            </div>
          </ActionTogetherProvider>
        )}
      </div>
    );
  }

  private requestStudentTeachers() {
    const {requestStudentTeachers, setClassroomNetworkError, role} = this.props;
    const roleToExpandTo = role === 'teacher' ? 'student' : 'teacher';
    this.studentTeachersRequest && this.studentTeachersRequest.cancel();
    this.studentTeachersRequest = makeCancellable(
      requestStudentTeachers(roleToExpandTo),
      this.handleStudentTeachersRequestSuccess,
      setClassroomNetworkError
    );
  }

  private handleStudentTeachersRequestSuccess = (action: RequestStudentTeachersSuccessAction) => {
    const {
      studentTeacherId: preselectedStudentTeacherId,
      setStudentTeachers,
      setIncorrectUrlId
    } = this.props;
    const studentTeachers: StudentTeachers = {};
    action.payload.data.forEach(studentTeacher => {
      studentTeachers![studentTeacher.id] = {
        ...studentTeacher,
        recipient: (studentTeacher.teacher || studentTeacher.student)!
      };
    });

    if (preselectedStudentTeacherId && !studentTeachers[preselectedStudentTeacherId]) {
      setIncorrectUrlId();
      return;
    }

    setStudentTeachers(studentTeachers);

    this.goToStudentTeacherPath(studentTeachers);
  };

  private goToStudentTeacherPath = (studentTeachersArg?: StudentTeachers) => {
    const {
      studentTeacherId: preselectedStudentTeacherId,
      selectedStudentTeacher,
      setSelectedStudentTeacher,
      studentTeachers,
      replace,
      selectChatRoomRelatedToSelectedStudentTeacher,
      courses
    } = this.props;
    const st = studentTeachersArg || studentTeachers;

    if (!st) return;

    const studentTeachersValues = Object.values(st);
    if (!preselectedStudentTeacherId) {
      if (selectedStudentTeacher && st[selectedStudentTeacher]) {
        replace(studentTeacherPath(selectedStudentTeacher));
      } else {
        const firstActiveStudentTeacher = studentTeachersValues.find(
          studentTeacher => !studentTeacher.deleted_at
        );
        const studentTeacherToSelect = firstActiveStudentTeacher || studentTeachersValues[0];
        setSelectedStudentTeacher(+studentTeacherToSelect.id, this.isStudent);
        replace(studentTeacherPath(studentTeacherToSelect.id));
      }
    } else {
      if (!courses) this.requestCourses(+preselectedStudentTeacherId);
      setSelectedStudentTeacher(+preselectedStudentTeacherId, this.isStudent);
    }
    selectChatRoomRelatedToSelectedStudentTeacher();
  };

  private requestCourses(studentTeacherId: number) {
    const {setClassroomNetworkError, requestCourses, clearCourses} = this.props;
    if (this.coursesRequest) return;
    clearCourses();
    this.coursesRequest = makeCancellable(
      requestCourses(studentTeacherId),
      this.handleCoursesRequestSuccess,
      () => {
        setClassroomNetworkError();
        this.coursesRequest = undefined;
      }
    );
  }

  private handleCoursesRequestSuccess = (action: RequestCoursesSuccessAction) => {
    const {
      setCourses,
      courseId: preselectedCourseId,
      studentTeacherId,
      replace,
      setIncorrectUrlId
    } = this.props;

    this.coursesRequest = undefined;

    const courses: Courses = {};
    action.payload.data.forEach(course => {
      courses![course.id] = course;
    });
    setCourses(courses);
    if (!preselectedCourseId) {
      const firstActiveCourse = action.payload.data.find(course => !course.deleted_at);
      const courseToSelect = firstActiveCourse || action.payload.data[0];
      replace(studentTeacherAndCoursePath(Number(studentTeacherId!), courseToSelect.id));
      return;
    }

    if (!courses[preselectedCourseId]) {
      setIncorrectUrlId();
    }
  };

  private clearClassroomAndUrl = () => {
    this.props.clearClassroom();
    this.props.push(roomPath());
    this.requestStudentTeachers();
  };

  private selectCourse = (id: number) => {
    const {push, studentTeacherId} = this.props;
    push(studentTeacherAndCoursePath(Number(studentTeacherId!), id));
  };

  private changeActiveStudentTeacher = (id: number) => {
    const {setSelectedStudentTeacher, studentTeachers, studentTeacherId, push} = this.props;
    if (studentTeachers) {
      setSelectedStudentTeacher(id, this.isStudent);
    }
    if (Number(studentTeacherId) !== id) {
      push(studentTeacherPath(id));
    }
  };
}

const LessonRouterPage: FC<LessonPageProps> = props => {
  const {studentTeacherId, courseId} = useParams<ClassroomUrlParams>();
  return <LessonPage {...props} studentTeacherId={studentTeacherId} courseId={courseId} />;
};

export default LessonRouterPage;
