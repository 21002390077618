import React, {useCallback} from 'react';
import type {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

import {scrollToBeVisible} from 'helpers/scrollToBeVisible';

import {ThumbnailItem} from './ThumbnailItem';
import './ThumbnailList.scss';

interface Props {
  currentIndex: number;
  doc: PDFDocumentProxy;
  rotation?: number;
  goToIndex(pageNumber: number): void;
}

export const ThumbnailList: React.FC<Props> = ({currentIndex, doc, rotation = 0, goToIndex}) => {
  const {numPages} = doc;
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  // Scroll to the thumbnail that represents the current page
  const scrollToThumbnail = useCallback((target: HTMLElement) => {
    const container = containerRef.current;
    if (container && target.parentElement) {
      scrollToBeVisible(target.parentElement, container);
    }
  }, []);

  return (
    <div ref={containerRef} className="pdf-contents-thumbnail-list">
      {Array(numPages)
        .fill(0)
        .map((_, index) => (
          <ThumbnailItem
            key={`thumbnail-item-${index}`}
            doc={doc}
            currentIndex={currentIndex}
            index={index}
            rotation={rotation}
            scrollToThumbnail={scrollToThumbnail}
            goToIndex={goToIndex}
          />
        ))}
    </div>
  );
};
