import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {type EditHomeworkDocCommentProps} from './EditHomeworkCommentModal';
import {closeEditHomeworkCommentModal} from '../../components/FilesWorkSpace/documentsTab/actions/action';
import {useApiRequest} from '../../../../hooks/rest/useApiRequest';
import {
  assignDocumentInstanceToDraft,
  editHWDocumentCommentRequest
} from '../CourseInstancePage/actions';

export default function useEditHomeworkDocComment({
  homeworkDocumentsList,
  homeworkPlayerActiveHomework,
  courseId
}: EditHomeworkDocCommentProps) {
  const intl = useIntl();
  const editDocumentInstanceId = useSelector(
    (state: AppState) => state.docs && state.docs.editHomeworkCommentForDocument
  );
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [requestAction, setRequestAction] = useState<AxiosRequestAction | null>(null);
  const dispatch = useDispatch();

  const initialComment = useMemo(() => {
    if (!homeworkDocumentsList) {
      return undefined;
    }
    const selectedDoc = homeworkDocumentsList.find(
      doc => doc.documentInstanceId === editDocumentInstanceId
    );
    return selectedDoc && selectedDoc.comment;
  }, [homeworkDocumentsList, editDocumentInstanceId]);

  const closeCommentEditor = useCallback(
    () => dispatch(closeEditHomeworkCommentModal()),
    [dispatch]
  );
  const {isLoading} = useApiRequest(
    requestAction,
    () => {
      const message = initialComment
        ? intl.formatMessage({id: 'Files.EditDraftCommentSuccess'})
        : intl.formatMessage({id: 'Files.AddDocToDraftSuccess'});
      toastr.success('', message);
      setRequestAction(null);
      closeCommentEditor();
    },
    () => {
      const message = initialComment
        ? intl.formatMessage({id: 'Files.EditCommentFail'})
        : intl.formatMessage({id: 'Files.AddDocToDraftFail'});
      toastr.error('', message);
      setRequestAction(null);
    }
  );
  const save = useCallback(
    (comment: string) => {
      if (homeworkPlayerActiveHomework) {
        setRequestAction(
          editHWDocumentCommentRequest(
            courseId,
            homeworkPlayerActiveHomework,
            editDocumentInstanceId!,
            comment
          )
        );
      } else {
        setRequestAction(assignDocumentInstanceToDraft(courseId, editDocumentInstanceId!, comment));
      }
    },
    [setRequestAction, courseId, editDocumentInstanceId, homeworkPlayerActiveHomework]
  );
  return {
    isLoading,
    save,
    initialComment,
    editDocumentInstanceId,
    closeCommentEditor,
    setComment,
    comment
  };
}
