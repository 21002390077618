import {type Action, type Dispatch} from 'redux';
import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import {
  type ChangePlayStatusActionCreator,
  type ChangeTimestampActionCreator,
  type ChangeVolumeActionCreator,
  type LengthEditCreator,
  type PlayMode,
  type PlayStatus,
  type Sound
} from '../../actions/interface';
import {changePlayStatus, changeTimestamp, changeVolume, lengthEdit} from '../../actions/action';
import AudioPlayer from './AudioPlayer';

interface AudioPlayerStateProps {
  activeSound?: Sound;
  activeFileId?: number;
  volume: number;
  startedAt?: number;
  playStatus: PlayStatus;
  playbackRate?: number;
  playMode: PlayMode | null;
  timestamp: number;
  filter: string;
  uniquePlaybackId?: string;
}

interface AudioPlayerDispatchProps {
  editDuration: LengthEditCreator;
  changePlayStatus: ChangePlayStatusActionCreator;
  changeTimestamp: ChangeTimestampActionCreator;
  changeVolume: ChangeVolumeActionCreator;
}

export interface AudioPlayerProps extends AudioPlayerStateProps, AudioPlayerDispatchProps {}

type AudioPlayerStateToProps = (state: AppState) => AudioPlayerStateProps;
type AudioPlayerDispatchToProps = (dispatch: Dispatch<Action>) => AudioPlayerDispatchProps;

const mapStateToProps: AudioPlayerStateToProps = state => ({
  activeSound: state.sounds!.activeSound
    ? state.sounds!.sounds![state.sounds!.activeSound]
    : undefined,
  activeFileId: state.sounds!.activeSound ? state.sounds!.activeSound : undefined,
  volume: state.sounds!.volume === undefined ? 1 : state.sounds!.volume,
  startedAt: state.sounds!.startedAt ? state.sounds!.startedAt : undefined,
  playStatus: state.sounds!.playStatus,
  playMode: state.sounds!.playMode,
  playbackRate: state.sounds!.playbackRate,
  timestamp: state.sounds!.timestamp,
  uniquePlaybackId: state.sounds!.uniquePlaybackId,
  filter: state.filesWorkSpace!.filesFilter
});

const mapDispatchToProps: AudioPlayerDispatchToProps = dispatch => ({
  editDuration: (id: number, duration: number) => dispatch(lengthEdit(id, duration)),
  changePlayStatus: (status: PlayStatus) => dispatch(changePlayStatus(status)),
  changeTimestamp: (timestamp: number) => dispatch(changeTimestamp(timestamp)),
  changeVolume: (volume: number) => dispatch(changeVolume(volume))
});

const AudioPlayerContainer = connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);

export default AudioPlayerContainer;
