import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import Icon from '../../Icon';

import './ViewModeToggles.scss';

export enum ViewMode {
  THUMBNAILS = 'thumbnails',
  OUTLINE = 'outline'
}

interface Props {
  mode: ViewMode;
  setMode: (m: ViewMode) => void;
  hasOutline?: boolean;
}

export const ViewModeToggles: FC<Props> = ({mode, setMode, hasOutline}) => {
  const intl = useIntl();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const {mode} = e.currentTarget.dataset;
      setMode(mode as ViewMode);
    },
    [setMode]
  );

  return (
    <div className="pdf-contents__view-mode-toggles">
      <button
        className={classNames({active: ViewMode.THUMBNAILS === mode})}
        data-mode={ViewMode.THUMBNAILS}
        onClick={onClick}
        title={intl.formatMessage({id: 'Common.Thumbnails'})}
      >
        <Icon name="th-large" />
      </button>
      <button
        className={classNames({active: ViewMode.OUTLINE === mode})}
        data-mode={ViewMode.OUTLINE}
        disabled={!hasOutline}
        onClick={onClick}
        title={intl.formatMessage({id: 'Common.Outline'})}
      >
        <Icon name="th-list" />
      </button>
    </div>
  );
};
