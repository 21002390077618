import {type Sounds, type SoundsState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {type WampPublishAction} from 'services/wamp/actions/interface';
import {resolveParamFromUri} from 'services/wamp/uriIdResolver';

import {
  type ChangeFileAction,
  type FileAction,
  type FileDeleteSuccessAction,
  type FileEditSuccessAction,
  type FileViewedSuccessAction,
  type LoadFilesSuccessAction,
  type OpenEditorAction,
  type SetIsDeletedRequestSentAction
} from '../../../../actions/interface';
import {
  CHANGE_ACTIVE,
  CHANGE_PLAY_STATUS,
  CHANGE_PLAYBACK_RATE,
  CHANGE_TIMESTAMP,
  CHANGE_VOLUME,
  CLEAR_OPENED_TOGETHER,
  DELETE_SOUND_SUCCESS,
  EDIT_LENGTH,
  EDIT_SOUND,
  EDIT_SOUND_ERROR,
  EDIT_SOUND_SUCCESS,
  OPEN_EDITOR,
  REQUEST_SOUNDS_FAIL,
  REQUEST_SOUNDS_SUCCESS,
  SET_SOUND_DELETING,
  SET_UNIQUE_PLAYBACK_ID,
  SOUND_VIEWED_SUCCESS,
  SOUNDS_RESET,
  TOGGLE_VOLUME_POPOVER
} from '../actions/actionTypes';
import {
  type ChangePlaybackRateAction,
  type ChangePlayStatusAction,
  type ChangeTimestampAction,
  type ChangeVolumeAction,
  type LengthEditAction,
  PLAYING,
  PAUSE,
  type PlayMode,
  type SetUniquePlaybackIdAction,
  type Sound,
  STOPPED
} from '../actions/interface';
import {PUBLISH_OPEN_MATERIALS} from '../../../../actions/actionTypes';
import {type ToggleElementAction} from '../../../../../../common/interface';
import {type ActionTogetherContext} from '../../context/AudioTogetherContext';

const initialSoundsState: SoundsState = {
  playStatus: STOPPED,
  playMode: null,
  timestamp: 0,
  editSoundAwait: false
};

const ACTION_HANDLERS: ActionHandlersList<SoundsState, FileAction> = {
  [CHANGE_ACTIVE]: (state: SoundsState, action: ChangeFileAction): SoundsState => {
    if (action.id === null) {
      const {startedAt, activeSound, ...nextState}: SoundsState = state;
      return {
        ...nextState,
        playMode: null
      };
    } else if (state.sounds?.[action.id]) {
      return {
        ...state,
        activeSound: action.id,
        timestamp: 0,
        playMode: null
      };
    } else {
      return state;
    }
  },
  [CHANGE_PLAY_STATUS]: (state: SoundsState, action: ChangePlayStatusAction): SoundsState => {
    if (state.activeSound) {
      if (action.playStatus !== PLAYING) {
        const {startedAt, ...nextState}: SoundsState = state;
        if (action.playStatus === STOPPED) {
          return {
            ...nextState,
            playStatus: PAUSE,
            playMode: null,
            timestamp: 0,
            uniquePlaybackId: undefined,
            startedAt: new Date().getTime()
          };
        } else {
          return {
            ...nextState,
            playStatus: action.playStatus
          };
        }
      } else {
        return {
          ...state,
          startedAt: action.startedAt,
          playStatus: action.playStatus,
          playMode: action.playMode as PlayMode
        };
      }
    } else {
      return state;
    }
  },
  [SET_UNIQUE_PLAYBACK_ID]: (state: SoundsState, {id}: SetUniquePlaybackIdAction): SoundsState => ({
    ...state,
    uniquePlaybackId: id
  }),
  [CHANGE_TIMESTAMP]: (state: SoundsState, action: ChangeTimestampAction): SoundsState => {
    if (action.timestamp >= 0) {
      return {
        ...state,
        timestamp: action.timestamp,
        startedAt: action.startedAt
      };
    } else {
      return state;
    }
  },
  [EDIT_LENGTH]: (state: SoundsState, action: LengthEditAction): SoundsState => {
    if (state.sounds && state.sounds[action.id]) {
      const sounds: Sounds = {
        ...state.sounds,
        [action.id]: {
          ...state.sounds[action.id],
          length: action.length
        }
      };
      return {
        ...state,
        sounds
      };
    } else {
      return state;
    }
  },
  [CHANGE_PLAYBACK_RATE]: (
    state: SoundsState,
    {playbackRate}: ChangePlaybackRateAction
  ): SoundsState => ({
    ...state,
    playbackRate
  }),
  [OPEN_EDITOR]: (state: SoundsState, action: OpenEditorAction): SoundsState => {
    if (action.id === null || state.sounds![action.id]) {
      return {
        ...state,
        editedSound: action.id || undefined
      };
    } else {
      return state;
    }
  },
  [REQUEST_SOUNDS_SUCCESS]: (state: SoundsState, action: LoadFilesSuccessAction): SoundsState => {
    const {loadingSounds, ...newState} = state;
    if (!newState.sounds) {
      newState.sounds = {};
    } else {
      newState.sounds = {...newState.sounds};
    }
    const ids = (action.payload.data as Sound[]).map(sound => {
      if (!newState.sounds![sound.id]) {
        newState.sounds![sound.id] = sound;
        sound.created_at = new Date(sound.created_at);
        sound.isNew = sound.views && (sound.views.length === 0 || !sound.views[0].read_at);
        sound.href = sound.url;
      } else if (state.sounds![sound.id].title !== sound.title) {
        newState.sounds![sound.id] = {
          ...newState.sounds![sound.id],
          title: sound.title
        };
      } else if (state.sounds![sound.id].pinned !== sound.pinned) {
        newState.sounds![sound.id] = {
          ...newState.sounds![sound.id],
          pinned: sound.pinned
        };
        if (!sound.pinned && sound.id === state.activeSound) {
          newState.activeSound = undefined;
          newState.openTogetherFileId = undefined;
        }
      }
      return String(sound.id);
    });
    Object.keys(newState.sounds).forEach(key => {
      if (!ids.includes(key)) {
        delete newState.sounds![key];
      }
    });
    if (newState.activeSound && !newState.sounds[newState.activeSound]) {
      newState.activeSound = undefined;
      newState.playStatus = STOPPED;
      newState.playMode = null;
      newState.startedAt = undefined;
    }
    return newState;
  },
  [REQUEST_SOUNDS_FAIL]: (state: SoundsState): SoundsState => ({
    ...state,
    loadingSounds: undefined,
    networkError: true,
    sounds: {}
  }),
  [EDIT_SOUND]: (state: SoundsState): SoundsState => ({
    ...state,
    editSoundAwait: true
  }),
  [EDIT_SOUND_SUCCESS]: (state: SoundsState, action: FileEditSuccessAction): SoundsState => {
    const {editedSound, ...newState} = state;
    newState.editSoundAwait = false;
    if (newState.sounds && newState.sounds[action.payload.data.id]) {
      newState.sounds = {...newState.sounds};
      newState.sounds[action.payload.data.id] = {
        ...newState.sounds[action.payload.data.id],
        title: action.payload.data.title
      };
    }
    return newState;
  },
  [EDIT_SOUND_ERROR]: (state: SoundsState): SoundsState => ({
    ...state,
    editSoundAwait: false
  }),
  [SET_SOUND_DELETING]: (
    state: SoundsState,
    action: SetIsDeletedRequestSentAction
  ): SoundsState => ({
    ...state,
    sounds: {
      ...state.sounds,
      [action.fileId]: {
        ...state.sounds![action.fileId],
        isBeingDeletedComponentId: action.isBeingDeleted ? action.componentId : undefined
      }
    }
  }),
  [DELETE_SOUND_SUCCESS]: (state: SoundsState, action: FileDeleteSuccessAction): SoundsState => {
    const newState: SoundsState = {
      ...state,
      sounds: {...state.sounds}
    };
    const soundId: number | null = resolveParamFromUri(
      'instanceId',
      action.meta.previousAction.payload.request.url!
    );
    if (soundId) {
      delete newState.sounds![soundId];
    }
    return newState;
  },
  [SOUND_VIEWED_SUCCESS]: (state: SoundsState, action: FileViewedSuccessAction): SoundsState => {
    if (!state.sounds || !state.sounds[action.payload.data.id]) {
      return state;
    }
    return {
      ...state,
      sounds: {
        ...state.sounds,
        [action.payload.data.id]: {
          ...state.sounds[action.payload.data.id],
          isNew: false
        }
      }
    };
  },
  [SOUNDS_RESET]: (): SoundsState => ({
    ...initialSoundsState
  }),
  [PUBLISH_OPEN_MATERIALS]: (
    state: SoundsState,
    action: WampPublishAction<Array<ActionTogetherContext>, {}>
  ): SoundsState => {
    if (!action.wamp.args) return state;

    const [context] = action.wamp.args;
    const {type, fileId} = context;

    if (type === 'audio') {
      return {...state, openTogetherFileId: fileId};
    }

    return state;
  },
  [CLEAR_OPENED_TOGETHER]: (state: SoundsState): SoundsState => ({
    ...state,
    openTogetherFileId: undefined
  }),
  [CHANGE_VOLUME]: (state: SoundsState, action: ChangeVolumeAction): SoundsState => ({
    ...state,
    volume: action.volume
  }),
  [TOGGLE_VOLUME_POPOVER]: (state: SoundsState, action: ToggleElementAction): SoundsState => ({
    ...state,
    volumePopoverOpen: action.show
  })
};

export default function (state: SoundsState = initialSoundsState, action: FileAction): SoundsState {
  const soundsReducer = ACTION_HANDLERS[action.type];
  return soundsReducer ? soundsReducer(state, action) : state;
}
