import {type Documents, type Sounds, type UploadingFiles} from 'store/interface';

import preventFilterRegexpErrors from '../../../../helpers/toValidFilter';

export function getSearchResultsNumber(array: UploadingFiles | Documents | Sounds, filter: string) {
  const parsedFilter: string = preventFilterRegexpErrors(filter);
  let result: number = 0;
  for (const id in array) {
    if (array[id].title.toLowerCase().search(parsedFilter) !== -1) {
      result++;
    }
  }
  return result;
}
