import {connect, type MapStateToProps} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';

import {type FileType} from '../../../../actions/interface';
import {
  type DispatchFilesListProps,
  type FilesListOwnProps,
  type FilesListStateProps
} from '../../components/interface';
import {
  clearOpenedTogetherDoc,
  documentDelete,
  documentEdit,
  documentOpenEditor,
  loadDocuments,
  resetDocuments
} from '../actions/action';
import FilesList from '../../components/FilesList';
import {libraryModalOpened} from '../../Library/actions';
import {removeFileFromUploads} from '../../uploadingFiles/actions/action';
import {publishMaterialsOpen, publishMaterialsUpdated} from '../../../../actions/action';

const listIsEmpty = (state: AppState, courseId?: string) => {
  const documents = state.docs!.documents;
  const uploadingDocuments = state.uploads.uploadingDocuments;
  const uploadingCount = Object.keys(uploadingDocuments).filter(
    id => uploadingDocuments[id].course_instance_id.toString() === courseId
  ).length;
  return documents && uploadingCount === 0 && Object.keys(documents).length === 0;
};

const mapStateToProps: MapStateToProps<FilesListStateProps, {}, AppState> = (
  state: AppState,
  {courseId}: FilesListOwnProps
) => {
  return {
    locale: state.intl.locale,
    files: state.docs!.documents,
    filter: state.filesWorkSpace!.filesFilter,
    role: state.user.role,
    fileType: 'document',
    userTimezone: state.user.profile!.timeZone,
    userId: state.user.id,
    uploadingFiles: state.uploads.uploadingDocuments,
    listIsEmpty: listIsEmpty(state, courseId),
    networkError: state.docs!.networkError,
    openTogetherFileId: state.docs!.openTogetherFileId
  } as FilesListStateProps;
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>, ownProps: FilesListOwnProps) => ({
  editFileName: (id: number, title: string) => dispatch(documentEdit(id, title)),
  openEditor: (id: number | null) => dispatch(documentOpenEditor(id)),
  openLibraryModal: () => dispatch(libraryModalOpened()),
  deleteFile: (id: number) => dispatch(documentDelete(id)),
  removeFileFromUploads: (md5: string, id?: string) => dispatch(removeFileFromUploads(md5, id)),
  reloadFiles: () => {
    dispatch(resetDocuments());
    dispatch(loadDocuments(Number(ownProps.courseId)));
  },
  clearOpenedTogether: () => dispatch(clearOpenedTogetherDoc()),
  publishMaterialsUpdated: () => {
    if (ownProps.courseId) {
      dispatch(publishMaterialsUpdated(Number(ownProps.courseId)));
    }
  },
  publishMaterialsOpen: (fileId: number) => {
    const courseId = ownProps.courseId;
    if (courseId) {
      const context = {fileId, type: 'document' as FileType};
      dispatch(publishMaterialsOpen(Number(courseId), context));
    }
  },
  changeActiveFile: ownProps.selectDocument
});

export default connect<FilesListStateProps, DispatchFilesListProps, FilesListOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(FilesList);
