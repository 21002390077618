import React from 'react';

import {type EditHomeworkDocCommentProps} from './EditHomeworkCommentModal';
import useEditHomeworkDocComment from './useEditHomeworkDocComment';
import './EditHomeworkCommentPopover.scss';
import {EditHomeworkCommentPopover} from './EditHomeworkCommentPopover';

const EditHomeworkDocCommentPopover: React.FC<EditHomeworkDocCommentProps> = props => {
  const {closeCommentViewer, closeRequested, tellCommentEditorJustClosed} = props;

  return (
    <EditHomeworkCommentPopover
      {...useEditHomeworkDocComment(props)}
      closeCommentViewer={closeCommentViewer}
      closeRequested={closeRequested}
      tellCommentEditorJustClosed={tellCommentEditorJustClosed}
    />
  );
};

export default EditHomeworkDocCommentPopover;
