import {connect} from 'react-redux';

import {type AppState, type Documents} from 'store/interface';

import DocumentsTab from './DocumentsTab';
import {getSearchResultsNumber} from '../../../utils';
import {type DocumentsTabOwnProps, type DocumentsTabStateProps} from './interface';

const mapStateToProps = (
  state: AppState,
  ownProps: DocumentsTabOwnProps
): DocumentsTabStateProps => {
  let hasNewDocuments: boolean = false;
  const documents: Documents | undefined = state.docs!.documents;
  if (documents) {
    for (const doc in documents) {
      if (documents[doc].isNew) {
        hasNewDocuments = true;
        break;
      }
    }
  }
  let searchResultsNumber: number | undefined;
  if (state.filesWorkSpace!.filesFilter.length > 0 && state.docs!.documents) {
    searchResultsNumber = getSearchResultsNumber(
      state.docs!.documents,
      state.filesWorkSpace!.filesFilter
    );
  }
  return {
    activeDocument: documents && ownProps.selectedDocId && documents[ownProps.selectedDocId],
    role: state.user.role,
    hasNewDocuments,
    searchResultsNumber,
    collapsed: state.filesWorkSpace!.searchBarOpen
  } as DocumentsTabStateProps;
};

export default connect(mapStateToProps)(DocumentsTab);
