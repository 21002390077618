import React from 'react';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';

import {requestUnitInstances} from 'common/action';
import {type AppState, type CoursebookSection, type UnitInstance} from 'store/interface';

import {
  requestCoursebookInstanceContentsBySection,
  requestCoursebookInstanceContentsByUnit,
  requestCoursebookSections
} from './action';
import {type ViewContentsModalTab} from './interface';
import ContentsViewer from './ContentsViewer';

interface OwnProps {
  selectedTab: ViewContentsModalTab;
  coursebookId: string;
  coursebookInstanceId: string;
}

interface DispatchProps {
  loadCoursebookInstanceUnits: (coursebookId: string) => Promise<Action>;
  loadCoursebookSections: (coursebookId: string) => Promise<Action>;
  requestContentsBySection: (coursebookId: string, sectionId: number) => Promise<Action>;
  requestContentsByUnit: (coursebookInstanceId: string, unitInstanceId: number) => Promise<Action>;
}

interface Props extends OwnProps, DispatchProps {}

interface State {
  unitInstances?: UnitInstance[];
  sections?: CoursebookSection[];
  selectedUnitId?: number;
  selectedSectionId?: number;
  requestFailed?: boolean;
  sidebarPulledOut: boolean;
}

class CoursebookInstanceContentsViewer extends React.Component<Props, State> {
  public render() {
    return (
      <ContentsViewer
        selectedTab={this.props.selectedTab}
        coursebookId={this.props.coursebookId}
        coursebookInstanceId={this.props.coursebookInstanceId}
        loadCoursebookUnits={this.props.loadCoursebookInstanceUnits}
        loadCoursebookSections={this.props.loadCoursebookSections}
        requestContentsBySection={this.props.requestContentsBySection}
        requestContentsByUnit={this.props.requestContentsByUnit}
      />
    );
  }
}

const mapDisaptchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  loadCoursebookInstanceUnits: (coursebookInstanceId: string) =>
    dispatch(requestUnitInstances(coursebookInstanceId)),
  loadCoursebookSections: (coursebookId: string) =>
    dispatch(requestCoursebookSections(coursebookId)),
  requestContentsBySection: (coursebookInstanceId: string, sectionId: number) =>
    dispatch(requestCoursebookInstanceContentsBySection(coursebookInstanceId, sectionId)),
  requestContentsByUnit: (coursebookInstanceId, unitInstanceId) =>
    dispatch(requestCoursebookInstanceContentsByUnit(coursebookInstanceId, unitInstanceId))
});

export default connect(null, mapDisaptchToProps)(CoursebookInstanceContentsViewer);
