import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {maxFilesSearchResultNumber} from 'config/static';

import {LOADING, PAUSE, PLAYING} from '../actions/interface';
import {type AudioTabDispatchProps, type AudioTabStateProps} from '../containers/interface';
import AudioPlayer from './AudioPlayer';
import AudioSlider from './AudioSlider';
import VolumeControl from './VolumeControl';
import {PlaybackRate} from '../../../../../../components/media/AudioPlayer/views/components/PlaybackRate';
import {type ActionTogetherProps, AudioTogetherContext} from '../../context/AudioTogetherContext';
import {ActionsTogether} from '../../../../../../common/enums';
import {audioMessages} from '../../messages';

interface Props extends AudioTabStateProps, AudioTabDispatchProps, WrappedComponentProps {}

class AudioTab extends React.Component<Props, {}> {
  private tabClass: string = `lesson-tab-${this.props.role}`;
  private pinnedBlock: boolean = false;

  static contextType = AudioTogetherContext;
  public declare context: ActionTogetherProps;

  componentDidUpdate(prevProps: Props) {
    if (this.props.uniquePlaybackId && this.props.uniquePlaybackId !== prevProps.uniquePlaybackId) {
      this.pinnedBlock = this.props.uniquePlaybackId.includes('pinned');
    }
  }

  public render() {
    const {collapsed} = this.props;
    return (
      <div className={this.tabClass}>
        <AudioPlayer />
        <Icon name="virc-audio" size="lg" />
        {this.renderSoundsNotificationCircle()}
        <div className="file-control-middle">
          {!collapsed && this.renderText()}
          {!collapsed && this.renderLoading()}
          {this.renderControls()}
          {this.renderProgressBar()}
        </div>
        {this.renderSearchResultsNumber()}
      </div>
    );
  }

  private renderProgressBar = () => {
    if (this.props.activeSound) {
      return (
        <AudioSlider
          isActive={false}
          fieldId={this.props.fileId}
          isTogether={this.context.isTogether}
        />
      );
    } else {
      return null;
    }
  };

  private renderText = () => {
    const className: string = 'lesson-tab-text tab-music-text';
    if (this.props.activeSound) {
      return (
        <span className={className + ' small-text'} title={this.props.activeSound.title}>
          {this.props.activeSound.title}
        </span>
      );
    } else {
      return (
        <span className={className}>
          <FormattedMessage id="LessonPage.Sounds" />
        </span>
      );
    }
  };

  private renderSearchResultsNumber = () => {
    const className: string = 'lesson-tab-text tab-music-text';
    const {searchResultsNumber} = this.props;
    if (searchResultsNumber !== undefined) {
      const numberToDisplay =
        searchResultsNumber <= maxFilesSearchResultNumber
          ? searchResultsNumber
          : `${maxFilesSearchResultNumber}+`;
      return <span className={className}>({numberToDisplay})</span>;
    }
    return null;
  };

  private renderControls = () => {
    const {isTogether} = this.context;
    const {activeSound, intl, playbackRate, playStatus, isMobile} = this.props;

    if (activeSound != null) {
      const playingOrLoading = playStatus === PLAYING || playStatus === LOADING;

      return (
        <div className="audio-controls">
          {!isMobile && <VolumeControl />}
          <PlaybackRate changePlaybackRate={this.changePlaybackRate} playbackRate={playbackRate} />
          <Button
            className={classNames('btn-circle', playingOrLoading ? 'pause' : 'play', {
              together: isTogether
            })}
            onClick={this.playOrPauseClickHandler}
          >
            <Icon
              name={playingOrLoading ? 'pause' : 'play'}
              title={intl.formatMessage(
                playingOrLoading ? audioMessages.PauseMessage : audioMessages.PlayMessage
              )}
            />
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  private playOrPauseClickHandler = (e: React.SyntheticEvent<Button>) => {
    e.preventDefault();
    e.stopPropagation();

    const {fileId, activeSound, playStatus, changePlayStatus, playSound} = this.props;

    const playingOrLoading = playStatus === PLAYING || playStatus === LOADING;
    const uniquePlaybackId = this.pinnedBlock ? `${activeSound.id}-pinned` : `${activeSound.id}`;

    if (this.context.isTogether && fileId) {
      const action = playingOrLoading ? ActionsTogether.Pause : ActionsTogether.Play;
      const timestamp = this.context.getCurrentTime();
      return this.context.actionTogether(action, {fileId, uniquePlaybackId, timestamp});
    }

    if (this.props.uniquePlaybackId) {
      return changePlayStatus(playingOrLoading ? PAUSE : PLAYING);
    }

    playSound(uniquePlaybackId);
  };

  private changePlaybackRate = (playbackRate: number) => {
    if (this.context.isTogether && this.props.fileId) {
      return this.context.actionTogether(ActionsTogether.ChangePlaybackRate, {
        fileId: this.props.fileId,
        uniquePlaybackId: this.props.uniquePlaybackId,
        playbackRate
      });
    }

    this.props.changePlaybackRate(playbackRate);
  };

  private renderLoading = () => (this.props.playStatus === LOADING ? <Spinner size={15} /> : null);

  private renderSoundsNotificationCircle = () => {
    if (this.props.hasNewSounds) {
      return <span className="lesson-notification-circle" />;
    } else {
      return null;
    }
  };
}

export default injectIntl(AudioTab);
