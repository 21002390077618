import React, {type FC} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';
import {type CoursebookUnit} from 'store/interface';
import * as toastr from 'components/toastr';

import './CardContainer.scss';

interface Props {
  unit?: CoursebookUnit;
  selectUnit?: (id: number) => void;
  renderIsClosedOverlay?: boolean;
  hideOverlayOnHover?: boolean;
  redirectsTo?: string;
}

export const CardContainer: FC<Props> = ({
  unit,
  selectUnit,
  renderIsClosedOverlay,
  children,
  hideOverlayOnHover,
  redirectsTo
}) => {
  const intl = useIntl();

  const overview = unit?.overview;
  const unitNotEmpty = overview
    ? !!overview.mainExercisesCount || !!overview.extraExercisesCount
    : false;

  const onClick = () => {
    if (!selectUnit || !unit) return;

    unitNotEmpty
      ? selectUnit(unit.id)
      : toastr.error('', intl.formatMessage({id: 'Coursebook.Unit.EmptyError'}));
  };

  const view = (
    <div
      className={classNames('card-container', {
        'hide-overlay-on-hover': hideOverlayOnHover,
        pointer: onClick
      })}
      onClick={onClick}
    >
      {renderIsClosedOverlay ? (
        <div className="overlay">
          <Icon name="lock" />
        </div>
      ) : null}
      {children}
    </div>
  );
  if (!redirectsTo) {
    return <>{view}</>;
  }
  return (
    <Link className="link-reset" to={redirectsTo}>
      {view}
    </Link>
  );
};
