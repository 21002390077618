import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';

import AudioSlider from './AudioSlider';
import {
  type ChangePlayStatusActionCreator,
  type ChangeTimestampActionCreator,
  type PlayStatus,
  type Sound
} from '../../actions/interface';
import {changePlayStatus, changeTimestamp} from '../../actions/action';

export interface AudioSliderStateProps {
  uniquePlaybackId?: string;
  playStatus: PlayStatus;
  activeSound?: Sound;
  timestamp: number;
}

export interface AudioSliderDispatchProps {
  changePlayStatus: ChangePlayStatusActionCreator;
  changeTimestamp: ChangeTimestampActionCreator;
}

export interface AudioSliderOwnProps {
  fieldId?: number;
  isTogether: boolean;
  isActive?: boolean;
}

type AudioSliderStateToProps = (state: AppState) => AudioSliderStateProps;

type AudioSliderDispatchToProps = (dispatch: Dispatch<Action>) => AudioSliderDispatchProps;

const mapStateToProps: AudioSliderStateToProps = state => ({
  uniquePlaybackId: state.sounds?.uniquePlaybackId,
  playStatus: state.sounds!.playStatus,
  activeSound: state.sounds!.activeSound
    ? state.sounds!.sounds![state.sounds!.activeSound]
    : undefined,
  timestamp: state.sounds!.timestamp || 0
});

const mapDispatchToProps: AudioSliderDispatchToProps = dispatch => ({
  changePlayStatus: (status: PlayStatus) => dispatch(changePlayStatus(status)),
  changeTimestamp: (timestamp: number) => dispatch(changeTimestamp(timestamp))
});

export default connect<AudioSliderStateProps, AudioSliderDispatchProps, AudioSliderOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(AudioSlider);
