import React, {useCallback, useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {push} from 'store/router';
import UnitPreviewContainer from 'components/modals/UnitPreview/UnitPreviewContainer';
import {type AppState} from 'store/interface';
import {type UnitInstancePreviewUrlParams, unitInstancePreviewPath} from 'common/paths';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {uuid4RegExp} from 'config/static';

import UnitInstancePreview from './UnitInstancePreview';
import {visibleOnlyForRoles} from '../../../../authentication';
import InvalidUrlId from '../../../../containers/InvalidUrlId';

const UnitPreviewPage: React.FC = () => {
  const dispatch = useAxiosDispatch();

  const params = useParams<UnitInstancePreviewUrlParams>() as UnitInstancePreviewUrlParams;
  const {coursebookInstanceId, unitInstanceId, courseId} = params;

  const activeCourseInstance = useSelector((s: AppState) => s.classroom?.courses?.[courseId!])!;

  const courseInstanceIsActive = !activeCourseInstance?.deleted_at;

  const {coursebookInstanceList} = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState
  );

  const unitInstances = useSelector(
    (state: AppState) => state.classroom!.courseInstanceState.coursebookInstanceState.unitInstances
  );

  const selectedCoursebookInstance = useMemo(
    () => coursebookInstanceList?.find(cbi => cbi.id === coursebookInstanceId)!,
    [coursebookInstanceId, coursebookInstanceList]
  );
  const uiId = Number(unitInstanceId);
  const selectedUnitInstance = useMemo(
    () => unitInstances?.find(ui => ui.id === uiId)!,
    [unitInstances, uiId]
  );

  const location = useLocation();
  const hash = location.hash.substring(1);
  const scrollToExerciseId = hash.length && uuid4RegExp.test(hash) ? hash : undefined;

  const unitId = selectedUnitInstance.unit.id;

  const changePage = useCallback(
    (page: number) => {
      dispatch(push(unitInstancePreviewPath(params, uiId, page)));
    },
    [dispatch, params, uiId]
  );

  const pageNumber = params.page !== undefined ? Number(params.page) : undefined;

  return (
    <UnitPreviewContainer
      unitId={unitId}
      coursebookId={selectedCoursebookInstance.coursebook.id}
      courseInstanceIsActive={courseInstanceIsActive}
      Component={UnitInstancePreview}
      changePage={changePage}
      pageNumber={pageNumber}
      scrollToExerciseId={scrollToExerciseId}
    />
  );
};

export default visibleOnlyForRoles('teacher', InvalidUrlId)(UnitPreviewPage);
