import React from 'react';
import {injectIntl, type WrappedComponentProps, defineMessages} from 'react-intl';

import {type UnitInstance} from 'store/interface';

import PieProgress from '../../../../components/PieProgress';

interface Props extends WrappedComponentProps {
  instance: UnitInstance;
}

const unitInstanceProgressMessages = defineMessages({
  ExercisesCompleted: {
    id: 'LessonPage.ExercisesCompleted'
  }
});

class UnitInstanceProgress extends React.Component<Props> {
  public render() {
    const {
      intl: {formatMessage},
      instance: {
        overview: {mainExercisesCount, mainExercisesCompleted}
      }
    } = this.props;
    return (
      <PieProgress
        size={14}
        total={mainExercisesCount}
        filled={mainExercisesCompleted}
        title={formatMessage(unitInstanceProgressMessages.ExercisesCompleted, {
          closed: mainExercisesCompleted,
          total: mainExercisesCount,
          percentage: Math.round(mainExercisesCompleted / (mainExercisesCount / 100))
        })}
      />
    );
  }
}

export default injectIntl(UnitInstanceProgress);
