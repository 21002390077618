import {generatePath, useMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useCallback, useEffect} from 'react';

import {push, replace} from 'store/router';
import {type FilesListSelectedDocPageUrlParams, filesListSelectedDocPattern} from 'common/paths';

interface Params extends FilesListSelectedDocPageUrlParams {
  pageNumber: string;
  page: 'page';
}

export const usePDFRoutePageNumber = (
  propsPageNumber?: number
): [number, (pageNumber: number) => void] => {
  const match = useMatch<keyof Params, string>({path: filesListSelectedDocPattern, end: false});

  const params = match?.params as Params;
  const p = match?.params?.pageNumber;
  const page = match?.params?.page;
  const path = match?.pattern?.path;

  const dispatch = useDispatch();
  const pageNumber = Number(p) || 1;
  const hasUrlParam = params?.hasOwnProperty('pageNumber');
  const hasUrlPageParam = params?.hasOwnProperty('page');

  const setPageNumber = useCallback(
    (pageNumber: number) => {
      const action = !hasUrlPageParam || page !== 'page' ? replace : push;
      path &&
        dispatch(
          action(
            generatePath(path, {
              ...params,
              pageNumber: Math.max(1, pageNumber),
              page: page || 'page'
            })
          )
        );
    },
    [dispatch, hasUrlPageParam, page, params, path]
  );

  useEffect(() => {
    if (
      !propsPageNumber &&
      (!p || !hasUrlPageParam || page !== 'page' || !hasUrlParam || pageNumber < 1)
    ) {
      setPageNumber(pageNumber < 1 ? 1 : pageNumber);
    }
  }, [setPageNumber, pageNumber, hasUrlParam, propsPageNumber, p, hasUrlPageParam, page]);

  return [Number(pageNumber), setPageNumber];
};
