import React, {useCallback, useContext, useRef} from 'react';
import RetinaImage from '@englex/react-retina-image';
import classNames from 'classnames';
import Label from 'react-bootstrap/lib/Label';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Tooltip from 'rc-tooltip';
import {useSelector} from 'react-redux';

import {CoursebookStatus, CoursebookType} from 'common/enums';
import {type AppState} from 'store/interface';
import {type CoursebookItemComponentProps} from 'components/CoursebookLibrary/interface';
import {coursebookLibraryMessages} from 'components/CoursebookLibrary/messages';
import Icon from 'components/Icon';
import EnglexCoursebookCover from 'components/EnglexCoursebookCover';
import OriginalAuthorTooltip from 'components/CoursebookLibrary/components/OriginalAuthorTooltip';

import AbbreviatedProperty from './AbbreviatedProperty';
import {GrammarBankButton} from '../../../components/CoursebookInfoDetailed/GrammarBankButton';
import {AddCoursebookStateManagementContext} from './context';

import './CoursebookItem.scss';

interface Props extends CoursebookItemComponentProps, WrappedComponentProps {}

const CoursebookItem: React.FC<Props> = (props: Props) => {
  const {
    coursebook: {
      cover,
      title,
      type,
      status,
      author: {
        profile: {firstName, lastName, role}
      },
      originalAuthor,
      overview,
      courses,
      levels,
      id,
      hasGrammar
    },
    intl: {formatMessage}
  } = props;
  const showStatus = useSelector<AppState, boolean>(
    state => !!state.settings?.teacher?.vircUnpublishedBookAccess
  );
  const {unitsCount, testsCount, exercisesNumber, unitSectionsNumber} = overview!;
  const tooltipContainer = useRef(null);
  const stateManager = useContext(AddCoursebookStateManagementContext);
  const viewCoursebookContent = useCallback(
    () => stateManager.viewCoursebookContents(id),
    [stateManager, id]
  );
  const viewCoursebookGrammar = useCallback(
    () => stateManager.viewCoursebookGrammar(id),
    [stateManager, id]
  );
  const selectCoursebook = useCallback(() => stateManager.selectCoursebook(id), [stateManager, id]);
  const addCoursebook = useCallback(() => stateManager.addCoursebook(id), [stateManager, id]);
  const isAlreadyAdded = stateManager.isAlreadyAdded(id);
  const statusPublished = status === CoursebookStatus.PUBLISHED;

  return (
    <div className="add-coursebook-modal-item">
      <div className="book-cover">
        {cover ? (
          <>
            <div className="mask" />
            <RetinaImage src={cover.urls} />
          </>
        ) : (
          <EnglexCoursebookCover fontSize={6} />
        )}
      </div>
      <div className="content">
        <div className="header">
          <h4 title={title} className="coursebook-title">
            {title}
          </h4>
          <Label
            bsStyle="info"
            className={classNames({'label-transparent': type === CoursebookType.PROPRIETARY})}
          >
            {type === CoursebookType.COMMON
              ? formatMessage(coursebookLibraryMessages.CoursebookTypeCommon)
              : formatMessage(coursebookLibraryMessages.CoursebookTypeProprietary)}
          </Label>
          {showStatus && (
            <Label bsStyle={statusPublished ? 'success' : 'danger'}>
              {statusPublished
                ? formatMessage(coursebookLibraryMessages.StatusPublished)
                : formatMessage(coursebookLibraryMessages.StatusHidden)}
            </Label>
          )}
        </div>

        <div className="body" ref={tooltipContainer}>
          <div className="property-line">
            <div className="property">
              <b>{formatMessage(coursebookLibraryMessages.Author)}:</b>
              {role === 'teacher'
                ? ` ${lastName} ${firstName}`
                : ` ${formatMessage(coursebookLibraryMessages.EnglexDepartmentOfMethodology)}`}
              {originalAuthor ? <OriginalAuthorTooltip originalAuthor={originalAuthor} /> : null}
            </div>
            <div className="property units">
              <b>{formatMessage({id: 'ViewCoursebookContentsModal.Units'})}:</b>
              {' ' + unitsCount}
            </div>
            <div className="property tests">
              <b>{formatMessage({id: 'Coursebook.Tests'})}:</b>
              {' ' + testsCount}
            </div>
            <div className="property exercises">
              <b>{formatMessage({id: 'Coursebook.Exercises'})}:</b>
              {' ' + exercisesNumber}
            </div>
          </div>

          <div className="property-line">
            <div className="property">
              <b>{formatMessage(coursebookLibraryMessages.Course)}:</b>
              <AbbreviatedProperty propertyList={courses.map(course => course.name)} />
            </div>

            <div className="property">
              <b>{formatMessage(coursebookLibraryMessages.Level)}:</b>
              <AbbreviatedProperty propertyList={levels.map(level => level.title)} />
            </div>
          </div>

          <div className="property-line course-info">
            <div>
              <b>{formatMessage({id: 'ViewCoursebookContentsModal.Units.Short'})}:</b>
              {' ' + unitsCount}
            </div>
            <div>
              <b>{formatMessage({id: 'Coursebook.Tests.Short'})}:</b>
              {' ' + testsCount}
            </div>
            <div>
              <b>{formatMessage({id: 'Coursebook.Exercises.Short'})}:</b>
              {' ' + exercisesNumber}
            </div>
          </div>

          <div className="controls">
            {unitSectionsNumber ? (
              <Tooltip
                overlay={<FormattedMessage id="AddCoursebookModal.Program" />}
                overlayClassName="add-coursebook-btn-tooltip "
                placement="top"
                defaultVisible={false}
                trigger={['hover']}
              >
                <Button bsSize="sm" onClick={viewCoursebookContent}>
                  <Icon name="sitemap" /> <FormattedMessage id="AddCoursebookModal.Program" />
                </Button>
              </Tooltip>
            ) : null}

            {hasGrammar && (
              <GrammarBankButton
                overlayClassName="add-coursebook-btn-tooltip"
                previewHasGrammar={hasGrammar}
                onShowGrammarClick={viewCoursebookGrammar}
              />
            )}

            <Tooltip
              overlay={<FormattedMessage id="AddCoursebookModal.View" />}
              overlayClassName="add-coursebook-btn-tooltip"
              placement="top"
              defaultVisible={false}
              trigger={['hover']}
            >
              <Button bsSize="sm" onClick={selectCoursebook}>
                <Icon name="play-circle" /> <FormattedMessage id="AddCoursebookModal.View" />
              </Button>
            </Tooltip>

            {isAlreadyAdded ? null : (
              <Tooltip
                overlay={<FormattedMessage id="AddCoursebookModal.Add" />}
                overlayClassName="add-coursebook-btn-tooltip"
                placement="top"
                defaultVisible={false}
                trigger={['hover']}
              >
                <Button bsSize="sm" bsStyle="success" onClick={addCoursebook}>
                  <Icon name="plus" /> <FormattedMessage id="AddCoursebookModal.Add" />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CoursebookItem);
