import React, {type SyntheticEvent} from 'react';
import {FormattedMessage, type WrappedComponentProps} from 'react-intl';
import {default as FormControl, type FormControlProps} from 'react-bootstrap/lib/FormControl';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import {type Role} from 'store/interface';
import {openTogetherAwaitTime} from 'config/static';
import {FileExtension} from 'common/enums';

import {type DocumentFile} from '../../documentsTab/actions/interface';
import {type Sound} from '../../soundsTab/actions/interface';
import {type PublishMaterialsOpenCreator} from '../../../../actions/interface';
import {fileIcon} from '../../../../../../helpers/file';
import {type PublishMaterialsOpen} from '../interface';
import {fileMessages} from '../../messages';

interface GenericFileControlStateProps {
  thisFileId: number;
  fileIsBeingEdited?: true;
  openTogetherFileId?: number;
  thisFile?: Sound | DocumentFile;
  editFileAwait?: boolean;
  readonly: boolean;
  role: Role;
}

interface GenericFileControlDispatchProps {
  editFileName: (id: number, title: string) => Promise<{}>;
  changeActiveFile: (id: number | null) => void;
  clearOpenedTogether: () => void;
  openEditor: (id: number | null) => void;
}

interface GenericFileControlOwnProps {
  fileIsActive: boolean;
  pinnedBlock?: boolean;
  publishMaterialsOpen: PublishMaterialsOpenCreator | PublishMaterialsOpen;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  deletingFileComponentId?: string;
}

export interface GenericFileControlState {
  componentBeingEdited: boolean;
  dropDownIsOpen: boolean;
  newName: string;
  nameIsValid: boolean;
}

export interface GenericFileControlProps
  extends GenericFileControlStateProps,
    GenericFileControlDispatchProps,
    GenericFileControlOwnProps {}

export default class GenericFileControl<
  P extends GenericFileControlProps & WrappedComponentProps,
  S extends GenericFileControlState
> extends React.PureComponent<P, S> {
  protected static fileNameMaxLength = 255;

  protected fileNameEl: HTMLSpanElement;
  protected playTogetherTimeout?: NodeJS.Timeout;
  protected componentId: string;

  constructor(props: P, context: {}) {
    super(props, context);
    this.componentId = `${props.thisFileId}${props.pinnedBlock ? '-pinned' : ''}`;
    this.state = {
      componentBeingEdited: false,
      dropDownIsOpen: false,
      newName: '',
      nameIsValid: true
    } as S;
  }

  public componentDidUpdate(prevProps: P) {
    if (
      !this.props.openTogetherFileId &&
      prevProps.openTogetherFileId &&
      this.playTogetherTimeout
    ) {
      clearTimeout(this.playTogetherTimeout);
    }
    if (
      prevProps.fileIsBeingEdited &&
      !this.props.fileIsBeingEdited &&
      this.state.componentBeingEdited
    ) {
      this.setState({componentBeingEdited: false});
    }
  }

  public componentWillUnmount() {
    const {clearOpenedTogether} = this.props;
    if (this.playTogetherTimeout) {
      clearTimeout(this.playTogetherTimeout);
      clearOpenedTogether();
    }

    if (this.props.fileIsBeingEdited) {
      this.props.openEditor(null);
    }
  }

  protected handleControlClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (this.props.fileIsBeingEdited) {
      return;
    }
    // if user has selected file name, we shouldn't open file
    const userSelection = window.getSelection();
    if (!userSelection) {
      return;
    }
    if (userSelection.toString().length) {
      const selectionRange = userSelection.getRangeAt(0);
      const parentNode = selectionRange.startContainer.parentNode;
      if (parentNode && this.fileNameEl && parentNode === this.fileNameEl) {
        e.stopPropagation();
        return;
      }
    }
    this.openFile();
  };

  protected renderFileName = () => {
    if (this.props.thisFile && !this.state.componentBeingEdited) {
      const fileName = this.props.thisFile.title;
      const showIcon = this.props.thisFile.type !== FileExtension.mp3;
      return (
        <span className="file-name" title={fileName} ref={this.fileNameRef}>
          <Icon name={fileIcon(this.props.thisFile.type)} className="file-icon" show={showIcon} />
          {fileName}
        </span>
      );
    }
    return null;
  };

  protected fileNameRef = (el: HTMLSpanElement) => el && (this.fileNameEl = el);

  protected renderEditor = () => {
    const [{fileIsBeingEdited, readonly, role}, {componentBeingEdited, newName}] = [
      this.props,
      this.state
    ];
    if (fileIsBeingEdited && !readonly && role === 'teacher' && componentBeingEdited) {
      return (
        <FormControl
          autoFocus={true}
          componentClass="input"
          value={newName}
          onChange={this.editorChangeHandler}
          onKeyDown={this.editorKeyDownHandler}
        />
      );
    }
    return null;
  };

  protected renderNotificationCircle = () => {
    const {thisFile} = this.props;
    return thisFile && thisFile.isNew ? <span className="notification-circle" /> : null;
  };

  protected openFile() {
    const {thisFileId, fileIsActive, changeActiveFile, deletingFileComponentId} = this.props;
    this.setState({dropDownIsOpen: false});
    if (!fileIsActive && !deletingFileComponentId) {
      changeActiveFile(thisFileId);
    }
  }

  protected editIconClickHandler = () => {
    const {deletingFileComponentId, thisFile, openEditor, thisFileId} = this.props;
    if (!deletingFileComponentId && thisFile) {
      this.setState({
        componentBeingEdited: true,
        newName: thisFile.title,
        nameIsValid: true
      });
      openEditor(thisFileId);
    }
  };

  protected saveEditingChanges = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    this.handleEditSend();
  };

  protected discardEditingChanges = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (!this.props.editFileAwait) {
      this.props.openEditor(null);
      this.setState({componentBeingEdited: false});
    }
  };

  protected handleEditSend = () => {
    const {newName, nameIsValid} = this.state;
    const {intl, thisFileId, editFileName, publishMaterialsUpdated} = this.props;
    if (nameIsValid) {
      const $newName = newName.trim();
      editFileName(thisFileId, $newName).then(
        () => {
          publishMaterialsUpdated();
          toastr.success('', intl.formatMessage(fileMessages.EditSuccessToast));
          this.setState({componentBeingEdited: false});
        },
        () => {
          toastr.error('', intl.formatMessage(fileMessages.EditErrorToast));
        }
      );
    }
  };

  protected editorChangeHandler = (e: SyntheticEvent<FormControlProps>) => {
    this.setState({newName: e.currentTarget.value} as GenericFileControlState, () => {
      this.validateFileName();
    });
  };

  protected editorKeyDownHandler = (e: React.KeyboardEvent<FormControl>) => {
    const [{openEditor}, enterKeyCode, escapeKeyCode] = [this.props, 13, 27];
    switch (e.keyCode) {
      case enterKeyCode:
        this.handleEditSend();
        break;
      case escapeKeyCode:
        this.setState({componentBeingEdited: false});
        openEditor(null);
        break;
      default:
        break;
    }
  };

  protected validateFileName = () => {
    const {newName} = this.state;
    if (newName.trim().length > 0 && newName.trim().length < GenericFileControl.fileNameMaxLength) {
      this.setState({nameIsValid: true});
    } else {
      this.setState({nameIsValid: false});
    }
  };

  protected renderDeletingOverlay = () => {
    if (this.props.deletingFileComponentId === this.componentId) {
      return (
        <div className="deleting-overlay" onClick={e => e.stopPropagation()}>
          <Spinner size={18} /> <FormattedMessage id="File.Deleting" />
        </div>
      );
    }
    return null;
  };

  protected openTogether = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!this.props.deletingFileComponentId && !this.props.openTogetherFileId) {
      (this.props.publishMaterialsOpen as PublishMaterialsOpen)(
        this.props.thisFileId,
        this.componentId
      );
      this.playTogetherTimeout = setTimeout(() => {
        this.props.clearOpenedTogether();
        this.playTogetherTimeout = undefined;
        toastr.error('', this.props.intl.formatMessage(fileMessages.PlayTogetherErrorToast));
      }, openTogetherAwaitTime);
    }
  };
}
