import React, {type MutableRefObject, useCallback, useEffect, useMemo, useRef} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {FormattedMessage} from 'react-intl';
import {type ScrollerProps, Virtuoso, type VirtuosoHandle} from 'react-virtuoso';

import {type LibraryNode, type LibrarySearchItem} from './interface';
import {searchTree} from './utils';
import {NotFound} from './NotFound';

import './library-search-result.scss';

interface Props {
  search: string;
  index?: number;
  library: LibraryNode;
  renderItem: (item: LibrarySearchItem, index: number, className: string) => React.ReactElement;
}

export const SearchResult: React.FC<Props> = React.memo(({search, index, library, renderItem}) => {
  const virtuoso = useRef<VirtuosoHandle>(null);

  const list = useMemo(() => {
    const searchWords = search.split(/[\s,]+/);
    return searchTree(searchWords, library);
  }, [search, library]);

  const renderItemContent = useCallback(
    (index: number) => renderItem(list[index], index, 'search-result__item'),
    [list, renderItem]
  );

  useEffect(() => {
    if (index) {
      virtuoso.current?.scrollToIndex({index});
    }
  }, [index]);

  if (list.length === 0) {
    return <NotFound />;
  }

  return (
    <div className="search-result">
      <div className="search-result__text">
        <FormattedMessage id="LibraryModal.FoundFiles" values={{count: list.length}} />
      </div>

      <div className="search-result__list">
        <Virtuoso
          ref={virtuoso}
          totalCount={list.length}
          itemContent={renderItemContent}
          components={{
            Scroller: CustomScroll
          }}
        />
      </div>
    </div>
  );
});

const CustomScroll = React.forwardRef<HTMLDivElement, ScrollerProps>(
  ({children, ...props}, ref) => {
    const refSetter = useCallback(
      scrollbarsRef => {
        if (scrollbarsRef) {
          (ref as MutableRefObject<HTMLDivElement>).current = scrollbarsRef.view;

          // These attributes are required for Virtuoso Scroller component.
          scrollbarsRef.view.setAttribute('data-test-id', 'virtuoso-scroller');
          scrollbarsRef.view.setAttribute('data-virtuoso-scroller', true);
        }
      },
      [ref]
    );

    return (
      <Scrollbars
        ref={refSetter}
        autoHide={true}
        autoHeight={true}
        autoHeightMin={274}
        autoHeightMax={'calc(100vh - 277px)'}
      >
        {children}
      </Scrollbars>
    );
  }
);
