import React, {useCallback, useState} from 'react';

import CoursebookLibrary from 'components/CoursebookLibrary/containers/CoursebookLibrary';
import {type Course, type CoursebookFilter, type LanguageLevel} from 'store/interface';
import {type BooleanEnum, type CoursebookAuthorRole} from 'common/enums';

import CoursebookItem from './CoursebookItem';
import {defaultCoursebookFilter, emptyCoursebookFilter} from './defaultFilter';

interface Props {
  filter: CoursebookFilter;
  changeFilter: (filter: CoursebookFilter) => void;
  hasUnpublishedBookAccess: boolean;
}

const Library: React.FC<Props> = (props: Props) => {
  const {filter, changeFilter, hasUnpublishedBookAccess} = props;
  const resetFilters = useCallback(() => {
    const coursebookFilter = hasUnpublishedBookAccess
      ? emptyCoursebookFilter
      : defaultCoursebookFilter;

    return changeFilter({...coursebookFilter, title: filter.title});
  }, [changeFilter, filter.title, hasUnpublishedBookAccess]);
  const changeTitleFilter = useCallback(
    (title: string) => changeFilter({...filter, title: title.length ? title : null}),
    [filter, changeFilter]
  );
  const changeRoleFilter = useCallback(
    (role: CoursebookAuthorRole) => changeFilter({...filter, role: role}),
    [filter, changeFilter]
  );
  const changeCoursesFilter = useCallback(
    (courses: number[]) => changeFilter({...filter, courses: courses.length ? courses : null}),
    [filter, changeFilter]
  );
  const changeLevelsFilter = useCallback(
    (levels: number[]) => changeFilter({...filter, levels: levels.length ? levels : null}),
    [filter, changeFilter]
  );
  const changeAuthorFilter = useCallback(
    (id: number | null) => changeFilter({...filter, authorId: id}),
    [filter, changeFilter]
  );
  const changeOriginalAuthorFilter = useCallback(
    (id: number | null) => changeFilter({...filter, originalAuthorId: id}),
    [filter, changeFilter]
  );
  const changePublishedFilter = useCallback(
    (published: BooleanEnum | null) => changeFilter({...filter, published: published}),
    [filter, changeFilter]
  );

  const [availableCourses, setAvailableCourses] = useState<Course[] | undefined>();
  const [availableLevels, setAvailableLevels] = useState<LanguageLevel[] | undefined>();
  return (
    <CoursebookLibrary
      filter={props.filter}
      resetFilters={resetFilters}
      changeTitleFilter={changeTitleFilter}
      changeRoleFilter={changeRoleFilter}
      changeCoursesFilter={changeCoursesFilter}
      changeLevelsFilter={changeLevelsFilter}
      changeAuthorFilter={changeAuthorFilter}
      changeOriginalAuthorFilter={changeOriginalAuthorFilter}
      changePublishedFilter={changePublishedFilter}
      availableLevels={availableLevels}
      availableCourses={availableCourses}
      setAvailableLevels={setAvailableLevels}
      setAvailableCourses={setAvailableCourses}
      title=""
      coursebookItemComponent={CoursebookItem}
    />
  );
};

export default Library;
