import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import Icon from 'components/Icon';
import {type AppState} from 'store/interface';
import './HomeworkDocCommentViewer.scss';

const messages = defineMessages({
  Title: {
    id: 'Files.ViewComment'
  }
});

interface Props extends WrappedComponentProps {
  closeCommentEditor(): void;
  comment: string;
  commentEditorJustClosed?: boolean;
  setCommentEditorJustClosed(flag: boolean): void;
  tellCommentViewerJustClosed(): void;
  closeRequested: boolean;
}

const HomeworkDocCommentViewer: FC<Props> = ({
  intl: {formatMessage},
  closeCommentEditor,
  comment,
  commentEditorJustClosed,
  setCommentEditorJustClosed,
  closeRequested,
  tellCommentViewerJustClosed
}) => {
  const refContainer = useRef(null);
  const commentEditorOpened = useSelector<AppState, boolean>(
    s => (s.docs && !!s.docs.editHomeworkCommentForDocument) || false
  );

  const [visible, setVisible] = useState<boolean | undefined>(false);

  const toggleVisibility = useCallback(() => {
    if (commentEditorOpened) {
      closeCommentEditor();
      return;
    }
    setVisible(!visible);
  }, [closeCommentEditor, commentEditorOpened, visible]);

  useEffect(() => {
    if (closeRequested) {
      setVisible(false);
      tellCommentViewerJustClosed();
    }
  }, [closeRequested, tellCommentViewerJustClosed]);

  useEffect(() => {
    if (commentEditorJustClosed) {
      setCommentEditorJustClosed(false);
      setVisible(true);
    }
  }, [commentEditorJustClosed, setCommentEditorJustClosed]);

  return (
    <div className="homework-doc-comment-viewer" ref={refContainer}>
      <Tooltip
        overlayClassName="homework-doc-comment-popover"
        overlay={() => (
          <Scrollbars autoHide={true} autoHeight={true} autoHeightMax={142}>
            <div className="comment-text">{comment}</div>
          </Scrollbars>
        )}
        trigger={['click']}
        placement="top"
        visible={visible}
        // this is here to leave possibility to close popover on click outside by keeping
        // property trigger.
        onVisibleChange={v => !v && setVisible(false)}
      >
        <Button
          title={formatMessage(messages.Title)}
          className={classNames('btn-circle', {visible})}
          onClick={toggleVisibility}
        >
          <Icon name="comment" />
        </Button>
      </Tooltip>
    </div>
  );
};

export default injectIntl(HomeworkDocCommentViewer);
