import {defineMessages} from 'react-intl';

const i18n = defineMessages({
  FileIsAlreadyUploading: {
    id: 'LessonPage.FileIsAlreadyUploading'
  },
  UnknownUploadingError: {
    id: 'Common.SomethingWentWrongPleaseTryAgainLater'
  },
  UploadingFailed: {
    id: 'LessonPage.UploadingFailed'
  },
  CloningSuccessful: {
    id: 'LessonPage.CloningSuccessful'
  },
  UploadingSuccessful: {
    id: 'LessonPage.UploadingSuccessful'
  },
  InvalidDocExtension: {
    id: 'LessonPage.InvalidDocExtension'
  },
  InvalidSoundExtension: {
    id: 'LessonPage.InvalidSoundExtension'
  },
  InvalidFileSize: {
    id: 'LessonPage.IvalidFileSize'
  },
  MultipleFilesErrorHeading: {
    id: 'LessonPage.MultipleFilesErrorHeading'
  },
  MultipleFilesError: {
    id: 'LessonPage.MultipleFilesError'
  },
  EmptyFile: {
    id: 'LessonPage.EmptyFile'
  },
  AudioFileTooltip: {
    id: 'LessonPage.AudioFileTooltip'
  },
  DocumentTooltip: {
    id: 'LessonPage.DocumentTooltip'
  }
});

export default i18n;
