import {type FC} from 'react';
import {type PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

import {OutlineItems} from './OutlineItems';
import {type Item} from './interface';
import './Outline.scss';

interface Props {
  pdf: PDFDocumentProxy;
  outline: Item[];
  currentIndex: number;
  goToIndex: (i: number) => void;
}

export const Outline: FC<Props> = ({pdf, outline, goToIndex}) => {
  return (
    <div className="pdf-contents__outline">
      <OutlineItems pdf={pdf} items={outline} goToIndex={goToIndex} />
    </div>
  );
};
